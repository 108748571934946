import {Component, OnInit, ViewChild} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {UnoDynamicFormComponent} from '../../../../components/uno-forms/uno-dynamic-form/uno-dynamic-form.component';
import {App} from '../../../../app';
import {Service} from '../../../../http/service';
import {ServiceList} from '../../../../http/service-list';
import {Session} from '../../../../session';
import {Modal} from '../../../../modal';
import {Locale} from '../../../../locale/locale';
import {UnoDynamicFormModule} from '../../../../components/uno-forms/uno-dynamic-form.module';
import {Observation} from '../../../../models/observations/observation';
import {ObservationsLayout} from '../observations-layout';
import {ScreenComponent} from '../../../../components/screen/screen.component';
import {UserPermissions} from '../../../../models/users/user-permissions';
import {UnoButtonComponent} from '../../../../components/uno/uno-button/uno-button.component';
import {UnoContentComponent} from '../../../../components/uno/uno-content/uno-content.component';

@Component({
	selector: 'create-observation',
	templateUrl: 'observations-create.page.html',
	standalone: true,
	imports: [UnoContentComponent, UnoDynamicFormModule, UnoButtonComponent, TranslateModule]
})
export class ObservationsCreatePage extends ScreenComponent implements OnInit {
	
	public get app(): any { return App; }

	public get layout(): any { return ObservationsLayout; }

	public permissions = [UserPermissions.OBSERVATIONS_CREATE];

	/**
	 * Observation data that will be sent to the API.
	 */
	public observation: any = new Observation();

	@ViewChild('form', {static: false})
	public form: UnoDynamicFormComponent = null;

	public ngOnInit(): void {
		super.ngOnInit();
		
		this.observation = new Observation();

		App.navigator.setTitle('create');
	}

	/**
	 * Create task in the API, send the task information to the server.
	 */
	public async create(): Promise<void> {
		if (!this.form.requiredFilled()) {
			Modal.alert(Locale.get('error'), Locale.get('requiredFieldsError'));
			return;
		}

		await Service.fetch(ServiceList.observation.create, null, null, this.observation, Session.session);
		Modal.toast(Locale.get('observationCreated'));
		App.navigator.pop();
	}
}
