/**
 * List of possible status for the repair inspection entries.
 *
 * Status is used to control the flow of the inspections.
 */
export const RepairInspectionStatus = {
	ALL: -1,

	NONE: 0,

	/**
	 * Inspection still not started waiting.
	 */
	TODO: 1,

	/**
	 * Inspection waiting approval.
	 */
	WAITING_APPROVAL: 2,

	/**
	 * Inspection is completed and no further events will happen.
	 */
	COMPLETED: 3
};

/**
 * List of possible status for the repair entries.
 */
export const RepairInspectionStatusLabel: Map<number, string> = new Map([
	[RepairInspectionStatus.ALL, 'all'],
	[RepairInspectionStatus.NONE, 'none'],
	[RepairInspectionStatus.TODO, 'todo'],
	[RepairInspectionStatus.WAITING_APPROVAL, 'waitingApproval'],
	[RepairInspectionStatus.COMPLETED, 'completed']
]);
