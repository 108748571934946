/**
 * FFP recommendation data.
 *
 * Each FPP has a list of recommendations of the actions that should be taken in the ActionPlan.
 *
 * Each FFP has a set of recommendations attached. FFP may be later aggregated by recommendation to create Action Plans.
 */
export class FFPRecommendation {
	/**
	 * Label show to the user.
	 */
	public label: string = '';

	/**
	 * Numeric ID of the FFP action.
	 */
	public id: number = 0;

	/**
	 * Parse the data of an API retrieved FFP object.
	 *
	 * @param data - Object to parse data from.
	 * @returns An FFP recommendation with parsed data.
	 */
	public static parse(data: any): FFPRecommendation {
		const recommendation = new FFPRecommendation();

		recommendation.id = data.id;
		recommendation.label = data.label;

		return recommendation;
	}
}

