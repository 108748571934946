import {Locale} from 'src/app/locale/locale';
import {SortDirection} from 'src/app/utils/sort-direction';
import {UnoFormUtils} from '../../../../components/uno-forms/uno-dynamic-form/uno-form-utils';
import {UnoFormField, OptionsDisplayMode, OptionsDisplayModeLabel} from '../../../../components/uno-forms/uno-dynamic-form/uno-form-field';
import {UnoFormFieldTypes} from '../../../../components/uno-forms/uno-dynamic-form/uno-form-field-types';
import {InspectionFormFieldType, InspectionFormFieldTypeLabel} from '../../data/form/inspection-form-field-type';
import {Service} from '../../../../http/service';
import {ServiceList} from '../../../../http/service-list';
import {Session} from '../../../../session';
import {InspectionForm} from '../../../../models/inspections/form/inspection-form';
import {CheckBoxGapOptions, CheckBoxGapOptionslabel} from '../../../../models/inspections/form/inspection-form-field';
import {InspectionFormService} from '../../services/inspection-form.service';


export const InspectionFormLayout: UnoFormField[] = [
	{
		label: 'uuid',
		attribute: 'uuid',
		type: UnoFormFieldTypes.UUID
	},
	{
		required: true,
		attribute: 'name',
		label: 'name',
		type: UnoFormFieldTypes.TEXT
	},
	{
		required: false,
		attribute: 'description',
		label: 'description',
		type: UnoFormFieldTypes.TEXT_MULTILINE
	}
];

export const InspectionFormFieldLayout: UnoFormField[] = [
	{
		label: 'uuid',
		attribute: 'uuid',
		type: UnoFormFieldTypes.UUID
	},
	{
		required: true,
		label: 'type',
		attribute: 'type',
		type: UnoFormFieldTypes.OPTIONS,
		sort: false,
		isEmpty: function(object) {
			return object.type === InspectionFormFieldType.NONE;
		},
		options: Object.values(InspectionFormFieldType).map(function(value) {
			return {value: value, label: InspectionFormFieldTypeLabel.get(value)};
		}),
		onChange: function(object: any) {
			object.initializeData();
		}
	},
	{
		required: true,
		attribute: 'text',
		label: 'text',
		type: UnoFormFieldTypes.TEXT
	},
	{
		required: false,
		attribute: 'label',
		label: 'label',
		type: UnoFormFieldTypes.TEXT,
		isActive: function(object, row) { return object.type !== InspectionFormFieldType.TITLE; }
	},
	{
		required: true,
		attribute: 'required',
		label: 'required',
		type: UnoFormFieldTypes.CHECKBOX,
		isActive: function(object, row) { return object.type !== InspectionFormFieldType.TITLE; }
	},
	{
		required: true,
		isActive: function(object, row) { return object.type === InspectionFormFieldType.OPTIONS || object.type === InspectionFormFieldType.OPTIONS_MULTIPLE; },
		attribute: 'optionsDisplayMode',
		label: 'optionsDisplayMode',
		sort: false,
		type: UnoFormFieldTypes.OPTIONS,
		isEmpty: function(object) {
			return !object.optionsDisplayMode;
		},
		options: Object.values(OptionsDisplayMode).map(function(value) {
			return {value: value, label: OptionsDisplayModeLabel.get(value)};
		})
	},
	{
		required: false,
		isActive: function(object, row) { return object.type === InspectionFormFieldType.OPTIONS; },
		label: 'defaultOption',
		attribute: 'data.defaultOptionUuid',
		type: UnoFormFieldTypes.OPTIONS,
		translate: false,
		options: [{label: 'empty', value: null}]
	},
	{
		required: false,
		isActive: function(object, row) { return object.type === InspectionFormFieldType.OPTIONS || object.type === InspectionFormFieldType.OPTIONS_MULTIPLE; },
		label: 'options',
		attribute: 'data.options',
		type: UnoFormFieldTypes.KEY_VALUE_ARRAY,
		onChange: (object: any, row: UnoFormField, value: any, layout: UnoFormField[]): void => {
			const gapMultipleOptions: UnoFormField = UnoFormUtils.getFormFieldByAttribute(layout, 'gapRule.options');
			const defaultOptionsField: UnoFormField = UnoFormUtils.getFormFieldByAttribute(layout, 'data.defaultOptionUuid');

			// Update list of default options to be selected when a new entry option is added or modified
			if (object.type === InspectionFormFieldType.OPTIONS || object.type === InspectionFormFieldType.OPTIONS_MULTIPLE) {
				if (value instanceof Array) {
					// Add empty option
					let options: any[] = [{label: Locale.get('empty'), value: null}];
					
					const gapOptions = value.map((d: any) => {
						return {label: d.value, value: d.key};
					});
				
					// Build options from selection from the received array of values
					options = options.concat(gapOptions);

					defaultOptionsField.options = options;
					gapMultipleOptions.options = gapOptions;
				}
			}
		}
	},
	{
		required: false,
		attribute: 'generatesGaps',
		label: 'generatesGaps',
		type: UnoFormFieldTypes.CHECKBOX,
		isActive: function(object, row) { return object.type === InspectionFormFieldType.OPTIONS || object.type === InspectionFormFieldType.OPTIONS_MULTIPLE || object.type === InspectionFormFieldType.CHECKBOX || object.type === InspectionFormFieldType.SUB_FORM || object.type === InspectionFormFieldType.COMPOSED_FIELD || object.type === InspectionFormFieldType.MULTIPLE_FORMS; },
		onChange: function(object, row) {
			object.initializeGapRule();
		}
	},
	{
		required: true,
		attribute: 'gapRule.checked',
		label: 'generatesGapsOptions',
		type: UnoFormFieldTypes.OPTIONS,
		translate: false,
		isActive: function(object, row) { return object.type === InspectionFormFieldType.CHECKBOX && object.generatesGaps === true; },
		options: Object.values(CheckBoxGapOptions).map(function(value) {
			return {value: value, label: CheckBoxGapOptionslabel.get(value)};
		})
	},
	{
		required: true,
		attribute: 'gapRule.options',
		label: 'generatesGapsMultipleOptions',
		translate: false,
		type: UnoFormFieldTypes.OPTIONS_MULTIPLE,
		isActive: function(object, row) { return (object.type === InspectionFormFieldType.OPTIONS || object.type === InspectionFormFieldType.OPTIONS_MULTIPLE) && object.generatesGaps === true; },
		options: []
	},
	{
		required: true,
		isActive: function(object, row) { return object.isSubform();},
		attribute: 'subFormUuid',
		label: 'form',
		type: UnoFormFieldTypes.OPTIONS_MULTIPLE_LAZY,
		multiple: false,
		identifierAttribute: 'uuid',
		fetchOptionsLazy: async function(request: any): Promise<void> {
			const data = {
				from: request.from,
				count: request.count,
				search: request.search,
				sortField: '[inspection_form].[name]',
				sortDirection: SortDirection.ASC
			};

			try {
				const req = await InspectionFormService.list(data);
				request.onFinish(req.forms, req.hasMore, req.id);
			} catch {
				request.onError();
			}
		},
		fetchOptionsBatch: async function(request: any): Promise<void> {
			const data = {forms: request.options};

			try {
				const req = await Service.fetch(ServiceList.inspection.form.listBatch, null, null, data, Session.session);
				request.onFinish(req.response.forms);
			} catch {
				request.onError();
			}
		},
		getOptionText: function(option: InspectionForm): string {
			return option.name;
		}
	}
];
