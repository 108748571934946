import {UUID, UUIDIdentification} from '../../uuid';

/**
 * Inspection fields stores the values inserted by the user for each inspection form field.
 *
 * The fields are stored by their UUID.
 *
 * When sub-forms are used the UUID of the field is used with an object that contains the fields from the sub-form.
 */
export type InspectionDataFields = {[key: string]: any};

/**
 * Inspection data type presents a specific field in inspection data.
 *
 * The path is composed of the UUID of the sub-forms used in the data object.
 *
 * Can also contain indexes for repeated form field.
 *
 * (e.g. [XXXX-XXX-XXX-XXX, 0, XXXX-XXX-XXX-XXX]
 *
 * The last UUID in the path always points to a field that does not contain sub-form attached.
 */
export type InspectionDataPath = (UUID|number)[];

/**
 * Stores data of an inspection for a specific step.
 *
 * The data of the form attached to the inspection step in stored in this structure.
 */
export class InspectionData extends UUIDIdentification {
	/**
	 * Inspection that this data belongs to.
	 */
	public inspectionUuid: UUID = null;

	/**
	 * Workflow step relative to this inspection data.
	 *
	 * Used to read the inspection form fields.
	 */
	public stepUuid: UUID = null;

	/**
	 * Revision of the inspection data, indicates how many times the inspection has been saved in this step.
	 */
	public revision: number = 0;

	/**
	 * Actual inspection data for the form attached to the workflow step.
	 *
	 * Each field response is stored in data by the UUID of the InspectionFormField object associated.
	 */
	public data: InspectionDataFields = {};

	/**
	 * Marks the inspection data as "dirty" and it should not be taken into account for required filled check.
	 * 
	 * This may happen when the inspection step data had missing required fields upon update but the inspection required filled check was bypassed and inspection transited to a different step anyway.
	 */
	public missingRequiredFields: boolean = false;

	/**
	 * Parse data into InspectionData object.
	 *
	 * @param data - Data to be parsed.
	 * @returns Object of the correct type.
	 */
	public static parse(data: any): InspectionData {
		const inspection = new InspectionData();

		inspection.uuid = data.uuid;
		inspection.createdAt = new Date(data.createdAt);
		inspection.updatedAt = new Date(data.updatedAt);

		inspection.inspectionUuid = data.inspectionUuid;
		inspection.revision = data.revision;
		inspection.stepUuid = data.stepUuid;
		inspection.data = data.data || {};
		inspection.missingRequiredFields = data.missingRequiredFields;

		return inspection;
	}

	/**
	 * Gets field data entry from its path.
	 *
	 * Each step in the path represents a sub-form.
	 * 
	 * @param data - The data to look the data for.
	 * @param path - The path to follow to reach the data, by UUID and/or number.
	 * @returns The found data or undefined;
	 */
	public static getDataByPath(data: InspectionDataFields, path: InspectionDataPath): any {
		if (path.length === 0) {
			throw new Error('Path cannot be empty, no elements in path');
		}

		for (const i in data) {
			if (i === path[0]) {
				if (path.length === 1) {
					return data[i];
				} else {
					return InspectionData.getDataByPath(data[i], path.slice(1));
				}
			}
		}

		throw new Error('Path entry ' + path[0] + ' not found in data.');
	}
}
