import {Component, Input} from '@angular/core';
import {IonicModule} from '@ionic/angular';
import {NgStyle} from '@angular/common';
import {Resource} from '../../../../../models/resource';
import {App} from '../../../../../app';
import {CompanyListPage, HierarchicalCompany} from '../company-list.page';
import {UnoListItemLabelComponent} from '../../../../../components/uno/uno-list-item/uno-list-item-label.component';
import {UnoListItemIconComponent} from '../../../../../components/uno/uno-list-item/uno-list-item-icon.component';
import {UnoListItemComponent} from '../../../../../components/uno/uno-list-item/uno-list-item.component';
import {ResourceUtils} from '../../../../../utils/resource-utils';

/**
 * Company list item is used to display a company (with its subcompanies on a collapsable tree list)
 */
@Component({
	selector: 'company-list-item',
	templateUrl: 'company-list-item.component.html',
	standalone: true,
	imports: [UnoListItemComponent, UnoListItemIconComponent, NgStyle, IonicModule, UnoListItemLabelComponent]
})
export class CompanyListItemComponent {
	public get resource(): any { return Resource; }
	
	@Input()
	public company: HierarchicalCompany = null;

	/**
	 * Level of this object in the tree.
	 */
	@Input()
	public level: number = 1;

	/**
	 * If true the tree list is expanded to show its children.
	 */
	public expanded: boolean = false;

	
	/**
	 * Check if the tree list node has children.
	 */
	public hasChildren(): boolean {
		return this.company.children && this.company.children.length > 0;
	}


	/**
	 * Method called when the node is clicked, to open the company edit page.
	 */
	public editCompany(): void {
		App.navigator.navigate('/menu/companies/edit', {uuid: this.company.uuid});
	}

	/**
	 * Toggle the value of expand var, making template expand/collapse
	 * 
	 * May require data to be loaded lazily.
	 */
	public async expand(event: MouseEvent): Promise<void> {
		// Lazy load data if method available.
		if (this.company.hasChildren && !this.company.children) {
			this.company.children = await CompanyListPage.loadData(this.company.uuid);
		}

		// Toggle expanded flag
		this.expanded = !this.expanded;

		// Cancel event bubble to prevent navigation
		if (event !== undefined) {
			event.preventDefault();
			event.cancelBubble = true;
		}
	}

	protected readonly ResourceUtils = ResourceUtils;
}
