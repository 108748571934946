<div style="height: fit-content; margin-top: 10px;">
	<!-- Search Bar -->
	@if (this.searchFilter !== null) {
		<uno-searchbar style="width: 100%;" [value]="this.filters[this.searchFilter]" (valueChange)="this.onSearchChange($event)"></uno-searchbar>
	}

	<!-- Filters -->
	<div style="display: flex; flex-direction: row; align-items: flex-start; width: 100%; height: 70px; gap: 5px; overflow-x: auto; overflow-y: hidden;">
		@for (f of this.options; track f) {
			<div style="display: flex; flex-direction: column;max-width: 200px;">
				@if (f.type === filterType.OPTIONS) {
					<uno-text size="small">{{f.label | translate}}</uno-text>
					<ion-select [multiple]="f.multiple === true" (ionChange)="this.onChange.emit(this.filters)" [(ngModel)]="this.filters[f.attribute]" interface="{{app.device.isMobile() ? 'alert' : 'popover'}}" [style]="{'min-width': '100px', 'width': '100%', 'max-width': '300px'}" fill="outline">
						@for (o of f.options; track o) {
							<ion-select-option [value]="o.value">{{o.label | translate}}</ion-select-option>
						}
					</ion-select>
				}
				@else if (f.type === filterType.OPTIONS_LAZY) {
					<uno-text size="small">{{f.label | translate}}</uno-text>
					<options-multiple-lazy [fetchOptionsBatch]="f.fetchOptionsBatch" [fetchOptionsLazy]="f.fetchOptionsLazy" [getOptionText]="f.getOptionText" [identifierAttribute]="f.identifierAttribute" [multiple]="f.multiple" [placeholder]="'all' | translate" [style]="{'min-width': '100px', 'width': '100%', 'max-width': '300px'}" (valueChanged)="this.multipleLazyChange($event, f.attribute)"></options-multiple-lazy>
				}
				@else if (f.type === filterType.BUTTON) {
					<uno-button>{{f.label | translate}}</uno-button>
				}
				@else if (f.type === filterType.CHECKBOX) {
					<uno-text size="small">{{f.label | translate}}</uno-text>
					<ion-checkbox (ionChange)="this.onChange.emit(this.filters)" [(ngModel)]="this.filters[f.attribute]" style="margin: 5px; vertical-align: middle;"></ion-checkbox>
				}
			</div>
		}
	</div>
</div>
