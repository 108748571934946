<div style="height: 110px; margin: 10px;">
	<!-- Search bar -->
	<uno-searchbar style="width: 100%;" [value]="selfStatic.filters.search" (valueChange)="onFilterChange($event)"></uno-searchbar>

	<!-- Filters -->
	<div style="width: 100%; height: 80px; overflow-x: auto; overflow-y: hidden; white-space: nowrap;">
		<!-- Sort direction -->
		<div style="min-width: 100px; display: inline-block; margin-right: 5px;">
			<ion-text style="font-size: 12px;">{{'direction' | translate}}</ion-text>
			<ion-select (ionChange)="onFilterChange($event)" [(ngModel)]="selfStatic.filters.sortDirection" interface="{{app.device.isMobile() ? 'alert' : 'popover'}}" fill="outline">
				@for (value of selfStatic.sortDirectionValues; track value) {
					<ion-select-option [value]="value">{{value | translate}}</ion-select-option>
				}
			</ion-select>
		</div>

		<!-- Sort field -->
		<div style="min-width: 150px; display: inline-block; margin-right: 5px;">
			<ion-text style="font-size: 12px;">{{'sortField' | translate}}</ion-text>
			<ion-select (ionChange)="onFilterChange($event)" [(ngModel)]="selfStatic.filters.sortField" interface="{{app.device.isMobile() ? 'alert' : 'popover'}}" fill="outline">
				@for (value of selfStatic.sortFieldValues; track value) {
					<ion-select-option [value]="value.attribute">{{value.label | translate}}</ion-select-option>
				}
			</ion-select>
		</div>

		<!-- Search fields -->
		<div style="min-width: 150px; display: inline-block; margin-right: 5px;">
			<ion-text style="font-size: 12px;">{{'searchFields' | translate}}</ion-text>
			<ion-select (ionChange)="onFilterChange($event)" [(ngModel)]="selfStatic.filters.searchFields" [interfaceOptions]="{header: ('searchFields' | translate)}" [multiple]="true" interface="alert" fill="outline">
				@for (field of selfStatic.searchFieldValues; track field) {
					<ion-select-option [value]="field.attribute">{{field.label | translate}}</ion-select-option>
				}
			</ion-select>
		</div>

		<!-- Result field -->
		<div style="min-width: 150px; display: inline-block; margin-right: 5px;">
			<ion-text style="font-size: 12px;">{{'result' | translate}}</ion-text>
			<ion-select (ionChange)="onFilterChange($event)" [(ngModel)]="selfStatic.filters.resultField" [interfaceOptions]="{header: ('result' | translate)}" [multiple]="true" interface="alert" fill="outline">
				@for (recipient of inspectionResultLabel | keyvalue; track recipient) {
					<ion-select-option [value]="recipient.key">{{$any(recipient).value | translate}}</ion-select-option>
				}
			</ion-select>
		</div>
	</div>
</div>

<ng-template #itemTemplate let-inspection="item">
	<uno-list-item (click)="app.navigator.navigate('/menu/repairs/inspections/edit', {uuid: inspection.uuid});">
		<uno-list-item-icon src="{{(inspection.pictures != null && inspection.pictures.length > 0) ? ResourceUtils.getURL(inspection.pictures[0], './assets/placeholder/asset.png') : './assets/placeholder/asset.png'}}"></uno-list-item-icon>

		<uno-list-item-label>
			<h3>{{inspection.repair.criticality | repairCriticalityLabel | translate}} | {{inspection.repair.status | repairStatusLabel | translate }}</h3>
			<h2>{{'dueDate' | translate}}: {{inspection.dueDate | formatDate}}</h2>
			<p>{{'status' | translate}}: {{inspectionStatusLabel.get(inspection.status) | translate}}</p>
			<p>{{'updatedAt' | translate}}: {{inspection.updatedAt | formatDate}}</p>
		</uno-list-item-label>

		<!-- Open new tab -->
		@if (app.device.isDesktop()) {
			<ion-icon (click)="app.openInTab('/menu/repairs/inspections/edit', {uuid: inspection.uuid}, $event);" name="desktop-outline" slot="end"></ion-icon>
		}
	</uno-list-item>
</ng-template>

<uno-content [ngStyle]="{height: 'calc(100% - 130px)'}">
	<uno-list [handler]="this.handler" [itemTemplate]="itemTemplate"></uno-list>
</uno-content>
