import {Component, forwardRef, Input, ViewEncapsulation} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR, FormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {IonicModule} from '@ionic/angular';

import {App} from '../../../app';
import {DateRange} from '../../../models/date-range';
import {DateTimeMode, DateTimeComponent} from '../date-time/date-time.component';

/**
 * Date range is a util component used to select two dates that compose a range.
 *
 * A min and max date can be defined, if none defined every possible date can be selected.
 *
 * Dates are stored and accessed directly as ISO 8601 date strings.
 */
@Component({
	selector: 'date-time-range',
	templateUrl: 'date-time-range.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => { return DateTimeRangeComponent; }),
			multi: true
		}
	],
	standalone: true,
	imports: [IonicModule, DateTimeComponent, FormsModule, TranslateModule]
})
export class DateTimeRangeComponent implements ControlValueAccessor {
	public get app(): any { return App; }

	/**
	 * Border to be displayed around the input.
	 */
	@Input()
	public border: boolean = true;

	/**
	 * Time selection mode.
	 */
	@Input()
	public mode: DateTimeMode = 'date';

	/**
	 * Allow the input to be disabled.
	 */
	@Input()
	public disabled: boolean = false;

	/**
	 * Minimum selectable date applied booth for the beginning and end dates.
	 */
	@Input()
	public min: Date = new Date(1900, 0, 0);

	/**
	 * Maximum selectable date applied booth for the beginning and end dates.
	 */
	@Input()
	public max: Date = new Date(2100, 0, 0);

	/**
	 * Date range being edited in the field.
	 */
	public value: DateRange = new DateRange();

	public onChange: Function = null;

	public registerOnChange(onChange: any): void {
		this.onChange = onChange;
	}

	public registerOnTouched(fn: any): void {}

	public setDisabledState(disabled: boolean): void {
		this.disabled = disabled;
	}

	public setTo(to: Date): void {
		this.value.to = to;
		this.writeValue(this.value);
	}

	public setFrom(from: Date): void {
		this.value.from = from;
		this.writeValue(this.value);
	}

	public writeValue(value: DateRange): void {
		this.value = value;

		if (this.onChange) {
			this.onChange(this.value);
		}
	}
}
