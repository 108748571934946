import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {FFPListPage} from './screens/list/ffp-list.page';
import {FFPEditPage} from './screens/edit/ffp-edit.page';
import {FFPRecommendationsPage} from './screens/master-data/ffp-recommendations/ffp-recommendations.page';

const routes: Routes = [
	{
		path: '',
		component: FFPListPage
	},
	{
		path: 'edit',
		component: FFPEditPage
	},
	{
		path: 'master-data/recommendations',
		component: FFPRecommendationsPage
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class FFPRouterModule { }
