import {Component, ViewEncapsulation} from '@angular/core';
import {IonicModule} from '@ionic/angular';

@Component({
	selector: 'uno-title',
	templateUrl: './uno-title.component.html',
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [IonicModule]
})
export class UnoTitleComponent {}
