import {OptionsDisplayMode} from '../../../components/uno-forms/uno-dynamic-form/uno-form-field';
import {UUID, UUIDIdentification} from '../../uuid';
import {InspectionFormFieldType} from '../../../modules/inspections/data/form/inspection-form-field-type';

/**
 * Options field for checkbox gap generation.
 */
export const CheckBoxGapOptions = {	
	/**
	 * Generate gap when checkbox is picked.
	 */
	TRUE: true,

	/**
	 * Generate gap when checkbox is not picked.
	 */
	FALSE: false
};

/**
 * Map of the options field checkbox generation and its corresponding label stored in locale.
 */
export const CheckBoxGapOptionslabel: Map<boolean, string> = new Map([
	[CheckBoxGapOptions.TRUE, 'true'],
	[CheckBoxGapOptions.FALSE, 'false']
]);

/**
 * CheckboxGapRule defines the struct when gap rule is from a checkbox field.
 */
export class CheckboxGapRule {
	/**
	 * Value that will generate gap.
	 */
	public checked: boolean = true;
}

/**
 * CheckboxGapRule defines the struct when gap rule is from an options or multiple options field.
 */
export class OptionsGapRule {
	/**
	 * Options that will generate gap.
	 */
	public options: UUID[] = [];
}

/**
 * Possible response for the options field.
 */
export class OptionsDataEntry {
	/**
	 * Label of the option.
	 */
	public value: string;

	/**
	 * ID of the option.
	 */
	public key: UUID;
};

/**
 * Data structure used for options fields.
 */
export class OptionsData {
	/**
	 * Default response if any.
	 */
	public defaultValue: UUID;

	/**
	 * Possible resposnses for the field.
	 */
	public options: OptionsDataEntry[];
}

/**
 * Form fields are used to compose inspection form templates.
 *
 * These fields define the data to be inserted by the user in the inspection.
 */
export class InspectionFormField extends UUIDIdentification {
	/**
	 * Form where the inspection field belongs.
	 */
	public formUuid: UUID = null;

	/**
	 * Inspection field type, depending on the type data stored in the field may be different.
	 */
	public type: number = InspectionFormFieldType.NONE;

	/**
	 * Index of the form field entry.
	 */
	public index: number = 0;

	/**
	 * Label of the inspection field.
	 */
	public label: string = '';
	
	/**
	 * Text shown to the user alongside the label.
	 */
	public text: string = '';

	/**
	 * Indicates if the field is required to be filled when submitting a form.
	 */
	public required: boolean = false;

	/**
	 * Indicates if the field generates gaps.
	 */
	public generatesGaps: boolean = false;

	/**
	 * Indicates the rule created based on the type of the field. Depending on the type, gapRule uses the correct structure defined.
	 */
	public gapRule: OptionsGapRule | CheckboxGapRule = null;

	/**
	 * Specific form type data.
	 */
	public data: any = null;

	/**
	 * Sub-form UUID associated with the inspection field.
	 */
	public subFormUuid: UUID = null;
	
	/**
	 * Display mode of the options component. Option items can be presented on a Modal, popover or drop-down selector.
	 */
	public optionsDisplayMode: number = OptionsDisplayMode.POPOVER;

	/**
	 * Initialize field data based on field type.
	 */
	public initializeData(): void {
		switch (this.type) {
			case InspectionFormFieldType.OPTIONS:
			case InspectionFormFieldType.OPTIONS_MULTIPLE:
				this.data = {
					options: [],
					defaultOptionUuid: null
				};
				break;
			default:
				this.data = null;
				break;
		}

		this.initializeGapRule();
	}

	public initializeGapRule(): void {
		if (this.generatesGaps) {
			switch (this.type) {
				case InspectionFormFieldType.OPTIONS:
				case InspectionFormFieldType.OPTIONS_MULTIPLE:
					this.gapRule = new OptionsGapRule();
					break;
				case InspectionFormFieldType.CHECKBOX:
					this.gapRule = new CheckboxGapRule();
					break;
				// This three types don't have a gap rule, but cannot reach default , as default sets generatesGaps to false.
				case InspectionFormFieldType.COMPOSED_FIELD:
				case InspectionFormFieldType.SUB_FORM:
				case InspectionFormFieldType.MULTIPLE_FORMS:
					this.gapRule = null;
					break;
				default:
					this.generatesGaps = false;
					this.gapRule = null;
					break;
			}
		} else {
			this.gapRule = null;
		}
	}

	/**
	 * Checks if the field requires a valid subform UUID.
	 * 
	 * @returns True if the field requires a valid subform UUID. False otherwise.
	 */
	public isSubform(): boolean {
		return this.type === InspectionFormFieldType.MULTIPLE_FORMS || this.type === InspectionFormFieldType.COMPOSED_FIELD || this.type === InspectionFormFieldType.SUB_FORM;
	}
	
	/**
	 * Parse the data of an API retrieved and create a Inspection field object.
	 *
	 * @param data - Inspection object to parse data
	 * @returns InspectionFormField object.
	 */
	public static parse(data: any): InspectionFormField {
		const field = new InspectionFormField();

		field.uuid = data.uuid;
		field.createdAt = new Date(data.createdAt);
		field.updatedAt = new Date(data.updatedAt);

		field.formUuid = data.formUuid;
		field.type = data.type;
		field.label = data.label;
		field.text = data.text;
		field.index = data.index;
		field.required = data.required === true;
		field.generatesGaps = data.generatesGaps;
		field.data = data.data;
		field.gapRule = data.gapRule;
		field.subFormUuid = data.subFormUuid || null;
		field.optionsDisplayMode = data.optionsDisplayMode || OptionsDisplayMode.POPOVER;

		return field;
	}

	/**
	 * Parse a array of inspection fields retrieved from the API.
	 *
	 * @param data - Array of fields to be parsed.
	 * @returns Array of InspectionFormField objects.
	 */
	public static parseArray(data: any[]): InspectionFormField[] {
		const fields: InspectionFormField[] = [];

		for (let i = 0; i < data.length; i++) {
			fields.push(InspectionFormField.parse(data[i]));
		}

		// Sort fields by index
		fields.sort((a, b): number => {
			return a.index - b.index;
		});

		// Fill possible gaps or missing index by reindexing the form fields.
		for (let i = 0; i < fields.length; i++) {
			fields[i].index = i;
		}

		return fields;
	}
}
