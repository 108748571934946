import {Component, Input, ViewEncapsulation, forwardRef, EventEmitter, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR, FormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {IonicModule} from '@ionic/angular';

import {
	AtexAmbient,
	AtexAmbientKeys,
	AtexAmbientLabels,
	AtexCategory,
	AtexCategoryKeys,
	AtexCategoryLabels,
	AtexEpl,
	AtexEplKeys,
	AtexEplLabels,
	AtexExplosionGroups,
	AtexExplosionGroupsKeys,
	AtexExplosionGroupsLabels,
	AtexGroup,
	AtexGroupKeys,
	AtexGroupLabels,
	AtexProtections,
	AtexProtectionsKeys,
	AtexProtectionsLabels,
	AtexTemperature,
	AtexTemperatureKeys,
	IPRatingDust,
	IPRatingDustKeys,
	IPRatingDustLabels,
	IPRatingWater,
	IPRatingWaterKeys,
	IPRatingWaterLabels
} from '../../../../models/atex/atex-enums';
import {App} from '../../../../app';
import {AtexTag} from '../../../../models/atex/atex';
import {UnoButtonComponent} from '../../../uno/uno-button/uno-button.component';
import {UnoTextComponent} from '../../../uno/uno-text/uno-text.component';

@Component({
	selector: 'atex-tag',
	templateUrl: './atex-tag.component.html',
	styleUrls: ['./atex-tag.component.scss'],
	encapsulation: ViewEncapsulation.None,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => { return AtexTagComponent; }),
			multi: true
		}
	],
	standalone: true,
	imports: [IonicModule, FormsModule, UnoTextComponent, UnoButtonComponent, TranslateModule]
})
export class AtexTagComponent implements ControlValueAccessor {
	public get atexGroup(): any { return AtexGroup; }

	public get atexGroupKeys(): any { return AtexGroupKeys; }

	public get atexGroupLabels(): any { return AtexGroupLabels; }

	public get atexCategory(): any { return AtexCategory; }

	public get atexCategoryKeys(): any { return AtexCategoryKeys; }

	public get atexCategoryLabels(): any { return AtexCategoryLabels; }

	public get atexEpl(): any { return AtexEpl; }

	public get atexEplKeys(): any { return AtexEplKeys; }

	public get atexEplLabels(): any { return AtexEplLabels; }

	public get atexProtections(): any { return AtexProtections; }

	public get atexProtectionsKeysNotDust(): any { return AtexProtectionsKeys.filter((key: string) => {return !key.endsWith('D');}); }

	public get atexProtectionsKeysDust(): any { return AtexProtectionsKeys.filter((key: string) => {return key.endsWith('D');}); }

	public get atexProtectionsLabels(): any { return AtexProtectionsLabels; }

	public get atexExplosionGroups(): any { return AtexExplosionGroups; }

	public get atexExplosionGroupsKeys(): any { return AtexExplosionGroupsKeys; }

	public get atexExplosionGroupsLabels(): any { return AtexExplosionGroupsLabels; }

	public get atexTemperature(): any { return AtexTemperature; }

	public get atexTemperatureKeys(): any { return AtexTemperatureKeys; }

	public get atexAmbient(): any { return AtexAmbient; }

	public get atexAmbientKeys(): any { return AtexAmbientKeys; }

	public get atexAmbientLabels(): any { return AtexAmbientLabels; }

	public get ipRatingDust(): any { return IPRatingDust; }

	public get ipRatingDustKeys(): any { return IPRatingDustKeys; }

	public get ipRatingDustLabels(): any { return IPRatingDustLabels; }

	public get ipRatingWater(): any { return IPRatingWater; }

	public get ipRatingWaterKeys(): any { return IPRatingWaterKeys; }

	public get ipRatingWaterLabels(): any { return IPRatingWaterLabels; }

	public get app(): any { return App; }

	/**
	 * Event to request the AtexTagGroup to delete the entry.
	 */
	@Output()
	public onDelete: EventEmitter<any> = new EventEmitter<any>();

	/**
	 * Allow the input to be disabled.
	 */
	@Input()
	public disabled = false;

	/**
	 * Atex stored in this input component.
	 */
	public value: AtexTag = new AtexTag();

	/**
	 * Method called when the data is changed.
	 */
	public onChange: (value: any)=> void = function(value) {};

	/**
	 * Check if the value is a valid Atex tag.
	 */
	public isValid(): boolean {
		return this.value && !(this.value.group === AtexGroup.UNKNOWN && this.value.category === AtexCategory.UNKNOWN && this.value.ambient === AtexAmbient.UNKNOWN);
	}

	/**
	 * Set a attribute of the atex object by its name.
	 *
	 * @param attribute - Attribute to be changed.
	 * @param value - New value.
	 */
	public setAttribute(attribute: string, value: any): any {
		if (!this.value) {
			throw new Error('Value is not of the type AtexTag.');
		}

		this.value[attribute] = value;
		this.writeValue(this.value);
	}

	/**
	 * Remove this item from the list of tags.
	 */
	public removeItem(): any {
		this.onDelete.emit();
	}

	public registerOnChange(onChange: any): void {
		this.onChange = onChange;
	}

	public writeValue(value: AtexTag): void {
		this.value = value;
		this.onChange(this.value);
	}

	public registerOnTouched(fn: any): void {}

	public setDisabledState(disabled: boolean): void {
		this.disabled = disabled;
	}
}
