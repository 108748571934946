import {Resource} from '../resource';

/**
 * Stores settings applied to the asset, in master data.
 */
export class AssetSettings {
	/**
	 * Report template to be used for the asset.
	 */
	public reportTemplate: Resource = null;

	/**
	 * Parse the data from API for a AssetSettings object.
	 *
	 * @param data - settings object to parse data.
	 * @returns The parsed object containing data.
	 */
	public static parse(data: any): AssetSettings {
		const settings = new AssetSettings();

		settings.reportTemplate = Resource.parse(data.reportTemplate);

		return settings;
	}
}
