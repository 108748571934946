import {Component, OnInit, ViewChild} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {UnoDynamicFormComponent} from '../../../../components/uno-forms/uno-dynamic-form/uno-dynamic-form.component';
import {App} from '../../../../app';
import {Modal} from '../../../../modal';
import {Locale} from '../../../../locale/locale';
import {RoleFormLayout} from '../role-form-layouts';
import {UnoDynamicFormModule} from '../../../../components/uno-forms/uno-dynamic-form.module';
import {ScreenComponent} from '../../../../components/screen/screen.component';
import {Role} from '../../../roles/data/role';
import {UnoButtonComponent} from '../../../../components/uno/uno-button/uno-button.component';
import {UnoContentComponent} from '../../../../components/uno/uno-content/uno-content.component';
import {RoleService} from '../../services/role.service';

@Component({
	selector: 'role-create-page',
	templateUrl: 'role-create.page.html',
	standalone: true,
	imports: [UnoContentComponent, UnoDynamicFormModule, UnoButtonComponent, TranslateModule]
})
export class RoleCreatePage extends ScreenComponent implements OnInit {

	public get layout(): any { return RoleFormLayout; }

	public get app(): any { return App; }

	@ViewChild('form', {static: false})
	public form: UnoDynamicFormComponent = null;

	/**
	 * User data that will be sent to the API.
	 */
	public role: Role = new Role();

	public ngOnInit(): void {
		super.ngOnInit();
		
		App.navigator.setTitle('userRoles');
	}

	/**
	 * Call API to create a new role.
	 */
	public async create(): Promise<void> {
		if (!this.form.requiredFilled()) {
			Modal.alert(Locale.get('error'), Locale.get('requiredFieldsError'));
			return;
		}

		await RoleService.create(this.role);

		Modal.toast(Locale.get('userRoleCreated'));
		App.navigator.pop();
	}
}
