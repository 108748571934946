import {Component, OnInit, ViewChild} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';

import {UnoDynamicFormComponent} from '../../../../../../components/uno-forms/uno-dynamic-form/uno-dynamic-form.component';
import {ScreenComponent} from '../../../../../../components/screen/screen.component';
import {App} from '../../../../../../app';
import {UserPermissions} from '../../../../../../models/users/user-permissions';
import {Session} from '../../../../../../session';
import {Service} from '../../../../../../http/service';
import {ServiceList} from '../../../../../../http/service-list';
import {Modal} from '../../../../../../modal';
import {Locale} from '../../../../../../locale/locale';
import {UnoDynamicFormModule} from '../../../../../../components/uno-forms/uno-dynamic-form.module';
import {UUID} from '../../../../../../models/uuid';
import {CMP} from '../../../../../../models/pipeline-integrity/mot/cmp';
import {MotCmpLayout} from '../mot-cmp-layout';
import {FileUtils} from '../../../../../../utils/file-utils';
import {PipelineImport} from '../../../../data/mot/pipeline-import';
import {UnoFormField} from '../../../../../../components/uno-forms/uno-dynamic-form/uno-form-field';
import {UnoFormFieldTypes} from '../../../../../../components/uno-forms/uno-dynamic-form/uno-form-field-types';
import {UnoButtonComponent} from '../../../../../../components/uno/uno-button/uno-button.component';
import {UnoContentComponent} from '../../../../../../components/uno/uno-content/uno-content.component';

@Component({
	selector: 'mot-cmp-edit-page',
	templateUrl: 'mot-cmp-edit.page.html',
	standalone: true,
	imports: [UnoContentComponent, UnoDynamicFormModule, UnoButtonComponent, TranslateModule]
})
export class MOTCmpEditPage extends ScreenComponent implements OnInit {
	public get app(): any { return App; }

	public get userPermissions(): any { return UserPermissions; }

	public get session(): any { return Session; }

	@ViewChild('form', {static: false})
	public form: UnoDynamicFormComponent = null;

	public permissions = [UserPermissions.PIPELINE_INTEGRITY_MOT_CMP_CREATE, UserPermissions.PIPELINE_INTEGRITY_MOT_CMP_EDIT, UserPermissions.PIPELINE_INTEGRITY_PIPELINE_DELETE];

	/**
	 * CMP being edited/created on this screen, data is fetched from the API.
	 */
	public cmp: CMP = null;

	/**
	 * UUID of the pipeline that this CMP belongs.
	 */
	public pipelineUuid: UUID = null;

	/**
	 * Flag to indicate if the screen is being used to create a new entry.
	 */
	public createMode: boolean = false;

	/**
	 * Layout used to edit CMP.
	 */
	public cmpLayout: UnoFormField[] = MotCmpLayout;

	public async ngOnInit(): Promise<void> {
		super.ngOnInit();

		this.cmp = null;
		this.createMode = false;

		const data = App.navigator.getData();
		if (!data || !data.cmpUuid && !data.createMode) {
			App.navigator.pop();
			return;
		}
	
		if (data.cmpUuid && data.createMode) {
			throw Error('UUID and createMode cannot be used simultaneously.');
		}

		// Create CMP
		if (data.createMode) {
			App.navigator.setTitle('create');
			this.createMode = true;
			this.pipelineUuid = data.pipelineUuid;
			this.cmp = new CMP();
		// Edit CMP, load specific CMP
		} else {
			this.pipelineUuid = data.pipelineUuid;
			this.cmp = await this.loadData(data.cmpUuid);
			App.navigator.setTitle(this.cmp.name || 'edit');
		}

		this.cmpLayout = MotCmpLayout.concat([
			{
				required: true,
				attribute: 'segmentUuid',
				label: 'segment',
				type: UnoFormFieldTypes.OPTIONS,
				options: [],
				editable: true,
				fetchOptions: async(object: any, row: UnoFormField) => {
					const request = await Service.fetch(ServiceList.pipelineIntegrity.pipeline.segment.list, null, null, {pipelineUuid: this.pipelineUuid}, Session.session);
					const segments = request.response.segments;

					const options = [];
					for (let i = 0; i < segments.length; i++) {
						options.push({
							label: segments[i].name,
							value: segments[i].uuid
						});
					}
					row.options = options;
				}
			}
		]);
	}

	/**
	 * Load CMP data by UUID from API
	 * 
	 * @param uuid - CMP UUID
	 * @returns Data of the CMP obtained from the API.
	 */
	public async loadData(uuid: UUID): Promise<CMP> {
		const request = await Service.fetch(ServiceList.pipelineIntegrity.mot.cmp.get, null, null, {uuid: uuid}, Session.session);
		return CMP.parse(request.response.cmp);
	}

	/**
	 * Create or update CMP data in the API.
	 * 
	 * Checks if all required form fields are filled. Display toast on success.
	 *
	 * @param stayOnPage - Flag to indicate if the system should stay on the page after changes are saved.
	 */
	public async update(stayOnPage: boolean = false): Promise<void> {
		if (!this.form.requiredFilled()) {
			Modal.alert(Locale.get('error'), Locale.get('requiredFieldsError'));
			return;
		}

		await Service.fetch(this.createMode ? ServiceList.pipelineIntegrity.mot.cmp.create : ServiceList.pipelineIntegrity.mot.cmp.update, null, null, this.cmp, Session.session);
		if (!stayOnPage) {
			App.navigator.pop();
		}

		Modal.toast(Locale.get('updatedSuccessfully'));
	}

	/**
	 * Delete CMP.
	 */
	public async delete(): Promise<void> {
		const confirm = await Modal.confirm(Locale.get('confirm'), Locale.get('confirmDelete'));
		if (confirm) {
			await Service.fetch(ServiceList.pipelineIntegrity.mot.cmp.delete, null, null, {uuid: this.cmp.uuid}, Session.session);
			Modal.toast(Locale.get('deleteSuccessfully'));
			App.navigator.pop();
		}
	}

	/**
	 * Select a .fea file and import it to the application.
	 */
	public async importFea(): Promise<void> {
		const files = await FileUtils.chooseFile('.fea', false);
		if (files.length > 0) {
			try {
				await PipelineImport.importFea(files[0], this.pipelineUuid, this.cmp.uuid);
				Modal.toast(Locale.get('updatedSuccessfully'));
			} catch (e) {
				Modal.alert(Locale.get('error'), Locale.get('errorImport'));
			}
		}

	}
}
