<ion-item class="ion-no-margin ion-no-padding" lines="none">
	<ionic-selectable (ngModelChange)="this.writeValue($event);" (onInfiniteScroll)="this.onInfiniteLoad($event);" (onSearch)="this.onSearch($event);" [canSearch]="true" [placeholder]="'empty' | translate" [disabled]="this.disabled" [hasInfiniteScroll]="true" [isMultiple]="this.multiple" [items]="this.options" [itemValueField]="'uuid'" [ngModel]="this.value" [ngModelOptions]="{standalone: true}" [searchDebounce]="500" [shouldStoreItemValue]="true" closeButtonText="{{'close' | translate}}" confirmButtonText="{{'ok' | translate}}" itemTextField="display" style="min-width: 100%;">
	</ionic-selectable>

	<!-- Qr -->
	@if (app.device.hasCamera && session.hasPermissions([permissions.ASSET_PORTFOLIO_QR]) && !this.disabled) {
		<ion-icon (click)="this.selectAssetQR();" name="qr-code-outline" slot="end" style="cursor: pointer;"></ion-icon>
	}
	<!-- Nfc -->
	@if (app.device.hasNFC() && session.hasPermissions([permissions.ASSET_PORTFOLIO_NFC]) && !this.disabled) {
		<ion-icon (click)="this.selectAssetNFC();" slot="end" src="assets/icons/material/nfc/outline.svg" style="cursor: pointer;"></ion-icon>
	}
	<!-- Clear -->
	@if (this.value && !this.disabled && this.showClear) {
		<ion-icon (click)="this.writeValue(null);" name="close-outline" slot="end" style="cursor: pointer;"></ion-icon>
	}
	<!-- Create -->
	@if (session.hasPermissions([permissions.ASSET_PORTFOLIO_ASSET_CREATE]) && !this.value && !this.disabled) {
		<ion-icon (click)="this.createAssetModal();" name="add-outline" slot="end" style="cursor: pointer;"></ion-icon>
	}
</ion-item>
