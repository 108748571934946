import {APAsset} from 'src/app/models/asset-portfolio/asset';
import {UUID} from '../../../../models/uuid';
import {Service} from '../../../../http/service';
import {ServiceList} from '../../../../http/service-list';
import {Session} from '../../../../session';
import {AtexInspection} from '../../../../models/atex-inspections/inspections/atex-inspection';

export type AtexInspectionHistory = {
	// ID of the history entry
	id: number,
	// History action (create, update)
	action: number,
	// History date
	date: Date,
	// User that performed change
	user: {
		// ID of the user
		uuid: UUID,
		// Name
		name: string,
		// Email
		email: string,
		// Phone number
		phone: string
	}
}

export type AtexInspectionList = {
	// UUID of the atex inspection.
	uuid: UUID,
	// Asset of the atex inspection.
	asset: {name: string, tag: string, pictures: any[], parent: APAsset},
	// Result of the atex inspection.
	result: number,
	// Status of the atex inspection.
	status: number,
	// When the atex inspection was updated.
	updatedAt: string
}

export type AtexInspectionListParams = {
	sortField?: string,
	sortDirection?: string,
	status?: number,
	filterWaitingDocuments?: number,
	filterResult?: number,
	filterSubtype?: UUID,
	noPictures?: boolean,
	from?: number,
	count?: number,
	search?: string,
	searchFields?: string[],
};

export type AtexInspectionCountParams = {
	status?: number,
	waitingDocuments?: number,
	result?: number,
	subtype?: UUID,
	search?: string,
	searchFields?: string[],
};

export class AtexInspectionService {
	/**
	 * Get an Atex inspection by its UUID.
	 * 
	 * @param uuid - UUID of the inspection.
	 */
	public static async get(uuid: UUID): Promise<AtexInspection> {
		const request = await Service.fetch(ServiceList.atexInspection.get, null, null, {uuid: uuid}, Session.session);
		return AtexInspection.parse(request.response.inspection);
	}

	/**
	 * List history entries for Atex inspection.
	 * 
	 * @param uuid - uuid of the Atex inspection to load the history of.
	 */
	public static async listHistoryUsers(uuid: UUID): Promise<AtexInspectionHistory[]> {
		const response = await Service.fetch(ServiceList.atexInspection.historyListUsers, null, null, {uuid: uuid}, Session.session);
		return response.response.history;
	}

	/**
	 * List all Atex Inspections meeting the parameters.
	 *
	 * @param params - The parameters to apply on the fetch request.
	 */
	public static async list(params: AtexInspectionListParams): Promise<{hasMore: boolean, inspections: AtexInspectionList[], id: number}> {
		const request = await Service.fetch(ServiceList.atexInspection.list, null, null, params, Session.session);

		return {
			hasMore: request.response.hasMore,
			inspections: request.response.inspections,
			id: request.id
		};
	}

	/**
	 * Count all Atex Inspections meeting the parameters.
	 *
	 * @param params - The parameters to apply on the fetch request.
	 */
	public static async count(params: AtexInspectionCountParams): Promise<number> {
		return (await Service.fetch(ServiceList.atexInspection.count, null, null, params, Session.session)).response.count;
	}

}
