import {Resource} from '../resource';
import {UUID, UUIDIdentification} from '../uuid';
import {UserTwoFactorAuth} from './user-two-factor-auth';

/**
 * Declares the model of an user as seen by the client application.
 */
export class User extends UUIDIdentification {
	/**
	 * User email used for login.
	 */
	public email: string = '';

	/**
	 * Username displayed when searching for the user.
	 */
	public name: string = '';

	/**
	 * Version of the privacy policy accepted by the user.
	 */
	public privacyPolicy: number = -1;

	/**
	 * UUID of the Teams that this user belongs to.
	 */
	public teamUuids: UUID[] = null;

	/**
	 * Indicates if two factor authentication is enabled for the user.
	 */
	public twoFactorAuth: number = UserTwoFactorAuth.NONE;

	/**
	 * User phone number, might be used to send SMS.
	 */
	public phoneNumber: string = '';

	/**
	 * SHA-256 of the user password.
	 *
	 * The user password should never be stored anywhere or sent to the API in plain value.
	 */
	public password: string = '';

	/**
	 * UUID of the company that this user belongs to.
	 */
	public companyUuid: UUID = null;

	/**
	 * Notes about the user.
	 */
	public notes: string = '';

	/**
	 * List of permissions of this user.
	 */
	public permissions: number[] = [];

	/**
	 * Flag to set user as admin
	 */
	public isAdmin: boolean = false;

	/**
	 * Indicates if the user account is active.
	 */
	public active: boolean = true;

	/**
	 * Picture of the user (profile photo)
	 */
	public picture: Resource = null;

	/**
	 * Signature of the user, (picture of signature)
	 */
	public signature: Resource = null;

	/**
	 * UUID of the Roles this user has.
	 */
	public roleUuids: UUID[] = [];

	/**
	 * Parse user data from object.
	 *
	 * @param data - Data to be parsed into object.
	 */
	public static parse(data: any): User {
		const user = new User();

		user.uuid = data.uuid;
		user.createdAt = new Date(data.createdAt);
		user.updatedAt = new Date(data.updatedAt);
		user.email = data.email;
		user.name = data.name;
		user.teamUuids = data.teamUuids;
		user.phoneNumber = data.phoneNumber;
		user.password = data.password;
		user.companyUuid = data.companyUuid;
		user.notes = data.notes;
		user.permissions = data.permissions || [];
		user.isAdmin = data.isAdmin === true;
		user.active = data.active;
		user.picture = Resource.parse(data.picture);
		user.signature = Resource.parse(data.signature);
		user.twoFactorAuth = data.twoFactorAuth || UserTwoFactorAuth.NONE;
		user.privacyPolicy = data.privacyPolicy;
		user.roleUuids = data.roleUuids || [];

		return user;
	}
}

