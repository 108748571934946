import {UUID} from '../../uuid';

/**
 * An acquisition is the date and time that a CMP made the measurements/readings.
 */
export class Acquisition {

	/**
	 * UUID of the acquisition.
	 */
	public uuid: UUID = null;

	/**
	 * Description of the acquisition.
	 */
	public description: string = '';

	/**
	 * Date that the acquisition will take place.
	 */
	public dateTime: Date = null;

	/**
	 * UUID of the CMP that this acquisition belongs.
	 */
	public cmpUuid: UUID = null;

	/**
	 * Parse the data of an API retrieved acquisition object.
	 *
	 * @param data - Acquisition object to parse data
	 */
	public static parse(data: any): Acquisition {
		const acquisition = new Acquisition();

		acquisition.uuid = data.uuid;
		acquisition.description = data.description;
		acquisition.dateTime = new Date(data.dateTime);
		acquisition.cmpUuid = data.cmpUuid;

		return acquisition;
	}
}

