import {Resource} from '../../resource';

/**
 * Inspection report stores the data that describes a report template attached to a inspection project.
 */
export class InspectionReportTemplate {
	/**
	 * Name of the report.
	 */
	public name: string = '';

	/**
	 * Description of the report model.
	 */
	public description: string = '';

	/**
	 * Possible formats for the report template.
	 */
	public formats: number[] = [];

	/**
	 * Template resource that contains the model file.
	 */
	public template: Resource = null;

	/**
	* Parse inspection project report template to create a InspectionReportTemplate object.
	*
	* @param data - Data to be parsed.
	* @returns Object of the correct type.
	*/
	public static parse(data: any): InspectionReportTemplate {
		const template: InspectionReportTemplate = new InspectionReportTemplate();

		template.name = data.name;
		template.description = data.description;
		template.formats = data.formats;
		template.template = data.template;

		return template;
	}
}
