import {Component, OnInit, ViewChild} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';

import {UnoDynamicFormComponent} from '../../../../../components/uno-forms/uno-dynamic-form/uno-dynamic-form.component';
import {ScreenComponent} from '../../../../../components/screen/screen.component';
import {App} from '../../../../../app';
import {UserPermissions} from '../../../../../models/users/user-permissions';
import {Modal} from '../../../../../modal';
import {Locale} from '../../../../../locale/locale';
import {Service} from '../../../../../http/service';
import {ServiceList} from '../../../../../http/service-list';
import {Session} from '../../../../../session';
import {DigitalTwinScene} from '../../../data/digital-twin-scene';
import {UnoDynamicFormModule} from '../../../../../components/uno-forms/uno-dynamic-form.module';
import {DigitalTwinSceneLayout} from '../digital-twin-scene-layout';
import {UUID} from '../../../../../models/uuid';
import {UnoButtonComponent} from '../../../../../components/uno/uno-button/uno-button.component';
import {UnoContentComponent} from '../../../../../components/uno/uno-content/uno-content.component';

@Component({
	selector: 'digital-twin-scene-edit',
	templateUrl: './digital-twin-scene-edit.page.html',
	standalone: true,
	imports: [UnoContentComponent, UnoDynamicFormModule, UnoButtonComponent, TranslateModule]
})
export class DigitalTwinSceneEditPage extends ScreenComponent implements OnInit {
	public get app(): any { return App; }

	public get layout(): any { return DigitalTwinSceneLayout; }

	public get userPermissions(): any { return UserPermissions; }

	public get session(): any { return Session; }

	@ViewChild('form', {static: false})
	public form: UnoDynamicFormComponent = null;

	public permissions = [UserPermissions.DIGITAL_TWIN];

	public scene: DigitalTwinScene = null;

	/**
	 * Flag to indicate if the screen is being used to create a new entry.
	 */
	public createMode: boolean = false;

	public async ngOnInit(): Promise<void> {
		super.ngOnInit();
		
		App.navigator.setTitle('digitalTwin');
		const data = App.navigator.getData();
		if (!data || !data.uuid && !data.createMode) {
			App.navigator.pop();
			return;
		}

		if (data.uuid && data.createMode) {
			throw Error('UUID and createMode cannot be used simultaneously.');
		}

		if (data.createMode) {
			this.createMode = true;
			this.scene = new DigitalTwinScene();
		} else {
			this.scene = await this.loadScene(data.uuid);
		}
	}

	/**
	 * Load the scene from API.
	 */
	public async loadScene(uuid: UUID): Promise<DigitalTwinScene> {
		const request = await Service.fetch(ServiceList.digitalTwin.scene.get, null, null, {uuid: uuid}, Session.session);
		return DigitalTwinScene.parse(request.response.scene);
	}

	/**
	 * Create/update the scene on the API.
	 */
	public async update(): Promise<void> {
		if (!this.form.requiredFilled()) {
			Modal.alert(Locale.get('error'), Locale.get('requiredFieldsError'));
			return;
		}

		await Service.fetch(this.createMode ? ServiceList.digitalTwin.scene.create : ServiceList.digitalTwin.scene.update, null, null, this.scene, Session.session);

		App.navigator.pop();
		Modal.toast(Locale.get('updatedSuccessfully'));
	}

	/**
	 * Delete the scene from database.
	 */
	public async delete(): Promise<void> {
		const confirm = await Modal.confirm(Locale.get('confirm'), Locale.get('confirmDelete'));
		if (confirm) {
			await Service.fetch(ServiceList.digitalTwin.scene.delete, null, null, {uuid: this.scene.uuid}, Session.session);
			Modal.toast(Locale.get('deleteSuccessfully'));
			App.navigator.pop();
		}
	}
}
