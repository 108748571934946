import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';
import {NgClass} from '@angular/common';
import {AssetSelectorComponent} from '../input/asset-selector/asset-selector.component';
import {AtexInspectionFieldComponent} from '../input/atex-inspection-field/atex-inspection-field.component';
import {AtexTagGroupComponent} from '../input/atex-tag-group/atex-tag-group.component';
import {AudioSelectorComponent} from '../input/audio-selector/audio-selector.component';
import {CompanySelectorComponent} from '../input/company-selector/company-selector.component';
import {DateTimeComponent} from '../input/date-time/date-time.component';
import {FileSelectorComponent} from '../input/file-selector/file-selector.component';
import {GeoPositionComponent} from '../input/geo-position/geo-position.component';
import {ImageSelectorComponent} from '../input/image-selector/image-selector.component';
import {KeyValueComponent} from '../input/key-value-array/key-value-array.component';
import {NFCTagComponent} from '../input/nfc-tag/nfc-tag.component';
import {OptionsMultipleLazyComponent} from '../input/options-multiple-lazy/options-multiple-lazy.component';
import {OptionsMultipleSelectorComponent} from '../input/options-multiple-selector/options-multiple-selector.component';
import {OptionsSelectorComponent} from '../input/options-selector/options-selector.component';
import {QrCodeComponent} from '../input/qr-code/qr-code.component';
import {TeamSelectorComponent} from '../input/team-selector/team-selector.component';
import {TextListComponent} from '../input/text-list/text-list.component';
import {UserSelectorComponent} from '../input/user-selector/user-selector.component';
import {VideoSelectorComponent} from '../input/video-selector/video-selector.component';
import {UnoLateralSelectorSwitchComponent} from '../uno/uno-lateral-selector-switch/uno-lateral-selector-switch.component';
import {UnoOptionsSearchComponent} from '../uno/uno-options-search/uno-options-search.component';
import {UnoOptionsList} from '../uno/uno-options/uno-options-list/uno-options-list.component';
import {UnoPeriodicitySelector} from '../uno/uno-periodicity-selector/uno-periodicity-selector.component';
import {UnoButtonComponent} from '../uno/uno-button/uno-button.component';
import {UnoTextComponent} from '../uno/uno-text/uno-text.component';
import {UnoTitleComponent} from '../uno/uno-title/uno-title.component';
import {UnoUuidComponent} from '../input/uno-uuid/uno-uuid.component';
import {UnoDynamicFormComponent} from './uno-dynamic-form/uno-dynamic-form.component';
import {UnoFormFieldSelectorComponent} from './uno-form-field-selector/uno-form-field-selector.component';
import {UnoFormCollapsableFieldComponent} from './uno-form-collapsable-field/uno-form-collapsable-field.component';
import {UnoFormComposedFieldComponent} from './uno-form-composed-field/uno-form-composed-field.component';
import {UnoFormRepetitiveFieldComponent} from './uno-form-repetitive-field/uno-form-repetitive-field.component';

@NgModule({
	imports: [
		IonicModule,
		FormsModule,
		UnoUuidComponent,
		DateTimeComponent,
		ImageSelectorComponent,
		UnoButtonComponent,
		UnoTextComponent,
		VideoSelectorComponent,
		AudioSelectorComponent,
		OptionsSelectorComponent,
		TranslateModule,
		OptionsMultipleSelectorComponent,
		UnoOptionsSearchComponent,
		UnoPeriodicitySelector,
		UnoLateralSelectorSwitchComponent,
		UnoOptionsList,
		AssetSelectorComponent,
		TeamSelectorComponent,
		CompanySelectorComponent,
		UserSelectorComponent,
		OptionsMultipleLazyComponent,
		GeoPositionComponent,
		AtexInspectionFieldComponent,
		AtexTagGroupComponent,
		FileSelectorComponent,
		QrCodeComponent,
		NFCTagComponent,
		TextListComponent,
		KeyValueComponent,
		UnoTitleComponent,
		NgClass
	],
	providers: [],
	declarations: [UnoDynamicFormComponent, UnoFormFieldSelectorComponent, UnoFormComposedFieldComponent, UnoFormRepetitiveFieldComponent, UnoFormCollapsableFieldComponent],
	exports: [UnoDynamicFormComponent, UnoFormFieldSelectorComponent, UnoFormComposedFieldComponent, UnoFormRepetitiveFieldComponent, UnoFormCollapsableFieldComponent],
	bootstrap: []
})
export class UnoDynamicFormModule { }
