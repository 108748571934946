/**
 * List of possible UI scales to apply to the application.
 */
export enum UIScale {
    /**
     * 50% scale.
     */
    S50 = 0.5,

    /**
     * 75% scale.
     */
    S75 = 0.75,

    /**
     * Default scale
     */
    DEFAULT = 1.0,

    /**
     * 125% scale.
     */
    S125 = 1.25,

    /**
     * 150% scale.
     */
    S150 = 1.5,

    /**
     * 2X scale.
     */
    S200 = 2.0
}

