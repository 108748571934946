<uno-content>
	<ion-card-content>
		<ion-grid>
			<!-- Atex Inspections -->
			@if (session.hasPermissions([userPermissions.ATEX_INSPECTION_DASHBOARD_PROGRESS, userPermissions.ATEX_INSPECTION_DASHBOARD_STATES, userPermissions.ATEX_INSPECTION_DASHBOARD_STATISTICS_DONE, userPermissions.ATEX_INSPECTION_DASHBOARD_STATISTICS_REVIEW, userPermissions.ATEX_INSPECTION_DASHBOARD_STATISTICS_UPDATED])) {
				<ion-title color="primary">{{'atexInspection' | translate}}</ion-title>

				<ion-row>
					@if (session.hasPermissions([userPermissions.ATEX_INSPECTION_DASHBOARD_PROGRESS])) {
						<ion-col size="12" size-lg="4" size-md="6" size-xl="3">
							<graph-atex-inspection-progress></graph-atex-inspection-progress>
						</ion-col>
					}
					@if (session.hasPermissions([userPermissions.ATEX_INSPECTION_DASHBOARD_STATES])) {
						<ion-col size="12" size-lg="4" size-md="6" size-xl="3">
							<graph-atex-inspection-states></graph-atex-inspection-states>
						</ion-col>
					}
					@if (session.hasPermissions([userPermissions.ATEX_INSPECTION_DASHBOARD_STATISTICS_DONE])) {
						<ion-col size="12" size-lg="4" size-md="6" size-xl="3">
							<graph-atex-inspection-stats-done></graph-atex-inspection-stats-done>
						</ion-col>
					}
					@if (session.hasPermissions([userPermissions.ATEX_INSPECTION_DASHBOARD_STATISTICS_REVIEW])) {
						<ion-col size="12" size-lg="4" size-md="6" size-xl="3">
							<graph-atex-inspection-stats-review></graph-atex-inspection-stats-review>
						</ion-col>
					}
					@if (session.hasPermissions([userPermissions.ATEX_INSPECTION_DASHBOARD_STATISTICS_UPDATED])) {
						<ion-col size="12" size-lg="4" size-md="6" size-xl="3">
							<graph-atex-inspection-stats-updated></graph-atex-inspection-stats-updated>
						</ion-col>
					}
				</ion-row>
			}
			<!-- Action plan -->
			@if (session.hasPermissions([userPermissions.ACTION_PLAN_DASHBOARD_STATES])) {
				<ion-title color="primary">{{'atexActionPlan' | translate}}</ion-title>

				<ion-row>
					<ion-col size="12" size-lg="4" size-md="6" size-xl="3">
						<graph-action-plan-states></graph-action-plan-states>
					</ion-col>
					<ion-col size="12" size-lg="4" size-md="6" size-xl="3">
						<graph-action-plan-rejected></graph-action-plan-rejected>
					</ion-col>
					<ion-col size="12" size-lg="4" size-md="6" size-xl="3">
						<graph-action-plan-executed></graph-action-plan-executed>
					</ion-col>
					<ion-col size="12" size-lg="4" size-md="6" size-xl="3">
						<graph-action-plan-blocked></graph-action-plan-blocked>
					</ion-col>

				</ion-row>
			}
			<!-- Repairs -->
			@if (session.hasPermissions([userPermissions.REPAIR_STATUS_GRAPH, userPermissions.REPAIR_CRITICALITY_GRAPH])) {
				<ion-title color="primary">{{'repairs' | translate}}</ion-title>

				<ion-row>
					@if (session.hasPermissions([userPermissions.REPAIR_STATUS_GRAPH])) {
						<ion-col size="12" size-lg="4" size-md="6" size-xl="3">
							<graph-repairs-states></graph-repairs-states>
						</ion-col>
					}
					@if (session.hasPermissions([userPermissions.REPAIR_CRITICALITY_GRAPH])) {
						<ion-col size="12" size-lg="4" size-md="6" size-xl="3">
							<graph-repair-criticality></graph-repair-criticality>
						</ion-col>
					}
				</ion-row>
			}

			<!-- Inspections -->
			@if (session.hasPermissions([userPermissions.INSPECTION_PROJECT_DASHBOARD_STEPS])) {
				<ion-title color="primary">{{'inspections' | translate}}</ion-title>
				<ion-row>
					@for (project of this.inspectionProjects; track project) {
						<ion-col size="12" size-lg="4" size-md="6" size-xl="3">
							<inspection-steps-graph [project]="project"></inspection-steps-graph>
						</ion-col>
					}
				</ion-row>
			}

		</ion-grid>
	</ion-card-content>
</uno-content>
