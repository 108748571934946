import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {DL50MenuPage} from './screens/menu/dl50-menu.page';
import {DL50MasterDataMenuPage} from './screens/master-data/menu/dl50-master-data-menu.page';
import {DL50QuestionsListPage} from './screens/master-data/questions/list/dl50-questions-list.page';
import {DL50InspectionsListPage} from './screens/inspections/list/dl50-inspections-list.page';
import {DL50RegulatoryStandardsListPage} from './screens/master-data/regulatory-standards/list/dl50-regulatory-standards-list.page';
import {DL50InspectionsEditPage} from './screens/inspections/edit/dl50-inspections-edit.page';

const routes: Routes = [
	{
		path: '',
		component: DL50MenuPage
	},
	{
		path: 'master-data/menu',
		component: DL50MasterDataMenuPage
	},
	{
		path: 'master-data/regulatory-standards/list',
		component: DL50RegulatoryStandardsListPage
	},
	{
		path: 'master-data/questions/list',
		component: DL50QuestionsListPage
	},
	{
		path: 'inspections/list',
		component: DL50InspectionsListPage
	},
	{
		path: 'inspections/edit',
		component: DL50InspectionsEditPage
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class Dl50RouterModule {}
