import {Component, Input, ViewEncapsulation, forwardRef} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {IonicModule} from '@ionic/angular';

import {Service} from '../../../http/service';
import {ServiceList} from '../../../http/service-list';
import {FileUtils} from '../../../utils/file-utils';
import {Resource} from '../../../models/resource';

@Component({
	selector: 'video-selector',
	templateUrl: './video-selector.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => { return VideoSelectorComponent; }),
			multi: true
		}
	],
	standalone: true,
	imports: [IonicModule, TranslateModule]
})
export class VideoSelectorComponent implements ControlValueAccessor {
	public static FORMATS: string = '.mp4, .mkv, .mpeg, .avi';

	/**
	 * Allow the input to be disabled.
	 */
	@Input()
	public disabled = false;

	/**
	 * Resource representing the image stored.
	 */
	public value: Resource = null;

	/**
	 * Method called when the data is changed.
	 */
	public onChange: (value: any)=> void = function(value) {};

	/**
	 * Get the image URL to be displayed on this component.
	 */
	public get videoUrl(): any {
		return Service.getURL(ServiceList.resources.file.get, {
			uuid: this.value.uuid,
			format: this.value.format
		});
	}

	/**
	 * Select image file from the user.
	 */
	public async selectFile(): Promise<void> {
		const files: File[] = await FileUtils.chooseFile(VideoSelectorComponent.FORMATS, false);
		if (files.length > 0) {
			await this.uploadFile(files[0]);
		}
	}

	/**
	 * Method called when a new file is inserted.
	 *
	 * @param file - File to be uploaded.
	 */
	public async uploadFile(file): Promise<void> {
		const format = FileUtils.getFileExtension(file);

		const form = new FormData();
		form.append('file', file, 'video');
		form.append('format', format);

		const request = await Service.fetch(ServiceList.resources.file.upload, null, null, form, null);

		this.writeValue(new Resource(request.response.uuid, format));
	}

	public registerOnChange(onChange: any): void {
		this.onChange = onChange;
	}

	public writeValue(value: any): void {
		this.value = value;
		this.onChange(this.value);
	}

	public registerOnTouched(fn: any): void {}

	public setDisabledState(disabled: boolean): void {
		this.disabled = disabled;
	}
}
