import {Component, OnInit} from '@angular/core';
import {SortDirection} from 'src/app/utils/sort-direction';
import {TranslateModule} from '@ngx-translate/core';
import {NgStyle} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {ScreenComponent} from '../../../../../components/screen/screen.component';
import {UserPermissions} from '../../../../../models/users/user-permissions';
import {App} from '../../../../../app';
import {Session} from '../../../../../session';
import {ServiceList} from '../../../../../http/service-list';
import {Resource} from '../../../../../models/resource';
import {RepairLongevity, RepairLongevityLabel} from '../../../../../models/repairs/repairs/repair-longevity';
import {QRGenerator} from '../../../../qr/data/qr-generator';
import {ServiceSync} from '../../../../../http/service-sync';
import {UnoListLazyLoadHandler} from '../../../../../components/uno/uno-list/uno-list-lazy-load-handler';
import {RepairStatus, RepairStatusLabel} from '../../../../../models/repairs/repairs/repair-status';
import {Locale} from '../../../../../locale/locale';
import {RepairCriticalityLabelPipe} from '../../pipes/repair-criticality-label.pipe';
import {RepairStatusLabelPipe} from '../../pipes/repair-status-label.pipe';
import {FormatDatePipe} from '../../../../../pipes/format-date.pipe';
import {UnoListComponent} from '../../../../../components/uno/uno-list/uno-list-component';
import {UnoContentComponent} from '../../../../../components/uno/uno-content/uno-content.component';
import {UnoListItemLabelComponent} from '../../../../../components/uno/uno-list-item/uno-list-item-label.component';
import {UnoListItemIconComponent} from '../../../../../components/uno/uno-list-item/uno-list-item-icon.component';
import {UnoListItemComponent} from '../../../../../components/uno/uno-list-item/uno-list-item.component';
import {UnoSearchbarComponent} from '../../../../../components/uno/uno-searchbar/uno-searchbar.component';
import {ResourceUtils} from '../../../../../utils/resource-utils';

@Component({
	selector: 'repairs-list-page',
	templateUrl: 'repairs-list.page.html',
	standalone: true,
	imports: [UnoSearchbarComponent, IonicModule, FormsModule, UnoListItemComponent, UnoListItemIconComponent, UnoListItemLabelComponent, UnoContentComponent, NgStyle, UnoListComponent, TranslateModule, FormatDatePipe, RepairStatusLabelPipe, RepairCriticalityLabelPipe]
})
export class RepairsListPage extends ScreenComponent implements OnInit {
	public get generateQr(): any { return QRGenerator.generateFile; }

	public get app(): any { return App; }

	public get selfStatic(): any { return RepairsListPage; }

	public get session(): any { return Session; }


	public get resource(): any { return Resource; }

	public permissions = [UserPermissions.REPAIR_LIST_ALL];

	/**
	 * Text used to filter repairs.
	 */
	public search: string = '';

	/**
	 * List of possible search fields.
	 */
	public static searchFieldValues: any = [
		{label: 'uuid', attribute: '[repair].[id]'},
		{label: 'assetName', attribute: '[ap_asset].[name]'},
		{label: 'assetTag', attribute: '[ap_asset].[tag]'},
		{label: 'assetUuid', attribute: '[ap_asset].[id]'},
		{label: 'repairOrder', attribute: '[repair].[repair_order]'},
		{label: 'description', attribute: '[repair].[description]'},
		{label: 'notes', attribute: '[repair].[notes]'},
		{label: 'proposalNotes', attribute: '[repair].[proposal_notes]'},
		{label: 'riskAssessmentNotes', attribute: '[repair].[risk_assessment_notes]'},
		{label: 'proposalApprovalNotes', attribute: '[repair].[proposal_approval_notes]'},
		{label: 'blockedNotes', attribute: '[repair].[blocked_notes]'}
	];

	/**
	 * List of possible longevity fields.
	 */
	public static longevityFilterValues: {label: string, attribute: number}[] = Array.from(RepairLongevityLabel.keys()).map((value) => {return {label: RepairLongevityLabel.get(value), attribute: value};});

	/**
	 * Search fields to be considered.
	 */
	public searchFields: string[] = [];

	/**
	 * Database attribute name used to sort the values.
	 */
	public sortField: string = '';

	/**
	 * Sort direction applied to the loaded list from database.
	 */
	public sortDirection: string = '';

	/**
	 * Possible sort direction values.
	 */
	public static sortDirectionValues: any = [SortDirection.ASC, SortDirection.DESC];

	/**
	 * Possible database filter to be used for ordering the users list.
	 */
	public static sortFieldValues: any = [
		{label: 'updatedAt', attribute: '[repair].[updated_at]'},
		{label: 'createdAt', attribute: '[repair].[created_at]'},
		{label: 'criticality', attribute: '[repair].[criticality]'},
		{label: 'assetName', attribute: '[ap_asset].[name]'},
		{label: 'assetTag', attribute: '[ap_asset].[tag]'}
	];

	/**
	 * Object to synchronize service requests.
	 */
	public serviceSync: ServiceSync = new ServiceSync();

	/**
	 * Lazy list loading handler.
	 */
	public handler: UnoListLazyLoadHandler<any> = new UnoListLazyLoadHandler<any>();

	/**
	 * Status of repair.
	 */
	public status: number = RepairStatus.ALL;

	public static filters = {
		/**
		 * Text used to filter repairs by their name.
		 */
		search: '',

		/**
		 * Sort direction applied to the loaded list from database.
		 */
		sortDirection: RepairsListPage.sortDirectionValues[1],

		/**
		 * Database attribute name used to sort the values.
		 */
		sortField: RepairsListPage.sortFieldValues[0].attribute,

		/**
		 * Search fields to be considered.
		 */
		searchFields: [RepairsListPage.searchFieldValues[0].attribute, RepairsListPage.searchFieldValues[1].attribute, RepairsListPage.searchFieldValues[2].attribute, RepairsListPage.searchFieldValues[3].attribute, RepairsListPage.searchFieldValues[5].attribute],

		/**
		 * Longevity filters to be considered on repair filter
		 */
		longevityFilters: [RepairLongevity.UNKNOWN, RepairLongevity.TEMPORARY, RepairLongevity.DEFINITIVE]
	};

	public static defaultFilters = structuredClone(RepairsListPage.filters);

	public resetFilters(): void {
		this.serviceSync.reset();

		Object.assign(RepairsListPage.filters, RepairsListPage.defaultFilters);
	}

	public ngOnInit(): void {
		super.ngOnInit();

		// Read status from the route data
		const data = App.navigator.getData();
		if (!data) {
			App.navigator.pop();
			return;
		}

		App.navigator.setTitle(Locale.get(RepairStatusLabel.has(data.status) ? RepairStatusLabel.get(data.status) : 'list'));
		this.status = data.status !== undefined ? data.status : RepairStatus.ALL;

		this.handler.loadMore = async(count: number, pageSize: number) => {
			const params = {
				status: this.status,
				from: count,
				count: pageSize,
				search: RepairsListPage.filters.search,
				sortDirection: RepairsListPage.filters.sortDirection,
				sortField: RepairsListPage.filters.sortField,
				searchFields: RepairsListPage.filters.searchFields,
				longevityFilters: RepairsListPage.filters.longevityFilters
			};

			const request = await this.serviceSync.fetch(ServiceList.repairs.list, null, null, params, Session.session);
			const response = request.response;

			return {
				elements: response.repairs,
				hasMore: response.hasMore
			};
		};
		this.handler.reset();
	}

	/**
	 * Update the search term used.
	 *
	 * @param event - DOM event.
	 */
	public onFilterChange(event: any): void {
		if (event.target === undefined) {
			RepairsListPage.filters.search = event;
		}

		this.serviceSync.reset();
		this.handler.reset();
	}

	protected readonly ResourceUtils = ResourceUtils;
}
