import {Component, OnDestroy, OnInit} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {IonicModule} from '@ionic/angular';
import {App} from '../../../app';
import {Locale} from '../../../locale/locale';
import {Modal} from '../../../modal';
import {ScreenComponent} from '../../../components/screen/screen.component';
import {UserPermissions} from '../../../models/users/user-permissions';
import {Environment} from '../../../../environments/environment';
import {NFC} from '../data/nfc';
import {NFCHandler} from '../data/nfc-handler';
import {UnoContentComponent} from '../../../components/uno/uno-content/uno-content.component';
import {AssetService} from '../../asset-portfolio/services/asset.service';

@Component({
	selector: 'nfc-page',
	templateUrl: 'nfc.page.html',
	standalone: true,
	imports: [UnoContentComponent, IonicModule, TranslateModule]
})
export class NFCPage extends ScreenComponent implements OnInit, OnDestroy {
	public permissions = [UserPermissions.ASSET_PORTFOLIO_NFC];

	/**
	 * Callback method if specified it is used to process the data received from NFC tags.
	 */
	public onRead: (serial: string)=> void = null;

	/**
	 * Indicates if the API is waiting for an API response.
	 */
	public waitingAPI: boolean = false;

	/**
	 * Enables/Disables NFC scan
	 */
	public enabled: boolean = false;

	/**
	 * Handler to read NFC data.
	 */
	public nfc: NFCHandler = null;

	public async ngOnInit(): Promise<void> {
		super.ngOnInit();

		this.nfc = await NFC.read((result: any) => {
			this.process(result.serialNumber);
		});

		this.onRead = null;
		this.enabled = true;

		const data = App.navigator.getData();
		if (data && data.onRead) {
			this.onRead = data.onRead;
		}

		App.navigator.setTitle('nfcReader');
	}

	public ngOnDestroy(): void {
		if (this.nfc) {
			this.nfc.abort();
		}
	}

	/**
	 * Process the NFC tag value. Navigate to the asset edit page if a valid asset is found for the given NFC tag.
	 * 
	 * @param nfc - NFC tag value.
	 */
	public async process(nfc: string): Promise<void> {
		if (!Environment.PRODUCTION) {
			console.log('EQS: Read NFC tag data:', nfc);
		}

		if (this.onRead !== null) {
			this.onRead(nfc);
		} else if (!this.waitingAPI) {
			this.waitingAPI = true;

			try {
				const asset = await AssetService.getByNFC(nfc);
				this.enabled = false;
				App.navigator.navigate('/menu/asset-portfolio/asset/edit', {uuid: asset.uuid});
			} catch (e) {
				Modal.alert(Locale.get('warning'), Locale.get('nfcUnknown'));
			}

			this.waitingAPI = false;
		}
	}
}
