<div style="height: 110px; margin: 10px;">
	<div style="display:flex; align-items: center; gap: 5px;">
		<!-- Create -->
		@if (session.hasPermissions(userPermissions.TEAM_CREATE)) {
			<uno-button icon="assets/icons/uno/add-icon.svg" (click)="app.navigator.navigate('/menu/teams/edit', {createMode: true})">
				@if (app.device.isDesktop()) {
					{{'create' | translate}}
				}
			</uno-button>
		}

		<!-- Search bar -->
		<uno-searchbar style="width: 100%;" [value]="selfStatic.filters.search" (valueChange)="onFilterChange($event)"></uno-searchbar>
	</div>

	<!-- Filters -->
	<div style="width: 100%; height: 80px; overflow-x: auto; overflow-y: hidden; white-space: nowrap;">
		<!-- Sort direction -->
		<div style="min-width: 100px; display: inline-block; margin-right: 5px;">
			<ion-text style="font-size: 12px;">{{'direction' | translate}}</ion-text>
			<ion-select (ionChange)="onFilterChange($event)" [(ngModel)]="selfStatic.filters.sortDirection" interface="{{app.device.isMobile() ? 'alert' : 'popover'}}" fill="outline">
				@for (value of selfStatic.sortDirectionValues; track value) {
					<ion-select-option [value]="value">{{value | translate}}</ion-select-option>
				}
			</ion-select>
		</div>

		<!-- Sort field -->
		<div style="min-width: 150px; display: inline-block; margin-right: 5px;">
			<ion-text style="font-size: 12px;">{{'sortField' | translate}}</ion-text>
			<ion-select (ionChange)="onFilterChange($event)" [(ngModel)]="selfStatic.filters.sortField" interface="{{app.device.isMobile() ? 'alert' : 'popover'}}" fill="outline">
				@for (value of selfStatic.sortFieldValues; track value) {
					<ion-select-option [value]="value.attribute">{{value.label | translate}}</ion-select-option>
				}
			</ion-select>
		</div>
	</div>
</div>

<!-- List -->
<uno-content [ngStyle]="{height: 'calc(100% - 130px)'}">
	@if (this.teams.length === 0) {
		<uno-no-data></uno-no-data>
	}
	@if (this.teams.length !== 0) {
		@for (team of this.teams; track team) {
			<team-list-item [team]="team"></team-list-item>
		}
	}
</uno-content>
