import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {SvgIconComponent} from 'angular-svg-icon';
import {NgStyle} from '@angular/common';
import {CssNgStyle} from '../../../utils/css-ng-style';
import {UnoColor, UnoColorMap} from '../uno-color/uno-color';

/**
 * UNO icon can be used to display icons alongside text.
 *
 * Can be placed inside of button, selectors, etc.
 *
 * The main difference between icon and image is that icons can be colored based on the UI theme.
 */
@Component({
	selector: 'uno-icon',
	templateUrl: './uno-icon.component.html',
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [NgStyle, SvgIconComponent]
})
export class UnoIconComponent implements OnChanges, OnInit {
	/**
	 * Style to be applied to the image.
	 */
	@Input()
	public ngStyle: CssNgStyle = {};

	/**
	 * Source of the image element.
	 */
	@Input()
	public src: string = '';

	/**
	 * Width of the icon.
	 */
	@Input()
	public width: number = 22;

	/**
	 * Height of the icon.
	 */
	@Input()
	public height: number = 22;

	/**
	 * If set the color of the icon will be changed.
	 */
	@Input()
	public color: string = null;

	/**
	 * The svg viewbox.
	 */
	@Input()
	public viewbox: string = 'auto';

	/**
	 * Style to apply to the svg div.
	 */
	@Input()
	public svgStyle: CssNgStyle = {
			'pointer-events': 'none',
			fill: UnoColorMap.get(UnoColor.CONTRAST),
			margin: 'none'
		};

	public style: CssNgStyle = {};

	public ngOnInit(): void {
		this.updateStyle();
	}

	public ngOnChanges(changes: SimpleChanges): void {
		this.updateStyle();
	}

	/**
	 * Update the style of the component.
	 */
	public updateStyle(): void {
		Object.assign(this.style, this.ngStyle);

		if (this.color) {
			this.svgStyle.fill = UnoColorMap.has(this.color) ? UnoColorMap.get(this.color) : this.color;
		} else {
			this.svgStyle.fill = null;
		}

		this.svgStyle.height = this.height + 'px';
		this.svgStyle.width = this.width + 'px';

		this.style.height = this.height + 'px';
		this.style.width = this.width + 'px';
	}
}
