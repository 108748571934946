<div class="image-selector-container">
	<!-- Active -->
	@if (!this.disabled) {
		<div class="image-selector-image-container">
			@if (this.multiple) {
				@for (resource of $any(this.value); track index; let index = $index) {
					<div class="image-selector-image-div">
						<img (click)="this.viewImage(resource)" src="{{this.urls[index]}}" class="image-selector-image" />
						<div class="image-selector-image-delete" (click)="this.removeImage(resource)">
							<uno-icon [width]="30" [height]="30" [src]="'assets/icons/uno/close-icon.svg'" [color]="'var(--gray-11)'"></uno-icon>
						</div>
					</div>
				}
			}
			@else if(this.value)
            {
				<div class="image-selector-image-div">
					<img (click)="this.viewImage($any(this.value))" src="{{this.urls[0]}}" class="image-selector-image" />
					<div class="image-selector-image-delete" (click)="this.removeImage($any(this.value))">
						<uno-icon [width]="30" [height]="30" [src]="'assets/icons/uno/close-icon.svg'" [color]="'var(--gray-11)'"></uno-icon>
					</div>
				</div>
			}

			<!-- Add images -->
			@if (this.multiple || this.value === null) {
				<div>
					<uno-button icon="assets/components/image-selector/upload-image-icon.svg" color="primary" type="border" (click)="this.selectFiles()" style="width: 110px; min-height: 80px; max-height: 160px" [expand]="true">{{'selectFile' | translate }}</uno-button>
				</div>
			}
		</div>
	}

	<!-- Disabled -->
	@if (this.disabled) {
		<div class="image-selector-image-container">
			@if (this.multiple) {
				@for (resource of $any(this.value); track index; let index = $index) {
					<div class="image-selector-image-disabled">
						<img (click)="this.viewImage(resource)" src="{{this.urls[index]}}" style="width: 120px; height: 80px; cursor: pointer; object-fit: contain;" />
					</div>
				}
			}
			@else if(this.value) {
				<div class="image-selector-image-disabled">
					<img (click)="this.viewImage($any(this.value))" src="{{this.urls[0]}}" style="width: 120px; height: 80px; cursor: pointer; object-fit: contain;" />
				</div>
			}
		</div>
	}
</div>
