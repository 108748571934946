import {UUIDIdentification} from '../../uuid';

export class GAActionPlanHistory extends UUIDIdentification {
	/**
	 * Status of the gap at this history entry.
	 */
	public status: number = 0;

	/**
	 * The name of the user that changed this gap history entry.
	 */
	public userName: string = '';

	/**
	 * The email of the user that changed this gap history entry.
	 */
	public userEmail: string = '';

	/**
	 * Parse JSON data received from the API into an object of the correct type.
	 * 
	 * @param data - Object received from API.
	 * @returns Gap object instance built from the data received.
	 */
	public static parse(data: any): GAActionPlanHistory {
		const history = new GAActionPlanHistory();

		history.uuid = data.uuid;
		history.updatedAt = new Date(data.updatedAt);
		history.createdAt = new Date(data.createdAt);

		history.status = data.status;
		history.userName = data.userName;
		history.userEmail = data.userEmail;

		return history;
	}
}
