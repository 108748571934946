@if (this.value) {
	<div class="atex-tag-container">
		<!-- CE -->
		<div style="display:flex; flex-direction: row; gap: 3px; align-items: center;">
			<ion-icon src="./assets/icons/atex/ce.svg" style="width: 20px; color: var(--gray-1)"></ion-icon>
			<ion-checkbox (ngModelChange)="this.setAttribute('ce', $event);" [disabled]="this.disabled" [ngModel]="this.value.ce" [ngModelOptions]="{standalone: true}" style="min-width: 20px;"></ion-checkbox>
		</div>
		<!-- Cert number -->
		<ion-input fill="outline" class="atex-tag-input" (ngModelChange)="this.setAttribute('certificationNumber', $event);" [disabled]="this.disabled" [ngModel]="this.value.certificationNumber" [ngModelOptions]="{standalone: true}" inputmode="numeric" style="margin-left: 3px; min-width: 30px; max-width: 80px; height: 25px;" type="text"></ion-input>
		<!-- EX -->
		<div style="display:flex; flex-direction: row; gap: 3px; align-items: center;">
			<ion-img src="./assets/icons/atex/ex.svg" style="width: 30px;"></ion-img>
			<ion-checkbox (ngModelChange)="this.setAttribute('ex', $event);" [disabled]="this.disabled" [ngModel]="this.value.ex" [ngModelOptions]="{standalone: true}" style="min-width: 20px;"></ion-checkbox>
		</div>
		<!-- Group -->
		<div>
			<ion-select fill="outline" class="atex-tag-input" (ngModelChange)="this.setAttribute('group', $event);" [disabled]="this.disabled" [ngModel]="this.value.group" [ngModelOptions]="{standalone: true}" interface="{{app.device.isMobile() ? 'alert' : 'popover'}}" style="max-width: 80px;">
				@for (i of atexGroupKeys; track i) {
					<ion-select-option [value]="atexGroup[i]">{{atexGroupLabels.get(atexGroup[i])}}</ion-select-option>
				}
			</ion-select>
		</div>
		<!-- Category -->
		<div>
			<ion-select fill="outline" class="atex-tag-input" (ngModelChange)="this.setAttribute('category', $event);" [disabled]="this.disabled" [ngModel]="this.value.category" [ngModelOptions]="{standalone: true}" interface="{{app.device.isMobile() ? 'alert' : 'popover'}}" style="max-width: 80px;">
				@for (i of atexCategoryKeys; track i) {
					<ion-select-option [value]="atexCategory[i]">{{atexCategoryLabels.get(atexCategory[i])}}</ion-select-option>
				}
			</ion-select>
		</div>
		<!-- Ambient -->
		<div>
			<ion-select fill="outline" class="atex-tag-input" (ngModelChange)="this.setAttribute('ambient', $event);" [disabled]="this.disabled" [ngModel]="this.value.ambient" [ngModelOptions]="{standalone: true}" interface="{{app.device.isMobile() ? 'alert' : 'popover'}}" style="max-width: 80px;">
				@for (i of atexAmbientKeys; track i) {
					<ion-select-option [value]="atexAmbient[i]">{{atexAmbientLabels.get(atexAmbient[i])}}</ion-select-option>
				}
			</ion-select>
		</div>
		<!-- Eex -->
		<div style="display:flex; flex-direction: row; gap: 3px; align-items: center;">
			<ion-text>Ex</ion-text>
			<ion-checkbox (ngModelChange)="this.setAttribute('eex', $event);" [disabled]="this.disabled" [ngModel]="this.value.eex" [ngModelOptions]="{standalone: true}" style="min-width: 20px;"></ion-checkbox>
		</div>
		<!-- Protection -->
		<div style="display:flex; flex-direction: row; align-items: center;">
			@if (this.value.ambient === atexAmbient.D) {
				<ion-select fill="outline" class="atex-tag-input" (ngModelChange)="this.setAttribute('protections', $event);" [disabled]="this.disabled" [interfaceOptions]="{header: ('equipmentProtection' | translate)}" [ngModel]="this.value.protections" [ngModelOptions]="{standalone: true}" multiple="true" style="max-width: 200px;">
					@for (i of atexProtectionsKeysDust; track i) {
						<ion-select-option [value]="atexProtections[i]">{{atexProtectionsLabels.get(atexProtections[i])}}</ion-select-option>
					}
				</ion-select>
			}
			@if (this.value.ambient !== atexAmbient.D) {
				<ion-select fill="outline" class="atex-tag-input" (ngModelChange)="this.setAttribute('protections', $event);" [disabled]="this.disabled" [interfaceOptions]="{header: ('equipmentProtection' | translate)}" [ngModel]="this.value.protections" [ngModelOptions]="{standalone: true}" multiple="true" style="max-width: 200px;">
					@for (i of atexProtectionsKeysNotDust; track i) {
						<ion-select-option [value]="atexProtections[i]">{{atexProtectionsLabels.get(atexProtections[i])}}</ion-select-option>
					}
				</ion-select>
			}
		</div>
		<!-- Explosion -->
		<div>
			<ion-select fill="outline" class="atex-tag-input" (ngModelChange)="this.setAttribute('explosion', $event);" [disabled]="this.disabled" [ngModel]="this.value.explosion" [ngModelOptions]="{standalone: true}" interface="{{app.device.isMobile() ? 'alert' : 'popover'}}" style="min-width: 60px; max-width: 60px;">
				@for (i of atexExplosionGroupsKeys; track i) {
					<ion-select-option [value]="atexExplosionGroups[i]">{{atexExplosionGroupsLabels.get(atexExplosionGroups[i])}}</ion-select-option>
				}
			</ion-select>
		</div>
		<!-- Temperature -->
		<div style="display:flex; flex-direction: row; align-items: center;">
			@if (this.value.ambient === atexAmbient.G) {
				<ion-select fill="outline" class="atex-tag-input" (ngModelChange)="this.setAttribute('temperature', $event);" [disabled]="this.disabled" [ngModel]="this.value.temperature" [ngModelOptions]="{standalone: true}" interface="{{app.device.isMobile() ? 'alert' : 'popover'}}" style="max-width: 60px;">
					<ion-select-option [value]="0">-</ion-select-option>
					@for (i of atexTemperatureKeys; track i) {
						<ion-select-option [value]="atexTemperature[i]">{{i}}</ion-select-option>
					}
				</ion-select>
			}
			@if (this.value.ambient !== atexAmbient.G) {
				<uno-text>T<sub>max</sub></uno-text>
				<ion-input fill="outline" class="atex-tag-input" (ngModelChange)="this.setAttribute('temperature', $event);" [disabled]="this.disabled" [ngModel]="this.value.temperature" [ngModelOptions]="{standalone: true}" style="margin-left: 3px; min-width: 30px; max-width: 80px; height: 25px;" type="number"></ion-input>
			}
		</div>
		<!-- EPL -->
		<div>
			<ion-select fill="outline" class="atex-tag-input" (ngModelChange)="this.setAttribute('epl', $event);" [disabled]="this.disabled" [ngModel]="this.value.epl" [ngModelOptions]="{standalone: true}" interface="{{app.device.isMobile() ? 'alert' : 'popover'}}" style="min-width: 45px;">
				@for (i of atexEplKeys; track i) {
					<ion-select-option [value]="atexEpl[i]">{{atexEplLabels.get(atexEpl[i])}}</ion-select-option>
				}
			</ion-select>
		</div>
		<!-- IPx rating -->
		<div>
			<ion-text>IP</ion-text>
		</div>
		<!-- IPX Rating Dust Resistance -->
		<div>
			<ion-select fill="outline" class="atex-tag-input" (ngModelChange)="this.setAttribute('ipxDust', $event);" [disabled]="this.disabled" [ngModel]="this.value.ipxDust" [ngModelOptions]="{standalone: true}" interface="{{app.device.isMobile() ? 'alert' : 'popover'}}" style="max-width: 80px;">
				@for (i of ipRatingDustKeys; track i) {
					<ion-select-option [value]="ipRatingDust[i]">{{ipRatingDustLabels.get(ipRatingDust[i])}}</ion-select-option>
				}
			</ion-select>
		</div>
		<!-- IP Rating Water Resistance -->
		<div>
			<ion-select fill="outline" class="atex-tag-input" (ngModelChange)="this.setAttribute('ipxWater', $event);" [disabled]="this.disabled" [ngModel]="this.value.ipxWater" [ngModelOptions]="{standalone: true}" interface="{{app.device.isMobile() ? 'alert' : 'popover'}}" style="max-width: 80px;">
				@for (i of ipRatingWaterKeys; track i) {
					<ion-select-option [value]="ipRatingWater[i]">{{ipRatingWaterLabels.get(ipRatingWater[i])}}</ion-select-option>
				}
			</ion-select>
		</div>
		<!-- Ambient Temperature -->
		<ion-input fill="outline" class="atex-tag-input" (ngModelChange)="this.setAttribute('temperatureMin', $event);" [disabled]="this.disabled" [ngModel]="this.value.temperatureMin" [ngModelOptions]="{standalone: true}" style="margin-left: 3px; min-width: 20px; max-width: 80px; height: 25px;" type="number"></ion-input>
		<uno-text>≤ T<sub>amb</sub> ≤</uno-text>
		<ion-input fill="outline" class="atex-tag-input" (ngModelChange)="this.setAttribute('temperatureMax', $event);" [disabled]="this.disabled" [ngModel]="this.value.temperatureMax" [ngModelOptions]="{standalone: true}" style="margin-left: 3px; min-width: 20px; max-width: 80px; height: 25px;" type="number"></ion-input>

		<!-- Delete Button -->
		<div>
			@if (!this.disabled) {
				<uno-button icon="/assets/icons/uno/bin-icon.svg" (click)="this.removeItem()"></uno-button>
			}
		</div>
	</div>
}
