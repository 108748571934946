import {Component, OnInit} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';

import {ScreenComponent} from '../../../../../../components/screen/screen.component';
import {App} from '../../../../../../app';
import {Service} from '../../../../../../http/service';
import {UserPermissions} from '../../../../../../models/users/user-permissions';
import {RepairWeldingStandard} from '../../../master-data/repair-welding-standard';
import {ServiceList} from '../../../../../../http/service-list';
import {Locale} from '../../../../../../locale/locale';
import {Modal} from '../../../../../../modal';
import {Session} from '../../../../../../session';
import {UnoButtonComponent} from '../../../../../../components/uno/uno-button/uno-button.component';
import {UnoDynamicFormModule} from '../../../../../../components/uno-forms/uno-dynamic-form.module';
import {UnoContentComponent} from '../../../../../../components/uno/uno-content/uno-content.component';
import {RepairWeldingStandardLayout} from './repair-welding-standard-layout';

@Component({
	selector: 'masterdata-repair-welding-standards-page',
	templateUrl: 'repair-welding-standards.page.html',
	standalone: true,
	imports: [UnoContentComponent, UnoDynamicFormModule, UnoButtonComponent, TranslateModule]
})
export class RepairWeldingStandardsPage extends ScreenComponent implements OnInit {

	public get layout(): any { return RepairWeldingStandardLayout; }

	public permissions = [UserPermissions.REPAIR_WELDING_TYPES_EDIT];

	/**
	 * List of repair welding standards available in database.
	 */
	public standards: RepairWeldingStandard[] = [];

	public ngOnInit(): void {
		super.ngOnInit();
		
		App.navigator.setTitle('weldingStandards');

		RepairWeldingStandard.load().then((types: RepairWeldingStandard[]) => {
			this.standards = types;
		});
	}

	/**
	 * Add a welding standard to the list.
	 */
	public add(): void {
		this.standards.push(new RepairWeldingStandard());
	}

	/**
	 * Delete the welding standards from the list.
	 *
	 * @param standard - Element to be deleted from list.
	 */
	public delete(standard: RepairWeldingStandard): void {
		Modal.confirm(Locale.get('confirm'), Locale.get('confirmDelete')).then((confirm) => {
			if (confirm) {
				Service.call(ServiceList.repairs.weldingStandard.delete, null, null, {id: standard.id}, Session.session, (response, xhr) => {
					const index = this.standards.indexOf(standard);
					if (index !== -1) {
						this.standards.splice(index, 1);
					}
				});
			}
		});
	}

	/**
	 * Update the checklist value in the server.
	 */
	public save(): void {
		const data = structuredClone(this.standards);

		Modal.confirm(Locale.get('confirm'), Locale.get('masterdataConfirm')).then((confirm) => {
			if (confirm) {
				Service.call(ServiceList.repairs.weldingStandard.updateBulk, null, null, data, Session.session, (response, xhr) => {
					Modal.toast(Locale.get('updatedSuccessfully'));
					App.navigator.pop();
				});
			}
		});
	}

}
