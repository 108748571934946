import {EChartsType, init, use} from 'echarts/core';
import {LineChart, ScatterChart} from 'echarts/charts';
import {UniversalTransition} from 'echarts/features';
import {CSSUtils} from 'src/app/utils/css-utils';
import {
	DataZoomComponent,
	GridComponent,
	LegendComponent,
	MarkLineComponent,
	TimelineComponent,
	TitleComponent,
	ToolboxComponent,
	TooltipComponent
} from 'echarts/components';
import {CanvasRenderer} from 'echarts/renderers';
import {StyleManager} from 'src/app/theme/style-manager';
import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {EChartsOption} from 'echarts';
import {IonicModule} from '@ionic/angular';

import {UnoDynamicFormComponent} from '../../../../../../components/uno-forms/uno-dynamic-form/uno-dynamic-form.component';
import {FeatureTypeColor} from '../../../../../../models/pipeline-integrity/pipeline/feature-type';
import {ScreenComponent} from '../../../../../../components/screen/screen.component';
import {App} from '../../../../../../app';
import {UserPermissions} from '../../../../../../models/users/user-permissions';
import {Session} from '../../../../../../session';
import {Service} from '../../../../../../http/service';
import {ServiceList} from '../../../../../../http/service-list';
import {Locale} from '../../../../../../locale/locale';
import {UUID} from '../../../../../../models/uuid';
import {Experiment} from '../../../../../../models/pipeline-integrity/mot/experiment';
import {ChannelData} from '../../../../../../models/pipeline-integrity/mot/channel-data';
import {ResizeDetector} from '../../../../../../utils/resize-detector';
import {PipelineImport, SegmentData} from '../../../../data/mot/pipeline-import';
import {FormatDatePipe} from '../../../../../../pipes/format-date.pipe';
import {UnoNoDataComponent} from '../../../../../../components/uno/uno-no-data/uno-no-data.component';
import {UnoContentComponent} from '../../../../../../components/uno/uno-content/uno-content.component';

// Register required components for echarts
use([TitleComponent, TooltipComponent, GridComponent, LineChart, DataZoomComponent, TimelineComponent, CanvasRenderer, LegendComponent, UniversalTransition, ScatterChart, MarkLineComponent, ToolboxComponent]);

@Component({
	selector: 'mot-acquisition-chart-page',
	templateUrl: 'mot-acquisition-chart.page.html',
	standalone: true,
	imports: [UnoContentComponent, UnoNoDataComponent, IonicModule, FormatDatePipe]
})
export class MOTAcquisitionChartPage extends ScreenComponent implements OnInit, OnDestroy {
	public get app(): any { return App; }

	public get userPermissions(): any { return UserPermissions; }

	public get session(): any { return Session; }

	public permissions = [UserPermissions.PIPELINE_INTEGRITY];

	@ViewChild('form', {static: false})
	public form: UnoDynamicFormComponent = null;

	/**
	 * Div to place the chart in.
	 */
	@ViewChild('chart', {static: true})
	public chartDiv: ElementRef = null;

	/**
	 * List of Experiments to be displayed retrieved by the API.
	 */
	public experiments: Experiment[] = [];

	/**
	 * EChart object used to plot and view the CMP information.
	 */
	public chart: EChartsType = null;

	/**
	 * Object with chart options.
	 */
	public chartOptions: EChartsOption = {};

	/**
	 * UUID of the pipeline that belongs to the acquisition.
	 */
	public pipelineUuid: UUID;

	/**
	 * UUID of the CMP that belongs to the acquisition.
	 */
	public cmpUuid: UUID;

	/**
	 * Resize detector.
	 */
	public resizeDetector: ResizeDetector = null;

	public async ngOnInit(): Promise<void> {
		super.ngOnInit();

		App.navigator.setTitle('acquisition');
		const data = App.navigator.getData();

		if (!data || !data.uuid) {
			App.navigator.pop();
			return;
		}

		this.pipelineUuid = data.pipelineUuid;
		this.cmpUuid = data.cmpUuid;

		this.experiments = await this.loadExperiments(data.uuid);

		// Load chart of the first element in the list.
		if (this.experiments.length > 0) {
			await this.displayExperiment(this.experiments[0]);
		}

		this.resizeDetector = new ResizeDetector(this.chartDiv.nativeElement, () => {
			this.chart.resize();
		});
	}

	/**
	 * Destroy chart and event manager.
	 */
	public ngOnDestroy(): void {
		if (this.chart) {
			this.chart.dispose();
			this.chart = null;
		}

		this.resizeDetector.destroy();
	}

	/**
	 * Load Experiments data by acquisition from API 
	 *
	 * @param acquisitionUuid - Acquisition UUID
	 */
	 public async loadExperiments(acquisitionUuid: UUID): Promise<Experiment[]> {
		const request = await Service.fetch(ServiceList.pipelineIntegrity.mot.experiment.list, null, null, {acquisitionUuid: acquisitionUuid}, Session.session);
		return request.response.experiments.map(Experiment.parse);
	}

	/**
	 * Load channels data by experiment to display in the chart. 
	 *
	 * @param experiment - Experiment selected in the list.
	 */
	public async displayExperiment(experiment: Experiment): Promise<void> {
		// Initialize chart data
		if (!this.chart) {
			this.initChart();
		}

		// Load data for the experiment
		const request = await Service.fetch(ServiceList.pipelineIntegrity.mot.channel.getDataChannelsByExperiment, null, null, {experimentUuid: experiment.uuid}, Session.session);
		const channelData: ChannelData[] = [];
		for ( const chData of request.response.channels) {
			channelData.push(ChannelData.parse(chData));
		}

		// Load object with distances between segments,cmp's and features.
		const pipelineData: SegmentData[] = await PipelineImport.loadPipelineData(this.pipelineUuid, this.cmpUuid);

		const series: any = [];
		const markLine: any = [];

		// Add data series for features/CMP.
		for (let i = 0; i < pipelineData.length; i++) {
			// CMP position
			for (const cmpD of pipelineData[i].cmps) {
				markLine.push({
					name: cmpD.name,
					xAxis: cmpD.distance,
					lineStyle: {color: CSSUtils.getVariable('--special-blue-1'), type: 'solid', width: 2},
					label: {position: 'end', show: 'true', formatter: '{b}'}
				});
			}

			// Features position
			for (const featD of pipelineData[i].features) {
				markLine.push({
					name: featD.description,
					xAxis: featD.distance,
					lineStyle: {color: CSSUtils.getVariable(FeatureTypeColor.get(featD.type)), type: 'solid', width: 2},
					label: {position: 'end', show: 'true', formatter: '{b}'}
				});
			}
		}

		series.push({
			type: 'line',
			markLine: {data: markLine, symbol: ['none', 'none']}
		});
		
		// Add data series for channels.
		const title: string = `${new Date(experiment.dateTime).toLocaleString(Locale.code)} - ${experiment.frequency}KHZ`;
		const channelsLegend: any = [];
		for (let i = 0; i < channelData.length; i++) {
			series.push({
				type: 'line',
				data: channelData[i].data,
				name: channelData[i].name,
				symbol: 'none',
				sampling: 'lttb,',
				tooltip: {valueFormatter: (value: number) => {return Math.round(value * 100) / 100;}}
				
			});

			channelsLegend.push(channelData[i].name);
		}

		// @ts-ignore
		this.chartOptions.legend.data = channelsLegend;
		// @ts-ignore
		this.chartOptions.title.text = title;
		this.chartOptions.series = series;
		this.chart.setOption(this.chartOptions);
	}

	/**
	 * Initialize e-chart with default options.
	 * 
	 * Data and specific options are added on experiment selection.
	 */
	public initChart(): void {
		this.chart = init(this.chartDiv.nativeElement, StyleManager.theme.echarts, {
			width: null,
			height: null,
			renderer: 'canvas',
			locale: Locale.code
		});

		// Add options to chart
		this.chartOptions = {
			title: {},
			tooltip: {trigger: 'axis'},
			legend: {},
			grid: {
				left: '3%',
				right: '4%',
				bottom: '3%',
				containLabel: true
			},
			xAxis: {
				type: 'value',
				axisLabel: {formatter: function(value: any): string {return value.toFixed(2);}}
			},
			yAxis: {
				type: 'value',
				axisLabel: {formatter: function(value: any): string {return value.toFixed(2);}}
			},
			dataZoom: [
				{
				  type: 'slider',
				  xAxisIndex: 0,
				  filterMode: 'none'
				},
				{
				  type: 'slider',
				  yAxisIndex: 0,
				  filterMode: 'none'
				},
				{
				  type: 'inside',
				  xAxisIndex: 0,
				  filterMode: 'none'
				},
				{
				  type: 'inside',
				  yAxisIndex: 0,
				  filterMode: 'none'
				}
			  ],
			series: []
		};

		this.chart.setOption(this.chartOptions);
	}
}
