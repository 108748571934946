<div class="ion-padding-vertical">
	<ng-template [ngIf]="this.disabled">
		<ion-icon src="assets/icons/material/nfc/outline.svg" style="color: var(--gray-8); font-size: 70px;"></ion-icon>
	</ng-template>
	<ng-template [ngIf]="!this.disabled">
		<ion-icon (click)="this.select();" src="assets/icons/material/nfc/outline.svg" style="cursor: pointer; font-size: 70px;"></ion-icon>
		<ion-button mode="ios" *ngIf="this.value" (click)="this.writeValue(null)" expand="block" fill="outline" style="max-width: 150px;">
			<ion-label slot="end">{{'clear' | translate }}</ion-label>
		</ion-button>
	</ng-template>
</div>
