import {Component, OnInit} from '@angular/core';
import {App} from '../../../../app';
import {ScreenComponent} from '../../../../components/screen/screen.component';
import {Session} from '../../../../session';
import {UserPermissions} from '../../../../models/users/user-permissions';
import {Service} from '../../../../http/service';
import {ServiceList} from '../../../../http/service-list';
import {ObservationStatus} from '../../../../models/observations/observation-status';
import {MenuCardsGroup, MenuCardsGroupsComponent} from '../../../../components/layout/menu-cards-groups/menu-cards-groups.component';
import {UnoContentComponent} from '../../../../components/uno/uno-content/uno-content.component';

@Component({
	selector: 'observations-page',
	templateUrl: './observations.page.html',
	standalone: true,
	imports: [UnoContentComponent, MenuCardsGroupsComponent]
})
export class ObservationsPage extends ScreenComponent implements OnInit {

	public get app(): any { return App; }

	public get session(): any { return Session; }

	public permissions = [UserPermissions.OBSERVATIONS];

	public groups: MenuCardsGroup[] = [
		{
			label: 'global',
			permissions: [],
			options: [
				{
					label: 'create',
					route: '/menu/observations/create',
					icon: 'add',
					permissions: [UserPermissions.OBSERVATIONS_CREATE],
					data: null,
					badge: null
				},
				{
					label: 'list',
					route: '/menu/observations/list',
					icon: 'create',
					permissions: [],
					data: null,
					badge: (async() => {
						return (await Service.fetch(ServiceList.observation.count, null, null, null, Session.session, true)).response.count;
					})()
				}
			]
		},
		{
			label: 'todo',
			permissions: [],
			options: [
				{
					label: 'todo',
					route: '/menu/observations/list',
					icon: 'hammer',
					permissions: [],
					data: {status: ObservationStatus.TODO},
					badge: (async() => {
						return (await Service.fetch(ServiceList.observation.count, null, null, {status: ObservationStatus.TODO}, Session.session, true)).response.count;
					})()
				}
			]
		},
		{
			label: 'completed',
			permissions: [],
			options: [
				{
					label: 'done',
					route: '/menu/observations/list',
					icon: 'checkbox-outline',
					permissions: [],
					data: {status: ObservationStatus.DONE},
					badge: (async() => {
						return (await Service.fetch(ServiceList.observation.count, null, null, {status: ObservationStatus.DONE}, Session.session, true)).response.count;
					})()
				}
			]
		}
	];

	public ngOnInit(): void {
		super.ngOnInit();
		
		App.navigator.setTitle('observations');
	}
}
