import {Component, OnInit} from '@angular/core';
import {SortDirection} from 'src/app/utils/sort-direction';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {NgStyle} from '@angular/common';
import {ScreenComponent} from '../../../../components/screen/screen.component';
import {App} from '../../../../app';
import {Service} from '../../../../http/service';
import {Team} from '../../../../models/teams/team';
import {UserPermissions} from '../../../../models/users/user-permissions';
import {UUID} from '../../../../models/uuid';
import {Session} from '../../../../session';
import {ServiceList} from '../../../../http/service-list';
import {UnoNoDataComponent} from '../../../../components/uno/uno-no-data/uno-no-data.component';
import {UnoContentComponent} from '../../../../components/uno/uno-content/uno-content.component';
import {UnoSearchbarComponent} from '../../../../components/uno/uno-searchbar/uno-searchbar.component';
import {UnoButtonComponent} from '../../../../components/uno/uno-button/uno-button.component';
import {TeamListItemComponent} from './team-list-item/team-list-item.component';

/**
 * The extension of a team adding its children sub-teams and the loader function
 */
export class HierarchicalTeam extends Team {
	/**
	 * List of team children elements.
	 */
	public children: HierarchicalTeam[] = null;

	/**
	 * Indicates if children have been loaded.
	 */
	public hasChildren: boolean = false;
}

@Component({
	selector: 'teams-page',
	templateUrl: 'team-list.page.html',
	standalone: true,
	imports: [UnoButtonComponent, UnoSearchbarComponent, IonicModule, FormsModule, UnoContentComponent, NgStyle, UnoNoDataComponent, TeamListItemComponent, TranslateModule]
})
export class TeamListPage extends ScreenComponent implements OnInit {

	public get app(): any { return App; }

	public get session(): any { return Session; }

	public get userPermissions(): any { return UserPermissions; }

	public get selfStatic(): any { return TeamListPage; }

	public permissions = [UserPermissions.TEAM];
	
	/**
	 * Possible database filter to be used for ordering the teams list.
	 */
	public static sortFieldValues: any = [
		{label: 'name', attribute: 'name'},
		{label: 'createdAt', attribute: 'created_at'},
		{label: 'updatedAt', attribute: 'updated_at'}
	];

	/**
	 * Possible sort direction values.
	 */
	public static sortDirectionValues: any = [SortDirection.ASC, SortDirection.DESC];

	/**
	 * List of possible search fields
	 */
	public static searchFieldValues: any = [
		{label: 'name', attribute: 'name'}
	];

	/**
	 * List of teams retrieved from the API.
	 */
	public teams: HierarchicalTeam[] = [];

	public async ngOnInit(): Promise<void> {
		super.ngOnInit();
		
		App.navigator.setTitle('teams');

		this.teams = await TeamListPage.loadData(null);
	}


	public static filters = {
		/**
		 * Text used to filter list entries by their content.
		 * 
		 * searchFields cannot be set on this filter, the recursive query searchs from the threepath that contains only name attribute.
		 */
		search: '',

		/**
		 * Database attribute name used to sort the values.
		 */
		sortField: TeamListPage.sortFieldValues[0].attribute,

		/**
		 * Sort direction applied to the loaded list from database.
		 */
		sortDirection: TeamListPage.sortDirectionValues[0]
	};

	public static defaultFilters = structuredClone(TeamListPage.filters);

	public resetFilters(): void {
		Object.assign(TeamListPage.filters, TeamListPage.defaultFilters);
	}

	/**
	 * Update the search term used.
	 *
	 * @param event - DOM event.
	 */
	public async onFilterChange(event): Promise<void> {
		if (event.target === undefined) {
			TeamListPage.filters.search = event;
		}

		this.teams = await TeamListPage.loadData(null);
	}

	/**
	 * Load more data from the API to display. Data is loaded one tree branch at a time.
	 * 
	 * @param uuid - Team parent UUID whose child we want to load
	 */
	public static async loadData(uuid: UUID = null): Promise<any> {
		const data = {
			uuid: uuid,
			sortDirection: TeamListPage.filters.sortDirection,
			sortField: TeamListPage.filters.sortField,
			search: TeamListPage.filters.search
		};

		const request = await Service.fetch(ServiceList.teams.listChild, null, null, data, Session.session);
		return request.response.children;
	}
}
