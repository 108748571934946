<div class="ion-padding-vertical">
	@if (this.value !== null) {
		<video controls src="{{this.videoUrl}}" style="max-width: 200px; max-height: 200px; cursor: pointer"></video>
		@if (!this.disabled) {
			<ion-button mode="ios" (click)="this.writeValue(null)" expand="block" fill="outline">
				<ion-label slot="end">{{'delete' | translate }}</ion-label>
			</ion-button>
			<ion-button mode="ios" (click)="this.selectFile()" expand="block" fill="outline">
				<ion-label slot="end">{{'selectFile' | translate }}</ion-label>
			</ion-button>
		}
	}
	@if (!this.value) {
		@if (this.disabled) {
			<ion-icon name="videocam" style="font-size: 70px; opacity: 0.4;"></ion-icon>
		}
		@if (!this.disabled) {
			<ion-icon (click)="this.selectFile();" name="videocam" style="cursor: pointer; font-size: 70px;"></ion-icon>
		}
	}
</div>
