import {Color} from 'three';
import {MasterSettingsAppearance} from '../models/master-settings/master-settings-appearance';
import {Service} from '../http/service';
import {ServiceList} from '../http/service-list';
import {ThemeUtils} from '../utils/theme-utils';
import {ColorPalette, IonicCSSUtils} from '../utils/ionic-css-utils';
import {Locale} from '../locale/locale';
import {Themes} from './themes';
import {Theme} from './theme';

/**
 * Style manager is used to store, manage and access GUI theme related data.
 *
 * Also manages the master appearance settings that can be used to customize the base theme data.
 */
export class StyleManager {
	/**
	 * Theme currently applied to the system.
	 */
	public static theme: Theme = null;

	/**
	 * Master appearance configuration to obtain customization details from.
	 */
	public static appearance: MasterSettingsAppearance = null;

	/**
	 * Accent stylesheet, if not created is set to null.
	 */
	public static accentStylesheet: HTMLStyleElement = null;

	/**
	 * Get theme title.
	 */
	public static get title(): string {
		return this.appearance?.title?.length > 0 ? this.appearance.title : Locale.get('app');
	}

	/**
	 * Background picked randomly everytime that the app is run.
	 *
	 * Used for the login screen unless a customized background is defined.
	 */
	public static randomBackground = './assets/background/' + Math.ceil(Math.random() * 3) + '.jpg';

	/**
	 * Load appearance configuration to update the application style.
	 */
	public static async loadAppearance(): Promise<any> {
		const request = await Service.fetch(ServiceList.masterSettings.appearance.get, null, null, {}, null, true);
		StyleManager.setAppearance(MasterSettingsAppearance.parse(request.response.appearance));
	}

	/**
	 * Get the login background image URL.
	 */
	public static get loginBackground(): string {
		if (this.appearance && this.appearance.loginBackgroundImage) {
			return Service.getURL(ServiceList.resources.image.get, {
				uuid: this.appearance.loginBackgroundImage.uuid,
				format: this.appearance.loginBackgroundImage.format
			});
		}

		return StyleManager.randomBackground;
	}

	/**
	 * Get a gradient style based on the accent color selected by the user.
	 */
	public static get gradient(): string {
		if (this.appearance && this.appearance.accentColor) {
			const accent = new Color(this.appearance.accentColor);
			const palette: ColorPalette = IonicCSSUtils.paletteFromAccent(accent);
			return `linear-gradient(to bottom, rgba(${255 * palette.tertiary.r}, ${255 * palette.tertiary.g}, ${255 * palette.tertiary.b}, 0.2), rgba(${255 * palette.primary.r}, ${255 * palette.primary.g}, ${255 * palette.primary.b}, 0.4))`;
		}

		return 'linear-gradient(221deg, #5a01d344, #0902d444 67%, #0902d444 42%, #0902d444)';
	}

	/**
	 * Set the appearance configuration to use.
	 *
	 * @param appearance - Appearance description object.
	 */
	public static setAppearance(appearance: MasterSettingsAppearance): void {
		this.appearance = appearance;

		// Title
		const titles = document.getElementsByTagName('title');
		if (titles.length > 0) {
			titles[0].innerText = this.title;
		}

		// Meta color
		const metas = document.getElementsByTagName('meta');
		for (let i = 0; i < metas.length; i++) {
			const meta = metas[i];
			if (meta.getAttribute('name') === 'theme-color') {
				if (this.appearance && this.appearance.accentColor) {
					const accent = new Color(this.appearance.accentColor);
					meta.setAttribute('content', accent.getStyle());
				} else {
					meta.setAttribute('content', '#7e7e7e');
				}
			}
		}

		// Style sheet
		if (this.appearance && this.appearance.accentColor) {
			if (!this.accentStylesheet) {
				this.accentStylesheet = document.createElement('style');
				document.head.appendChild(this.accentStylesheet);
			}

			const accent = new Color(this.appearance.accentColor);
			this.accentStylesheet.innerText = 'body.accent { ' + ThemeUtils.generate(accent) + ' }';

			document.body.classList.toggle('accent', true);
		} else {
			document.body.classList.toggle('accent', false);
		}
	}

	/**
	 * Set the theme to be used in the application, iterates over the list of themes, disable all class lists and enables the one being set.
	 *
	 * @param theme - Id of the theme being activated
	 */
	public static setTheme(theme: string): void {
		for (let i = 0; i < Themes.length; i++) {
			if (Themes[i].class.length > 0) {
				document.body.classList.toggle(Themes[i].class, theme === Themes[i].id);
			}

			if (theme === Themes[i].id) {
				this.theme = Themes[i];
			}
		}
	}
}
