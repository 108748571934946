import {UserPermissions} from 'src/app/models/users/user-permissions';

/**
 * Role tree structure.
 */
export type UserPermissionTree = {
	// Role name
	label: string,
	// Role code
	value: number,
	// Collapsed
	collapsed: boolean,
	// Possible sub-permissions
	subPermissions: {
		// Sub-permission name
		label: string,
		// Sub-permission code
		value: number
	}[]
}

/**
 * Utility class for permission manipulation.
 * 
 * Contains methods to prepare list of permissions as tree and toggle permission selection.
 */
export class UserPermissionUtils {
	/**
	 * Prepare list of permissions as tree.
	 * 
	 * Fill the list with permissions / sub-permissions
     * 
     * @returns List of permissions as tree.
	 */
	public static getPermissionsTree(): UserPermissionTree[] {
		const options = [];

		for (const i in UserPermissions) {
			if (UserPermissions[i] % 1e3 === 0) {
				options.push({
					label: i,
					value: UserPermissions[i],
					collapsed: true,
					subPermissions: []
				});
			} else {
				options[options.length - 1].subPermissions.push({
					label: i,
					value: UserPermissions[i]
				});
			}
		}

		return options;
	}

	/**
	 * Toggle the selection of a permission.
	 *
	 * Automatically selects all sub-permissions if the permission provided is a module.
	 *
     * @param permissions - List of permissions to be updated.
	 * @param permission - Permission to be toggled.
     * @returns List of permissions updated.
	 */
	public static togglePermission(permissions: number[], permission: number): number[] {
		permissions = permissions.splice(0);

		const index = permissions.indexOf(permission);

		// Permission is not selected
		if (index === -1) {
			// Select category
			if (permission % 1e3 === 0) {
				const category = Math.floor(permission / 1e3);
				permissions.push(permission);
				for (const i in UserPermissions) {
					if (permissions.indexOf(UserPermissions[i]) === -1 && category === Math.floor(UserPermissions[i] / 1e3)) {
						permissions.push(UserPermissions[i]);
					}
				}
			} else {
				permissions.push(permission);
			}
		// Permission is selected
		} else {
			// Unselect category
			if (permission % 1e3 === 0) {
				const category = Math.floor(permission / 1e3);
				permissions.splice(index, 1);
				for (const i in UserPermissions) {
					const j = permissions.indexOf(UserPermissions[i]);
					if (j !== -1 && category === Math.floor(UserPermissions[i] / 1e3)) {
						permissions.splice(j, 1);
					}
				}
			} else {
				permissions.splice(index, 1);
			}
		}
        
		return permissions;
	}
}
