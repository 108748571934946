
import {Service} from 'src/app/http/service';
import {ServiceList} from 'src/app/http/service-list';
import {Pipeline} from 'src/app/models/pipeline-integrity/pipeline/pipeline';
import {UUID} from 'src/app/models/uuid';
import {Session} from 'src/app/session';

export type PipelineListParams = {
	// Plant uuid to apply on the request
	plantUuid: UUID,
	filters?: {
		// Search value to apply
		search?: string,
		// Fields to search by
		searchFields?: string[],
		// Sort Direction to apply on the response
		sortDirection?: string,
		// Sort Field to apply on the response
		sortField?: string,
		// From where to start fetching items
		offset?: number,
		// How many items to fetch
		count?: number,
	}
}

export type PipelineCountParams = {
	// Plant uuid to apply on the request
	plantUuid: UUID,
	filters?: {
		// Search value to apply
		search?: string,
		// Fields to search by
		searchFields?: string[],
	}
}

// Pipeline list API response format.
export type PipelineListResponse = {
	// If true, there are more items to fetch on the list.
	hasMore: boolean,
	// The fetched Pipelines.
	pipelines: Pipeline[],
	// The id of the api call.
	id: number
};

export class PipelineService {

	/**
	 * List all pipelines that match the params.
	 *
	 * @param params - The parameters to apply on the fetch request.
	 */
	public static async list(params: PipelineListParams): Promise<PipelineListResponse> {
		const request = await Service.fetch(ServiceList.pipelineIntegrity.pipeline.list, null, null, params, Session.session);

		return {
			hasMore: request.response.hasMore,
			id: request.id,
			pipelines: request.response.pipeline.map((data: any) => {return Pipeline.parse(data);})
		};
	}

	/**
	 * Count all pipelines that match the params.
	 *
	 * @param params - The parameters to apply on the fetch request.
	 */
	public static async count(params: PipelineCountParams): Promise<number> {
		return (await Service.fetch(ServiceList.pipelineIntegrity.pipeline.count, null, null, params, Session.session)).response.count;
	}
}
