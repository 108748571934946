<div style="display: flex; flex-direction: row; align-items: center;">
	@if (this.value) {
		@if (this.value.from) {
			<ion-icon name="calendar-outline" style="width: 15px; height: 15px; margin-right: 5px;"></ion-icon>
			<date-time [border]="this.border" (ngModelChange)="this.setFrom($event);" [mode]="this.mode" [(ngModel)]="this.value.from" [max]="this.max" [min]="this.min" [ngModelOptions]="{standalone: true}" placeholder="{{'fromDate' | translate}}" style="display:inline-block;"></date-time>
		}
		@if (this.value.to) {
			<ion-icon name="caret-forward-outline" style="width: 15px; height: 15px;"></ion-icon>
			<date-time [border]="this.border" (ngModelChange)="this.setTo($event);" [mode]="this.mode" [(ngModel)]="this.value.to" [max]="this.max" [min]="this.min" [ngModelOptions]="{standalone: true}" placeholder="{{'toDate' | translate}}" style="display:inline-block;"></date-time>
		}
	}
</div>
