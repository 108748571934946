import {Service} from '../../../http/service';
import {ServiceList} from '../../../http/service-list';
import {Locale} from '../../../locale/locale';
import {Modal} from '../../../modal';
import {ProgressBar} from '../../../progress-bar';
import {Session} from '../../../session';
import {FormSortUtils} from '../../../utils/form-sort-utils';
import {XlsxSheetData, XlsxUtils} from '../../../utils/xlsx-utils';
import {APAssetFormBlock, APAssetFormBlockPrivateFilter} from '../../../models/asset-portfolio/asset-form-block';
import {APAssetFormBlockField} from '../../../models/asset-portfolio/asset-form-block-field';
import {APAssetFormBlockFieldComponentTypeLabel} from '../../../models/asset-portfolio/asset-form-block-field-type';
import {APAssetFormTab} from '../../../models/asset-portfolio/asset-form-tab';
import {APAssetFormTabCard} from '../../../models/asset-portfolio/asset-form-tab-card';
import {APAssetSubType} from '../../../models/asset-portfolio/asset-sub-type';
import {APAssetType} from '../../../models/asset-portfolio/asset-type';
import {ResourceUtils} from '../../../utils/resource-utils';

export class AssetPortfolioStructureDataTools {
	/**
	 * The name of the asset types sheet name, used on XLSX files from locale.
	 */
	public static assetTypesSheetName: string = 'assetTypes';

	/**
	 * The name of the asset subtypes sheet name, used on XLSX files from locale.
	 */
	public static assetSubTypesSheetName: string = 'assetSubTypes';

	/**
	 * The name of the form tabs sheet name, used on XLSX files from locale.
	 */
	public static formTabsSheetName: string = 'formTabs';

	/**
	 * The name of the form tab cards sheet name, used on XLSX files from locale.
	 */
	public static formTabCardsSheetName: string = 'formTabCards';

	/**
	 * The name of the form blocks sheet name, used on XLSX files from locale.
	 */
	public static formBlocksSheetName: string = 'formBlock';

	/**
	 * The name of the asset form block fields sheet name, used on XLSX files from locale.
	 */
	public static formBlockFieldsSheetName: string = 'formBlockFields';

	/**
	 * Sheet that contains the asset types base data. An asset type per row.
	 */
	public static assetTypesBaseInfoSheetData: ()=> XlsxSheetData = () => {
		return {
			name: Locale.get(AssetPortfolioStructureDataTools.assetTypesSheetName),
			data: [
				[
					// Sheet headers
					Locale.get('uuid'), Locale.get('createdAt'), Locale.get('updatedAt'), 
					Locale.get('name'), Locale.get('description'),
					Locale.get('picture'),
					Locale.get('allowedParentTypes'), Locale.get('allowedOnRoot')
				]
			]
		};
	};

	/**
	 * Sheet that contains the asset subtypes base data. An asset subtype per row.
	 */
	public static assetSubtypesBaseInfoSheetData: ()=> XlsxSheetData = () => {
		return {
			name: Locale.get(AssetPortfolioStructureDataTools.assetSubTypesSheetName),
			data: [
				[
					// Sheet headers
					Locale.get('uuid'), Locale.get('createdAt'), Locale.get('updatedAt'), 
					Locale.get('name'), Locale.get('description'),
					Locale.get('assetTypeUuid'), Locale.get('assetType'), Locale.get('parentSubTypeUuid'), Locale.get('parentSubType')
				]
			]
		};
	};

	/**
	 * Sheet that contains the form tabs data. A form tab per row.
	 */
	public static tabsSheetData: ()=> XlsxSheetData = () => {
		return {
			name: Locale.get(AssetPortfolioStructureDataTools.formTabsSheetName),
			data: [
				[
					// Sheet headers
					Locale.get('uuid'), Locale.get('createdAt'), Locale.get('updatedAt'), 
					Locale.get('name'), Locale.get('description'),
					Locale.get('assetTypeUuid'), Locale.get('assetType'), Locale.get('assetSubTypeUuid'), Locale.get('assetSubType'),
					Locale.get('indexes') 
				]
			]
		};
	};

	/**
	 * Sheet that contains the form tab cards data. A form tab card per row.
	 */
	public static cardsSheetData: ()=> XlsxSheetData = () => {
		return {
			name: Locale.get(AssetPortfolioStructureDataTools.formTabCardsSheetName),
			data: [
				[
					// Sheet headers
					Locale.get('uuid'), Locale.get('createdAt'), Locale.get('updatedAt'), 
					Locale.get('name'), Locale.get('description'),
					Locale.get('privateBlock'),
					Locale.get('formTabUuid'), Locale.get('formTab'),
					Locale.get('assetSubTypeUuid'), Locale.get('assetSubType'),
					Locale.get('formBlockUuid'), Locale.get('formBlock'),
					Locale.get('indexes')
				]
			]
		};
	};

	/**
	 * Sheet that contains the form block data. A form block per row.
	 */
	public static blocksSheetData: ()=> XlsxSheetData = () => {
		return {
			name: Locale.get(AssetPortfolioStructureDataTools.formBlocksSheetName),
			data: [
				[
				// Sheet headers
					Locale.get('uuid'), Locale.get('createdAt'), Locale.get('updatedAt'), 
					Locale.get('name'), Locale.get('description'),
					Locale.get('privateBlock')
				]
			]
		};
	};

	/**
	 * Sheet that contains the form block fields data. A form block field per row.
	 */
	public static fieldsSheetData: ()=> XlsxSheetData = () => {
		return {
			name: Locale.get(AssetPortfolioStructureDataTools.formBlockFieldsSheetName),
			data: [
				[
				// Sheet headers
					Locale.get('uuid'), Locale.get('createdAt'), Locale.get('updatedAt'), 
					Locale.get('name'), Locale.get('description'),
					Locale.get('formBlockUuid'), Locale.get('formBlock'),
					Locale.get('required'),
					Locale.get('componentName'),
					Locale.get('data'),
					Locale.get('indexes')
				]
			]
		};
	};

	/**
	 * Export structure of the asset portfolio as XLSX file.
	 * 
	 * Asset types, subtypes, tabs, cards, blocks and fields are exported by this tool.
	 */
	public static async exportXLSX(): Promise<void> {		
		// The progress bar used to display the export progress on a modal
		const progressBar: ProgressBar = new ProgressBar();
		progressBar.show();

		try {
			const assetTypesSheetData: XlsxSheetData = AssetPortfolioStructureDataTools.assetTypesBaseInfoSheetData();
			const assetSubtypesSheetData: XlsxSheetData = AssetPortfolioStructureDataTools.assetSubtypesBaseInfoSheetData();
			const tabsSheetData: XlsxSheetData = AssetPortfolioStructureDataTools.tabsSheetData();
			const cardsSheetData: XlsxSheetData = AssetPortfolioStructureDataTools.cardsSheetData();
			const blocksSheetData: XlsxSheetData = AssetPortfolioStructureDataTools.blocksSheetData();
			const fieldsSheetData: XlsxSheetData = AssetPortfolioStructureDataTools.fieldsSheetData();
	
			const workBookSheets: XlsxSheetData[] = [assetTypesSheetData, assetSubtypesSheetData, tabsSheetData, cardsSheetData, blocksSheetData, fieldsSheetData];

			// Get the list of asset types and parse them into the correct objects
			const reqTypes = await Service.fetch(ServiceList.assetPortfolio.assetType.listDetailed, null, null, null, Session.session, true, false);
			const assetTypes: APAssetType[] = reqTypes.response.types.map((t: any) => { return APAssetType.parse(t); });
	
			// Get the list of asset subtypes and parse them into the correct objects
			const reqSubTypes = await Service.fetch(ServiceList.assetPortfolio.assetSubType.listDetailed, null, null, null, Session.session, true, false);
			const assetSubtypes: APAssetSubType[] = reqSubTypes.response.subTypes.map((s: any) => { return APAssetSubType.parse(s); });

			// Get the list of all form tabs and parse them into the correct objects
			const reqTabs = await Service.fetch(ServiceList.assetPortfolio.formTab.listAll, null, null, null, Session.session, true, false);
			const tabs: APAssetFormTab[] = reqTabs.response.tabs.map((t: any) => { return APAssetFormTab.parse(t); });
			
			// Get the list of all form tab cards and parse them into the correct objects
			const reqTabCards = await Service.fetch(ServiceList.assetPortfolio.formTabCard.listAll, null, null, null, Session.session, true, false);
			const cards: APAssetFormTabCard[] = reqTabCards.response.cards.map((c: any) => { return APAssetFormTabCard.parse(c); });

			// Get the list of form blocks with their fields and parse them into the correct objects
			const reqBlocks = await Service.fetch(ServiceList.assetPortfolio.formBlock.listDetailed, null, null, {listFields: true, privateFilter: APAssetFormBlockPrivateFilter.ALL}, Session.session, true, false);
			const formBlocks: APAssetFormBlock[] = reqBlocks.response.blocks.map((b: any) => { return APAssetFormBlock.parse(b); });
			
			// Compute the total number of fields to process
			const fieldsLength: number = formBlocks.reduce((sum: number, block: APAssetFormBlock) => { return sum + block.fields.length; }, 0);

			// Setter for the progress bar value
			let count: number = 0; 
			const updateProgress: Function = function() {
				// Update progress
				progressBar.update(Locale.get('export'), count / (assetTypes.length + assetSubtypes.length + tabs.length + cards.length + formBlocks.length + fieldsLength));
			};

			for (let i = 0; i < assetTypes.length; i++) {
				count++;
				updateProgress();
	
				const type: APAssetType = assetTypes[i];
								
				// Push asset type data to the asset types sheet
				assetTypesSheetData.data.push([
					type.uuid, type.createdAt ? type.createdAt.toLocaleString(Locale.code) : '', type.updatedAt ? type.updatedAt.toLocaleString(Locale.code) : '',
					type.name, type.description,
					ResourceUtils.getURL(type.picture),
					JSON.stringify(type.allowedParentTypesUuids), type.allowedOnRoot
				]);
			}

			for (let i = 0; i < assetSubtypes.length; i++) {
				count++;
				updateProgress();
					
				// Push asset subtype data to the asset types sheet
				const subType: APAssetSubType = assetSubtypes[i];
				assetSubtypesSheetData.data.push([
					subType.uuid, subType.createdAt ? subType.createdAt.toLocaleString(Locale.code) : '', subType.updatedAt ? subType.updatedAt.toLocaleString(Locale.code) : '',
					subType.name, subType.description,
					subType.typeUuid ? subType.typeUuid : '', subType.typeUuid ? assetTypes.find((t: APAssetType) => {return t.uuid === subType.typeUuid;}).name : '',
					subType.parentSubTypeUuid ? subType.parentSubTypeUuid : '', subType.parentSubTypeUuid ? assetSubtypes.find((s: APAssetSubType) => {return s.uuid === subType.parentSubTypeUuid;}).name : ''
				]);
			}

			for (let i = 0; i < tabs.length; i++) {
				count++;
				updateProgress();

				const tab: APAssetFormTab = tabs[i];
								
				// Push form tab data to the form tabs sheet
				tabsSheetData.data.push([
					tab.uuid, tab.createdAt ? tab.createdAt.toLocaleString(Locale.code) : '', tab.updatedAt ? tab.updatedAt.toLocaleString(Locale.code) : '',
					tab.name, tab.description,
					tab.typeUuid ? tab.typeUuid : '', tab.typeUuid ? assetTypes.find((t: APAssetType) => {return t.uuid === tab.typeUuid;}).name : '',
					tab.subTypeUuid ? tab.subTypeUuid : '', tab.subTypeUuid ? assetSubtypes.find((s: APAssetSubType) => {return s.uuid === tab.subTypeUuid;}).name : '',
					tab.indexes
				]);
			}

			for (let i = 0; i < cards.length; i++) {
				count++;
				updateProgress();

				const card: APAssetFormTabCard = cards[i];

				// Push form tab card data to the form tab cards sheet
				cardsSheetData.data.push([
					card.uuid, card.createdAt ? card.createdAt.toLocaleString(Locale.code) : '', card.updatedAt ? card.updatedAt.toLocaleString(Locale.code) : '',
					card.name, card.description,
					card.usesPrivateBlock,
					card.tabUuid, card.tabUuid ? tabs.find((t: APAssetFormTab) => {return t.uuid === card.tabUuid;}).name : '', 
					card.subTypeUuid, card.subTypeUuid ? assetSubtypes.find((s: APAssetSubType) => {return s.uuid === card.subTypeUuid;}).name : '', 
					card.blockUuid, card.blockUuid ? formBlocks.find((b: APAssetFormBlock) => {return b.uuid === card.blockUuid;}).name : '', 
					card.indexes
				]);
			}

			for (let i = 0; i < formBlocks.length; i++) {
				count++;
				updateProgress();

				const block: APAssetFormBlock = formBlocks[i];
				block.fields = FormSortUtils.sortByIndexes(block.fields);

				// Push form block data to the form blocks sheet
				blocksSheetData.data.push([
					block.uuid, block.createdAt ? block.createdAt.toLocaleString(Locale.code) : '', block.updatedAt ? block.updatedAt.toLocaleString(Locale.code) : '',
					block.name, block.description,
					block.privateBlock
				]);

				for (let j = 0; j < block.fields.length; j++) {
					count++;
					updateProgress();

					const field: APAssetFormBlockField = block.fields[j];
					
					// Push form block field data to the form block fields sheet
					fieldsSheetData.data.push([
						field.uuid, field.createdAt ? field.createdAt.toLocaleString(Locale.code) : '', field.updatedAt ? field.updatedAt.toLocaleString(Locale.code) : '',
						field.name, field.description,
						block.uuid, block.name,
						field.required,
						Locale.get(APAssetFormBlockFieldComponentTypeLabel.get(field.formFieldComponent)),
						field.data ? JSON.stringify(field.data) : '',
						field.indexes
					]);
				}
			}

			XlsxUtils.writeMultiSheetFile(workBookSheets, 'asset-portfolio-structure.xlsx');
		} catch (e) {
			Modal.alert(Locale.get('error'), e.response ? e.response : e);
		}

		progressBar.destroy();
	}
}
