import {UUID} from '../../uuid';
import {Geolocation} from '../../geolocation';
import {FeatureType} from './feature-type';

/**
 * A feature can be a support, weld or joint.
 * 
 * It's something that influences values retrieved and must be flagged in the segment.
 */
export class Feature {

	/**
	 * UUID of the feature.
	 */
	public uuid: UUID = null;

	/**
	 * Type of feature(weld,joint,support, etc...)
	 */
	public type: number = FeatureType.NONE;

	/**
	 * Subtype of the feature.
	 */
	public subtype: string = '';

	/**
	 * Name of the feature.
	 */
	public name: string = '';

	/**
	 * Size of the feature in meters.
	 */
	public size: number = 0;

	/**
	 * Observations about the feature.
	 */
	public observations: string = '';

	/**
	 * Tag of the feature.
	 */
	public tag: string = '';

	/**
	 * GPS position of the feature.
	 */
	public position: Geolocation = null;

	/**
	 * UUID of the segment this feature belongs to.
	 */
	public segmentUuid: UUID = null;

	/**
	 * Parse the data of an API retrieved feature object.
	 *
	 * @param data - Feature object to parse data
	 */
	public static parse(data: any): Feature {
		const feature = new Feature();

		feature.uuid = data.uuid;
		feature.type = data.type;
		feature.subtype = data.subtype;
		feature.name = data.name;
		feature.size = data.size;
		feature.observations = data.observations;
		feature.tag = data.tag;
		

		// GPS Position
		if (data.position) {
			feature.position = Geolocation.parse(data.position);
		}

		feature.segmentUuid = data.segmentUuid;

		return feature;
	}
}
