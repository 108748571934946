import {PointCloudOctree, Potree} from 'potree-core';
import {Box3, Points, Vector3} from 'three';
import {Environment} from '../../../../../environments/environment';
import {UUID} from '../../../../models/uuid';
import {Mouse} from '../../render/input/mouse';
import {Keyboard} from '../../render/input/keyboard';
import {DigitalTwinObject} from '../digital-twin-object';
import {DigitalTwinObjectType} from '../digital-twin-object-type';

/**
 * Point cloud object is used to represent a potree point cloud object.
 */
export class PointCloud extends DigitalTwinObject {
	/**
	 * Point cloud manager object.
	 *
	 * Used to load a potree point cloud data and manage the point budget.
	 */
	public static potree: Potree = new Potree();

	/**
	 * List of point cloud octrees currently visible in the scene.
	 *
	 * Before loading a new scene the reset() method has to be called to clean up the scene.
	 */
	public static pointClouds: PointCloudOctree[] = [];

	/**
	 * Point cloud octree.
	 */
	public pointCloudOctree: PointCloudOctree = null;

	/**
	 * Bounding box covers the volume of the point cloud tree.
	 */
	public boundingBox: Box3 = null;

	/**
	 * UUID of the point cloud data.
	 *
	 * UUID is used to load point cloud data from the digital twin server.
	 */
	public pointCloudUuid: UUID = null;

	/**
	 * Offset applied to the position of the point cloud.
	 *
	 * Automatically set from the point cloud bounding box on first load.
	 */
	public offset: Vector3 = null;
	
	public constructor() {
		super(DigitalTwinObjectType.POINTCLOUD);

		this.name = 'pointcloud ' + this.uuid.substr(0, 3);
	}

	/**
	 * Reset the point cloud handlers.
	 *
	 * Should be called before loading a new scene. The handler list is used to update the point clouds visible in the scene.
	 */
	public static reset(): void {
		this.pointClouds = [];
		this.potree.pointBudget = 1e8;
	}

	public async initialize(): Promise<void> {
		if (this.pointCloudUuid) {
			await this.load();
		}
	}

	/**
	 * Load point cloud from its UUID.
	 *
	 * @param pointCloudUuid - UUID of the point cloud to load from the server.
	 */
	public async setPointCloud(pointCloudUuid: string): Promise<void> {
		this.pointCloudUuid = pointCloudUuid;
		await this.load();
	}

	/**
	 * Load point cloud from the digital twin server.
	 *
	 * The potree manager will handle all the subsequent requests of the point cloud octree structure.
	 */
	public async load(): Promise<void> {
		const server = Environment.DIGITAL_TWIN_SERVER + '/v1/pointcloud/get/';

		this.pointCloudOctree = await PointCloud.potree.loadPointCloud('metadata.json', (url) => {return server + this.pointCloudUuid + '/' + url;});
		this.pointCloudOctree.material.size = 1.0;
		this.pointCloudOctree.material.shape = 2; // PointShape.PARABOLOID
		this.pointCloudOctree.material.pointSizeType = 2; // PointSizeType.ADAPTIVE
		this.add(this.pointCloudOctree);
		
		if (this.offset) {
			this.pointCloudOctree.position.copy(this.offset);
		} else {
			this.pointCloudOctree.position.set(0, 0, 0);
		}

		this.pointCloudOctree.updateMatrix();

		PointCloud.pointClouds.push(this.pointCloudOctree);

		console.log('EQS: Loaded pointcloud octree.', this.pointCloudOctree, this.pointCloudOctree.material);
	}

	public update(time: number, delta: number, mouse: Mouse, keyboard: Keyboard): void {
		if ((this.offset === null || this.boundingBox === null) && this.pointCloudOctree !== null && this.pointCloudOctree.children.length > 0) {
			// @ts-ignore
			const points: Points = this.pointCloudOctree.children[0];
			points.geometry.computeBoundingBox();

			this.boundingBox = points.geometry.boundingBox.clone();

			const box = this.boundingBox.clone();
			this.offset = box.min;
			this.offset.add(box.max);
			this.offset.divideScalar(-2);

			this.pointCloudOctree.position.copy(this.offset);
			this.pointCloudOctree.updateMatrix();
		}
	}

	public parse(data: any): any {
		super.parse(data);

		this.pointCloudUuid = data.data.pointCloudUuid || null;

		if (data.data.offset) {
			this.offset = new Vector3();
			this.offset.fromArray(data.data.offset);
		}

		if (data.data.boundingBox && data.data.boundingBox.length > 0) {
			this.boundingBox = new Box3();
			this.boundingBox.min.fromArray(data.data.boundingBox.slice(0, 3));
			this.boundingBox.max.fromArray(data.data.boundingBox.slice(3, 6));
		}
	}

	public toJSON(): any {
		const data = super.toJSON();
		data.data.pointCloudUuid = this.pointCloudUuid || null;
		data.data.offset = this.offset ? this.offset.toArray() : null;

		if (this.boundingBox) {
			let bbox: number[] = [];
			bbox = bbox.concat(this.boundingBox.min.toArray());
			bbox = bbox.concat(this.boundingBox.max.toArray());
			data.data.boundingBox = bbox;
		} else {
			data.data.boundingBox = null;
		}

		return data;
	}
}

PointCloud.reset();
