import {FileUtils} from '../../../../utils/file-utils';
import {InspectionForm} from '../../../../models/inspections/form/inspection-form';

/**
 * Utils to export data from inspection forms.
 */
export class InspectionFormExport {
	/**
	 * Export form as JSON file.
	 *
	 * @param form - Inspection form to export.
	 */
	public static exportJSON(form: InspectionForm): void {
		FileUtils.writeFile('form.json', JSON.stringify(form, null, '\t'));
	}
}
