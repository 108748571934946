import {InputOptionsMultipleLazyPageRequest, InputOptionsMultipleBatchRequest} from 'src/app/components/input/options-multiple-lazy/options-multiple-lazy.component';
import {Session} from 'src/app/session';
import {SortDirection} from 'src/app/utils/sort-direction';
import {UnoFormFieldTypes} from '../../../../../../components/uno-forms/uno-dynamic-form/uno-form-field-types';
import {UnoFormField} from '../../../../../../components/uno-forms/uno-dynamic-form/uno-form-field';
import {Service} from '../../../../../../http/service';
import {ServiceList} from '../../../../../../http/service-list';
import {ServiceResponse} from '../../../../../../http/service-response';
import {AtexInspectionFormFieldType} from '../../../data/master-data/atex-inspection-fields';

export const AtexInspectionFormFieldLayout: UnoFormField[] = [
	{
		label: 'attribute',
		attribute: 'attribute',
		required: true,
		type: UnoFormFieldTypes.TEXT
	},
	{
		label: 'label',
		attribute: 'label',
		required: true,
		type: UnoFormFieldTypes.TEXT
	},
	{
		label: 'type',
		attribute: 'type',
		sort: false,
		required: true,
		type: UnoFormFieldTypes.OPTIONS,
		options: [
			{label: 'title', value: AtexInspectionFormFieldType.TITLE},
			{label: 'field', value: AtexInspectionFormFieldType.FIELD}
		]
	},
	{
		label: 'required',
		attribute: 'required',
		type: UnoFormFieldTypes.CHECKBOX,
		isActive: function(obj) {return obj.type === AtexInspectionFormFieldType.FIELD;}
	},
	{
		label: 'inspector',
		attribute: 'inspector',
		type: UnoFormFieldTypes.CHECKBOX,
		isActive: function(obj) {return obj.type === AtexInspectionFormFieldType.FIELD;}
	},
	{
		label: 'backoffice',
		attribute: 'backoffice',
		type: UnoFormFieldTypes.CHECKBOX,
		isActive: function(obj) {return obj.type === AtexInspectionFormFieldType.FIELD;}
	},
	{
		label: 'justifications',
		attribute: 'justifications',
		type: UnoFormFieldTypes.STRING_ARRAY,
		isActive: function(obj) {return obj.type === AtexInspectionFormFieldType.FIELD;}
	},
	{
		attribute: 'onlyApplicable',
		label: 'onlyApplicable',
		type: UnoFormFieldTypes.OPTIONS_MULTIPLE_LAZY,
		showClear: true,
		required: false,
		multiple: true,
		placeholder: 'all',
		identifierAttribute: 'uuid',
		isActive: function(obj) {return obj.type === AtexInspectionFormFieldType.FIELD;},
		fetchOptionsLazy: async function(request: InputOptionsMultipleLazyPageRequest, params: any): Promise<void> {
			const data = {
				from: request.from,
				count: request.count,
				search: request.search,
				typeUuid: params.typeUuid,
				sortField: '[ap_asset_sub_type].[name]',
				sortDirection: SortDirection.ASC
			};

			try {
				const req: ServiceResponse = await Service.fetch(ServiceList.assetPortfolio.assetSubType.listName, null, null, data, Session.session);
				request.onFinish(req.response.subTypes, req.response.hasMore, req.id);
			} catch {
				request.onError();
			}
		},
		fetchOptionsBatch: async function(request: InputOptionsMultipleBatchRequest, object: any): Promise<void> {
			const data = {subTypes: request.options};

			try {
				const req: ServiceResponse = await Service.fetch(ServiceList.assetPortfolio.assetSubType.getBatch, null, null, data, Session.session);
				request.onFinish(req.response.subType);
			} catch {}
		},
		getOptionText: function(option: any): string {
			return option.name;
		}
	}
];
