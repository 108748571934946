import {Service} from '../../../../http/service';
import {ServiceList} from '../../../../http/service-list';
import {Session} from '../../../../session';

/**
 * Object that represents a repair welding standard.
 */
export class RepairWeldingStandard {
	/**
	 * Numeric ID.
	 */
	public id: number = 0;

	/**
	 * Label shown to the user.
	 */
	public label: string = null;

	/**
	 * List of all possible repair welding standards.
	 */
	public static async load(): Promise<RepairWeldingStandard[]> {
		const request = await Service.fetch(ServiceList.repairs.weldingStandard.list, null, null, null, Session.session);

		return Promise.resolve(request.response.standards);
	}
}
