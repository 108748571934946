<div class="ion-padding-vertical">
	@if (this.disabled) {
		@if (this.value) {
			<ion-img (click)="this.save()" src="{{this.preview}}" style="object-fit: contain; width: 120px; height: 120px; cursor: pointer;"></ion-img>
		}
		@if (!this.value) {
			<ion-icon name="qr-code-outline" style="color: var(--gray-8); font-size: 70px;"></ion-icon>
		}
	}
	@if (!this.disabled) {
		@if (this.value) {
			<ion-img (click)="this.save()" src="{{this.preview}}" style="object-fit: contain; width: 120px; height: 120px; cursor: pointer;"></ion-img>
			<ion-button mode="ios" (click)="this.select()" expand="block" fill="outline" style="max-width: 150px;">
				<ion-label slot="end">{{'register' | translate }}</ion-label>
			</ion-button>
			<ion-button mode="ios" (click)="this.writeValue(null)" expand="block" fill="outline" style="max-width: 150px;">
				<ion-label slot="end">{{'clear' | translate }}</ion-label>
			</ion-button>
		}
		@if (!this.value) {
			<ion-icon (click)="this.select();" name="qr-code-outline" style="cursor: pointer; font-size: 70px;"></ion-icon>
		}
	}
</div>
