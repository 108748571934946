import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {RolePage} from './screens/list/role.page';
import {RoleEditPage} from './screens/edit/role-edit.page';
import {RoleCreatePage} from './screens/create/role-create.page';

const routes: Routes = [
	{
		path: '',
		component: RolePage
	},
	{
		path: 'edit',
		component: RoleEditPage
	},
	{
		path: 'create',
		component: RoleCreatePage
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class RolesRouterModule { }
