<uno-tab>
	@if (this.form) {
		<uno-tab-section [active]="true" title="{{'form' | translate}}">
			<!-- Form -->
			<uno-dynamic-form [layout]="this.layout" [object]="this.form" #baseForm></uno-dynamic-form>

			<!-- Fields -->
			<uno-title>{{'fields' | translate}}</uno-title>
			@if (this.form.fields.length > 0) {
				@for (field of this.form.fields; track field) {
					<uno-dynamic-form [layout]="this.formFieldLayout" [object]="field" #fieldsForm></uno-dynamic-form>
					<div style="display: flex; flex-direction: row-reverse; gap: 5px;" class="ion-padding">
						<uno-button (click)="this.removeField(field);" color="error">{{'delete' | translate}}</uno-button>
						@if (field.index < this.form.fields.length - 1) { <uno-button (click)="this.moveFieldUp(field);">
								<ion-icon name="caret-down-outline"></ion-icon>
							</uno-button>
						}
						@if (field.index > 0) {
							<uno-button (click)="this.moveFieldDown(field);">
								<ion-icon name="caret-up-outline"></ion-icon>
							</uno-button>
						}
					</div>
				}
			}
			@else {
				<ion-item class="ion-margin-horizontal ion-no-padding" lines="none">
					<ion-label>
						<p class="ion-margin-horizontal">{{'noFormFields' | translate}}</p>
					</ion-label>
				</ion-item>
			}

			<!-- Add Field -->
			<div style="display: flex; flex-direction: row-reverse; gap: 5px;" class="ion-padding">
				<uno-button (click)="this.addField();">{{'add' | translate}}</uno-button>
			</div>

			<!-- Save/Update -->
			<div style="display: flex; flex-direction: row-reverse; gap: 5px;" class="ion-padding">
				@if (session.hasPermissions([userPermissions.INSPECTION_FORM_CREATE])) {
					<uno-button (click)="this.update();">{{(this.createMode ? 'create' : 'save') | translate}}</uno-button>
				}
				@if (!this.createMode) {
					@if (session.hasPermissions([userPermissions.INSPECTION_FORM_EDIT])) {
						<uno-button (click)="this.update(true);">{{'update' | translate}}</uno-button>
					}
					@if (session.hasPermissions([userPermissions.INSPECTION_FORM_DELETE])) {
						<uno-button (click)="this.delete();" color="error">{{'delete' | translate}}</uno-button>
					}
					<uno-button (click)="inspectionFormExport.exportJSON(this.form);">{{'exportJSON' | translate}}</uno-button>
					<uno-button (click)="this.clone();">{{'clone' | translate}}</uno-button>
				}
			</div>
		</uno-tab-section>
		<uno-tab-section (onPresent)="preparePreviewLayout();" title="{{'preview' | translate}}">
			<uno-dynamic-form [editable]="true" [layout]="this.previewLayout" [object]="this.previewObject" #fieldsForm></uno-dynamic-form>
		</uno-tab-section>
	}
</uno-tab>
