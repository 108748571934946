
import JSZip from 'jszip';
import {Service} from 'src/app/http/service';
import {ServiceList} from 'src/app/http/service-list';
import {Locale} from 'src/app/locale/locale';
import {Modal} from 'src/app/modal';
import {APAsset} from 'src/app/models/asset-portfolio/asset';
import {InspectionForm} from 'src/app/models/inspections/form/inspection-form';
import {Inspection} from 'src/app/models/inspections/inspection/inspection';
import {InspectionProject} from 'src/app/models/inspections/project/inspection-project';
import {InspectionReportTemplate} from 'src/app/models/inspections/project/inspection-report-template';
import {InspectionWorkflowStep} from 'src/app/models/inspections/workflow/inspection-workflow-step';
import {UUID} from 'src/app/models/uuid';
import {InspectionReport} from 'src/app/modules/inspections/data/inspection/inspection-report';
import {InspectionService} from 'src/app/modules/inspections/services/inspection.service';
import {ProgressBar} from 'src/app/progress-bar';
import {Session} from 'src/app/session';
import {FileUtils} from 'src/app/utils/file-utils';
import {ReportTemplateFormat, ReportTemplateFormatLabel} from 'src/app/utils/report-template-format';
import {XlsxUtils} from 'src/app/utils/xlsx-utils';
import {StringUtils} from 'src/app/utils/string-utils';
import * as XLSX from 'xlsx';
import {FileReaderAsync} from 'src/app/utils/file-reader-async';
import {UnoFormFieldTypes} from '../../app/components/uno-forms/uno-dynamic-form/uno-form-field-types';
import {UnoFormField} from '../../app/components/uno-forms/uno-dynamic-form/uno-form-field';
import {InspectionAverageADN} from './inspection-average-adn';

/**
 * Utils to manipulate inspection reports in ADN environment.
 */
export class InspectionReportADN {

	/**
	 * Export the reports of all the inspections of all projects in DOCx, PDF or both for a specific list of asset UUIDs.
	 */
	public static async exportReportsBulk(): Promise<void> {
		const fileName = [
			{
				label: 'tag',
				value: 'tag'
			},
			{
				label: 'uuid',
				value: 'uuid'
			}
		];

		// Object with the needed layout attributes for the tool modal prompted to user
		const values = {
			// Export inspection reports formats
			formats: [],
			// File with assets uuids, respecting sample file layout
			assetsUuidXlsx: null,
			// Inspection key question codes, respecting sample file layout
			keyQuestionCodesXlsx: null,
			// Naming scheme to use for each file
			fileName: 'uuid'
		};
		
		const layout: UnoFormField[] = [
			{
				required: true,
				attribute: 'formats',
				label: 'formats',
				type: UnoFormFieldTypes.OPTIONS_MULTIPLE,
				sort: false,
				isEmpty: function(object) {
					return object.formats.length === 0;
				},
				options: Object.values(ReportTemplateFormat).map((value: number) => {
					return {
						label: ReportTemplateFormatLabel.get(value),
						value: value
					};
				})
			},
			{
				required: true,
				attribute: 'fileName',
				label: 'fileName',
				type: UnoFormFieldTypes.OPTIONS,
				sort: false,
				options: fileName
			},
			{
				required: true,
				attribute: 'assetsUuidXlsx',
				label: 'assetsXlsx',
				sampleData: 'assets/template/assets_inspections_export_input_sample.xlsx',
				type: UnoFormFieldTypes.DOCUMENT_RESOURCE,
				filter: '.xlsx',
				local: true,
				isEmpty: function(object) {
					return !object.assetsUuidXlsx;
				}
			},
			{
				required: false,
				attribute: 'keyQuestionCodesXlsx',
				label: 'keyQuestionCodesXlsx',
				sampleData: 'assets/template/key-question-codes.xlsx',
				local: true,
				type: UnoFormFieldTypes.DOCUMENT_RESOURCE,
				filter: '.xlsx'
			}
		];

		// Present modal to select which formats should be exported and select file to import assets UUIDs data column
		await Modal.form(Locale.get('exportInspectionsReportsBulk'), values, layout);

		// Extract document rows from the first sheet
		let rows: any[] = [];

		const assetUuidsFileContent = XLSX.read(await FileReaderAsync.readAsArrayBuffer(values.assetsUuidXlsx), {type: 'array', cellDates: true});
		if (assetUuidsFileContent.SheetNames.length > 0) {
			rows = XLSX.utils.sheet_to_json(assetUuidsFileContent.Sheets[assetUuidsFileContent.SheetNames[0]]);
		}

		// Extract assets UUIDs from first column of the imported XLSx file
		const assetUuids: UUID[] = [];
		for (let i = 0; i < rows.length; i++) {
			const uuid: UUID = XlsxUtils.readRow(rows[i], Locale.getAllTranslations('assets'));
			if (uuid !== null) {
				assetUuids.push(uuid);
			}
		}

		// Extract document rows from the first sheet
		let keyQuestionCodesRows: any[] = [];

		// Get key question codes file from resources API server
		if (values.keyQuestionCodesXlsx) {
			const keyQuestionCodesFileContent = XLSX.read(await FileReaderAsync.readAsArrayBuffer(values.keyQuestionCodesXlsx), {type: 'array', cellDates: true});
			if (keyQuestionCodesFileContent.SheetNames.length > 0) {
				keyQuestionCodesRows = XLSX.utils.sheet_to_json(keyQuestionCodesFileContent.Sheets[keyQuestionCodesFileContent.SheetNames[0]]);
			}
		}

		// Extract key question codes from first column of the imported XLSx file
		const keyQuestionCodes: string[] = [];
		for (let i = 0; i < keyQuestionCodesRows.length; i++) {
			const code: UUID = XlsxUtils.readRow(keyQuestionCodesRows[i], ['Codes']);
			if (code !== null) {
				keyQuestionCodes.push(code);
			}
		}

		// The progress bar use to display export progress on a modal
		const progressBar: ProgressBar = new ProgressBar();
		progressBar.show();

		// Keep every report to export in a single zip file
		const zipFile = new JSZip();

		// Assets by UUID
		const assets: Map<UUID, APAsset> = new Map<UUID, APAsset>();

		// Pre-load assets data
		const assetsRequest = await Service.fetch(ServiceList.assetPortfolio.asset.getBatch, null, null, {assets: assetUuids}, Session.session, true, false);
		for (let i = 0; i < assetsRequest.response.assets.length; i++) {
			const asset: APAsset = APAsset.parse(assetsRequest.response.assets[i]);
			assets.set(asset.uuid, asset);
		}


		// Get all the inspections in all the projects for every asset UUID in the list
		for (let i = 0; i < assetUuids.length; i++) {
			// The global progress value computed from the current asset within the total number of assets
			const progress: number = (i + 1) / assetUuids.length;
			progressBar.update(Locale.get('loadingData'), progress);

			// List all the projects that have inspections for the asset UUID in the list
			const projectsReq = await Service.fetch(ServiceList.inspection.project.listByAsset, null, null, {assetUuid: assetUuids[i]}, Session.session, true);

			// Get every project inspections filtered by asset
			for (let j = 0; j < projectsReq.response.projects.length; j++) {
				const project: InspectionProject = InspectionProject.parse(projectsReq.response.projects[j]);

				const list = await InspectionService.listDetailed({project: project.uuid, searchFields: ['inspection.[asset_id]'], search: assetUuids[i], fetchData: true});
				
				// Steps of the inspection by UUID
				const steps: Map<UUID, InspectionWorkflowStep> = new Map<UUID, InspectionWorkflowStep>();

				for (let k = 0; k < list.inspections.length; k++) {
					const inspection: Inspection = list.inspections[k];

					// Get the inspection current step
					let inspectionStep: InspectionWorkflowStep = steps.get(inspection.stepUuid);
					if (!inspectionStep) {
						const inspectionStepReq = await Service.fetch(ServiceList.inspection.workflowStep.get, null, null, {uuid: inspection.stepUuid}, Session.session, true);		
						inspectionStep = InspectionWorkflowStep.parse(inspectionStepReq.response.step);
						
						// Add workflow step to the cache
						steps.set(inspection.stepUuid, inspectionStep);
					}

					let fname: string = inspection.uuid;
					if (values.fileName === 'tag') {
						const asset: APAsset = assets.get(assetUuids[i]);
						fname = StringUtils.replacePunctuation(asset.tag, '_') + '_' + inspection.uuid;
					}

					for (let l = 0; l < project.reports.length; l++) {
						const template: InspectionReportTemplate = project.reports[l];
						
						try {
							const additionalData: any = {attributesEmpty: false};
							
							// Inspection forms by UUID
							let forms: Map<UUID, InspectionForm> = new Map<UUID, InspectionForm>();

							try {
								// Get all the forms used by this inspection step form
								forms = await InspectionReport.loadForms(inspectionStep.formUuid, forms);

								// Compute inspection average value to be used on report export
								additionalData.inspectionStepAverage = (await InspectionAverageADN.evaluateStepData(inspectionStep, inspection, keyQuestionCodes, forms)).answersAvg;
							} catch (e) {
								Modal.alert(Locale.get('error'), Locale.get('errorComputingInspectionAverageDetails', {details: e}));
								return;
							}

							const doc: ArrayBuffer = await InspectionReport.generateDocx(inspection, template, additionalData, assets, steps, forms);
	
							// DOCX
							if (values.formats.includes(ReportTemplateFormat.DOCX)) {
								zipFile.file(fname + '.docx', doc);
							}

							// PDF
							if (values.formats.includes(ReportTemplateFormat.PDF)) {
								const form = new FormData();
								form.append('file', new Blob([doc]), inspection.uuid + '.docx');
								
								const request = await Service.fetch(ServiceList.fileConverter.docxToPdf, null, null, form, null, true);
								zipFile.file(fname + '.pdf', request.response);
							}
						} catch (e) {
							Modal.alert(Locale.get('error'), Locale.get('errorGeneratingReportDetails', {details: e}));
						}
					}
				}
			}			
		}

		progressBar.destroy();

		const data: ArrayBuffer = await zipFile.generateAsync({type: 'arraybuffer'});

		FileUtils.writeFileArrayBuffer('inspections_reports.zip', data);
	}
}
