import {Component, Input, ViewEncapsulation, forwardRef, OnDestroy, ChangeDetectorRef} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import Viewer from 'viewerjs';
import {TranslateModule} from '@ngx-translate/core';
import {Service} from '../../../http/service';
import {ServiceList} from '../../../http/service-list';
import {FileUtils} from '../../../utils/file-utils';
import {Resource} from '../../../models/resource';
import {ImageUtils} from '../../../utils/image-utils';
import {Session} from '../../../session';
import {Environment} from '../../../../environments/environment';
import {UnoButtonComponent} from '../../uno/uno-button/uno-button.component';
import {UnoIconComponent} from '../../uno/uno-icon/uno-icon.component';

@Component({
	selector: 'image-multiple-selector',
	templateUrl: './image-selector.component.html',
	encapsulation: ViewEncapsulation.None,
	styleUrls: ['image-selector.component.css'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => { return ImageSelectorComponent; }),
			multi: true
		}
	],
	standalone: true,
	imports: [UnoButtonComponent, TranslateModule, UnoIconComponent]
})
export class ImageSelectorComponent implements ControlValueAccessor, OnDestroy {
	/**
	 * If true allow to select multiple files.
	 */
	@Input()
	public multiple: boolean = true;

	/**
	 * Allow the input to be disabled.
	 */
	@Input()
	public disabled: boolean = false;

	/**
	 * If set true, the images are compressed before they are sent to the server.
	 *
	 * Uses the configuration stored in the user settings.
	 */
	@Input()
	public compress: boolean = true;

	/**
	 * Local flag control if the file should be stored on resource server or stored in memory.
	 */
	@Input()
	public local: boolean = false;

	/**
	 * Resource representing the images stored.
	 */
	public value: (Resource[] | File[] | Resource | File) = [];

	/**
	 * URL of the images stored.
	 */
	public urls: string[] = [];

	/**
	 * Image viewer if there is one active. Should be destroyed when the component is destroyed.
	 */
	public viewer: Viewer = null;

	/**
	 * Method called when the data is changed.
	 */
	public onChange: (value: any)=> void = function(value) {};

	/**
	 * Formats of images supported.
	 */
	public static FORMATS: string = '.jpeg, .jpg, .gif, .bpm, .png';

	public constructor(public ref: ChangeDetectorRef) {}

	/**
	 * Update the list of URL to display images on the component.
	 */
	public updateUrls(): string[] {
		const urls = [];

		// @ts-ignore
		const values: (Resource|File)[] = this.multiple && this.value instanceof Array ? this.value : this.value ? [this.value] : [];

		for (let i = 0; i < values.length; i++) {
			if (this.local) {
				urls.push(window.URL.createObjectURL(values[i] as File));
			} else {
				const image = values[i] as Resource;
				urls.push(Service.getURL(ServiceList.resources.image.get, {
					uuid: image.uuid,
					format: image.format
				}));
			}
		}

		return urls;
	}

	/**
	 * Open the image viewer to show the image in detail.
	 *
	 * @param resource - Image resource to focus when the viewer is opened.
	 */
	public viewImage(resource: Resource | File): void {
		if (this.viewer !== null) {
			if (!Environment.PRODUCTION) {
				console.warn('EQS: There is already one viewer opened.', this);
			}
			return;
		}

		const values: (Resource|File)[] = this.value instanceof Array ? this.value : [this.value];

		// Show all images in the viewer
		const ul = document.createElement('ul');
		let initialViewIndex = 0;
		for (let i = 0; i < values.length; i++) {
			const li = document.createElement('li');

			const image = document.createElement('img');
			image.src = this.urls[i];

			if (this.value[i] === resource) {
				initialViewIndex = i;
			}

			li.appendChild(image);
			ul.appendChild(li);
		}

		this.viewer = new Viewer(ul, {
			hidden: () => {
				this.viewer.destroy();
				this.viewer = null;
			},
			title: false,
			transition: false,
			initialViewIndex: initialViewIndex,
			zIndex: 1e8
		});

		this.viewer.show();
	}

	public ngOnDestroy(): void {
		if (this.viewer !== null) {
			this.viewer.destroy();
			this.viewer = null;
		}
	}

	/**
	 * Remove an image from the list.
	 *
	 * @param resource - Resource of the image to be removed.
	 */
	public removeImage(resource: Resource | File): void {
		if (this.multiple) {
			if (this.value instanceof Array) {
				// @ts-ignore
				const index = this.value.indexOf(resource);
				if (index !== -1) {
					this.value.splice(index, 1);
					this.writeValue(this.value);
				}
			} else {
				throw new Error('Value must be an array in multiple mode');
			}
		} else {
			this.writeValue(null);
		}
	}

	/**
	 * Select image file(s) selected by the user with a file chooser.
	 */
	public async selectFiles(): Promise<void> {
		const files: File[] = await FileUtils.chooseFile(ImageSelectorComponent.FORMATS, this.multiple);

		if (this.multiple && this.value instanceof Array) {
			const values: any[] = this.value.slice();
			for (const file of files) {
				if (this.local) {
					values.push(file as any);
				} else {
					values.push(await this.uploadFile(file));
				}
			}
			this.writeValue(values);
		} else if (files.length > 0) {
			const file = files[0];
			if (this.local) {
				this.writeValue(file as any);
			} else {
				this.writeValue(await this.uploadFile(file));
			}
		}
	}

	/**
	 * Upload an image file to the resource server. Depending on the configuration, the image may be compressed before upload.
	 *
	 * @param file - Image file to be uploaded.
	 */
	public async uploadFile(file: File): Promise<Resource> {
		if (this.local) {
			throw new Error('When local is set true, uploadFile() should not be called');
		}

		if (this.compress !== false) {
			file = await ImageUtils.compressImage(file, Session.settings.pictureMaxSize, Session.settings.pictureFormat, Session.settings.pictureQuality);
		}

		const format = FileUtils.getFileExtension(file);

		const form = new FormData();
		form.append('file', file, 'image');
		form.append('format', format);

		const request = await Service.fetch(ServiceList.resources.image.upload, null, null, form, null);
		
		return new Resource(request.response.uuid, format);
	}

	public registerOnChange(onChange: any): void {
		this.onChange = onChange;
	}

	public writeValue(value: (Resource[]|File[]|Resource|File)): void {
		if (!value) {
			value = this.multiple ? [] : null;
		}

		this.value = value;
		this.urls = this.updateUrls();
		
		this.onChange(this.value);
		this.ref.detectChanges();
	}

	public registerOnTouched(fn: any): void {}

	public setDisabledState(disabled: boolean): void {
		this.disabled = disabled;
	}
}
