import {SortDirection} from 'src/app/utils/sort-direction';
import {ServiceList} from '../../../http/service-list';
import {Service} from '../../../http/service';
import {Session} from '../../../session';
import {UnoFormField} from '../../../components/uno-forms/uno-dynamic-form/uno-form-field';
import {UnoFormFieldTypes} from '../../../components/uno-forms/uno-dynamic-form/uno-form-field-types';

/**
 * Form used to edit company entries.
 */
export const CompanyFormLayout: UnoFormField[] = [
	{
		label: 'uuid',
		attribute: 'uuid',
		type: UnoFormFieldTypes.UUID
	},
	{
		attribute: 'name',
		required: true,
		type: UnoFormFieldTypes.TEXT
	},
	{
		attribute: 'email',
		required: true,
		type: UnoFormFieldTypes.EMAIL
	},
	{
		attribute: 'vat',
		type: UnoFormFieldTypes.TEXT
	},
	{
		attribute: 'phoneNumber',
		type: UnoFormFieldTypes.PHONE_NUMBER
	},
	{
		attribute: 'address',
		type: UnoFormFieldTypes.TEXT_MULTILINE
	},
	{
		attribute: 'notes',
		type: UnoFormFieldTypes.TEXT_MULTILINE
	},
	{
		attribute: 'picture',
		type: UnoFormFieldTypes.IMAGE_RESOURCE
	},
	{
		required: false,
		attribute: 'parent',
		label: 'parentCompany',
		type: UnoFormFieldTypes.OPTIONS_MULTIPLE_LAZY,
		multiple: false,
		identifierAttribute: 'uuid',
		fetchOptionsLazy: async function(request: any, object: any): Promise<void> {
			const data: any = {
				from: request.from,
				count: request.count,
				search: request.search,
				sortField: '[company].[name]',
				sortDirection: SortDirection.ASC
			};

			if (object.uuid) {
				data.uuid = object.uuid;
			}

			try {
				const req = await Service.fetch(ServiceList.company.listAllowedParents, null, null, data, Session.session);
				request.onFinish(req.response.companies, req.response.hasMore, req.id);
			} catch {
				request.onError();
			}
		},
		fetchOptionsBatch: async function(request: any): Promise<void> {
			if (request.options.length === 0) {
				request.onFinish([]);
				return;
			}

			try {
				const req = await Service.fetch(ServiceList.company.get, null, null, {uuid: request.options[0]}, Session.session);
				request.onFinish([req.response.company]);
			} catch {
				request.onError();
			}
		},
		getOptionText: function(option: any): string {
			return option.name;
		}
	}
];
