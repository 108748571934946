import {Component, OnInit, signal, ViewChild, WritableSignal} from '@angular/core';
import {sha256} from 'js-sha256';
import {TranslateModule} from '@ngx-translate/core';
import {IonicModule} from '@ionic/angular';
import {UserPermissionTree, UserPermissionUtils} from 'src/app/models/users/user-permission-utils';
import {UnoDynamicFormComponent} from '../../../../components/uno-forms/uno-dynamic-form/uno-dynamic-form.component';
import {App} from '../../../../app';
import {Service} from '../../../../http/service';
import {ServiceList} from '../../../../http/service-list';
import {Session} from '../../../../session';
import {Modal} from '../../../../modal';
import {Locale} from '../../../../locale/locale';
import {ValidationUtils} from '../../../../utils/validation-utils';
import {UnoDynamicFormModule} from '../../../../components/uno-forms/uno-dynamic-form.module';
import {UserFormLayout, UserPassword} from '../user-form-layouts';
import {ScreenComponent} from '../../../../components/screen/screen.component';
import {Environment} from '../../../../../environments/environment';
import {UserPermissions} from '../../../../models/users/user-permissions';
import {User} from '../../../../models/users/user';
import {UnoButtonComponent} from '../../../../components/uno/uno-button/uno-button.component';
import {UnoContentComponent} from '../../../../components/uno/uno-content/uno-content.component';
import {UserService} from '../../services/users.service';
import {UnoTabSectionComponent} from '../../../../components/uno/uno-tab/uno-tab-section/uno-tab-section.component';
import {UnoTabComponent} from '../../../../components/uno/uno-tab/uno-tab.component';

@Component({
	selector: 'user-edit-page',
	templateUrl: 'user-edit.page.html',
	standalone: true,
	imports: [IonicModule, UnoContentComponent, UnoDynamicFormModule, UnoButtonComponent, TranslateModule, UnoTabSectionComponent, UnoTabComponent]
})
export class UserEditPage extends ScreenComponent implements OnInit {
	public get app(): any { return App; }

	public get layout(): any { return UserFormLayout; }

	public get session(): any { return Session; }

	public get roleUtils(): any { return UserPermissionUtils; }

	@ViewChild('userForm', {static: false})
	public userForm: UnoDynamicFormComponent = null;

	/**
	 * User data obtained from the API, received from route data.
	 */
	public user: User = null;

	/**
	 * Flag to indicate if the page is in create mode.
	 *
	 * If true the page is used to create a new User.
	 */
	public createMode: WritableSignal<boolean> = signal(false);

	/**
	 * Indicates if the user can be edited.
	 */
	public canEdit: WritableSignal<boolean> = signal(false);

	/**
	 * List of existing permissions in the application with the sub set of permissions.
	 */
	public options: UserPermissionTree[] = [];

	public async ngOnInit(): Promise<void> {
		super.ngOnInit();
		
		this.user = null;

		const data = App.navigator.getData();
		if (!data) {
			App.navigator.pop();
			return;
		}
		
		this.createMode.set(data.createMode === true);
		
		if (this.createMode()) {
			this.user = new User();
			App.navigator.setTitle('create');
		} else {
			// Check if editing self or has permissions
			if (data.uuid !== Session.user.uuid && !Session.hasPermissions([UserPermissions.USER_EDIT])) {
				Modal.alert(Locale.get('warning'), Locale.get('noPermission'));
				App.navigator.pop();
				return;
			}

			this.user = await UserService.get(data.uuid);
			App.navigator.setTitle('edit');
		}

		this.canEdit.set(this.user !== null && (this.user.uuid === Session.user.uuid || Session.hasPermissions([UserPermissions.USER_EDIT])));

		this.options = UserPermissionUtils.getPermissionsTree();
	}

	/**
	 * Open modal form to change the user password.
	 */
	public async changePassword(): Promise<void> {
		const values = await Modal.form(Locale.get('changePassword'), {password: '', confirm: ''}, UserPassword);

		// Passwords must be equal
		if (values.password !== values.confirm) {
			Modal.alert(Locale.get('error'), Locale.get('passwordsDontMatch'));
			return;
		}

		// Check if password is valid
		if (!ValidationUtils.validPassword(values.password)) {
			Modal.alert(Locale.get('error'), Locale.get('passwordSizeError'));
			return;
		}

		// Encode password to SHA-256
		try {
			values.password = sha256(values.password);
		} catch (e) {
			if (!Environment.PRODUCTION) {
				console.warn('EQS: Error encoding password.', values, e);
			}
			Modal.alert(Locale.get('error'), Locale.get('errorEncodingData'));
			return;
		}

		// Update the user password
		await Service.fetch(ServiceList.users.changePassword, null, null, {uuid: this.user.uuid, password: values.password}, Session.session);
		Modal.toast(Locale.get('updatedSuccessfully'));
	}

	/**
	 * Call API to update user details.
	 */
	public async update(stayOnPage: boolean = false): Promise<void> {
		if (!this.userForm.requiredFilled()) {
			Modal.alert(Locale.get('error'), Locale.get('requiredFieldsError'));
			return;
		}

		// Check if email is valid
		if (!ValidationUtils.isEmail(this.user.email)) {
			Modal.alert(Locale.get('error'), Locale.get('invalidEmail'));
			return;
		}

		try {
			await Service.fetch(this.createMode() ? ServiceList.users.create : ServiceList.users.update, null, null, this.user, Session.session);

			// If changing itself, update local data.
			if (Session.user.uuid === this.user.uuid) {
				Session.user = this.user;
			}

			if (!stayOnPage) {
				App.navigator.pop();
			}

			Modal.toast(this.createMode() ? Locale.get('userCreated') : Locale.get('updatedSuccessfully'));
		} catch (e) {}
	}
}
