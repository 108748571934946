import {Component, OnInit, signal, ViewChild, WritableSignal} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {IonicModule} from '@ionic/angular';
import {UnoTabSectionComponent} from 'src/app/components/uno/uno-tab/uno-tab-section/uno-tab-section.component';
import {UnoTabComponent} from 'src/app/components/uno/uno-tab/uno-tab.component';
import {UserPermissionTree, UserPermissionUtils} from 'src/app/models/users/user-permission-utils';
import {UnoDynamicFormComponent} from '../../../../components/uno-forms/uno-dynamic-form/uno-dynamic-form.component';
import {App} from '../../../../app';
import {Session} from '../../../../session';
import {Modal} from '../../../../modal';
import {Locale} from '../../../../locale/locale';
import {UnoDynamicFormModule} from '../../../../components/uno-forms/uno-dynamic-form.module';
import {RoleFormLayout} from '../role-form-layouts';
import {ScreenComponent} from '../../../../components/screen/screen.component';
import {Role} from '../../../roles/data/role';
import {UnoButtonComponent} from '../../../../components/uno/uno-button/uno-button.component';
import {UnoContentComponent} from '../../../../components/uno/uno-content/uno-content.component';
import {RoleService} from '../../services/role.service';


@Component({
	selector: 'role-edit-page',
	templateUrl: 'role-edit.page.html',
	standalone: true,
	imports: [IonicModule, UnoContentComponent, UnoDynamicFormModule, UnoButtonComponent, TranslateModule, UnoTabSectionComponent, UnoTabComponent]
})
export class RoleEditPage extends ScreenComponent implements OnInit {

	public get app(): any { return App; }

	public get layout(): any { return RoleFormLayout; }

	public get session(): any { return Session; }

	public get roleUtils(): any { return UserPermissionUtils; }

	@ViewChild('form', {static: false})
	public form: UnoDynamicFormComponent = null;

	/**
	 * Role data obtained from the API, received from route data.
	 */
	public role: Role = null;
	
	/**
	 * Indicates if the role can be edited.
	 */
	public canEdit: WritableSignal<boolean> = signal(false);

	/**
	 * List of existing permissions in the application with the sub set of permissions.
	 */
	public options: UserPermissionTree[] = [];

	public async ngOnInit(): Promise<void> {
		super.ngOnInit();
		
		this.role = null;

		App.navigator.setTitle('role');

		const data = App.navigator.getData();
		if (!data || !data.uuid) {
			App.navigator.pop();
			return;
		}

		this.role = await RoleService.get(data.uuid);
		this.canEdit.set(this.role !== null && Session.user.isAdmin);

		this.options = UserPermissionUtils.getPermissionsTree();
	}

	/**
	 * Call API to update user details.
	 */
	public async update(stayOnPage: boolean = false): Promise<void> {
		if (!this.form.requiredFilled()) {
			Modal.alert(Locale.get('error'), Locale.get('requiredFieldsError'));
			return;
		}
	
		await RoleService.update(this.role);
		if (!stayOnPage) {
			App.navigator.pop();
		}

		Modal.toast(Locale.get('updatedSuccessfully'));
	}

	/**
	 * Delete role.
	 *
	 * This action cannot be reversed, the user is prompted to confirm the action.
	 */
	public async delete(): Promise<void> {
		const confirm = await Modal.confirm(Locale.get('confirm'), Locale.get('confirmDelete'));
		if (confirm) {
			await RoleService.delete(this.role.uuid);
			Modal.toast(Locale.get('userRoleDeleted'));
			App.navigator.pop();
		}
		
	}
}
