import {Service} from '../../../http/service';
import {ServiceList} from '../../../http/service-list';
import {ServiceResponse} from '../../../http/service-response';
import {Session} from '../../../session';
import {UUID} from '../../../models/uuid';
import {CalendarEvent} from '../../../models/asset-planning/calendar-event';
import {CalendarEventOccurrence} from '../../../models/asset-planning/calendar-event-occurrence';

export type AssetPlanningEventServiceListByAssetParams = {
	// The index of the list to fetch.
	from?: number,
	// The number of items to fetch starting on "from" index.
	count?: number,
	// Either to list asset children or not.
	assetChildren?: boolean,
	// The field to sort the list by.
	sortField?: string,
	// The sort direction.
	sortDirection?: string,
	// The date to filter the list from.
	startDate?: Date,
	// The date to filter the list to.
	endDate?: Date,
	// Pagination mode defines if pagination is made by event or occurrence
	paginationMode?: number
};

export type AssetPlanningEventServiceCountParams = {
	// The UUID of the asset to filter count by.
	assetUuid?: UUID,
	// The UUID of the inspection project to filter count by.
	inspectionProjectUuid?: UUID,
	// The UUID of the repair to filter count by.
	repairUuid?: UUID,
	// String to use on search filter.
	search?: string,
	// List of fields to consider in the search.
	searchFields?: string[],
	// The date to begin the count of calendar events.
	startDate?: Date,
	// The date to finish the count of calendar events.
	endDate?: Date,
	// The types to filter the calendar events by.
	types?: number[],
	// The subtypes to filter the calendar events by.
	subtypes?: number[]
};

export class AssetPlanningEventService {
	/**
	 * Gets a filtered list of calendar events for an asset by its UUID.
	 * 
	 * Only the asset UUID is required. All the other filters are optional.
	 * 
	 * @param assetUuid - The asset UUID to filter the list by.
	 * @param params - The filters to apply to the list.
	 * @returns An object with all the events and occurrences filling the applied filters and a hasMore attribute telling if there are still more items to fetch.
	 */
	public static async listEventsAndOccurrencesByAsset(assetUuid: UUID, params: AssetPlanningEventServiceListByAssetParams): Promise<{calendarEvents: CalendarEvent[], occurrences: CalendarEventOccurrence[], hasMore: boolean}> {
		const request: ServiceResponse = await Service.fetch(ServiceList.assetPlanning.calendarEvent.listByAsset, null, null, {
			assetUuid: assetUuid,
			from: params.from,
			count: params.count,
			assetChildren: params.assetChildren,
			sortField: params.sortField,
			sortDirection: params.sortDirection,
			startDate: params.startDate,
			endDate: params.endDate,
			paginationMode: params.paginationMode
		}, Session.session);

		return {
			calendarEvents: request.response.calendarEvents.map((data: any) => {return CalendarEvent.parse(data);}),
			occurrences: request.response.occurrences.map((data: any) => {return CalendarEventOccurrence.parse(data);}),
			hasMore: request.response.hasMore
		};
	}

	/**
	 * Get the total number of calendar events given the provided filters.
	 * 
	 * All filters are optional. If no filters are provided, all the calendar events are returned.
	 * 
	 * @param params - The filters to apply to the count.
	 * @returns The count of calendar events.
	 */
	public static async count(params: AssetPlanningEventServiceCountParams): Promise<number> {
		const request = await Service.fetch(ServiceList.assetPlanning.calendarEvent.count, null, null, {
			assetUuid: params.assetUuid,
			inspectionProjectUuid: params.inspectionProjectUuid,
			repairUuid: params.repairUuid,
			search: params.search,
			searchFields: params.searchFields,
			startDate: params.startDate,
			endDate: params.endDate,
			types: params.types,
			subtypes: params.subtypes
		}, Session.session);

		return request.response.count;
	}
}
