import {FeatureType} from '../../../../models/pipeline-integrity/pipeline/feature-type';

const FeatureTypeAlias = new Map([
	['W', FeatureType.WELD],
	['PS', FeatureType.SUPPORT],
	['F', FeatureType.FLANGE],
	['T', FeatureType.TEE_BRANCH],
	['EW', FeatureType.ELBOW],
	['B', FeatureType.BRACE],
	['D', FeatureType.DEFECT],
	['I', FeatureType.INDICATION],
	['V', FeatureType.VALVES],
	['Fe', FeatureType.FEATURE_GENERIC]
]);

export type MOTFeaData = {
	features: {
		name: string,
		type: number,
		distance: number,
		size: number,
		subtype: number,
		observations: string,
	}[],
	cmps: {
		name: string,
		distance: number
	}[]
};

/**
 * Utils to parse data from imported nano file.
 */
export class MOTImportUtils {
	/**
	 * Parses data from nano file.
	 *
	 * Nano files are structured has 2 objects and dynamic columns.
	 * 
	 * The first object has data related to CMP,Acquisition and experiment.
	 * 
	 * The second object has data related to observations of the experiment.
	 * 
	 * Each column represents the number of channels for that experiment and the number of rows the number of measurements for that experiment.
	 * 
	 * @param data - Data imported from nano file.
	 * @returns An object with 2 properties: data and rows.
	 */
	public static parseNano(data: any): any {
		const lines = data.split('\n');
		const obj = lines.shift().replace('NaN', 'null').split('\t');

		// Read objects
		const a = JSON.parse(obj[0]);
		const b = JSON.parse(obj[1]);

		// Read columns
		const rows = [];
		for (let i = 0; i < lines.length; i++) {
			const line = lines[i].split('\t');
			if (line.length < 2) {
				continue;
			}

			const r = [];
			for (let j = 0; j < line.length; j++) {
				r.push(Number.parseFloat(line[j]));
			}
			rows.push(r);
		}
		return {data: [a, b], rows: rows};
	}

	/**
	 * Parses data from fea file.
	 *
	 * Nano files have the following stucture:
	 * 
	 * The tag ['Features'] or ['Sensors'] is the delimiter of the data.
	 * 
	 * 1st line - Data type : features or sensors.
	 * 
	 * 2nd line - Header: Columns names.
	 * 
	 * rest of the lines - before '=' name of the sensor , and after the values matching the column names.
	 * 
	 * @param data - Data imported from fea file.
	 * @returns An object with 2 properties: features and cmps.
	 */
	public static parseFea(data: any): MOTFeaData {
		const output: MOTFeaData = {features: [], cmps: []};

		// File section names, sections are represented by text between brackets
		const sectionRegex: RegExp = /\[[A-z\s]+\]/g;
		const sectionNames = [...data.matchAll(sectionRegex)].map((a: any) => {return a[0].slice(1, -1);});

		// Data of each section indexed by its name
		const sectionData: Map<string, any[]> = new Map<string, any[]>();

		// Parse file format
		for (let i = 0; i < sectionNames.length; i++) {
			const section = data.substring(data.indexOf('[' + sectionNames[i] + ']'), i + 1 < sectionNames.length ? data.indexOf('[' + sectionNames[i + 1] + ']') : data.length);
			let lines = section.replaceAll('\r', '').split('\n');
			
			// Remove header and title
			lines.splice(0, 1);

			// Remove empty lines
			lines = lines.filter((l: any) => {return l;});
			
			const lineData = [];

			for (let j = 0; j < lines.length; j++) {
				// Split values by ' = ' and by tab
				let line = lines[j];
				line = line.replaceAll('"', '').split(' = ');
				line = [line[0]].concat(line[1].split('\t'));

				// Attempt to cast values
				for (let k = 0; k < line.length; k++) {
					// Numeric values
					const nmb = Number.parseFloat(line[k].replace(',', '.'));
					if (!isNaN(nmb)) {
						line[k] = nmb;
						continue;
					}

					// Array data
					if (line[k].startsWith('[') && line[k].endsWith(']')) {
						try {
							line[k] = JSON.parse(line[k]);
						} catch (e) {}
					}

				}
				
				lineData.push(line);
			}

			if (lineData.length > 0) {
				sectionData.set(sectionNames[i], lineData);
			}
		}

		// Features
		if (sectionData.has('Features')) {
			const lines = sectionData.get('Features');
			for (let i = 1; i < lines.length; i++) {
				const line = lines[i];
				const al = line[0].split('_');
				if (FeatureTypeAlias.has(al[0])) {
					output.features.push({
						name: line[0],
						type: FeatureTypeAlias.get(al[0]),
						distance: line[1],
						size: line[2],
						subtype: line[3],
						observations: line[4]
					});
				
				// Ignore unknow features(ex: Emissor(E))
				} else if (al[0] !== 'E') {
					throw new Error('Feature type unknown, check the file');
				}
			}
		}

		// Sensors
		if (sectionData.has('Sensors')) {
			const lines = sectionData.get('Features');
			for (let i = 1; i < lines.length; i++) {
				const line = lines[i];
				output.cmps.push({
					name: line[0],
					distance: line[1]
				});
			}
		}

		return output;
	}
}
