import {Component, Input, ViewEncapsulation, forwardRef} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {IonicModule} from '@ionic/angular';
import {Service} from '../../../http/service';
import {ServiceList} from '../../../http/service-list';
import {FileUtils} from '../../../utils/file-utils';
import {Resource, ResourceType} from '../../../models/resource';
import {ResourceUtils} from '../../../utils/resource-utils';
@Component({
	selector: 'file-multiple-selector',
	templateUrl: './file-selector.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => { return FileSelectorComponent; }),
			multi: true
		}
	],
	standalone: true,
	imports: [IonicModule, TranslateModule]
})
export class FileSelectorComponent implements ControlValueAccessor {
	public get fileUtils(): any {return FileUtils;}

	/**
	 * URL of the sample file if there is any available.
	 */
	@Input()
	public sampleFile: string = null;

	/**
	 * If true allow to select multiple files.
	 */
	@Input()
	public multiple: boolean = true;

	/**
	 * Allow the input to be disabled.
	 */
	@Input()
	public disabled: boolean = false;

	/**
	 * File type filter to allow a specific type of files only.
	 */
	@Input()
	public filter: string = '';

	/**
	 * Local flag control if the file should be stored on resource server or stored in memory.
	 */
	@Input()
	public local: boolean = false;

	/**
	 * Resources representing the documents stored.
	 */
	public value: (Resource[] | File[] | Resource | File) = [];

	/**
	 * Method called when the data is changed.
	 */
	public onChange: (value: any)=> void = function(value) {};

	/**
	 * Download the document into the device.
	 */
	public downloadFile(resource: (Resource|File)): void {
		if (this.local) {
			FileUtils.download(resource as File);
		} else {
			ResourceUtils.download(resource as Resource);
		}
	}

	/**
	 * Remove a document from the list.
	 *
	 * @param resource - Resource of the file to be removed.
	 */
	public remove(resource: Resource | File): void {
		if (this.multiple) {
			if (this.value instanceof Array) {
				// @ts-ignore
				const index = this.value.indexOf(resource);
				if (index !== -1) {
					this.value.splice(index, 1);
					this.writeValue(this.value);
				}
			} else {
				throw new Error('Value must be an array in multiple mode');
			}
		} else {
			this.writeValue(null);
		}
	}

	/**
	 * Select document file and add it to the values array.
	 */
	public async selectFiles(): Promise<void> {
		const files: File[] = await FileUtils.chooseFile(this.filter, this.multiple);

		if (this.multiple && this.value instanceof Array) {
			const values: any[] = this.value.slice();
			for (const file of files) {
				if (this.local) {
					values.push(file as any);
				} else {
					values.push(await this.uploadFile(file));
				}
			}
			this.writeValue(values);
		} else if (files.length > 0) {
			const file = files[0];
			if (this.local) {
				this.writeValue(file as any);
			} else {
				this.writeValue(await this.uploadFile(file));
			}
		}
	}


	/**
	 * Upload a document to the resource server.
	 *
	 * @param file - File to be uploaded.
	 */
	public async uploadFile(file: File): Promise<Resource> {
		if (this.local) {
			throw new Error('When local is set true, uploadFile() should not be called');
		}

		const format = FileUtils.getFileExtension(file);

		const form = new FormData();
		form.append('file', file, 'document');
		form.append('format', format);

		const res = new Resource(null, format);
		res.description = file.name;
		res.type = ResourceType.FILE;

		const request = await Service.fetch(ServiceList.resources.file.upload, null, null, form, null);

		res.uuid = request.response.uuid;

		return res;
	}

	public registerOnChange(onChange: any): void {
		this.onChange = onChange;
	}

	public writeValue(value: (Resource[]|File[]|Resource|File)): void {
		if (!value) {
			value = this.multiple ? [] : null;
		}

		this.value = value;
		this.onChange(this.value);
	}

	public registerOnTouched(fn: any): void {}

	public setDisabledState(disabled: boolean): void {
		this.disabled = disabled;
	}
}

