import {UUID, UUIDIdentification} from '../../uuid';
import {AtexInspectionStatus} from './atex-inspection-status';
import {AtexInspectionResult} from './atex-inspection-result';
import {AtexInspectionData} from './atex-inspection-data';

/**
 * Represents a inspection, inspections are performed by multiple users.
 *
 * First the INSPECTOR picks the inspection from TO DO state and fills the base information of the asset.
 *
 * The inspector selects the appropriate inspection forms and fills them after that the status of the INSPECTION is UNDER_ANALYSIS.
 *
 * Next the BACKOFFICE, picks the inspection and checks everything. Fills the BACKOFFICE specific forms and APPROVES or REJECTS the inspection.
 *
 * If the inspection is REJECTED a inspector has to pick it and review everything to correct the inspection.
 */
export class AtexInspection extends UUIDIdentification {
	/**
	 * UUID of the asset for this inspection.
	 */
	public assetUuid: UUID = null;

	/**
	 * UUID of the Team that is executing the inspection.
	 */
	public teamUuid: UUID = null;

	/**
	 * Indicates if the inspection has documents pending.
	 */
	public waitingDocuments: boolean = false;

	/**
	 * Indicates if lifting equipments are needed to perform inspection.
	 */
	public liftingEquipmentRequired: boolean = false;

	/**
	 * Status of the inspection.
	 */
	public status: number = AtexInspectionStatus.TODO;

	/**
	 * Result of the inspection, set the field inspector.
	 */
	public result: number = AtexInspectionResult.NONE;

	/**
	 * Final result of the inspection set by the backoffice.
	 */
	public resultFinal: number = AtexInspectionResult.NONE;

	/**
	 * Actual data of the inspection, contains the forms filled and the type of inspections.
	 */
	public data: AtexInspectionData = new AtexInspectionData();

	/**
	 * Check if the inspection has at least one form selected.
	 */
	public hasForm(): boolean {
		for (const attr in this.data.inspections) {
			if (this.data.inspections[attr] === true) {
				return true;
			}
		}

		return false;
	}

	/**
	 * Parse the data field JSON string into an object.
	 *
	 * @param data - Inspection object received from API.
	 * @returns AtexInspection object instance built from the data received.
	 */
	public static parse(data: any): AtexInspection {
		const inspection = new AtexInspection();

		inspection.uuid = data.uuid;
		inspection.updatedAt = new Date(data.updatedAt);
		inspection.createdAt = new Date(data.createdAt);

		inspection.teamUuid = data.teamUuid;
		inspection.status = data.status;
		inspection.result = data.result;
		inspection.resultFinal = data.resultFinal;
		inspection.waitingDocuments = data.waitingDocuments;
		inspection.liftingEquipmentRequired = data.liftingEquipmentRequired;
		inspection.assetUuid = data.assetUuid;
		inspection.data = data.data ? AtexInspectionData.parse(data.data) : new AtexInspectionData();

		return inspection;
	}
}
