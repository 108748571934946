<div style="height: 120px; margin: 10px;">
	<!-- Search bar -->
	<uno-searchbar style="width: 100%;" [value]="selfStatic.filters.search" (valueChange)="onSearchChange($event)"></uno-searchbar>

	<!-- Filters -->
	<div style="overflow-x: auto; display: flex; align-items: flex-end; justify-content: flex-start; flex-direction: row; gap: 5px; width: 100%">
		<uno-filter-bar [filters]="selfStatic.filters" [options]="selfStatic.filterOptions" (onChange)="this.onFilterChange($event);" style="height:80px"></uno-filter-bar>
	</div>
</div>

<div style="width: calc(100% - 36px); height: calc(100% - 140px); margin: 10px 18px; overflow: hidden;">
	<uno-responsive-table-list [loadMore]="this.loadTableItems" [totalItems]="this.tableTotalItemsCount" [layout]="this.tableLayout" [pageSize]="selfStatic.tablePageSize" [labelShown]="true" [selectable]="false" (rowClick)="app.navigator.navigate('/menu/gap-analysis/action-plans/edit', {uuid: $event.element.uuid.value});" (sortChange)="this.sortChanged($event.sortBy)">
	</uno-responsive-table-list>
</div>
