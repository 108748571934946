import {Component, HostListener} from '@angular/core';
import {ENTER} from '@angular/cdk/keycodes';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule} from '@angular/forms';
import {NgClass, NgStyle} from '@angular/common';
import {Service} from '../../../../../http/service';
import {ServiceList} from '../../../../../http/service-list';
import {Modal} from '../../../../../modal';
import {Locale} from '../../../../../locale/locale';
import {App} from '../../../../../app';
import {UnoButtonComponent} from '../../../../../components/uno/uno-button/uno-button.component';
import {UnoInputComponent} from '../../../../../components/uno/uno-input/uno-input-component';
import {UnoTextComponent} from '../../../../../components/uno/uno-text/uno-text.component';

@Component({
	selector: 'app-recover-password-box',
	templateUrl: './recover-password-box.component.html',
	styleUrls: ['../login-box/login-box.component.scss'],
	standalone: true,
	imports: [UnoTextComponent, NgClass, NgStyle, FormsModule, UnoInputComponent, UnoButtonComponent, TranslateModule]
})
export class RecoverPasswordBoxComponent {
	public email: string = '';

	@HostListener('window:keydown', ['$event'])
	public keyDown(event: any): void {
		if (event.which === ENTER) {
			this.forgotPassword();
		}
	}

	/**
	 * Call service to recover the password.
	 */
	public async forgotPassword(): Promise<void> {
		await Service.fetch(ServiceList.authentication.forgot, null, null, {email: this.email}, null);
		Modal.alert(Locale.get('success'), Locale.get('recoverEmail'));
		App.navigator.navigate('login');
	}

	public returnLogin(): void {
		App.navigator.navigate('login/login');
	}
}
