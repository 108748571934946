export const CalendarEventTriggerDirection = {
	SIMULTANEOUS: 0,
	BEFORE: 1,
	AFTER: 2
};

export const CalendarEventTriggerDirectionLabel = new Map<number, string> ([
	[CalendarEventTriggerDirection.SIMULTANEOUS, 'simultaneous'],
	[CalendarEventTriggerDirection.BEFORE, 'before'],
	[CalendarEventTriggerDirection.AFTER, 'after']
]);
