/**
 * Level of criticality of the repair, higher the value the more important is it to fix the repair.
 */
export const RepairCriticalityLevel = {
	NONE: 0,
	VERY_LOW: 1,
	LOW: 2,
	NORMAL: 3,
	HIGH: 4,
	VERY_HIGH: 5,
	EMERGENCY: 6
};

export const RepairCriticalityLevelLabel: Map<number, string> = new Map([
	[RepairCriticalityLevel.NONE, 'nd'],
	[RepairCriticalityLevel.VERY_LOW, 'veryLow'],
	[RepairCriticalityLevel.LOW, 'low'],
	[RepairCriticalityLevel.NORMAL, 'normal'],
	[RepairCriticalityLevel.HIGH, 'high'],
	[RepairCriticalityLevel.VERY_HIGH, 'veryHigh'],
	[RepairCriticalityLevel.EMERGENCY, 'emergency']
]);
