export const Environment = {
	PRODUCTION: true,
	CLIENT_ID: 'adn-uat',
	DEFAULT_ROUTE: null,
	TEST: false,
	VERSION: '0.333.39',
	TIMESTAMP: '2024-05-25T15:34:10.280Z',
	COMMIT: '0f87275fe52eb79ef35640539fb275b3ae433703',
	BRANCH: 'task/AM-2601-uno-content-borders',
	API_SERVER: 'https://adn-uat-inspections-api.unoplatform.io',
	MAPBOX_TOKEN: 'pk.eyJ1IjoidW5vM2QiLCJhIjoiY2swbWk3ZDU1MTQ2aTNubXprbnZ6Y2c1bCJ9.fGhQreB4AkAUcW_5_tr81Q',
	RESOURCE_SERVER: 'https://adn-inspections-resources.unoplatform.io',
	FILE_CONVERTER_SERVER: 'https://file-converter.unoplatform.io',
	PIPELINE_INTEGRITY_SERVER: null,
	DIGITAL_TWIN_SERVER: null
};
