<div style="width:100%; height:100%;">
	<uno-tab>
		<uno-tab-section [active]="true" title="{{'actionPlan' | translate}}">
			<!-- Base Form -->
			<uno-tab-section title="{{'actionPlan' | translate}}"></uno-tab-section>
			<uno-dynamic-form [layout]="this.actionPlanLayout" [object]="this.actionPlan" #actionPlanForm></uno-dynamic-form>

			@if (this.actionPlan.state === state.WAITING_REINSPECTION && session.hasPermissions([userPermissions.ATEX_INSPECTION]) && (this.inspections | objectKeys).length > 0) {
				<!-- Re-inspection form -->
				<uno-title>{{'reinspection' | translate}}</uno-title>

				<!-- Cycle all Inspections -->
				@for (i of this.inspections | objectKeys; track i) {
					@if (this.inspections[i] !== undefined && this.inspections[i].forms !== undefined) {
						<!-- Inspections field forms -->
						@for (f of this.inspections[i].forms | objectKeys; track f) {
							<uno-dynamic-form [editable]="true" [layout]="this.inspections[i].forms[f]" [object]="this.inspections[i].data.responses[f]"></uno-dynamic-form>
						}

						<!-- Open page -->
						<div style="display: flex; flex-direction: row-reverse; gap: 5px;" class="ion-padding">
							@if (session.hasPermissions([userPermissions.ATEX_INSPECTION])) {
								<uno-button (click)="app.navigator.navigate('/menu/atex/inspections/edit', {uuid: this.inspections[i].uuid});">{{'viewInspection' | translate}}</uno-button>
							}
							@if (this.actionPlan.data.documents.length > 0 && session.hasPermissions([userPermissions.ASSET_PORTFOLIO_ASSET_EDIT])) {
								<uno-button (click)="copyDocuments(this.assets[this.inspections[i].assetUuid].uuid);">{{'copyDocuments' | translate}}</uno-button>
							}
						</div>
					}
				}
			}

			<!-- Submit buttons -->
			<div style="display: flex; flex-direction: row-reverse; gap: 5px;" class="ion-padding">
				@if (this.createMode && session.hasPermissions([userPermissions.ACTION_PLAN_CREATE])) {
					<uno-button (click)="this.create();">{{'create' | translate}}</uno-button>
				}

				@if (!this.createMode) {
					<uno-button (click)="this.update();">{{'save' | translate}}</uno-button>
					<uno-button (click)="this.update(undefined, true);">{{'update' | translate}}</uno-button>
					@if (this.actionPlan.state === state.TODO && session.hasPermissions([userPermissions.ACTION_PLAN_SUBMIT])) {
						<uno-button (click)="this.update(state.WAITING_CLIENT_VALIDATION);" color="success">{{'submit' | translate}}</uno-button>
					}
					@if (this.actionPlan.state === state.WAITING_CLIENT_VALIDATION && session.hasPermissions([userPermissions.ACTION_PLAN_CLIENT])) {
						<uno-button (click)="this.update(state.CLIENT_ACCEPTED);" color="success">{{'approve' | translate}}</uno-button>
						<uno-button (click)="this.update(state.CLIENT_REJECTED);" color="error">{{'reject' | translate}}</uno-button>
					}
					@if (this.actionPlan.state === state.CLIENT_ACCEPTED && session.hasPermissions([userPermissions.ACTION_PLAN_COMPANY])) {
						<uno-button (click)="this.update(state.WAITING_REINSPECTION);" color="success">{{'finish' | translate}}</uno-button>
						<uno-button (click)="this.update(state.BLOCKED);" color="error">{{'blocked' | translate}}</uno-button>
					}
					@if (this.actionPlan.state === state.BLOCKED && session.hasPermissions([userPermissions.ACTION_PLAN_UNBLOCK])) {
						<uno-button (click)="this.update(state.CLIENT_ACCEPTED);" color="success">{{'unlock' | translate}}</uno-button>
					}
					@if (this.actionPlan.state === state.WAITING_REINSPECTION && session.hasPermissions([userPermissions.ACTION_PLAN_REINSPECT])) {
						<uno-button (click)="this.update(state.DONE);">{{'finish' | translate}}</uno-button>
					}
					@if (session.hasPermissions([userPermissions.ACTION_PLAN_ARCHIVE]) && this.actionPlan.state !== state.ARCHIVED) {
						<uno-button (click)="this.update(state.ARCHIVED);" color="error">{{'archive' | translate}}</uno-button>
					}
					@if (session.hasPermissions([userPermissions.ACTION_PLAN_DELETE])) {
						<uno-button (click)="this.delete();" color="error">{{'delete' | translate}}</uno-button>
					}
				}
			</div>
		</uno-tab-section>

		<!-- Assets -->
		@if ((this.assets | objectKeys).length > 0) {
			<uno-tab-section title="{{'assets' | translate}}">
				<uno-title>{{'assets' | translate}}</uno-title>

				@for (i of this.assets | objectKeys; track i) {
					<!-- Asset information -->
					<uno-dynamic-form [editable]="false" [layout]="assetsLayout" [object]="this.assets[i]"></uno-dynamic-form>

					<!-- Open page -->
					<div style="display: flex; flex-direction: row-reverse; gap: 5px;" class="ion-padding">
						@if (session.hasPermissions([userPermissions.ASSET_PORTFOLIO])) {
							<uno-button (click)="app.navigator.navigate('/menu/asset-portfolio/asset/edit', {uuid: this.assets[i].uuid});">{{'viewAsset' | translate}}</uno-button>
						}
						@if (this.actionPlan.data.documents.length > 0 && session.hasPermissions([userPermissions.ASSET_PORTFOLIO_ASSET_EDIT])) {
							<uno-button (click)="copyDocuments(this.assets[i].uuid);">{{'copyDocuments' | translate}}</uno-button>
						}
					</div>
				}
			</uno-tab-section>
		}

		@if (this.history.length > 0) {
			<uno-tab-section title="{{'history' | translate}}">
				<!-- History -->
				<uno-title>{{'history' | translate}}</uno-title>
				<ion-list class="ion-no-padding ion-no-margin">
					@for (option of this.history; track option) {
						<ion-item class="ion-margin-horizontal ion-no-padding" lines="full">
							<ion-label>{{option.date | formatDate}}</ion-label>
							<div class="ion-text-end">
								<ion-label>{{option.user.name}}</ion-label>
								<ion-label color="medium" style="font-size: 13px;">{{option.user.email + (option.user.phone.length > 0 ? (' | ' + option.user.phone) : '')}}</ion-label>
							</div>
						</ion-item>
					}
				</ion-list>
			</uno-tab-section>
		}
	</uno-tab>
</div>
