/**
 * A universally unique identifier is a 128-bit number used to identify data in the system.
 *
 * It is stored in a string.
 */
export type UUID = string;

/**
 * Lookup table to accelerate the UUID generation process.
 */
const lut = [];
for (let i = 0; i < 256; i++) {
	lut[i] = (i < 16 ? '0' : '') + i.toString(16);
}

/**
 * Generate a UUID used to identify.
 *
 * http://stackoverflow.com/questions/105034/how-to-create-a-guid-uuid-in-javascript/21963136#21963136
 */
export function generateUUID(): UUID {
	const d0 = Math.random() * 0xffffffff | 0;
	const d1 = Math.random() * 0xffffffff | 0;
	const d2 = Math.random() * 0xffffffff | 0;
	const d3 = Math.random() * 0xffffffff | 0;
	return lut[d0 & 0xff] + lut[d0 >> 8 & 0xff] + lut[d0 >> 16 & 0xff] + lut[d0 >> 24 & 0xff] + '-' +
		lut[d1 & 0xff] + lut[d1 >> 8 & 0xff] + '-' + lut[d1 >> 16 & 0x0f | 0x40] + lut[d1 >> 24 & 0xff] + '-' +
		lut[d2 & 0x3f | 0x80] + lut[d2 >> 8 & 0xff] + '-' + lut[d2 >> 16 & 0xff] + lut[d2 >> 24 & 0xff] +
		lut[d3 & 0xff] + lut[d3 >> 8 & 0xff] + lut[d3 >> 16 & 0xff] + lut[d3 >> 24 & 0xff];
}


/**
 * Class that contain the base attributes of a object identified using a UUID.
 *
 * Mimics the structure used in the API to store structures into the database.
 */
export class UUIDIdentification {
	/**
	 * UUID identifier of the entry.
	 */
	public uuid: UUID = null;

	/**
	 * Date of the last update to this data entry.
	 *
	 * Updated anytime any kind of change is applied to the entry.
	 */
	public updatedAt: Date = null;

	/**
	 * Date of creation of this entry.
	 */
	public createdAt: Date = null;
}
