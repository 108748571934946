/**
 * Type of the protection equipment.
 */
export const EPIBarrierType = {
	NONE: -1,
	ZENER: 1,
	GALVANIC: 2
};

export const EPIBarrierTypeLabel: Map<number, string> = new Map([
	[EPIBarrierType.NONE, 'none'],
	[EPIBarrierType.ZENER, 'zener'],
	[EPIBarrierType.GALVANIC, 'galvanic']
]);
