/**
 * Priority of the FFP.
 */
export const FFPPriority = {
	LOW: 0,
	MEDIUM: 1,
	HIGH: 2
};

export const FFPPriorityLabel: Map<number, string> = new Map([
	[FFPPriority.LOW, 'low'],
	[FFPPriority.MEDIUM, 'medium'],
	[FFPPriority.HIGH, 'high']
]);
