/**
 * Stores preferences applied to the application, these values are used as default values for the user settings.
 */
export class MasterSettingsPreferences {
	/**
	 * Preferred locale code.
	 */
	public locale: string = '';

	/**
	 * Maximum size in pixel of the photos taken by the application.
	 *
	 * Is applied in booth axis to the photo (keep the aspect ratio of the image).
	 */
	public pictureMaxSize: number = 0;

	/**
	 * Preferred picture format (e.g. png, jpeg). Pictures inserted by the user may be converted to this format before storage.
	 */
	public pictureFormat: string = '';

	/**
	 * Picture encoding quality level to be used (only for lossy formats).
	 */
	public pictureQuality: number = 0;

	/**
	 * Parse the data from API for a MasterSettingsPreferences object.
	 *
	 * @param data - settings object to parse data
	 */
	public static parse(data: any): MasterSettingsPreferences {
		const preferences = new MasterSettingsPreferences();

		preferences.locale = data.locale;
		preferences.pictureMaxSize = data.pictureMaxSize;
		preferences.pictureFormat = data.pictureFormat;
		preferences.pictureQuality = data.pictureQuality;

		return preferences;
	}
}
