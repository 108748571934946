import {EPI, EPICableData} from './epi';
import {EPIResult} from './epi-result';
import {EPIBarrierType} from './epi-barrier';

/**
 * Represents a EPI validation entry, all validations must pass in order for the EPI to be approved.
 */
type EPIValidation = {
	/**
	 * Label presented to the user.
	 */
	label: string,

	/**
	 * Indicates if the validation should be applied only to the backoffice steps.
	 */
	backofficeOnly: boolean,

	/**
	 * Function to validate if the condition has been met.
	 *
	 * @param epi - EPI to analyse.
	 */
	validator: (epi: EPI)=> boolean,

	/**
	 * Values displayed to the user, for visual validation.
	 *
	 * @param epi - EPI to analyse.
	 */
	values?: (epi: EPI)=> string
}

/**
 * Utils to calculate and validate the EPI values to check if they match the required specification.
 */
export class EPIValidations {
	/**
	 * Calculate the resulting cable of the EPI by merging all cable data together.
	 */
	public static calculateResultingCable(cables: EPICableData[]): EPICableData {
		const cable = new EPICableData();

		for (let i = 0; i < cables.length; i++) {
			cable.capacity += 1 / cables[i].capacity;
			cable.resistance += cables[i].resistance;
			cable.inductance += cables[i].inductance;
			cable.length += cables[i].length;
		}

		cable.capacity = 1 / cable.capacity;

		return cable;
	}

	/**
	 * Get the result of an EPI from the validation conditions.
	 *
	 * All conditions have to be true for the EPI to get approved.
	 *
	 * @param epi - EPI to calculate result.
	 */
	public static getResult(epi: EPI): number {
		const validations = EPIValidations.getValidations(epi);

		for (let i = 0; i < validations.length; i++) {
			if (!validations[i].validator(epi)) {
				return EPIResult.FAILED;
			}
		}

		return EPIResult.APPROVED;
	}

	/**
	 * Get the list of validations for a EPI.
	 *
	 * @param epi - EPI to get validations.
	 */
	public static getValidations(epi: EPI): EPIValidation[] {
		if (epi.barrierType === EPIBarrierType.GALVANIC) {
			return EPIValidations.GALVANIC;
		} else if (epi.barrierType === EPIBarrierType.ZENER) {
			return EPIValidations.ZENER;
		}

		return null;
	}

	/**
	 * Validations for galvanic barriers.
	 *
	 * Has all the conditions necessary for a EPI inspection to be accepted.
	 */
	public static GALVANIC: EPIValidation[] = [
		{
			label: 'Vo ≤ Vi',
			backofficeOnly: false,
			validator: function(epi: EPI) { return epi.data.barrier.maxOutputVoltage <= epi.data.equipment.maxInputVoltage; }
		},
		{
			label: 'Io ≤ Ii',
			backofficeOnly: false,
			validator: function(epi: EPI) { return epi.data.barrier.maxOutputCurrent <= epi.data.equipment.maxInputCurrent; }
		},
		{
			label: 'Po ≤ Pi',
			backofficeOnly: false,
			validator: function(epi: EPI) { return epi.data.barrier.maxOutputPower <= epi.data.equipment.maxInputPower; }
		},
		{
			label: 'Co ≥ Ci + Cc',
			backofficeOnly: true,
			validator: function(epi: EPI) {
				const cable = EPIValidations.calculateResultingCable(epi.data.cables);
				return epi.data.barrier.maxExternalCapacity >= epi.data.equipment.maxInternalCapacity + cable.capacity;
			}
		},
		{
			label: 'Lo ≥ Li + Lc',
			backofficeOnly: true,
			validator: function(epi: EPI) {
				const cable = EPIValidations.calculateResultingCable(epi.data.cables);
				return epi.data.barrier.maxExternalInductance >= epi.data.equipment.maxInternalInductance + cable.inductance;
			}
		}
	];

	/**
	 * Validations for zener barriers.
	 *
	 * Has all the conditions necessary for a EPI inspection to be accepted.
	 */
	public static ZENER: EPIValidation[] = EPIValidations.GALVANIC.concat([
		{
			label: 'Rt ≤ 1Ω',
			backofficeOnly: false,
			values: function(epi: EPI) { return epi.data.barrier.groundResistance + ' ≤ 1Ω'; },
			validator: function(epi: EPI) { return epi.data.barrier.groundResistance <= 1.0; }
		}
	]);
}
