/**
 * List of possible status for the gap entries.
 */
export enum GAGapStatus {
	/**
	 * All status.
	 */
	ALL = -1,

	/**
	 * Waiting for resolution.
	 */
	WAITING = 0,

	/**
	 * When action plan resolved the gap.
	 */
	RESOLVED = 1,

	/**
	 * User archived the gap manually.
	 */
	ARCHIVED = 2
};

/**
 * List of possible status labels for the gap entries.
 */
export const GAGapStatusLabel: Map<number, string> = new Map([
	[GAGapStatus.ALL, 'all'],
	[GAGapStatus.WAITING, 'waiting'],
	[GAGapStatus.RESOLVED, 'resolved'],
	[GAGapStatus.ARCHIVED, 'archived']
]);
