<div style="height: 110px; margin: 15px;">
	<!-- Search bar -->
	<uno-searchbar style="width: 100%;" [value]="selfStatic.filters.search" (valueChange)="onSearch($event)"></uno-searchbar>

	<!-- Filters -->
	<div style="overflow-x: auto; display: flex; align-items: flex-end; justify-content: flex-start; flex-direction: row; gap: 5px; width: 100%">

		<uno-filter-bar [filters]="selfStatic.filters" [options]="selfStatic.filterOptions" (onChange)="this.onFilterChange($event);"></uno-filter-bar>

		@if (this.app.device.isMobile()) {
			<!-- Toggle selection mode -->
			<div style="min-width: 150px; height: 40px; align-items: center; gap: 5px; display: flex; flex-direction: row;">
				<ion-label>{{'selectionMode' | translate}}</ion-label>
				<ion-checkbox [(ngModel)]="this.selectionMode"></ion-checkbox>
			</div>
		}

		<!-- Create action plan button -->
		@if (this.selectionMode || this.checkedTableRows.length > 0 && session.hasPermissions(userPermissions.ACTION_PLAN_CREATE)) {
			<uno-button icon="assets/icons/uno/add-icon.svg" (click)="this.createActionPlan()">
				{{'createActionPlan' | translate}}
			</uno-button>
		}

		@if (this.selectionMode || this.checkedTableRows.length > 0) {
			<uno-button icon="assets/icons/uno/checklist-icon.svg" (click)="this.appendRecommendations()">
				{{'recommendations' | translate}}
			</uno-button>
		}

		@if (this.selectionMode || this.checkedTableRows.length > 0) {
			<uno-button icon="assets/icons/uno/bin-icon.svg" (click)="this.deleteSelection()">
				{{'delete' | translate}}
			</uno-button>
		}
	</div>
</div>

<div style="width: 100%; height: calc(100% - 130px); overflow: hidden;">
	<uno-responsive-table-list [loadMore]="this.loadTableItems" [totalItems]="this.tableTotalItemsCount" [layout]="this.tableLayout" [pageSize]="this.tablePageSize" [selectable]="false" [labelShown]="true" [selectable]="this.selectionMode" (rowClick)="app.navigator.navigate('/menu/atex/ffp/edit', {ffp: $event.element.uuid.value, asset: $event.element.assetUuid.value, inspection: $event.element.inspection.value.uuid});" (sortChange)="this.sortChanged($event.sortBy)" (rowChecked)="this.checkedRow($event)">
	</uno-responsive-table-list>
</div>
