import {Component, OnInit, ViewChild} from '@angular/core';
import {SortDirection} from 'src/app/utils/sort-direction';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {NgStyle} from '@angular/common';
import {UnoTableColumnLayout, UnoTableColumnType} from 'src/app/components/uno/uno-table/uno-table.component';
import {UnoFilterBarComponent, UnoFilterBarOption, UnoFilterBarOptionType} from 'src/app/components/uno/uno-filter-bar/uno-filter-bar.component';
import {UnoResponsiveTableListComponent} from 'src/app/components/uno/uno-responsive-table-list/uno-responsive-table-list.component';
import {ScreenComponent} from '../../../../../components/screen/screen.component';
import {App} from '../../../../../app';
import {Session} from '../../../../../session';
import {UserPermissions} from '../../../../../models/users/user-permissions';
import {ServiceList} from '../../../../../http/service-list';
import {UUID} from '../../../../../models/uuid';
import {Resource} from '../../../../../models/resource';
import {QRGenerator} from '../../../../qr/data/qr-generator';
import {Service} from '../../../../../http/service';
import {InspectionWorkflowStep} from '../../../../../models/inspections/workflow/inspection-workflow-step';
import {InspectionProject} from '../../../../../models/inspections/project/inspection-project';
import {FormatDatePipe} from '../../../../../pipes/format-date.pipe';
import {UnoListComponent} from '../../../../../components/uno/uno-list/uno-list-component';
import {UnoContentComponent} from '../../../../../components/uno/uno-content/uno-content.component';
import {UnoListItemLabelComponent} from '../../../../../components/uno/uno-list-item/uno-list-item-label.component';
import {UnoListItemIconComponent} from '../../../../../components/uno/uno-list-item/uno-list-item-icon.component';
import {UnoListItemComponent} from '../../../../../components/uno/uno-list-item/uno-list-item.component';
import {UnoSearchbarComponent} from '../../../../../components/uno/uno-searchbar/uno-searchbar.component';
import {UnoButtonComponent} from '../../../../../components/uno/uno-button/uno-button.component';
import {InspectionService} from '../../../services/inspection.service';
import {ResourceUtils} from '../../../../../utils/resource-utils';

@Component({
	selector: 'inspection-list-page',
	templateUrl: './inspection-list.page.html',
	standalone: true,
	imports: [
		UnoButtonComponent,
		UnoSearchbarComponent,
		IonicModule,
		FormsModule,
		UnoListItemComponent,
		UnoListItemIconComponent,
		UnoListItemLabelComponent,
		UnoContentComponent,
		NgStyle,
		UnoListComponent,
		TranslateModule,
		FormatDatePipe,
		UnoResponsiveTableListComponent,
		UnoFilterBarComponent
	]
})
export class InspectionListPage extends ScreenComponent implements OnInit {

	@ViewChild(UnoResponsiveTableListComponent) 
	public table: UnoResponsiveTableListComponent;

	public get settings(): any { return Session.settings; }

	public get resource(): any { return Resource; }

	public get generateQr(): any { return QRGenerator.generateFile; }

	public get app(): any { return App; }

	public get session(): any { return Session; }

	public get userPermissions(): any { return UserPermissions; }

	public get selfStatic(): any { return InspectionListPage; }

	public permissions = [UserPermissions.INSPECTION];

	/**
	 * Project UUID to list inspections for.
	 */
	public project: UUID = null;

	/**
	 * Step UUID to list inspections for.
	 */
	public step: UUID = null;

	/**
	 * The maximum number of items to show on table component.
	 */
	public tableTotalItemsCount: number = 100;

	/**
	 * The number of items to show on table per page.
	 */
	public static tablePageSize: number = 30;

	/**
	 * The layout to use on the Uno Table component.
	 */
	public tableLayout: UnoTableColumnLayout[] = [
		{header: 'image', type: UnoTableColumnType.IMAGE, attribute: 'image', visible: true, size: 'small'},
		{header: 'assetName', type: UnoTableColumnType.TEXT, attribute: 'assetName', visible: true, size: 'small', sortBy: '[ap_asset].[name]'},
		{header: 'assetTag', type: UnoTableColumnType.TEXT, attribute: 'assetTag', visible: true, size: 'small', sortBy: '[ap_asset].[tag]'},
		{header: 'name', type: UnoTableColumnType.TEXT, attribute: 'name', visible: true, size: 'small', sortBy: '[inspection].[name]'},
		{header: 'description', type: UnoTableColumnType.TEXT, attribute: 'description', visible: true, size: 'medium', sortBy: '[inspection].[description]'},
		{header: 'step', type: UnoTableColumnType.TEXT, attribute: 'step', visible: true, size: 'small'},
		{
			header: 'actions',
			type: UnoTableColumnType.ICONS,
			attribute: 'actions',
			visible: true,
			size: 'small',
			icons:
			[
				{
					src: './assets/icons/assets/expand-icon.svg',
					click: (row): void => {
						App.openInTab('/menu/inspection/edit', {uuid: row.uuid.value});
					}
				}
			]
		}
	];

	/**
	 * Possible database filter to be used for ordering the inspections list.
	 */
	public static filterOptions: UnoFilterBarOption[] = [
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'sortDirection',
			label: 'direction',
			default: SortDirection.DESC,
			options: [
				{label: 'asc', value: SortDirection.ASC},
				{label: 'desc', value: SortDirection.DESC}
			]
		},
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'sortField',
			label: 'sortField',
			default: '[inspection].[updated_at]',
			options: [
				{label: 'name', value: '[inspection].[name]'},
				{label: 'description', value: '[inspection].[description]'},
				{label: 'updatedAt', value: '[inspection].[updated_at]'},
				{label: 'createdAt', value: '[inspection].[created_at]'},
				{label: 'assetName', value: '[ap_asset].[name]'},
				{label: 'assetTag', value: '[ap_asset].[tag]'}
	
			]
		},
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'searchFields',
			label: 'searchFields',
			default: ['[inspection].[name]', '[inspection].[description]', '[ap_asset].[name]', '[ap_asset].[tag]'],
			multiple: true,
			options: [
				{label: 'inspectionUuid', value: '[inspection].[id]'},
				{label: 'name', value: '[inspection].[name]'},
				{label: 'description', value: '[inspection].[description]'},
				{label: 'assetUuid', value: '[ap_asset].[id]'},
				{label: 'assetName', value: '[ap_asset].[name]'},
				{label: 'assetTag', value: '[ap_asset].[tag]'}
			]
		}
	];

	public static filters = UnoFilterBarComponent.reset({
		/**
		 * Text used to filter list entries by their content.
		 */
		search: '',

		/**
		 * Search fields to be considered.
		 */
		searchFields: [],
		
		/**
		 * Sort direction applied to the loaded list from database.
		 */
		sortDirection: '',

		/**
		 * Database attribute name used to sort the values.
		 */
		sortField: ''
	}, InspectionListPage.filterOptions);

	public static defaultFilters = structuredClone(InspectionListPage.filters);

	public loadTableItems = async(count: number, pageSize: number): Promise<any> => {
		const params = {
			project: this.project,
			step: this.step,
			from: count,
			count: pageSize,
			search: InspectionListPage.filters.search,
			sortDirection: InspectionListPage.filters.sortDirection,
			sortField: InspectionListPage.filters.sortField,
			searchFields: InspectionListPage.filters.searchFields
		};

		const list = await InspectionService.list(params);

		for (const inspection of list.inspections) {
			inspection.image = inspection.asset?.pictures.length > 0 ? inspection.asset?.pictures[0] : './assets/placeholder/asset.png';
			inspection.assetName = inspection.asset?.name;
			inspection.assetTag = inspection.asset?.tag;
			inspection.step = inspection.step?.name;
		}

		return {
			elements: list.inspections,
			hasMore: list.hasMore
		};
	};

	public resetFilters(): void {
		this.table.reset();

		Object.assign(InspectionListPage.filters, InspectionListPage.defaultFilters);
	}

	public async ngOnInit(): Promise<void> {
		super.ngOnInit();

		const data = App.navigator.getData();


		this.project = data?.project ? data.project : null;
		this.step = data?.step ? data.step : null;

		App.navigator.setTitle('list');

		if (this.step) {
			const request = await Service.fetch(ServiceList.inspection.workflowStep.get, null, null, {uuid: this.step}, Session.session, true);
			const step = InspectionWorkflowStep.parse(request.response.step);
			if (step.name) {
				App.navigator.setTitle(step.name);
			}
		} else if (this.project) {
			const request = await Service.fetch(ServiceList.inspection.project.get, null, null, {uuid: this.project}, Session.session, true);
			const project = InspectionProject.parse(request.response.project);
			if (project.name) {
				App.navigator.setTitle(project.name);
			}
		}

		this.updateItemCount();
	}

	/**
	 * Update how many items available in the table
	 */
	public async updateItemCount(): Promise<void> {
		const params = {
			projectUuid: this.project,
			stepUuid: this.step,
			search: InspectionListPage.filters.search,
			searchFields: InspectionListPage.filters.searchFields
		};

		this.tableTotalItemsCount = await InspectionService.count(params);
	}

	/**
	 * Update the search term used.
	 *
	 * @param event - DOM event.
	 */
	public async onSearch(event: any): Promise<void> {
		if (event.target === undefined) {
			InspectionListPage.filters.search = event;
		}

		this.table.reset();

		await this.updateItemCount();
	}

	/**
	 * Update the filters and search term used.
	 *
	 * @param filters - DOM event.
	 */
	public async onFilterChange(filters: any): Promise<void> {
		InspectionListPage.filters = filters;

		this.table.sortDirection = InspectionListPage.filters.sortDirection;
		this.table.sortField = InspectionListPage.filters.sortField;

		this.table.reset();

		await this.updateItemCount();
	}

	/**
	 * Navigate to the inspection form edit page.
	 *
	 * @param uuid - The UUID of the gap to open in new page.
	 */
	public async navigate(uuid): Promise<void> {
		await App.navigator.navigate('/menu/inspection/edit', {uuid: uuid});
	}

	/**
	 * When the table emits a value to change which column to sort by.
	 * 
	 * @param attribute - The attribute to sort by.
	 */
	public sortChanged(attribute: string): void {
		// If the attribute is already the current one, change the sort direction.
		if (attribute === InspectionListPage.filters.sortField) {
			InspectionListPage.filters.sortDirection = this.table.sortDirection;
		} else {
			InspectionListPage.filters.sortField = attribute;
			InspectionListPage.filters.sortDirection = SortDirection.ASC;
		}
	
		this.table.reset();
	}

	protected readonly ResourceUtils = ResourceUtils;
}
