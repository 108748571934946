import {FileUtils} from '../../../../utils/file-utils';
import {Service} from '../../../../http/service';
import {ServiceList} from '../../../../http/service-list';
import {Session} from '../../../../session';
import {Locale} from '../../../../locale/locale';
import {Modal} from '../../../../modal';
import {XlsxUtils} from '../../../../utils/xlsx-utils';

export class RepairImport {
	/**
	 * Import repairs list from XLSX file. Parse is done on API.
	 */
	public static async importXLSX(): Promise<void> {
		const files = await FileUtils.chooseFile('.xlsx', false);
		if (files.length > 0) {
			const file = files[0];
			const form = new FormData();

			form.append('file', file, 'xlsx');

			try {
				const request = await Service.fetch(ServiceList.repairs.importXlsx, null, null, form, Session.session);
				const response = request.response;

				Modal.alert(Locale.get('success'), Locale.get('importedRepairsXlsxSuccessfully', {
					imported: response.imported,
					updated: response.updated,
					failed: response.failed
				}));

				if (request.response.failed > 0) {
					XlsxUtils.writeFile(response.rows, 'details.xlsx');
				}
			} catch (e) {
				Modal.alert(Locale.get('error'), Locale.get('errorImportRepairs'));
			}
		}
	}
}
