<div style="height: 110px; margin: 10px;">
	<div style="display:flex; align-items: center; gap: 5px;">
		<!-- Create button -->
		@if (session.user.isAdmin) {
			<uno-button icon="assets/icons/uno/add-icon.svg" (click)="app.navigator.navigate('/menu/role/create')">
				@if (app.device.isDesktop()) {
					{{'create' | translate}}
				}
			</uno-button>
		}

		<!-- Search bar -->
		<uno-searchbar style="width: 100%;" [value]="selfStatic.filters.search" (valueChange)="this.onSearchChange($event)"></uno-searchbar>
	</div>

	<uno-filter-bar [filters]="selfStatic.filters" [options]="selfStatic.filterOptions" (onChange)="this.onFilterChange($event);"></uno-filter-bar>
</div>

<!-- Desktop table -->
@if (app.device.isDesktop()) {
	<div style="width: calc(100% - 36px); height: calc(100% - 130px); margin: 10px 18px; overflow: hidden;">
		<uno-table [loadMore]="this.loadTableItems" [totalItems]="this.tableTotalItemsCount" [layout]="this.tableLayout" [pageSize]="selfStatic.tablePageSize" [selectable]="false" (rowClick)="app.navigator.navigate('/menu/role/edit', {uuid: $event.element.uuid.value});" (sortChange)="this.sortChanged($event.sortBy)">
		</uno-table>
	</div>
}

<!-- Mobile list -->
@if (app.device.isMobile()) {
	<ng-template #itemTemplate let-role="item">
		<uno-list-item (click)="app.navigator.navigate('/menu/role/edit', {uuid: role.uuid});" style="height: 48px;">
			<uno-list-item-icon>
				<div slot="start" style="line-height: 40px; color: var(--gray-12); font-size: 17px;
					  border-radius: 50%; text-align: center; background-color: var(--brand-primary); width: 40px; height:40px;">{{role.permissions.length}}</div>
			</uno-list-item-icon>

			<uno-list-item-label>
				<h2>{{role.name}}</h2>
				<h3>{{role.description}}</h3>
			</uno-list-item-label>
		</uno-list-item>
	</ng-template>

	<uno-content [ngStyle]="{height: 'calc(100% - 130px)'}">
		<uno-list [handler]="this.handler" [itemTemplate]="itemTemplate"></uno-list>
	</uno-content>
}
