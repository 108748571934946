import {Component, OnInit} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {IonicModule} from '@ionic/angular';
import {Session} from '../../../session';
import {App} from '../../../app';
import {ScreenComponent} from '../../../components/screen/screen.component';
import {UserPermissions} from '../../../models/users/user-permissions';
import {UUID} from '../../../models/uuid';
import {InspectionStepsGraphComponent} from '../../inspections/components/inspection-steps-graph/inspection-steps-graph.component';
import {RepairsCriticalityGraphComponent} from '../../repairs/repair-work/components/repair-criticality-graph/repair-criticality-graph.component';
import {RepairStatesGraphComponent} from '../../repairs/repair-work/components/repair-states-graph/repair-states-graph.component';
import {ActionPlanStatesComponent} from '../../atex/action-plan/components/action-plan-states/action-plan-states.component';
import {AtexInspectionDailyUpdatedComponent} from '../../atex/inspections/components/graphs/atex-inspection-daily-updated/atex-inspection-daily-updated.component';
import {AtexInspectionDailyStatusReviewComponent} from '../../atex/inspections/components/graphs/atex-inspection-daily-status-review/atex-inspection-daily-status-review.component';
import {AtexInspectionDailyStatusDoneComponent} from '../../atex/inspections/components/graphs/atex-inspection-daily-status-done/atex-inspection-daily-status-done.component';
import {AtexInspectionStatesComponent} from '../../atex/inspections/components/graphs/atex-inspection-states/atex-inspection-states.component';
import {AtexInspectionProgressComponent} from '../../atex/inspections/components/graphs/atex-inspection-progress/atex-inspection-progress.component';
import {UnoContentComponent} from '../../../components/uno/uno-content/uno-content.component';
import {InspectionProjectService} from '../../inspections/services/inspection-project.service';
import {ActionPlanBlockedComponent} from '../../atex/action-plan/components/action-plan-blocked/action-plan-blocked.component';
import {ActionPlanExecutedComponent} from '../../atex/action-plan/components/action-plan-executed/action-plan-executed.component';
import {ActionPlanRejectedComponent} from '../../atex/action-plan/components/action-plan-rejected/action-plan-rejected.component';

@Component({
	selector: 'dashboard-page',
	templateUrl: 'dashboard.page.html',
	standalone: true,
	imports: [UnoContentComponent, IonicModule, AtexInspectionProgressComponent, AtexInspectionStatesComponent, AtexInspectionDailyStatusDoneComponent, AtexInspectionDailyStatusReviewComponent, AtexInspectionDailyUpdatedComponent, ActionPlanStatesComponent, RepairStatesGraphComponent, RepairsCriticalityGraphComponent, InspectionStepsGraphComponent, TranslateModule, ActionPlanBlockedComponent, ActionPlanExecutedComponent, ActionPlanRejectedComponent]
})
export class DashboardPage extends ScreenComponent implements OnInit {
	public get session(): any { return Session; }

	public get userPermissions(): any { return UserPermissions; }

	/**
	 * List of inspection projects to load dashboard data.
	 */
	public inspectionProjects: UUID[] = [];

	public async ngOnInit(): Promise<void> {
		super.ngOnInit();

		App.navigator.setTitle('dashboard');

		await this.loadInspectionProjects();
	}

	/**
	 * Load the list of the inspection projects available in the platform.
	 */
	public async loadInspectionProjects(): Promise<void> {
		if (Session.hasPermissions([UserPermissions.INSPECTION_PROJECT_DASHBOARD_STEPS])) {
			const request = await InspectionProjectService.list({shownInMenuOnly: true});
			this.inspectionProjects = request.projects.map(function(project: any): any {
				return project.uuid;
			});
		}
	}
}


