import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {App} from '../../../app';
import {Locale} from '../../../locale/locale';
import {Modal} from '../../../modal';
import {Service} from '../../../http/service';
import {ServiceList} from '../../../http/service-list';
import {Session} from '../../../session';
import {ScreenComponent} from '../../../components/screen/screen.component';
import {UserPermissions} from '../../../models/users/user-permissions';
import {QRReaderComponent} from '../components/qr-reader/qr-reader.component';
import {AssetService} from '../../asset-portfolio/services/asset.service';
import {RepairService} from '../../repairs/repair-work/services/repair.service';
import {InspectionService} from '../../inspections/services/inspection.service';

/**
 * QR page is used to read QR code tags.
 *
 * When a QR code is read it automatically opens the associated item (asset, repair, etc).
 */
@Component({
	selector: 'qr-page',
	templateUrl: 'qr.page.html',
	standalone: true,
	imports: [QRReaderComponent]
})
export class QRPage extends ScreenComponent implements OnInit, OnDestroy {
	@ViewChild('reader', {static: true})
	public reader: QRReaderComponent = null;

	public permissions = [UserPermissions.REPAIR_QR, UserPermissions.ASSET_PORTFOLIO_QR, UserPermissions.INSPECTION_QR];

	/**
	 * Callback method used to perform a different task other than opening the item view/edit page.
	 */
	public onRead: (qr: string)=> void = null;

	/**
	 * Indicates if a QR value read is currently being process.
	 */
	public processing: boolean = false;

	public ngOnInit(): void {
		super.ngOnInit();
		
		const data = App.navigator.getData();
		if (data !== null && data.onRead !== undefined) {
			this.onRead = data.onRead;
		}

		this.processing = false;
		this.reader.enabled = true;
		this.reader.onRead = (qr: string) => {
			this.processQR(qr);
		};

		App.navigator.setTitle('qrReader');
	}


	/**
	 * Process a predefined action for a QR code based on its content.
	 * 
	 * @param qr - QR code read from the device camera.
	 * @returns True if the QR code was processed, false otherwise.
	 */
	public async processAction(qr: string): Promise<boolean> {
		if (Session.hasPermissions([UserPermissions.ASSET_PORTFOLIO_QR])) {
			try {
				const asset = await AssetService.getByQR(qr, true, false);
				App.navigator.navigate('/menu/asset-portfolio/asset/edit', {uuid: asset.uuid});
				return true;
			} catch (e) {}

			try {
				const asset = await AssetService.get(qr, true, false);
				App.navigator.navigate('/menu/asset-portfolio/asset/edit', {uuid: asset.uuid});
				return true;
			} catch (e) {}
		}

		if (Session.hasPermissions([UserPermissions.REPAIR_QR])) {
			try {
				const repair = await RepairService.getByQR(qr, true, false);
				App.navigator.navigate('/menu/repairs/works/edit', {uuid: repair.uuid});
				return true;
			} catch (e) {}
		}

		if (Session.hasPermissions([UserPermissions.INSPECTION_QR])) {
			try {
				const inspection = await InspectionService.getByQR(qr, true, false);
				App.navigator.navigate('/menu/inspection/edit', {uuid: inspection.uuid});
				return true;
			} catch (e) {}
		}

		return false;
	}

	/**
	 * Process a QR code read from the device camera, may process a predefined action for a type of QR marker or a used provided method.
	 *
	 * @param qr - QR code read from the device camera.
	 */
	public async processQR(qr: string): Promise<void> {
		if (this.processing) {
			return;
		}

		this.processing = true;

		if (this.onRead instanceof Function) {
			this.onRead(qr);
		} else {
			const found = await this.processAction(qr);
			if (!found) {
				Modal.alert(Locale.get('warning'), Locale.get('qrUnknown'));
			}
		}

		this.processing = false;
	}

	public ngOnDestroy(): void {
		this.reader.enabled = false;
	}
}
