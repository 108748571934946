import {Component, Input} from '@angular/core';
import {QRGenerator} from 'src/app/modules/qr/data/qr-generator';
import {Session} from 'src/app/session';
import {NgStyle} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {App} from '../../../../../../app';
import {Resource} from '../../../../../../models/resource';
import {StringUtils} from '../../../../../../utils/string-utils';
import {AssetTreeItem} from '../asset-tree-list.page';
import {ResourceUtils} from '../../../../../../utils/resource-utils';

/**
 * Asset list item is used to display an asset (with its child assets on a collapsable tree list)
 */
@Component({
	selector: 'asset-tree-list-item',
	templateUrl: 'asset-tree-list-item.component.html',
	standalone: true,
	imports: [IonicModule, NgStyle]
})
export class AssetTreeListItemComponent {
	public get settings(): any { return Session.settings; }

	public get app(): any { return App; }
	
	public get resource(): any { return Resource; }

	public get generateQr(): any { return QRGenerator.generateFile; }

	public get stringUtils(): any { return StringUtils; }

	@Input()
	public item: AssetTreeItem = null;

	/**
	 * Level of this object in the tree.
	 */
	@Input()
	public level: number = 1;

	/**
	 * Search filter.
	 */
	@Input()
	public search: string = null;

	/**
	 * Search fields to be considered on search filter.
	 */
	@Input()
	public searchFields: string[] = [];

	/**
	 * The field to sort assets by
	 */
	@Input()
	public sortField: string = null;

	/**
	 * The direction to sort assets
	 */
	@Input()
	public sortDirection: string = null;

	/**
	 * If true the tree list is expanded to show its children.
	 */
	public expanded: boolean = false;

	/**
	 * Check if the node can be expanded.
	 */
	public canExpand(): boolean {
		return this.item.children && this.item.children.length > 0;
	}

	/**
	 * Method called when the node is clicked, to open the asset edit page.
	 */
	public editAsset(): void {
		App.navigator.navigate('/menu/asset-portfolio/asset/edit', {uuid: this.item.uuid});
	}

	/**
	 * Toggle the value of expand var, making template expand/collapse
	 * 
	 * May require data to be loaded lazily.
	 * @returns A boolean promise to ensure reload page caused by the event listener
	 */
	public expand(event: MouseEvent): boolean {
		// Toggle expanded flag
		this.expanded = !this.expanded;

		// Cancel event bubble to prevent navigation
		if (event !== undefined) {
			event.preventDefault();
			event.cancelBubble = true;
		}

		return false;
	}

	protected readonly ResourceUtils = ResourceUtils;
}
