import {UUID, UUIDIdentification} from '../../uuid';

/**
 * Inspection workflow steps are used to build workflows.
 *
 * A step represents a phase in the inspection workflow. Steps are connected in a workflow graph.
 */
export class InspectionWorkflowStep extends UUIDIdentification {
	/**
	 * Name of the workflow step.
	 */
	public name: string = '';

	/**
	 * Description of the workflow step.
	 */
	public description: string = '';

	/**
	 * Allow the update of an inspection bypassing the required fields check on update
	 */
	public bypassRequiredCheck: boolean = false;

	/**
	 * Index of the workflow step.
	 */
	public index: number = 0;

	/**
	 * CSS color representing this workflow step.
	 */
	public color: string = '#FE7b05';

	/**
	 * UUID of the inspection form to be used in this workflow step.
	 */
	public formUuid: UUID = null;

	/**
	 * UUID of the workflow that this step belongs to
	 */
	public workflowUuid: UUID = null;

	/**
	 * Indicates if this step is the default step for the inspection workflow.
	 */
	public defaultStep: boolean = false;

	/**
	 * Status of the step(on inspection)
	 */
	public status: number = 0;

	/**
	 * Indicates if gaps will be analysed when the inspection is saved in this step.
	 */
	public analysesGaps: boolean = false;

	/**
	 * RBAC list of allowed roles
	 */
	public rbacMasterAllowedList: UUID[] = [];

	/**
	 * RBAC list of denied roles
	 */
	public rbacMasterDeniedList: UUID[] = [];

	/**
	 * RBAC list of allowed roles
	 */
	public rbacAllowedList: UUID[] = [];

	/**
	 * RBAC list of denied roles
	 */
	public rbacDeniedList: UUID[] = [];

	/**
	 * Parse the data of an API retrieved and create a inspection workflow step object.
	 *
	 * @param data - Object to parse data.
	 */
	public static parse(data: any): InspectionWorkflowStep {
		const step = new InspectionWorkflowStep();

		step.uuid = data.uuid;
		step.createdAt = new Date(data.createdAt);
		step.updatedAt = new Date(data.updatedAt);

		step.name = data.name;
		step.description = data.description;
		step.bypassRequiredCheck = data.bypassRequiredCheck === true;
		step.workflowUuid = data.workflowUuid;
		step.formUuid = data.formUuid;
		step.index = data.index;
		step.defaultStep = data.defaultStep === true;
		step.status = data.status;
		step.analysesGaps = data.analysesGaps;

		step.rbacMasterAllowedList = data.rbacMasterAllowedList || [];
		step.rbacMasterDeniedList = data.rbacMasterDeniedList || [];
		step.rbacAllowedList = data.rbacAllowedList || [];
		step.rbacDeniedList = data.rbacDeniedList || [];
		
		if (data.color) {
			step.color = data.color;
		}

		return step;
	}

	/**
	 * Parse a array of inspection steps retrieved from the API.
	 *
	 * @param data - Array of steps to be parsed.
	 */
	public static parseArray(data: any[]): InspectionWorkflowStep[] {
		const steps: InspectionWorkflowStep[] = [];

		for (let i = 0; i < data.length; i++) {
			steps.push(InspectionWorkflowStep.parse(data[i]));
		}

		steps.sort((a: InspectionWorkflowStep, b: InspectionWorkflowStep): number => {
			return a.index - b.index;
		});

		for (let i = 0; i < steps.length; i++) {
			steps[i].index = i;
		}

		return steps;
	}
}
