import {UnoFormField} from '../../../../components/uno-forms/uno-dynamic-form/uno-form-field';
import {UnoFormFieldTypes} from '../../../../components/uno-forms/uno-dynamic-form/uno-form-field-types';
import {Service} from '../../../../http/service';
import {ServiceList} from '../../../../http/service-list';
import {Session} from '../../../../session';

export const LdsLayoutSimplified: UnoFormField[] = [
	{
		label: 'uuid',
		attribute: 'uuid',
		type: UnoFormFieldTypes.UUID
	},
	{
		required: true,
		attribute: 'name',
		label: 'name',
		type: UnoFormFieldTypes.TEXT
	},
	{
		required: false,
		attribute: 'description',
		label: 'description',
		type: UnoFormFieldTypes.TEXT_MULTILINE
	},
	{
		required: true,
		attribute: 'spacing',
		label: 'spacing',
		type: UnoFormFieldTypes.NUMBER
	},
	{
		required: false,
		attribute: 'chemical',
		label: 'chemical',
		type: UnoFormFieldTypes.TEXT
	}
];

export const LdsLayout = LdsLayoutSimplified.concat([
	{
		required: true,
		attribute: 'pipelineUuid',
		label: 'pipeline',
		type: UnoFormFieldTypes.OPTIONS,
		options: [],
		editable: true,
		fetchOptions: async(object: any, row: UnoFormField) => {
			const request = await Service.fetch(ServiceList.pipelineIntegrity.pipeline.list, null, null, {plantUuid: null}, Session.session);
			const pipelines = request.response.pipelines;

			const options = [];
			for (let i = 0; i < pipelines.length; i++) {
				options.push({
					label: pipelines[i].name,
					value: pipelines[i].uuid
				});
			}

			row.options = options;
		}
	},
	{
		required: true,
		label: 'position',
		attribute: 'position',
		type: UnoFormFieldTypes.GEO_POSITION
	}
]);
