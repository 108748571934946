import {Service} from '../../../http/service';
import {ServiceList} from '../../../http/service-list';
import {UUID} from '../../../models/uuid';
import {Session} from '../../../session';
import {APAssetFieldData} from '../../../models/asset-portfolio/asset-field-data';

export class AssetReportUtils {
	/**
	 * Gets all the asset fields data for an asset UUID.
	 * 
	 * @param assetUuid - The UUID of the asset to get the fields data for.
	 * @param fields - The object used to keep a cache of asset fields data.
	 * @returns The array of fields data fetched from API to the asset UUID.
	 */
	public static async getAssetFieldsData(assetUuid: UUID, fields?: Map<UUID, APAssetFieldData[]>): Promise<APAssetFieldData[]> {
		if (fields && fields.has(assetUuid)) {
			return fields.get(assetUuid);
		}

		const data = (await Service.fetch(ServiceList.assetPortfolio.asset.data.list, null, null, {assetUuid: assetUuid}, Session.session, true)).response.data;

		const assetFieldsData: APAssetFieldData[] = [];
		for (let i = 0; i < data.length; i++) {
			assetFieldsData.push(APAssetFieldData.parse(data[i]));
		}

		if (fields) {
			fields.set(assetUuid, assetFieldsData);
		}

		return assetFieldsData;
	}
}
