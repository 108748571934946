import {Component, OnInit} from '@angular/core';
import {App} from '../../../../app';
import {ScreenComponent} from '../../../../components/screen/screen.component';
import {UserPermissions} from '../../../../models/users/user-permissions';
import {Session} from '../../../../session';
import {MenuCardsOptions, MenuCardsComponent} from '../../../../components/layout/menu-cards/menu-cards.component';
import {UnoContentComponent} from '../../../../components/uno/uno-content/uno-content.component';

@Component({
	selector: 'gap-analysis-menu-page',
	templateUrl: 'gap-analysis-menu.page.html',
	standalone: true,
	imports: [UnoContentComponent, MenuCardsComponent]
})
export class GapAnalysisMenuPage extends ScreenComponent implements OnInit {
	public get app(): any { return App; }

	public get session(): any { return Session; }

	public permissions = [UserPermissions.GA];

	public ngOnInit(): void {
		super.ngOnInit();

		App.navigator.setTitle('gapAnalysis');
	}

	/**
	 * List of options in this menu.
	 */
	public options: MenuCardsOptions[] = [
		{
			route: '/menu/gap-analysis/gaps/list',
			icon: 'build',
			label: 'gaps',
			permissions: [],
			data: null,
			badge: null
		},
		{
			route: '/menu/gap-analysis/action-plans',
			icon: 'build',
			label: 'actionPlans',
			permissions: [],
			data: null,
			badge: null
		}
	];
}
