/**
 * List of possible status for the gap entries.
 */
export const GAActionPlanStatus = {
	/**
	 * No specific status of action plans.
	 */
	ALL: -1,

	/**
	 * Action plan is waiting for a proposal.
	 */
	PROPOSAL: 0,

	/**
	 * Action plan with a proposal awaiting for approval.
	 */
	WAITING_APPROVAL: 1,

	/**
	 * The action plan proposal was rejected.
	 */
	REJECTED: 2,

	/**
	 * Action plan is under work, in the implementation step.
	 */
	IMPLEMENTATION: 3,

	/**
	 * Action plan could not be performed for some reason and is awaiting for resolution in order to proceed implementation jobs.
	 */
	BLOCKED: 4,

	/**
	 * Action plan was completed but awaits for reinspection/final approval.
	 */
	REINSPECTION: 5,
	
	/**
	 * Action plan was completed and approved.
	 */
	DONE: 6,
	
	/**
	 * Action plan is archived.
	 */
	ARCHIVED: 7
};

/**
 * List of possible status labels for the gap entries.
 */
export const GAActionPlanStatusLabel: Map<number, string> = new Map([
	[GAActionPlanStatus.ALL, 'all'],
	[GAActionPlanStatus.PROPOSAL, 'proposal'],
	[GAActionPlanStatus.WAITING_APPROVAL, 'waitingApproval'],
	[GAActionPlanStatus.REJECTED, 'rejected'],
	[GAActionPlanStatus.IMPLEMENTATION, 'implementation'],
	[GAActionPlanStatus.BLOCKED, 'blocked'],
	[GAActionPlanStatus.REINSPECTION, 'reinspection'],
	[GAActionPlanStatus.DONE, 'done'],
	[GAActionPlanStatus.ARCHIVED, 'archived']
]);
