import {UnoFormField} from '../../../../../components/uno-forms/uno-dynamic-form/uno-form-field';
import {UnoFormFieldTypes} from '../../../../../components/uno-forms/uno-dynamic-form/uno-form-field-types';


export const MotAcquisitionLayout: UnoFormField[] = [
	{
		label: 'uuid',
		attribute: 'uuid',
		type: UnoFormFieldTypes.UUID
	},
	{
		required: true,
		attribute: 'description',
		label: 'description',
		type: UnoFormFieldTypes.TEXT_MULTILINE
	},
	{
		required: true,
		attribute: 'dateTime',
		label: 'date',
		type: UnoFormFieldTypes.DATE
	},
	{
		required: true,
		attribute: 'cmpUuid',
		label: 'cmp',
		type: UnoFormFieldTypes.TEXT,
		editable: false
	}
];
