export const ObservationStatus = {
	ALL: -1,
	TODO: 1,
	DONE: 2
};

export const ObservationStatusLabel: Map<number, string> = new Map([
	[ObservationStatus.ALL, 'all'],
	[ObservationStatus.TODO, 'todo'],
	[ObservationStatus.DONE, 'done']
]);
