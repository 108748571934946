<div style="height: 110px; margin: 10px;">
	<div style="display:flex; align-items: center; gap: 5px;">
		<!-- Create button -->
		@if (session.hasPermissions([userPermissions.INSPECTION_WORKFLOW_CREATE])) {
			<uno-button icon="assets/icons/uno/add-icon.svg" (click)="app.navigator.navigate('/menu/inspection/workflow/edit', {createMode: true})">
				@if (app.device.isDesktop()) {
					{{'create' | translate}}
				}
			</uno-button>
		}
		<!-- Search bar -->
		<uno-searchbar style="width: 100%;" [value]="selfStatic.filters.search" (valueChange)="this.onSearch($event)"></uno-searchbar>
	</div>

	<uno-filter-bar [filters]="selfStatic.filters" [options]="selfStatic.filterOptions" (onChange)="this.onFilterChange($event);"></uno-filter-bar>
</div>

<!-- Desktop table -->
@if (app.device.isDesktop()) {
	<div style="width: calc(100% - 36px); height: calc(100% - 130px); margin: 10px 18px; overflow: hidden;">
		<uno-table [loadMore]="this.loadTableItems" [totalItems]="this.tableTotalItemsCount" [layout]="this.tableLayout" [pageSize]="selfStatic.tablePageSize" [selectable]="false" (rowClick)="this.navigate($event.element.uuid.value)" (sortChange)="this.sortChanged($event.sortBy)">
		</uno-table>
	</div>
}

@if (app.device.isMobile()) {
	<ng-template #itemTemplate let-workflow="item">
		<uno-list-item (click)="this.navigate(workflow.uuid)">
			<uno-list-item-label>
				<h2>{{workflow.name}}</h2>
				<h3>{{workflow.description}}</h3>
			</uno-list-item-label>
		</uno-list-item>
	</ng-template>

	<uno-content [ngStyle]="{height: 'calc(100% - 65px)'}">
		<uno-list [handler]="this.handler" [itemTemplate]="itemTemplate"></uno-list>
	</uno-content>
}
