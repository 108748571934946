import {AtexProtections, AtexZones} from './atex-enums';

/**
 * The atex protection zones indicates the relation the zones and the required protection for the equipment to be compliant with the zone.
 *
 * At least one of the protections if required to be accepted as compliant with the zone. This table should be used when there is no category associated with the equipment (for older Atex labels)
 */
export const AtexProtectionsZones: Map<number, number[]> = new Map<number, number[]>([
	[AtexZones.UNCLASSIFIED, []],
	[AtexZones.MINE, [
		AtexProtections.tD,
		AtexProtections.iaD,
		AtexProtections.maD,
		AtexProtections.ia,
		AtexProtections.ma,
		AtexProtections.c,
		AtexProtections.b,
		AtexProtections.k,
		AtexProtections.g
	]],
	[AtexZones.ZONE_0, [
		AtexProtections.ia,
		AtexProtections.ma,
		AtexProtections.c,
		AtexProtections.b,
		AtexProtections.k,
		AtexProtections.g
	]],
	[AtexZones.ZONE_1, [
		AtexProtections.e,
		AtexProtections.d,
		AtexProtections.q,
		AtexProtections.ia,
		AtexProtections.ib,
		AtexProtections.p,
		AtexProtections.ma,
		AtexProtections.mb,
		AtexProtections.o,
		AtexProtections.d,
		AtexProtections.c,
		AtexProtections.b,
		AtexProtections.p,
		AtexProtections.k,
		AtexProtections.g
	]],
	[AtexZones.ZONE_2, [
		AtexProtections.e,
		AtexProtections.nA,
		AtexProtections.d,
		AtexProtections.nC,
		AtexProtections.q,
		AtexProtections.ia,
		AtexProtections.ib,
		AtexProtections.ic,
		AtexProtections.nL,
		AtexProtections.p,
		AtexProtections.nR,
		AtexProtections.nZ,
		AtexProtections.ma,
		AtexProtections.mb,
		AtexProtections.o,
		AtexProtections.fr,
		AtexProtections.d,
		AtexProtections.c,
		AtexProtections.b,
		AtexProtections.p,
		AtexProtections.k,
		AtexProtections.g
	]],
	[AtexZones.ZONE_20, [
		AtexProtections.tD,
		AtexProtections.iaD,
		AtexProtections.maD,
		AtexProtections.c,
		AtexProtections.b,
		AtexProtections.k,
		AtexProtections.g
	]],
	[AtexZones.ZONE_21, [
		AtexProtections.tD,
		AtexProtections.iaD,
		AtexProtections.ibD,
		AtexProtections.pD,
		AtexProtections.maD,
		AtexProtections.ibD,
		AtexProtections.d,
		AtexProtections.c,
		AtexProtections.b,
		AtexProtections.p,
		AtexProtections.k,
		AtexProtections.g

	]],
	[AtexZones.ZONE_22, [
		AtexProtections.tD,
		AtexProtections.iaD,
		AtexProtections.ibD,
		AtexProtections.pD,
		AtexProtections.maD,
		AtexProtections.ibD,
		AtexProtections.fr,
		AtexProtections.d,
		AtexProtections.c,
		AtexProtections.b,
		AtexProtections.p,
		AtexProtections.k,
		AtexProtections.g
	]]
]);
