import {Component, OnInit} from '@angular/core';
import {App} from '../../../../app';
import {Session} from '../../../../session';
import {ScreenComponent} from '../../../../components/screen/screen.component';
import {UserPermissions} from '../../../../models/users/user-permissions';
import {MenuCardsOptions, MenuCardsComponent} from '../../../../components/layout/menu-cards/menu-cards.component';
import {UnoContentComponent} from '../../../../components/uno/uno-content/uno-content.component';

@Component({
	selector: 'logs-page',
	templateUrl: 'logs.page.html',
	standalone: true,
	imports: [UnoContentComponent, MenuCardsComponent]
})
export class LogsPage extends ScreenComponent implements OnInit {
	public get app(): any { return App; }

	public get session(): any { return Session; }

	public get userPermissions(): any { return UserPermissions; }


	public permissions = [UserPermissions.LOGS];

	public ngOnInit(): void {
		super.ngOnInit();
		
		App.navigator.setTitle('logs');
	}

	/**
	 * List of options in this menu.
	 */
	public options: MenuCardsOptions[] = [
		{
			label: 'errorLogs',
			route: '/menu/logs/error',
			icon: 'bug',
			data: null,
			permissions: [UserPermissions.LOGS_ERROR],
			badge: null
		},
		{
			label: 'accessLogs',
			route: '/menu/logs/access',
			icon: 'planet',
			data: null,
			permissions: [UserPermissions.LOGS_ACCESS],
			badge: null
		},
		{
			label: 'errorLogsTasks',
			route: '/menu/logs/tasks/error',
			icon: 'timer',
			data: null,
			permissions: [UserPermissions.LOGS_TASKS],
			badge: null
		},
		{
			label: 'errorLogsClient',
			route: '/menu/logs/client/error',
			icon: 'laptop-outline',
			data: null,
			permissions: [UserPermissions.LOGS_ERROR_CLIENT],
			badge: null
		}
	];
}
