import {Injectable} from '@angular/core';
import {Observation} from '../../../models/observations/observation';
import {Service} from '../../../http/service';
import {ServiceList} from '../../../http/service-list';
import {Session} from '../../../session';
import {UUID} from '../../../models/uuid';

@Injectable({providedIn: 'root'})
export class ObservationService {
	/**
	 * Get observation from API from its UUID.
	 */
	public async get(uuid: UUID): Promise<Observation> {
		const request = await Service.fetch(ServiceList.observation.get, null, null, {uuid: uuid}, Session.session);
		return Observation.parse(request.response.observation);
	}

	/**
	 * Delete observation from API from its UUID.
	 */
	public async delete(uuid: UUID): Promise<void> {
		await Service.fetch(ServiceList.observation.delete, null, null, {uuid: uuid}, Session.session);
	}

	/**
	 * Update observation in API
	 */
	public async update(observation: Observation): Promise<void> {
		await Service.fetch(ServiceList.observation.update, null, null, observation, Session.session);
	}
}
