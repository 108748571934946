import {Group} from 'three';
import {UUID} from '../../../models/uuid';
import {Mouse} from '../render/input/mouse';
import {Keyboard} from '../render/input/keyboard';

/**
 * Digital twin object to be mixed in with Object3D based classes.
 */
export class DigitalTwinObject extends Group {
	/**
	 * Type of the object.
	 */
	// @ts-ignore
	public type: string = '';

	/**
	 * Name of the object.
	 */
	public name: string = '';

	/**
	 * Description of the object.
	 */
	public description: string = '';

	/**
	 * Locked objects cannot be edited.
	 *
	 * Enabled this value to prevent unwanted changes in the editor.
	 */
	public locked: boolean = false;

	/**
	 * Index of the object in its parent tree.
	 */
	public index: number = 0;

	/**
	 * Scene where this object belongs.
	 */
	public sceneUuid: UUID = null;

	/**
	 * UUID of the parent object. If none is set null.
	 */
	public parentUuid: UUID = null;

	/**
	 * UUID of the asset attached to this object. If none is set null.
	 */
	public assetUuid: UUID = null;

	/**
	 * Indicates that this Object3D was mixed with the digital twin object.
	 */
	public isDigitalTwinObject: boolean = true;

	/**
	 * Keep how many digital twin object children there are in the object.
	 *
	 * Usefully to avoid iteration over nested drawing objects.
	 */
	public childrenCount: number = 0;

	public constructor(type: string) {
		super();

		this.type = type;
	}

	/**
	 * Initialization code called for every object before it's added to the rendering scene.
	 */
	public async initialize(): Promise<void> {}

	/**
	 * Update code called for every object before each rendering frame.
	 *
	 * The update method runs in sync. Objects are traversed in order.
	 *
	 * Any logic related with the object should be updated here.
	 */
	public update(time: number, delta: number, mouse: Mouse, keyboard: Keyboard): void {}

	/**
	 * Add a new object as children of the current object.
	 *
	 * Check if the children is a digital twin object and update children counter.
	 *
	 * @param object - List of object to be added.
	 */
	public add(...object: any[]): this {
		super.add(...object);

		for (let i = 0; i < object.length; i++) {
			if (object[i].isDigitalTwinObject) {
				this.childrenCount++;
			}
		}

		return this;
	}

	/**
	 * Remove an object from the list of children..
	 *
	 * Check if the children is a digital twin object and update children counter.
	 *
	 * @param object - List of object to be removed.
	 */
	public remove(...object: any[]): this {
		super.remove(...object);

		for (let i = 0; i < object.length; i++) {
			if (object[i].isDigitalTwinObject) {
				this.childrenCount--;
			}
		}

		return this;
	}

	/**
	 * Parse data received from the API.
	 *
	 * Should be extended by other classes to read additional properties stored in the object.
	 *
	 * @param data - Data to be parsed.
	 */
	public parse(data: any): any {
		this.uuid = data.uuid;
		this.name = data.name;
		this.description = data.description;
		this.locked = data.locked;
		this.visible = data.visible;
		this.type = data.type;
		this.index = data.index;
		this.sceneUuid = data.sceneUuid;
		this.parentUuid = data.parentUuid;
		this.assetUuid = data.assetUuid;
		this.position.fromArray(data.position);
		this.scale.fromArray(data.scale);
		this.quaternion.fromArray(data.quaternion);
	}

	/**
	 * Method to serialize digital twin objects to JSON.
	 *
	 * The Object3D object from three.js implements its own toJSON() serialization function.
	 *
	 * When mixed in with a object3D based class this method overrides its method.
	 */
	public toJSON(): any {
		return {
			uuid: this.uuid,
			name: this.name,
			description: this.description,
			locked: this.locked,
			visible: this.visible,
			index: this.index,
			type: this.type,
			sceneUuid: this.sceneUuid,
			parentUuid: this.parentUuid,
			assetUuid: this.assetUuid,
			position: this.position.toArray(),
			scale: this.scale.toArray(),
			quaternion: this.quaternion.toArray(),
			data: {}
		};
	}
}
