import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {PopoverController} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';

import {Service} from '../../../../http/service';
import {ServiceList} from '../../../../http/service-list';
import {Session} from '../../../../session';
import {PrivacyPolicy, PrivacyPolicyContent} from '../../../../models/privacy-policy/privacy-policy';
import {Locale} from '../../../../locale/locale';
import {StyleManager} from '../../../../theme/style-manager';
import {UnoButtonComponent} from '../../../../components/uno/uno-button/uno-button.component';
import {UnoContentComponent} from '../../../../components/uno/uno-content/uno-content.component';

/**
 * Component used to present a privacy policy to the user.
 */
@Component({
	selector: 'privacy-policy-view',
	templateUrl: 'privacy-policy-view.component.html',
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [UnoContentComponent, UnoButtonComponent, TranslateModule]
})
export class PrivacyPolicyViewComponent implements OnInit {
	public get themes(): any { return StyleManager; }

	public constructor(public controller: PopoverController) {}

	/**
	 * Display only. Don't show the accept/logout buttons.
	 */
	@Input()
	public displayOnly: boolean = false;

	/**
	 * Privacy policy to be displayed on the page.
	 */
	public policy: PrivacyPolicy = null;

	/**
	 * Content of the policy to be displayed.
	 */
	public content: PrivacyPolicyContent = null;

	public async ngOnInit(): Promise<void> {
		const request = await Service.fetch(ServiceList.privacyPolicy.latest, null, null, null, Session.session, true);
		this.policy = PrivacyPolicy.parse(request.response.policy);

		this.content = this.policy.getLocaleContent(Locale.code);
	}

	/**
	 * Accept the privacy policy. Sends the confirmation to the API and closes the popup.
	 *
	 * The user of the currently open session is updated.
	 */
	public async accept(): Promise<void> {
		this.controller.dismiss();

		const user = structuredClone(Session.user);
		user.privacyPolicy = this.policy.version;

		await Service.fetch(ServiceList.users.update, null, null, user, Session.session, false);
		Session.user.privacyPolicy = this.policy.version;
	}

	/**
	 * Reject the privacy policy hides the popover and logs the user out of the application.
	 */
	public reject(): void {
		this.controller.dismiss();
		Session.logout();
	}
}
