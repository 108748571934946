/**
 * Utils to manipulate enum structured.
 */
export class EnumUtils {
	/**
	 * Get the values of an enum. 
	 *
	 * Similar to how Object.values() works for objects.
	 *
	 * @param e - Enum to get values for
	 */
	public static values(e: any): any[] {
		const val = [];

		const values = Object.values(e);
		const keys = Object.keys(e);

		for (let i = 0; i < values.length; i++) {
			const num = Number.parseFloat(keys[i]);
			if (isNaN(num) && typeof values[i] === 'string' || typeof values[i] === 'number') {
				val.push(values[i]);
			}
		}

		return val;
	}
}
