import {EnumUtils} from 'src/app/utils/enum-utils';
import {UnoFormField} from '../../../../components/uno-forms/uno-dynamic-form/uno-form-field';
import {UnoFormFieldTypes} from '../../../../components/uno-forms/uno-dynamic-form/uno-form-field-types';
import {AlarmCondition, AlarmConditionLabel} from '../../../../models/pipeline-integrity/leak-detection/lds-alarm-condition';
import {LDSAlarmLevel, LDSAlarmLevelLabel} from '../../../../models/pipeline-integrity/leak-detection/lds-alarm-level';

export const LdsAlarmLayout: UnoFormField[] = [
	{
		attribute: 'uuid',
		label: 'uuid',
		type: UnoFormFieldTypes.UUID
	},
	{
		required: true,
		attribute: 'description',
		label: 'description',
		type: UnoFormFieldTypes.TEXT
	},
	{
		required: true,
		attribute: 'level',
		label: 'level',
		type: UnoFormFieldTypes.OPTIONS,
		options: EnumUtils.values(LDSAlarmLevel).map((value) => {
			return {label: LDSAlarmLevelLabel.get(value as LDSAlarmLevel), value: value};
		})
	},
	{
		required: true,
		attribute: 'condition',
		label: 'condition',
		type: UnoFormFieldTypes.OPTIONS,
		onChange: function(object, row, value, layout) {
			
		},
		options: EnumUtils.values(AlarmCondition).map((value) => {
			return {label: AlarmConditionLabel.get(value as AlarmCondition), value: value};
		})
	},
	{
		required: true,
		label: 'value',
		attribute: 'range.min',
		type: UnoFormFieldTypes.NUMBER,
		isActive: (object: any, row: UnoFormField) => {
			return object.condition === AlarmCondition.LESS;
		}
	},
	{
		required: true,
		attribute: 'range.max',
		label: 'value',
		type: UnoFormFieldTypes.NUMBER,
		isActive: (object: any, row: UnoFormField) => {
			return object.condition === AlarmCondition.GREATER;
		}
	},
	{
		required: true,
		attribute: 'range.min',
		label: 'min',
		type: UnoFormFieldTypes.NUMBER,
		isActive: (object: any, row: UnoFormField) => {
			return object.condition === AlarmCondition.IN_BETWEEN;
		}
	},
	{
		required: true,
		attribute: 'range.max',
		label: 'max',
		type: UnoFormFieldTypes.NUMBER,
		isActive: (object: any, row: UnoFormField) => {
			return object.condition === AlarmCondition.IN_BETWEEN;
		}
	},
	{
		required: true,
		attribute: 'activationTime',
		label: 'activationTime',
		type: UnoFormFieldTypes.NUMBER_UNITS,
		inUnit: 's',
		unit: 's',
		options: [
			{value: 's', label: 's'},
			{value: 'ms', label: 'ms'}
		]
	},
	{
		required: false,
		attribute: 'usersSms',
		label: 'usersSms',
		type: UnoFormFieldTypes.USER_SELECTOR,
		multiple: true
	},
	{
		required: false,
		attribute: 'usersEmail',
		label: 'usersEmail',
		type: UnoFormFieldTypes.USER_SELECTOR,
		multiple: true
	}
];
