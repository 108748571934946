<!-- Mobile -->
@if (app.device.isMobile()) {
	<ion-list>
		@for (option of this.options; track option) {
			@if (session.hasPermissions(option.permissions)) {
				<ion-item (click)="app.navigator.navigate(option.route, option.data)" button="true">
					<ion-icon name="{{option.icon}}" src="{{option.icon}}" style="padding: 10px;"></ion-icon>
					<ion-label>{{option.label | translate }}</ion-label>
					<div slot="end">
						@if (option.badge !== null) {
							<ion-badge color="primary" mode="ios">{{option.badge | async}}</ion-badge>
						}
						<ion-icon name="caret-forward-outline"></ion-icon>
					</div>
				</ion-item>
			}
		}
	</ion-list>
}
<!-- Desktop -->
@if (app.device.isDesktop()) {
	<ion-card-content>
		<ion-grid>
			<ion-row>
				@for (option of this.options; track option) {
					@if (session.hasPermissions(option.permissions)) {
						<ion-col size="4">
							<uno-card icon="{{option.icon}}" title="{{option.label | translate }}" (click)="app.navigator.navigate(option.route, option.data)" badge="{{option.badge | async}}"></uno-card>
						</ion-col>
					}
				}
			</ion-row>
		</ion-grid>
	</ion-card-content>
}
