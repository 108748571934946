import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import {NgStyle} from '@angular/common';
import {UUID, generateUUID} from '../../../../models/uuid';
import {UnoContentComponent} from '../../uno-content/uno-content.component';

/**
 * UNO Tab Section component displays the elements inside it.
 */
@Component({
	selector: 'uno-tab-section',
	templateUrl: './uno-tab-section.component.html',
	styleUrls: ['./uno-tab-section.component.css'],
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [NgStyle, UnoContentComponent]
})
export class UnoTabSectionComponent {
	/**
	 * The title to be shown
	 */
	@Input()
	public title: string = '';

	/**
	 * The icon to be shown. Can be name or SVG file.
	 */
	@Input()
	public icon: string = '';

	/**
	 * The color to be applied to the icon.
	 */
	@Input()
	public iconColor: string = '';

	/**
	 * Whether or not the current section is active or not
	 */
	@Input()
	public active: boolean = false;

	/**
	 * Triggered when the section is clicked
	 */
	@Output()
	public onPresent: EventEmitter<void> = new EventEmitter<void>();

	/**
	 * Triggered when the section is hidden from view.
	 */
	@Output()
	public onHide: EventEmitter<void> = new EventEmitter<void>();

	/**
	 * The section's uuid
	 */
	public uuid: UUID = generateUUID();
}
