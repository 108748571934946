import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';

import {UnoDynamicFormComponent} from '../../../../components/uno-forms/uno-dynamic-form/uno-dynamic-form.component';
import {App} from '../../../../app';
import {Service} from '../../../../http/service';
import {ServiceList} from '../../../../http/service-list';
import {Session} from '../../../../session';
import {Modal} from '../../../../modal';
import {Locale} from '../../../../locale/locale';
import {UnoDynamicFormModule} from '../../../../components/uno-forms/uno-dynamic-form.module';
import {ScreenComponent} from '../../../../components/screen/screen.component';
import {UserPermissions} from '../../../../models/users/user-permissions';
import {PrivacyPolicyLayout} from '../privacy-policy-layout';
import {PrivacyPolicy} from '../../../../models/privacy-policy/privacy-policy';
import {UnoButtonComponent} from '../../../../components/uno/uno-button/uno-button.component';
import {UnoContentComponent} from '../../../../components/uno/uno-content/uno-content.component';

@Component({
	selector: 'privacy-policy-edit',
	templateUrl: 'privacy-policy-edit.page.html',
	standalone: true,
	imports: [UnoContentComponent, UnoDynamicFormModule, UnoButtonComponent, TranslateModule]
})
export class PrivacyPolicyEditPage extends ScreenComponent implements OnInit {
	public get app(): any { return App; }

	public get userPermissions(): any { return UserPermissions; }

	public get session(): any { return Session; }

	public get layout(): any { return PrivacyPolicyLayout; }

	/**
	 * Privacy policy being edited in the page.
	 */
	@Input()
	public privacyPolicy: PrivacyPolicy = null;

	@ViewChild('form', {static: false})
	public form: UnoDynamicFormComponent = null;

	public permissions = [UserPermissions.PRIVACY_POLICY_EDIT];

	public ngOnInit(): void {
		super.ngOnInit();

		this.privacyPolicy = null;

		App.navigator.setTitle('privacyPolicy');

		this.privacyPolicy = App.navigator.getData();
		if (!this.privacyPolicy) {
			App.navigator.pop();
			return;
		}
	}

	/**
	 * Update the privacy policy in the API. The API automatically sets a new version for the privacy policy.
	 *
	 * @param stayOnPage - Flag to indicate if the system should stay on the page after changes are saved.
	 */
	public async update(stayOnPage: boolean = false): Promise<void> {
		if (!this.form.requiredFilled()) {
			Modal.alert(Locale.get('error'), Locale.get('requiredFieldsError'));
			return;
		}

		await Service.fetch(ServiceList.privacyPolicy.update, null, null, this.privacyPolicy, Session.session);

		if (!stayOnPage) {
			App.navigator.pop();
		}

		Modal.toast(Locale.get('updatedSuccessfully'));
	}

}
