import {Component, OnInit} from '@angular/core';
import {generateUUID} from '../../../../models/uuid';
import {UnoFormField, OptionsDisplayMode} from '../../../../components/uno-forms/uno-dynamic-form/uno-form-field';
import {UnoFormFieldTypes} from '../../../../components/uno-forms/uno-dynamic-form/uno-form-field-types';
import {App} from '../../../../app';
import {ScreenComponent} from '../../../../components/screen/screen.component';
import {UnoFormUtils} from '../../../../components/uno-forms/uno-dynamic-form/uno-form-utils';
import {InputOptionsMultipleBatchRequest, InputOptionsMultipleLazyPageRequest} from '../../../../components/input/options-multiple-lazy/options-multiple-lazy.component';
import {RandomUtils} from '../../../../utils/random-utils';
import {UnoButtonComponent} from '../../../../components/uno/uno-button/uno-button.component';
import {UnoDynamicFormModule} from '../../../../components/uno-forms/uno-dynamic-form.module';
import {UnoContentComponent} from '../../../../components/uno/uno-content/uno-content.component';

@Component({
	selector: 'demo-form-page',
	templateUrl: 'demo-dynamic-form-page.component.html',
	standalone: true,
	imports: [UnoContentComponent, UnoDynamicFormModule, UnoButtonComponent]
})
export class DemoDynamicFormPage extends ScreenComponent implements OnInit {
	public layout: UnoFormField[] = [
		{
			type: UnoFormFieldTypes.TITLE,
			label: 'Title A'
		},
		{
			attribute: 'options',
			label: 'Option (Modal)',
			type: UnoFormFieldTypes.OPTIONS,
			optionsDisplayMode: OptionsDisplayMode.MODAL,
			sort: false,
			options: [
				{label: 'Zero', value: 0},
				{label: 'One', value: 1},
				{label: 'Two', value: 2},
				{label: 'Three', value: 3}
			]
		},
		{
			attribute: 'options',
			label: 'Option (Popover)',
			type: UnoFormFieldTypes.OPTIONS,
			optionsDisplayMode: OptionsDisplayMode.POPOVER,
			sort: true,
			options: [
				{label: RandomUtils.randomText(30), value: 0},
				{label: RandomUtils.randomText(30), value: 1},
				{label: RandomUtils.randomText(30), value: 2},
				{label: RandomUtils.randomText(30), value: 3}
			]
		},
		{
			attribute: 'options',
			label: 'Option (Large Text Modal)',
			type: UnoFormFieldTypes.OPTIONS,
			optionsDisplayMode: OptionsDisplayMode.MODAL,
			sort: false,
			options: [
				{label: RandomUtils.randomText(30), value: 0},
				{label: RandomUtils.randomText(30), value: 1},
				{label: RandomUtils.randomText(30), value: 2},
				{label: RandomUtils.randomText(30), value: 3}
			]
		},
		{
			attribute: 'vector',
			label: 'Vector 3',
			type: UnoFormFieldTypes.VECTOR3,
			editable: true,
			required: true
		},
		{
			attribute: 'stringArray',
			label: 'String Array',
			type: UnoFormFieldTypes.STRING_ARRAY,
			editable: true,
			required: true
		},
		{
			attribute: 'keyValueArray',
			label: 'Key Value Array',
			type: UnoFormFieldTypes.KEY_VALUE_ARRAY,
			editable: true,
			required: true
		},
		{
			required: false,
			attribute: 'composedField',
			label: 'Composed Field',
			type: UnoFormFieldTypes.COMPOSED_FIELD,
			fields: [
				{
					attribute: 'text',
					label: 'Text',
					type: UnoFormFieldTypes.TEXT
				},
				{
					attribute: 'color',
					label: 'Color',
					type: UnoFormFieldTypes.COLOR
				}
			]
		},
		{
			required: false,
			attribute: 'repetitiveForm',
			label: 'Repetitive Form',
			type: UnoFormFieldTypes.REPETITIVE_FORM,
			fields: [
				{
					attribute: 'text',
					label: 'Text',
					type: UnoFormFieldTypes.TEXT
				},
				{
					attribute: 'color',
					label: 'Color',
					type: UnoFormFieldTypes.COLOR
				}
			]
		},
		{
			attribute: 'frequency',
			label: 'Date Frequency Input',
			type: UnoFormFieldTypes.DATE_FREQUENCY
		},
		{
			attribute: 'assetList',
			label: 'Asset Selector',
			type: UnoFormFieldTypes.ASSET_SELECTOR,
			showClear: true,
			sort: true
		},
		{
			attribute: 'team',
			label: 'Team Selector',
			type: UnoFormFieldTypes.TEAM_SELECTOR,
			showClear: true,
			sort: true
		},
		{
			attribute: 'company',
			label: 'Company Selector',
			type: UnoFormFieldTypes.COMPANY_SELECTOR,
			showClear: true,
			sort: true
		},
		{
			attribute: 'user',
			label: 'User Selector',
			type: UnoFormFieldTypes.USER_SELECTOR,
			showClear: true,
			sort: true
		},
		{
			type: UnoFormFieldTypes.SUB_FORM,
			label: 'Sub-form',
			attribute: 'subForm',
			expanded: true,
			fields: [
				{
					attribute: 'text',
					label: 'Text',
					type: UnoFormFieldTypes.TEXT
				},
				{
					attribute: 'color',
					label: 'Color',
					type: UnoFormFieldTypes.COLOR
				},
				{
					attribute: 'qr',
					label: 'QR',
					type: UnoFormFieldTypes.QR
				},
				{
					attribute: 'nfc',
					label: 'NFC',
					type: UnoFormFieldTypes.NFC
				}
			]
		},
		{
			type: UnoFormFieldTypes.TITLE,
			label: 'Title A'
		},
		{
			attribute: 'numberUnits',
			label: 'Number Units',
			type: UnoFormFieldTypes.NUMBER_UNITS,
			options: [
				{value: 'kg', label: 'Kg'},
				{value: 'g', label: 'g'},
				{value: 'mg', label: 'mg'}
			],
			inUnit: 'g',
			unit: 'mg'
		},
		{
			attribute: 'text',
			label: 'Text',
			type: UnoFormFieldTypes.TEXT
		},
		{
			attribute: 'color',
			label: 'Color',
			type: UnoFormFieldTypes.COLOR
		},
		{
			attribute: 'qr',
			label: 'QR',
			type: UnoFormFieldTypes.QR
		},
		{
			attribute: 'nfc',
			label: 'NFC',
			type: UnoFormFieldTypes.NFC
		},
		{
			attribute: 'optionsList',
			label: 'Options List',
			editable: true,
			type: UnoFormFieldTypes.OPTIONS_LIST,
			options: [
				{label: 'Zero', value: 0},
				{label: 'One', value: 1},
				{label: 'Two', value: 2},
				{label: 'Three', value: 3}
			]
		},
		{
			attribute: 'optionsSearch',
			label: 'Options Search',
			type: UnoFormFieldTypes.OPTIONS_SEARCH,
			options: [
				{label: 'Zero', value: 0},
				{label: 'One', value: 1},
				{label: 'Two', value: 2},
				{label: 'Three', value: 3}
			]
		},
		{
			attribute: 'periodicitySelector',
			label: 'Periodicity Selector',
			type: UnoFormFieldTypes.PERIODICITY_SELECTOR,
			min: 1,
			max: 100
		},
		{
			attribute: 'lateralSelector',
			label: 'Lateral Selector',
			type: UnoFormFieldTypes.LATERAL_SELECTOR,
			options: [
				{label: 'zero', value: 0},
				{label: 'one', value: 1},
				{label: 'two', value: 2},
				{label: 'three', value: 3},
				{label: 'four', value: 4},
				{label: 'five', value: 5},
				{label: 'six', value: 6}
			]
		},
		{
			attribute: 'optionsMultiple',
			label: 'Options Multiple (Popover)',
			isActive: (object) => {return App.device.isDesktop();},
			type: UnoFormFieldTypes.OPTIONS_MULTIPLE,
			optionsDisplayMode: OptionsDisplayMode.POPOVER,
			sort: false,
			showClear: true,
			options: [
				{label: 'Zero', value: 0},
				{label: 'One', value: 1},
				{label: 'Two', value: 2},
				{label: 'Three', value: 3}
			]
		},
		{
			attribute: 'optionsMultiple',
			label: 'Options Multiple (Modal)',
			type: UnoFormFieldTypes.OPTIONS_MULTIPLE,
			optionsDisplayMode: OptionsDisplayMode.MODAL,
			sort: true,
			showClear: false,
			options: [
				{label: 'Zero', value: 0},
				{label: 'One', value: 1},
				{label: 'Two', value: 2},
				{label: 'Three', value: 3}
			]
		},
		{
			attribute: 'optionsMultipleLazy',
			label: 'Options Multiple Lazy',
			type: UnoFormFieldTypes.OPTIONS_MULTIPLE_LAZY,
			showClear: true,
			multiple: true,
			identifierAttribute: 'uuid',
			fetchOptionsLazy: function(request: InputOptionsMultipleLazyPageRequest): void {
				const optionsLazy: any = [];

				for (let i = 0; i < request.count; i++) {
					optionsLazy.push({
						uuid: generateUUID(),
						name: 'Opt ' + (request.from + i) + '\n' + RandomUtils.randomText(10) + '\n' + RandomUtils.randomText(10)
					});
				}

				request.onFinish(optionsLazy, true, 0);
			},
			fetchOptionsBatch: function(request: InputOptionsMultipleBatchRequest): void {
				const optionsLazy: any = [];

				request.onFinish(optionsLazy);
			},
			getOptionText: function(option: any): string {
				return option.name;
			},
			onOpen: function(): void {
				console.log('EQS: OptionsMultipleLazy onOpen() callback.');
			},
			onClose: function(): void {
				console.log('EQS: OptionsMultipleLazy onClose() callback.');
			},
			onSelect: function(item: any, isSelected: boolean): void {
				console.log('EQS: OptionsMultipleLazy onSelect() callback.', item, isSelected);
			}
		},
		{
			attribute: 'atex',
			label: 'Atex Field',
			type: UnoFormFieldTypes.ATEX_FIELD
		},
		{
			attribute: 'position',
			label: 'GPS Position',
			type: UnoFormFieldTypes.GEO_POSITION
		},
		{
			attribute: 'atexInspectionField',
			label: 'Atex Inspection Field',
			type: UnoFormFieldTypes.ATEX_INSPECTION_FIELD,
			justifications: ['Atex Inspection Field ABC Inspection Field ABC Inspection Field ABC', 'Atex Inspection FiInspection Field ABC Inspection Field ABCeld DFG', 'Ins Inspection FielInspection Field ABCd ABC pection Field HIJ']
		},
		{
			type: UnoFormFieldTypes.TITLE,
			label: 'Title B'
		},
		{
			attribute: 'password',
			label: 'Password',
			type: UnoFormFieldTypes.PASSWORD
		},
		{
			attribute: 'uuid',
			label: 'UUID',
			type: UnoFormFieldTypes.UUID
		},
		{
			attribute: 'email',
			label: 'Email',
			type: UnoFormFieldTypes.EMAIL
		},
		{
			attribute: 'phoneNumber',
			label: 'Phone number',
			type: UnoFormFieldTypes.PHONE_NUMBER
		},
		{
			attribute: 'date',
			label: 'Date',
			type: UnoFormFieldTypes.DATE
		},
		{
			attribute: 'textMultiline',
			label: 'Text Multiline',
			type: UnoFormFieldTypes.TEXT_MULTILINE
		},
		{
			attribute: 'number',
			label: 'Number',
			type: UnoFormFieldTypes.NUMBER
		},
		{
			type: UnoFormFieldTypes.TITLE,
			label: 'Title C'
		},
		{
			attribute: 'checkbox',
			label: 'Checkbox',
			type: UnoFormFieldTypes.CHECKBOX
		},
		{
			attribute: 'checkboxInverted',
			label: 'Checkbox Inverted',
			invert: true,
			type: UnoFormFieldTypes.CHECKBOX
		},
		{
			attribute: 'image',
			label: 'Image resource',
			type: UnoFormFieldTypes.IMAGE_RESOURCE
		},
		{
			attribute: 'imageLocal',
			label: 'Image Local',
			local: true,
			type: UnoFormFieldTypes.IMAGE_RESOURCE
		},
		{
			attribute: 'multipleImages',
			label: 'Multiple Images',
			type: UnoFormFieldTypes.IMAGE_RESOURCE_MULTIPLE
		},
		{
			attribute: 'multipleImagesLocal',
			label: 'Multiple Images Local',
			type: UnoFormFieldTypes.IMAGE_RESOURCE_MULTIPLE,
			local: true
		},
		{
			attribute: 'document',
			label: 'Document resource',
			type: UnoFormFieldTypes.DOCUMENT_RESOURCE
		},
		{
			attribute: 'documentLocal',
			label: 'Document resource Local',
			type: UnoFormFieldTypes.DOCUMENT_RESOURCE,
			local: true
		},
		{
			attribute: 'documentMultiple',
			label: 'Document multiple resource',
			type: UnoFormFieldTypes.DOCUMENT_RESOURCE_MULTIPLE
		},

		{
			attribute: 'documentMultipleLocal',
			label: 'Document Multiple Resource Local',
			type: UnoFormFieldTypes.DOCUMENT_RESOURCE_MULTIPLE,
			local: true
		},
		{
			attribute: 'video',
			label: 'Video resource',
			type: UnoFormFieldTypes.VIDEO_RESOURCE
		},
		{
			attribute: 'audio',
			label: 'Audio resource',
			type: UnoFormFieldTypes.AUDIO_RESOURCE
		}
	];

	public object: any = UnoFormUtils.createObject(this.layout);

	public onChange(object, row, value): void {
		console.log('EQS: Demo object form change.', object, row, value);
	}

	public logObject(): void {
		console.log('EQS: Demo object form object.', this.object);
	}

	public ngOnInit(): void {
		super.ngOnInit();
		
		App.navigator.setTitle('form');

		this.object.uuid = generateUUID();
	}
}
