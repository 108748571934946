import {UnoFormField} from '../../../components/uno-forms/uno-dynamic-form/uno-form-field';
import {UnoFormFieldTypes} from '../../../components/uno-forms/uno-dynamic-form/uno-form-field-types';
import {CalendarEventSubtypes} from '../../../models/asset-planning/calendar-event-actions';
import {InputOptionsMultipleBatchRequest, InputOptionsMultipleLazyPageRequest} from '../../../components/input/options-multiple-lazy/options-multiple-lazy.component';
import {RepairStatus} from '../../../models/repairs/repairs/repair-status';
import {SortDirection} from '../../../utils/sort-direction';
import {Service} from '../../../http/service';
import {ServiceList} from '../../../http/service-list';
import {Session} from '../../../session';
import {AtexInspectionStatus} from '../../../models/atex-inspections/inspections/atex-inspection-status';
import {RepairInspectionStatus} from '../../../models/repairs/inspections/repair-inspection-status';
import {
	CalendarEventStatus,
	CalendarEventStatusLabel
} from '../../../models/asset-planning/calendar-event-occurrence-status';
import {
	CalendarEventOccurrencePriority,
	CalendarEventOccurrencePriorityLabel
} from '../../../models/asset-planning/calendar-event-occurrence-priority';
import {EnumUtils} from '../../../utils/enum-utils';
import {InspectionService} from '../../inspections/services/inspection.service';
import {AtexInspectionService} from '../../atex/inspections/services/atex-inspection.service';

export const CalendarOccurrenceLayout: UnoFormField[] = [
	{
		required: false,
		label: 'uuid',
		attribute: 'uuid',
		type: UnoFormFieldTypes.UUID
	},
	{
		required: false,
		label: 'name',
		attribute: 'event.name',
		type: UnoFormFieldTypes.TEXT,
		editable: false
	},
	{
		required: false,
		attribute: 'event.description',
		label: 'description',
		type: UnoFormFieldTypes.TEXT_MULTILINE,
		editable: false
	},
	{
		required: false,
		attribute: 'repairUuid',
		label: 'repair',
		multiple: false,
		type: UnoFormFieldTypes.OPTIONS_MULTIPLE_LAZY,
		identifierAttribute: 'uuid',
		isActive: (object: any, row: UnoFormField) => {
			return object.event.actionSubtype === CalendarEventSubtypes.REPAIR_DEFINITIVE_REPAIR;
		},
		fetchOptionsLazy: async function(request: InputOptionsMultipleLazyPageRequest): Promise<void> {
			const data = {
				status: RepairStatus.ALL,
				from: request.from,
				count: request.count,
				search: request.search,
				searchFields: ['[repair].[id]', '[repair].[description]', '[ap_asset].[id]', '[ap_asset].[name]', '[ap_asset].[tag]'],
				sortField: '[ap_asset].[name]',
				sortDirection: SortDirection.ASC
			};

			try {
				const req = await Service.fetch(ServiceList.repairs.list, null, null, data, Session.session);
				request.onFinish(req.response.repairs, req.response.hasMore, req.id);
			} catch {

				request.onError();
			}
		},
		fetchOptionsBatch: async function(request: InputOptionsMultipleBatchRequest): Promise<void> {
			if (request.options.length > 0) {
				const data = {search: request.options[0], searchFields: ['[repair].[id]']};
				const req = await Service.fetch(ServiceList.repairs.list, null, null, data, Session.session);
				request.onFinish(req.response.repairs);
			} else {
				request.onFinish([]);
			}
		},
		getOptionText: function(option: any): string {
			return option.asset.name + (option.asset.tag ? '(' + option.asset.tag + ')' : '') + (option.description ? ' - ' + option.description : '');
		}
	},
	{
		required: false,
		attribute: 'atexInspectionUuid',
		label: 'atexInspection',
		multiple: false,
		type: UnoFormFieldTypes.OPTIONS_MULTIPLE_LAZY,
		identifierAttribute: 'uuid',
		isActive: (object: any, row: UnoFormField) => {
			return object.event.actionSubtype === CalendarEventSubtypes.ASSET_ATEX_INSPECTION;
		},
		fetchOptionsLazy: async function(request: InputOptionsMultipleLazyPageRequest): Promise<void> {
			const data = {
				status: AtexInspectionStatus.ALL,
				from: request.from,
				count: request.count,
				search: request.search,
				searchFields: ['[atex_inspection].[id]', '[ap_asset].[id]', '[ap_asset].[name]', '[ap_asset].[tag]'],
				sortField: '[ap_asset].[name]',
				sortDirection: SortDirection.ASC
			};

			try {
				const req = await AtexInspectionService.list(data);
				request.onFinish(req.inspections, req.hasMore, req.id);
			} catch {

				request.onError();
			}
		},
		fetchOptionsBatch: async function(request: InputOptionsMultipleBatchRequest): Promise<void> {
			if (request.options.length > 0) {
				const data = {search: request.options[0], searchFields: ['[atex_inspection].[id]']};
				const req = await AtexInspectionService.list(data);
				request.onFinish(req.inspections);
			} else {
				request.onFinish([]);
			}
		},
		getOptionText: function(option: any): string {
			return option.asset.name + (option.asset.tag ? '(' + option.asset.tag + ')' : '');
		}
	},
	{
		required: false,
		attribute: 'inspectionUuid',
		label: 'inspection',
		multiple: false,
		type: UnoFormFieldTypes.OPTIONS_MULTIPLE_LAZY,
		identifierAttribute: 'uuid',
		isActive: (object: any, row: UnoFormField) => {
			return object.event.actionSubtype === CalendarEventSubtypes.ASSET_DYNAMIC_INSPECTION;
		},
		fetchOptionsLazy: async function(request: InputOptionsMultipleLazyPageRequest, object: any): Promise<void> {
			const data: any = {
				status: AtexInspectionStatus.ALL,
				from: request.from,
				count: request.count,
				search: request.search,
				searchFields: ['[inspection].[name]', '[inspection].[description]', '[ap_asset].[id]', '[ap_asset].[name]', '[ap_asset].[tag]'],
				sortField: '[ap_asset].[name]',
				sortDirection: SortDirection.ASC
			};
			
			if (object.event?.inspectionProjectUuid) {
				data.project = object.event.inspectionProjectUuid ;
			}

			try {
				const req = await InspectionService.list(data);
				request.onFinish(req.inspections, req.hasMore, req.id);
			} catch {

				request.onError();
			}
		},
		fetchOptionsBatch: async function(request: InputOptionsMultipleBatchRequest, object: any): Promise<void> {
			if (request.options.length > 0) {
				const data: any = {
					search: request.options[0], 
					searchFields: ['[inspection].[id]']
				};
				
				if (object.event?.inspectionProjectUuid) {
					data.project = object.event.inspectionProjectUuid ;
				}

				const req = await InspectionService.list(data);

				request.onFinish(req.inspections);
			} else {
				request.onFinish([]);
			}
		},
		getOptionText: function(option: any): string {
			return (option.name ? option.name : '') + (option.description ? ' - ' + option.description : '');
		}
	},
	{
		required: false,
		attribute: 'repairInspectionUuid',
		label: 'repairInspection',
		multiple: false,
		type: UnoFormFieldTypes.OPTIONS_MULTIPLE_LAZY,
		identifierAttribute: 'uuid',
		isActive: (object: any, row: UnoFormField) => {
			return object.event.actionSubtype === CalendarEventSubtypes.REPAIR_TEMPORARY_INSPECTION;
		},
		fetchOptionsLazy: async function(request: InputOptionsMultipleLazyPageRequest): Promise<void> {
			const data = {
				status: RepairInspectionStatus.ALL,
				from: request.from,
				count: request.count,
				search: request.search,
				searchFields: ['[repair].[id]', '[repair].[description]', '[ap_asset].[id]', '[ap_asset].[name]', '[ap_asset].[tag]'],
				sortField: '[ap_asset].[name]',
				sortDirection: SortDirection.ASC
			};

			try {
				const req = await Service.fetch(ServiceList.repairInspections.list, null, null, data, Session.session);
				request.onFinish(req.response.inspections, req.response.hasMore, req.id);
			} catch {

				request.onError();
			}
		},
		fetchOptionsBatch: async function(request: InputOptionsMultipleBatchRequest): Promise<void> {
			if (request.options.length > 0) {
				const data = {search: request.options[0], searchFields: ['[repair_inspection].[id]']};
				const req = await Service.fetch(ServiceList.repairInspections.list, null, null, data, Session.session);
				request.onFinish(req.response.inspections);
			} else {
				request.onFinish([]);
			}
		},
		getOptionText: function(option: any): string {
			return option.asset.name ? option.asset.name : '';
		}
	},
	{
		required: false,
		attribute: 'priority',
		label: 'priority',
		type: UnoFormFieldTypes.OPTIONS,
		options: EnumUtils.values(CalendarEventOccurrencePriority).map((value) => {
			return {label: CalendarEventOccurrencePriorityLabel.get(value as CalendarEventOccurrencePriority), value: value};
		})
	},
	{
		required: false,
		attribute: 'status',
		label: 'status',
		type: UnoFormFieldTypes.OPTIONS,
		editable: false,
		options: EnumUtils.values(CalendarEventStatus).map((value) => {
			return {label: CalendarEventStatusLabel.get(value as CalendarEventStatus), value: value};
		})
	},
	{
		required: true,
		attribute: 'date',
		label: 'date',
		isActive: (object: any, row: UnoFormField) => {
			return !object.triggerEvent;
		},
		type: UnoFormFieldTypes.DATE
	}
];
