import {UUID, UUIDIdentification} from '../../uuid';
import {Geolocation} from '../../geolocation';

/**
 * Leak detection sensors (LDS) store temperature data of the pipeline.
 *
 * The temperatures can be used to detect leak trough gas expansion temperature reduction.
 */
export class LDS extends UUIDIdentification {
	/**
	 * UUID of the pipeline attached to the LDS.
	 */
	public pipelineUuid: UUID = null;

	/**
	 * Name of the LDS:
	 */
	public name: string = '';

	/**
	 * Description of the LDS.
	 */
	public description: string = '';

	/**
	 * Spacing in meters between each point of the LDS.
	 *
	 * Used to calculate distance of each point.
	 */
	public spacing: number = 0;

	/**
	 * Composition of the content inside the pipeline.
	 */
	public chemical: string = '';

	/**
	 * Geolocation position.
	 */
	public position: Geolocation = null;

	/**
	 * Channels that compose the LDS.
	 */
	public channels: LDSChannel[] = [new LDSChannel()];

	/**
	 * Add a channel to the LDS.
	 */
	public addChannel(): void {
		const channel = new LDSChannel();
		channel.index = this.channels.length;
		this.channels.push(channel);
	}

	/**
	 * Remove a channel from the LDS sensor.
	 *
	 * The LDS must have at least one channel.
	 * 
	 * @param channel - Channel to be remove, if none the last channel is removed.
	 */
	public removeChannel(channel?: LDSChannel): void {
		if (this.channels.length === 1) {
			throw new Error('LDS must have at least one channel.');
		}

		if (channel) {
			const index = this.channels.indexOf(channel);
			if (index !== -1) {
				this.channels.splice(index, 1);
			}

			// Recalculate channel index
			for (let i = 0; i < this.channels.length; i++) {
				this.channels[i].index = i;
			}
		} else {
			this.channels.pop();
		}
	}

	/**
	 * Parse LDS data received from API into a LDS object.
	 *
	 * @param data - LDS data received from the API.
	 */
	public static parse(data: any): LDS {
		const lds = new LDS();

		lds.uuid = data.uuid;
		lds.updatedAt = new Date(data.updatedAt);
		lds.createdAt = new Date(data.createdAt);

		lds.name = data.name;
		lds.description = data.description;
		lds.spacing = data.spacing;
		lds.chemical = data.chemical;
		lds.pipelineUuid = data.pipelineUuid;
		lds.position = Geolocation.parse(data.position);

		// Parse channel data
		lds.channels = [];
		for (let i = 0; i < data.channels.length; i++) {
			lds.channels.push(LDSChannel.parse(data.channels[i]));
		}

		// Sort channels by index
		lds.channels.sort(function(a: LDSChannel, b: LDSChannel): number {
			return a.index - b.index;
		});


		return lds;
	}
}

/**
 * LDS can have multiple channels to capture data from different angles of the pipeline.
 */
export class LDSChannel {
	/**
	 * Index of the LDS channel.
	 *
	 * Should match the index of the data received.
	 */
	public index: number = 0;

	/**
	 * Identification tag of the LDS channel.
	 */
	public tag: string = '';

	/**
	 * Radial offset in degrees of the channel relative to the pipeline.
	 *
	 * Each channel should have a different offset to improve data coverage.
	 */
	public offset: number = 0;

	/**
	 * Parse data received from the API into LDSChannel object.
	 *
	 * @param data - LDS channel data.
	 */
	public static parse(data: any): LDSChannel {
		const channel = new LDSChannel();

		channel.index = data.index;
		channel.tag = data.tag;
		channel.offset = data.offset;

		return channel;
	}
}
