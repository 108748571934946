/**
 * List of possible statuses for workflow steps.
 */
export enum InspectionWorkflowStepStatus {
	TODO = 0,
	IN_PROGRESS = 1,
	DONE = 2
};

/**
 * List of possible status labels for workflow steps.
 */
export const InspectionWorkflowStepStatusLabel: Map<Number, string> = new Map([
	[InspectionWorkflowStepStatus.TODO, 'todo'],
	[InspectionWorkflowStepStatus.IN_PROGRESS, 'inProgress'],
	[InspectionWorkflowStepStatus.DONE, 'done']
]);
