import {UUID, UUIDIdentification} from '../uuid';
import {Resource} from '../resource';
import {Geolocation} from '../geolocation';
import {AssetFieldTabData} from '../../modules/asset-portfolio/screens/asset/edit/asset-edit.page';
import {Atex} from '../atex/atex';
import {APAssetFormTabCard} from './asset-form-tab-card';
import {APAssetFormBlockField} from './asset-form-block-field';
import {APAssetFieldData} from './asset-field-data';
import {APAssetFormBlockFieldComponentType} from './asset-form-block-field-type';
import {APAssetFormTab} from './asset-form-tab';

/**
 * APAsset class defines the base properties of an asset.
 */
export class APAsset extends UUIDIdentification {
	/**
	 * Name of the asset. This name must be unique.
	 */
	public name: string = '';

	/**
	 * The tag of the asset
	 */
	public tag: string = null;

	/**
	 * Description of the asset.
	 */
	public description: string = '';

	/**
	 * The pictures of the asset
	 */
	public pictures: Resource[] = [];

	/**
	 * The documents associated to this asset
	 */
	public documents: Resource[] = [];

	/**
	 * The position of the asset in world coordinated.
	 */
	public position: Geolocation = null;

	/**
	 * The QR code associated to this asset
	 */
	public qr: string = null;

	/**
	 * The NFC tag associated to this asset
	 */
	public nfc: string = null;

	/**
	 * Pictures of the asset nameplate.
	 */
	public nameplate: Resource[] = null;

	/**
	 * Asset manufacturer info
	 */
	public manufacturer: string = '';

	/**
	 * Asset model info
	 */
	public model: string = '';

	/**
	 * Asset serial number 
	 */
	public serialNumber: string = '';

	/**
	 * Year of manufacturing for the asset.
	 */
	public manufacturingYear: number = null;

	/**
	 * Date when the asset was installed in the client.
	 */
	public installationDate: Date = null;

	/**
	 * Atex specifications of the asset (if any). Each asset can have multiple atex tags.
	 */
	public atex: Atex = new Atex();

	/**
	 * The type of the asset
	 */
	public typeUuid: UUID = null;

	/**
	 * The sub-type of the asset
	 */
	public subTypeUuid: UUID = null;

	/**
	 * The asset parent UUID of this asset 
	 */
	public parentUuid: UUID = null;

	/**
	 * List of master allowed roles for RBAC
	 */
	public rbacMasterAllowedList: UUID[] = [];

	/**
	 * List of master denied roles for RBAC
	 */
	public rbacMasterDeniedList: UUID[] = [];
	
	/**
	 * List of allowed roles for RBAC
	 */
	public rbacAllowedList: UUID[] = [];

	/**
	 * List of denied roles for RBAC
	 */
	public rbacDeniedList: UUID[] = [];

	/**
	 * Parse JSON data received from the API into an object of the correct type.
	 *
	 * @param data - Object received from API.
	 * @returns Asset object instance built from the data received.
	 */
	public static parse(data: any): APAsset {
		const asset: APAsset = new APAsset();

		asset.uuid = data.uuid;
		asset.updatedAt = new Date(data.updatedAt);
		asset.createdAt = new Date(data.createdAt);

		asset.name = data.name;
		asset.tag = data.tag;
		asset.description = data.description;
		asset.pictures = Resource.parseArray(data.pictures);
		asset.documents = Resource.parseArray(data.documents);
		asset.position = Geolocation.parse(data.position);
		asset.qr = data.qr;
		asset.nfc = data.nfc;
		asset.nameplate = data.nameplate;

		asset.manufacturer = data.manufacturer;
		asset.model = data.model;
		asset.serialNumber = data.serialNumber;
		asset.manufacturingYear = data.manufacturingYear || null;
		asset.installationDate = data.installationDate ? new Date(data.installationDate) : null;

		asset.atex = data.atex ? Atex.parse(data.atex) : new Atex();

		asset.typeUuid = data.typeUuid;
		asset.subTypeUuid = data.subTypeUuid;
		asset.parentUuid = data.parentUuid;

		asset.rbacMasterAllowedList = data.rbacMasterAllowedList || [];
		asset.rbacMasterDeniedList = data.rbacMasterDeniedList || [];

		asset.rbacAllowedList = data.rbacAllowedList || [];
		asset.rbacDeniedList = data.rbacDeniedList || [];

		return asset;
	}

	/**
	 * Get the asset fields data for the fields of all the cards on a tab from within the whole asset fields data and transform them on a single object to be used on tab dynamic form.
	 * 
	 * The returned object contains a key for every card field on a tab that has data on asset fields data. Object keys names are the cards fields UUID and its value comes directly from the asset fields data for that card and field UUIDs.
	 * 
	 * @param tab - The form tab to get the asset field data object for.
	 * @param data - The array containing all the asset fields data sotred on DB.
	 * @returns An object containing all the tab fields UUIDs as individual keys and asset fields data as their values.
	 */
	public static setFieldsTabDataObject(tab: APAssetFormTab, data: APAssetFieldData[]): AssetFieldTabData {
		const tabData: AssetFieldTabData = {};
		for (let k = 0; k < tab.cards.length; k++) {
			const card: APAssetFormTabCard = tab.cards[k];

			if (card.block && card.block.fields) {
				for (let l = 0; l < card.block.fields.length; l++) {
					const field: APAssetFormBlockField = card.block.fields[l];

					const fieldData: APAssetFieldData = data.find((d: APAssetFieldData) => { return d.cardUuid === card.uuid && d.fieldUuid === field.uuid; });

					if (!fieldData || !fieldData.data) {
						if (field.formFieldComponent === APAssetFormBlockFieldComponentType.DOCUMENT_RESOURCE_MULTIPLE || field.formFieldComponent === APAssetFormBlockFieldComponentType.IMAGE_RESOURCE_MULTIPLE) {
							tabData[field.uuid] = [];
						}
					} else {
						tabData[field.uuid] = fieldData.data;
					}
				}
			}
		}

		return tabData;
	}
}
