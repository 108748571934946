import {Component, OnInit} from '@angular/core';
import {SortDirection} from 'src/app/utils/sort-direction';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {NgStyle} from '@angular/common';
import {ScreenComponent} from '../../../../components/screen/screen.component';
import {App} from '../../../../app';
import {Company} from '../../../../models/companies/company';
import {Service} from '../../../../http/service';
import {UserPermissions} from '../../../../models/users/user-permissions';
import {UUID} from '../../../../models/uuid';
import {Session} from '../../../../session';
import {ServiceList} from '../../../../http/service-list';
import {UnoNoDataComponent} from '../../../../components/uno/uno-no-data/uno-no-data.component';
import {UnoContentComponent} from '../../../../components/uno/uno-content/uno-content.component';
import {UnoSearchbarComponent} from '../../../../components/uno/uno-searchbar/uno-searchbar.component';
import {UnoButtonComponent} from '../../../../components/uno/uno-button/uno-button.component';
import {CompanyListItemComponent} from './company-list-item/company-list-item.component';

/**
 * The extension of a company adding its children sub-companies and the loader function
 */
export class HierarchicalCompany extends Company {
	/**
	 * List of company children elements.
	 */
	public children: HierarchicalCompany[] = [];

	/**
	 * Indicates if children have been loaded.
	 */
	public hasChildren: boolean = false;
}

@Component({
	selector: 'company-list-page',
	templateUrl: './company-list.page.html',
	standalone: true,
	imports: [UnoButtonComponent, UnoSearchbarComponent, IonicModule, FormsModule, UnoContentComponent, NgStyle, UnoNoDataComponent, CompanyListItemComponent, TranslateModule]
})
export class CompanyListPage extends ScreenComponent implements OnInit {
	public get app(): any { return App; }

	public get session(): any { return Session; }

	public get userPermissions(): any { return UserPermissions; }

	public get selfStatic(): any { return CompanyListPage; }

	public permissions = [UserPermissions.COMPANY];

	/**
	 * Possible database filter to be used for ordering the companies list.
	 */
	public static sortFieldValues: any = [
		{label: 'name', attribute: 'name'},
		{label: 'createdAt', attribute: 'created_at'},
		{label: 'updatedAt', attribute: 'updated_at'}
	];

	/**
	 * Possible sort direction values.
	 */
	public static sortDirectionValues: any = [SortDirection.ASC, SortDirection.DESC];

	/**
	 * List of possible search fields
	 */
	public static searchFieldValues: any = [
		{label: 'name', attribute: 'name'}
	];

	/**
	 * Companies to be displayed on the page.
	 */
	public companies: HierarchicalCompany[] = [];

	public async ngOnInit(): Promise<void> {
		super.ngOnInit();
		
		App.navigator.setTitle('companies');

		this.companies = await CompanyListPage.loadData(null);
	}

	public static filters = {
		/**
		 * Text used to filter list entries by their content.
		 */
		search: '',

		/**
		 * Sort direction applied to the loaded list from database.
		 */
		sortDirection: CompanyListPage.sortDirectionValues[1],

		/**
		 * Database attribute name used to sort the values.
		 */
		sortField: CompanyListPage.sortFieldValues[0].attribute
	};

	public static defaultFilters = structuredClone(CompanyListPage.filters);

	public resetFilters(): void {
		Object.assign(CompanyListPage.filters, CompanyListPage.defaultFilters);
	}

	/**
	 * Update the search term used.
	 *
	 * @param event - DOM event.
	 */
	public async onFilterChange(event: any): Promise<void> {
		if (event.type === undefined) {
			CompanyListPage.filters.search = event;
		}

		this.companies = await CompanyListPage.loadData(null);
	}

	/**
	 * Load more data from the API to display. Data is loaded one tree branch at a time.
	 * 
	 * @param uuid - Company parent UUID whose child we want to load
	 */
	public static async loadData(uuid: UUID = null): Promise<any> {
		const data = {
			uuid: uuid,
			sortDirection: CompanyListPage.filters.sortDirection,
			sortField: CompanyListPage.filters.sortField,
			search: CompanyListPage.filters.search
		};

		const request = await Service.fetch(ServiceList.company.listChild, null, null, data, Session.session);
		return request.response.children;
	}
}
