@if (this.value) {
	<!-- Desktop -->
	@if (app.device.isDesktop()) {
		<ion-item class="ion-no-margin ion-no-padding" lines="none">
			<!-- Label -->
			<div style="width: calc(100% - 450px);">
				<ion-label class="ion-margin-horizontal ion-text-wrap" style="min-width: 100%; white-space: pre-wrap">{{this.row.label}}</ion-label>
			</div>
			<!-- Content -->
			<div style="width: 450px">
				<uno-dynamic-form [editable]="!this.disabled" [layout]="this.row.fields" [object]="this.value" [onChange]="this.onChange"></uno-dynamic-form>
			</div>
		</ion-item>
	}
	<!-- Mobile -->
	@if (app.device.isMobile()) {
		<!-- Title -->
		<ion-item class="ion-margin-horizontal ion-no-padding" lines="none">
			<div style="width: 100%">
				<ion-label class="ion-text-wrap" style="white-space: pre-wrap">{{this.row.label}}</ion-label>
			</div>
		</ion-item>
		<!-- Content -->
		<ion-item class="ion-no-margin ion-no-padding" lines="none">
			<div style="width: 100%">
				<uno-dynamic-form [editable]="!this.disabled" [layout]="this.row.fields" [object]="this.value" [onChange]="this.onChange"></uno-dynamic-form>
			</div>
		</ion-item>
	}
}
