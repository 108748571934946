import {
	AtexAmbient,
	AtexAmbientLabels,
	AtexCategory,
	AtexEpl,
	AtexEplLabels,
	AtexExplosionGroups,
	AtexExplosionGroupsLabels,
	AtexGroup,
	AtexGroupLabels,
	AtexProtectionsLabels,
	AtexTemperatureLabels,
	AtexZones,
	IPRatingDust,
	IPRatingDustLabels,
	IPRatingWater,
	IPRatingWaterLabels
} from './atex-enums';
import {AtexProtectionsZones} from './atex-protections-zone';

/**
 * Stores the Atex data of a equipment, stores both the zones related data and the Atex tags of the equipment.
 */
export class Atex {
	/**
	 * Atex zone where the equipment is placed at.
	 *
	 * Multiple zones are allowed for each asset since that can be placed in zone where GAS and DUST might be present simultaneously.
	 */
	public zone: number[] = [];

	/**
	 * Explosion group of the zone where the equipment is placed at.
	 */
	public zoneExplosion: number = AtexExplosionGroups.UNKNOWN;

	/**
	 * Self-ignition temperature of the gas/dust of the zone.
	 */
	public zoneTemperature: number = 0;

	/**
	 * Atex tag of the equipment asset, some equipments might have multiple tags.
	 */
	public tags: AtexTag[] = [new AtexTag()];

	/**
	 * Parse Atex data stored as JSON in the database and migrate it if necessary.
	 *
	 * @param data - Data object to be parsed
	 */
	public static parse(data: any): Atex {
		const atex = new Atex();

		if (data.zone !== undefined) {
			// Zones must be converted from non-array value to an array.
			if (!(data.zone instanceof Array)) {
				data.zone = [data.zone];
			}

			atex.zone = data.zone;
		}

		if (data.zoneExplosion !== undefined) {
			atex.zoneExplosion = data.zoneExplosion;
		}

		if (data.zoneTemperature !== undefined) {
			atex.zoneTemperature = data.zoneTemperature;
		}

		atex.tags = AtexTag.parseTags(data);

		return atex;
	}

	/**
	 * Check if the Atex zone is for gases (zone 0, 1 or 2)
	 *
	 * @param zones - Atex Zones
	 */
	public static isGasZone(zones: number[]): boolean {
		return zones.indexOf(AtexZones.ZONE_0) !== -1 || zones.indexOf(AtexZones.ZONE_1) !== -1 || zones.indexOf(AtexZones.ZONE_2) !== -1;
	}

	/**
	 * Check if the Atex zone is for dust (zone 20, 21 or 22)
	 *
	 * @param zones - Atex Zones
	 */
	public static isDustZone(zones: number[]): boolean {
		return zones.indexOf(AtexZones.ZONE_20) !== -1 || zones.indexOf(AtexZones.ZONE_21) !== -1 || zones.indexOf(AtexZones.ZONE_22) !== -1;
	}

	/**
	 * Check if the Atex zone is for mine (zone 30)
	 *
	 * @param zones - Atex Zones
	 */
	public static isMineZone(zones: number[]): boolean {
		return zones.indexOf(AtexZones.MINE) !== -1;
	}

	/**
	 * Check if the tags are compliant with zone data.
	 */
	public isCompliant(): boolean {
		for (let i = 0; i < this.tags.length; i++) {
			if (this.tags[i].isCompliant(this.zone, this.zoneExplosion, this.zoneTemperature)) {
				return true;
			}
		}

		return false;
	}
}

/**
 * Represents an Atex equipment tag. The tag indicates the environment for which this equipment is certified.
 *
 * Some equipments might have different of these tags (e.g. one for dust and one for gas).
 */
export class AtexTag {
	/**
	 * CE certification.
	 */
	public ce: boolean = false;

	/**
	 * Number of the certification entity.
	 */
	public certificationNumber: string = '';

	/**
	 * Authorized for usage in explosive atmosphere.
	 */
	public ex: boolean = false;

	/**
	 * Equipment specifically produced for explosive atmospheres.
	 */
	public eex: boolean = false;

	/**
	 * Group of Atex equipments (mines or surface).
	 */
	public group: number = -1;

	/**
	 * Atex category.
	 */
	public category: number = AtexCategory.UNKNOWN;

	/**
	 * Atex sub-category data if present.
	 *
	 * Not in use, kept just for legacy compatibility.
	 */
	public subCategory: string = '';

	/**
	 * Atex ambient type (Dust, Mines, Gas).
	 */
	public ambient: number = AtexAmbient.UNKNOWN;

	/**
	 * Protections of the equipment.
	 */
	public protections: number[] = [];

	/**
	 * Explosion group.
	 */
	public explosion: number = AtexExplosionGroups.UNKNOWN;

	/**
	 * Product level (Ga, Gb, etc), indicate the Atex zone rating support of the equipment.
	 */
	public epl: number = -1;

	/**
	 * Temperature class, indicates the equipment surface temperature.
	 *
	 * For a equipment to be safe for a zone, its surface temperature must be lower than the zone temperature (zone temperature is the self-ignition temperature of the explosive content)
	 */
	public temperature: number = 0;

	/**
	 * IP protection level for dust (IP6X, IP5X, etc)
	 */
	public ipxDust: number = IPRatingDust.UNKNOWN;

	/**
	 * IP protection level for water resistance and tolerance.
	 */
	public ipxWater: number = IPRatingWater.UNKNOWN;

	/**
	 * Minimum ambient temperature supported by the equipment (Degrees Celsius).
	 */
	public temperatureMin: number = 0;

	/**
	 * Maximum ambient temperature supported by the equipment (Degrees Celsius).
	 */
	public temperatureMax: number = 0;

	/**
	 * Parse Atex data stored as JSON in the database and migrate it if necessary.
	 *
	 * @param data - Data object to be parsed
	 */
	public static parseTags(data: any): AtexTag[] {
		// Parse all Atex tags one by one, older data version may contain a single Atex tag
		const tags = [];
		if (data.tags !== undefined) {
			for (let i = 0; i < data.tags.length; i++) {
				tags.push(AtexTag.parse(data.tags[i]));
			}
		} else {
			tags.push(AtexTag.parse(data));
		}

		return tags;
	}

	/**
	 * Parse a single Atex tag data from JSON.
	 *
	 * @param data - Data object to be parsed.
	 */
	public static parse(data: any): AtexTag {
		const tag = new AtexTag();

		for (const attr in data) {
			tag[attr] = data[attr];
		}

		// IP rating dust legacy support
		if (data.ip !== undefined && tag.ipxDust === IPRatingDust.UNKNOWN) {
			tag.ipxDust = data.ip;
		}

		// Category open field legacy support
		if (data.subCategory !== undefined && data.subCategory.length > 0 && tag.category === AtexCategory.UNKNOWN) {
			if (tag.subCategory.startsWith('1')) {
				tag.category = AtexCategory.C1;
			} else if (tag.subCategory.startsWith('2')) {
				tag.category = AtexCategory.C2;
			} else if (tag.subCategory.startsWith('3')) {
				tag.category = AtexCategory.C3;
			}
		}

		return tag;
	}

	/**
	 * Create a string representing this Atex tag data.
	 */
	public toString(): string {
		let tag = '';

		function append(value): void {
			if (tag.length > 0) {
				tag += ' ';
			}

			tag += value;
		}

		if (this.ce) {
			append('CE');
			if (this.certificationNumber.length > 0) {
				append('(' + this.certificationNumber + ')');
			}
		}

		if (this.ex) {
			append('Ex');
		}

		if (this.group !== AtexGroup.UNKNOWN) {
			append(AtexGroupLabels.get(this.group));
		}

		if (this.category !== AtexCategory.UNKNOWN) {
			append(this.category);

			if (this.subCategory.length > 0) {
				append('(' + this.subCategory + ')');
			}
		}

		if (this.ambient !== AtexAmbient.UNKNOWN) {
			append(AtexAmbientLabels.get(this.ambient));
		}

		if (this.eex) {
			append('Eex');
		}

		if (this.protections.length > 0) {
			for (let i = 0; i < this.protections.length; i++) {
				append(AtexProtectionsLabels.get(this.protections[i]));
			}
		}

		if (this.explosion !== AtexExplosionGroups.UNKNOWN) {
			append(AtexExplosionGroupsLabels.get(this.explosion));
		}

		if (this.ambient !== AtexAmbient.UNKNOWN) {
			if (this.ambient === AtexAmbient.G) {
				append(AtexTemperatureLabels.get(this.temperature));
			} else {
				append('Tmax' + this.temperature);
			}
		}

		if (this.epl !== AtexEpl.UNKNOWN) {
			append(AtexEplLabels.get(this.epl));
		}

		if (this.ipxDust !== IPRatingDust.UNKNOWN || this.ipxWater !== IPRatingWater.UNKNOWN) {
			append('IP' + IPRatingDustLabels.get(this.ipxDust) + IPRatingWaterLabels.get(this.ipxWater));
		}

		if (this.temperatureMin !== 0 && this.temperatureMax !== 0) {
			append(this.temperatureMin + ' ≤ Tamb ≤ ' + this.temperatureMax);
		}

		return tag;
	}

	/**
	 * Check if Atex data is compliant with zone data.
	 *
	 * @param zones - Atex zones where equipment is placed at.
	 * @param zoneExplosion - Explosion group associated with the zone.
	 * @param zoneTemperature - Temperature of the zone. The equipment surface temperature should be lower than this zone temperature.
	 * @param validateZoneExplosion - If true the explosion level of the zone is validated. By default it's false.
	 * @param validateZoneTemperature - If true the temperature of the zone is validated. By default it's false.
	 */
	public isCompliant(zones: number[], zoneExplosion: number, zoneTemperature: number, validateZoneExplosion: boolean = false, validateZoneTemperature: boolean = false): boolean {
		if (zones && zones.length > 0) {
			// Atex environment (Dust, Gas or Mine)
			if (Atex.isGasZone(zones) && this.ambient !== AtexAmbient.G) { return false; }
			if (Atex.isDustZone(zones) && this.ambient !== AtexAmbient.D) { return false; }
			if (Atex.isMineZone(zones) && this.ambient !== AtexAmbient.M) { return false; }

			if (this.category !== AtexCategory.UNKNOWN) {
				// Check Atex categories when compared to zone
				if ((zones.indexOf(AtexZones.ZONE_20) !== -1 || zones.indexOf(AtexZones.ZONE_0) !== -1) && this.category !== AtexCategory.C1) { return false; }
				if ((zones.indexOf(AtexZones.ZONE_21) !== -1 || zones.indexOf(AtexZones.ZONE_1) !== -1) && (this.category !== AtexCategory.C2 && this.category !== AtexCategory.C1)) { return false; }
				if ((zones.indexOf(AtexZones.ZONE_22) !== -1 || zones.indexOf(AtexZones.ZONE_2) !== -1) && (this.category !== AtexCategory.C2 && this.category !== AtexCategory.C1 && this.category !== AtexCategory.C3)) { return false; }
			} else {
				for (let z = 0; z < zones.length; z++) {
					// Use protection mode if no categories are available. At least one protection required per zone.
					const protections: number[] = AtexProtectionsZones.get(zones[z]);

					let found = false;

					// Check if the Atex label has at least one protection from the protections list for each zone level
					for (let i = 0; i < this.protections.length; i++) {
						if (protections.indexOf(this.protections[i]) !== -1) {
							found = true;
							break;
						}
					}

					if (!found) {
						return false;
					}
				}
			}

			// Check EPL level (if unknown ignore this step)
			if (this.epl !== AtexEpl.UNKNOWN) {
				if (zones.indexOf(AtexZones.ZONE_20) !== -1 && this.epl !== AtexEpl.Dc) { return false; }
				if (zones.indexOf(AtexZones.ZONE_0) !== -1 && this.epl !== AtexEpl.Gc) { return false; }
				if (zones.indexOf(AtexZones.ZONE_21) !== -1 && this.epl !== AtexEpl.Dc && this.epl !== AtexEpl.Db) { return false; }
				if (zones.indexOf(AtexZones.ZONE_1) !== -1 && this.epl !== AtexEpl.Gc && this.epl !== AtexEpl.Gb) { return false; }
				if (zones.indexOf(AtexZones.ZONE_22) !== -1 && this.epl !== AtexEpl.Dc && this.epl !== AtexEpl.Db && this.epl !== AtexEpl.Da) { return false; }
				if (zones.indexOf(AtexZones.ZONE_2) !== -1 && this.epl !== AtexEpl.Gc && this.epl !== AtexEpl.Gb && this.epl !== AtexEpl.Ga) { return false; }
			}
		}

		if (validateZoneExplosion) {
			if (zoneExplosion !== AtexExplosionGroups.UNKNOWN) {
				// Mines
				if (zoneExplosion === AtexExplosionGroups.I && this.explosion !== AtexExplosionGroups.I) { return false; }

				// Gas
				if (zoneExplosion === AtexExplosionGroups.IIA && this.explosion !== AtexExplosionGroups.IIC && this.explosion !== AtexExplosionGroups.IIB && this.explosion !== AtexExplosionGroups.IIA) { return false; }
				if (zoneExplosion === AtexExplosionGroups.IIB && this.explosion !== AtexExplosionGroups.IIC && this.explosion !== AtexExplosionGroups.IIB) { return false; }
				if (zoneExplosion === AtexExplosionGroups.IIC && this.explosion !== AtexExplosionGroups.IIC) { return false; }

				// Dust
				if (zoneExplosion === AtexExplosionGroups.IIIA && this.explosion !== AtexExplosionGroups.IIIC && this.explosion !== AtexExplosionGroups.IIIB && this.explosion !== AtexExplosionGroups.IIIA) { return false; }
				if (zoneExplosion === AtexExplosionGroups.IIIB && this.explosion !== AtexExplosionGroups.IIIC && this.explosion !== AtexExplosionGroups.IIIB) { return false; }
				if (zoneExplosion === AtexExplosionGroups.IIIC && this.explosion !== AtexExplosionGroups.IIIC) { return false; }
			}
		}

		if (validateZoneTemperature) {
			if (zoneTemperature > 0) {
				// Check equipment surface temperature is bellow the zone temperature
				if (this.temperature < zoneTemperature) {
					return false;
				}
			}
		}

		return true;
	}
}
