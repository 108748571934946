import {MenuOption} from '../../data/menu-option';
import {UserPermissions} from '../../../../models/users/user-permissions';
import {App} from '../../../../app';
import {Environment} from '../../../../../environments/environment';
import {Dl50ClientModule} from '../../../../../client-custom/dl50/dl50-client.module';

/**
 * List of pages available from the menu.
 *
 * Used to create the page content for desktop and mobile.
 */
export const MenuOptions = function(): MenuOption[] {
	return [
		{
			route: '/menu/master-settings',
			icon: './assets/components/menu/settings-icon.svg',
			label: 'masterSettings',
			available: true,
			permissions: [UserPermissions.MASTER_SETTINGS]
		},
		{
			route: '/menu/masterdata',
			icon: './assets/components/menu/master-icon.svg',
			label: 'masterdata',
			available: true,
			permissions: [UserPermissions.MASTER_DATA]
		},
		{
			route: '/menu/companies',
			icon: './assets/components/menu/company-icon.svg',
			label: 'companies',
			available: true,
			permissions: [UserPermissions.COMPANY]
		},
		{
			route: '/menu/users',
			icon: './assets/components/menu/user-icon.svg',
			label: 'users',
			available: true,
			permissions: [UserPermissions.USER]
		},
		{
			route: '/menu/role',
			icon: './assets/components/menu/role-icon.svg',
			label: 'userRoles',
			available: true,
			permissions: [UserPermissions.ROLES]
		},
		{
			route: '/menu/teams',
			icon: './assets/components/menu/team-icon.svg',
			label: 'teams',
			available: true,
			permissions: [UserPermissions.TEAM]
		},
		{
			route: '/menu/data',
			icon: './assets/components/menu/export-import-icon.svg',
			label: 'importExport',
			available: true,
			permissions: [UserPermissions.TOOLS]
		},
		{
			route: '/menu/dashboard',
			icon: './assets/components/menu/dashboard-icon.svg',
			label: 'dashboard',
			available: true,
			permissions: [UserPermissions.DASHBOARD]
		},
		{
			route: '/menu/asset-portfolio',
			icon: './assets/components/menu/asset-portfolio-icon.svg',
			label: 'assetPortfolio',
			available: true,
			highlight: (option: MenuOption) => {
				if (App.navigator.has('/menu/asset-portfolio/asset/map')) {
					return false;
				}

				return App.navigator.has(option.route);
			},
			permissions: [UserPermissions.ASSET_PORTFOLIO]
		},
		{
			route: '/menu/asset-planning',
			icon: './assets/components/menu/schedule-icon.svg',
			label: 'assetPlanning',
			available: true,
			permissions: [UserPermissions.CALENDAR_EVENT]
		},
		{
			route: '/menu/gap-analysis',
			icon: './assets/components/menu/gap-analysis-icon.svg',
			label: 'gapAnalysis',
			available: true,
			permissions: [UserPermissions.GA, UserPermissions.GA_GAP, UserPermissions.GA_ACTION_PLAN]
		},
		{
			route: '/menu/atex',
			icon: './assets/components/menu/atex-icon.svg',
			label: 'atex',
			available: true,
			permissions: [UserPermissions.ATEX_INSPECTION, UserPermissions.ACTION_PLAN, UserPermissions.FFP]
		},
		{
			route: '/menu/inspection',
			icon: './assets/components/menu/inspections-icon.svg',
			label: 'inspections',
			highlight: (option: MenuOption) => {
				if (App.navigator.has('/menu/inspection/list/steps')) {
					return false;
				}

				return App.navigator.has(option.route);
			},
			available: !Dl50ClientModule.hasRole(),
			permissions: [UserPermissions.INSPECTION]
		},
		{
			route: '/menu/dl50',
			icon: './assets/components/menu/inspections-icon.svg',
			label: 'DL50',
			available: !Environment.PRODUCTION,
			permissions: [UserPermissions.DL50]
		},
		{
			route: '/menu/epi',
			icon: './assets/components/menu/csi-icon.svg',
			label: 'epiShort',
			available: true,
			permissions: [UserPermissions.EPI]
		},
		{
			route: '/menu/repairs',
			icon: './assets/components/menu/repairs-icon.svg',
			label: 'repairs',
			available: true,
			permissions: [UserPermissions.REPAIR, UserPermissions.REPAIR_INSPECTIONS]
		},
		{
			route: '/menu/digital-twin',
			icon: './assets/components/menu/digitaltwin-icon.svg',
			label: 'digitalTwin',
			available: Environment.DIGITAL_TWIN_SERVER !== null,
			permissions: [UserPermissions.DIGITAL_TWIN]
		},
		{
			route: '/menu/observations',
			icon: './assets/components/menu/observations-icon.svg',
			label: 'observations',
			available: true,
			permissions: [UserPermissions.OBSERVATIONS]
		},
		{
			route: '/menu/pipeline-integrity',
			icon: './assets/components/menu/pipeline-icon.svg',
			label: 'pipelineIntegrity',
			available: Environment.PIPELINE_INTEGRITY_SERVER !== null,
			permissions: [UserPermissions.PIPELINE_INTEGRITY]
		},
		{
			route: '/menu/asset-portfolio/asset/map',
			icon: './assets/components/menu/map-icon.svg',
			label: 'map',
			available: true,
			permissions: [UserPermissions.ASSET_PORTFOLIO_MAP]
		},
		{
			route: '/menu/qr',
			icon: './assets/components/menu/qrcode-icon.svg',
			label: 'qr',
			available: App.device.hasCamera(),
			permissions: [UserPermissions.ASSET_PORTFOLIO_QR, UserPermissions.REPAIR_QR]
		},
		{
			route: '/menu/nfc',
			icon: './assets/components/menu/company-icon.svg',
			label: 'nfc',
			available: App.device.hasNFC(),
			permissions: [UserPermissions.ASSET_PORTFOLIO_NFC]
		},
		{
			route: '/menu/logs',
			icon: './assets/components/menu/logs-icon.svg',
			label: 'logs',
			available: true,
			permissions: [UserPermissions.LOGS]
		},
		{
			route: '/menu/demo',
			icon: './assets/components/menu/bug-icon.svg',
			label: 'demo',
			available: !Environment.PRODUCTION,
			permissions: []
		},
		{
			route: '/menu/dl50-client',
			icon: './assets/components/menu/inspections-icon.svg',
			label: 'DL50',
			available: Dl50ClientModule.isClient(),
			permissions: []
		}
	];
};
