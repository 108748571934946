import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {AssetPortfolioMenuPage} from './screens/menu/asset-portfolio-menu.page';
import {AssetFormBlockListPage} from './screens/asset-structure/asset-form-block/list/asset-form-block-list.page';
import {AssetFormBlockEditPage} from './screens/asset-structure/asset-form-block/edit/asset-form-block-edit.page';
import {AssetTypeListPage} from './screens/asset-structure/asset-type/list/asset-type-list.page';
import {AssetTypeEditPage} from './screens/asset-structure/asset-type/edit/asset-type-edit.page';
import {AssetSubTypeListPage} from './screens/asset-structure/asset-sub-type/list/asset-sub-type-list.page';
import {AssetSubTypeEditPage} from './screens/asset-structure/asset-sub-type/edit/asset-sub-type-edit.page';
import {AssetEditPage} from './screens/asset/edit/asset-edit.page';
import {AssetStructureMenuPage} from './screens/asset-structure/menu/asset-structure-menu.page';
import {AssetsListPage} from './screens/asset/list/assets-list.page';
import {AssetSettingsPage} from './screens/master-data/asset-settings/asset-settings.page';
import {AssetsModelListPage} from './screens/asset/model-list/asset-model-list.page';
import {AssetTreeListPage} from './screens/asset/tree/asset-tree-list.page';
import {AssetsMapPage} from './screens/asset/map/assets-map-page.component';

const routes: Routes = [
	{
		path: '',
		component: AssetPortfolioMenuPage
	},
	{
		path: 'structure',
		component: AssetStructureMenuPage
	},
	{
		path: 'structure/form-block/list',
		component: AssetFormBlockListPage
	},
	{
		path: 'structure/form-block/edit',
		component: AssetFormBlockEditPage
	},
	{
		path: 'structure/asset-type/list',
		component: AssetTypeListPage
	},
	{
		path: 'structure/asset-type/edit',
		component: AssetTypeEditPage
	},
	{
		path: 'structure/asset-sub-type/list',
		component: AssetSubTypeListPage
	},
	{
		path: 'structure/asset-sub-type/edit',
		component: AssetSubTypeEditPage
	},
	{
		path: 'asset/list/tree',
		component: AssetTreeListPage
	},
	{
		path: 'asset/list',
		component: AssetsListPage
	},
	{
		path: 'asset/list/model',
		component: AssetsModelListPage
	},
	{
		path: 'asset/edit',
		component: AssetEditPage
	},
	{
		path: 'asset/map',
		component: AssetsMapPage
	},
	{
		path: 'master-data/settings',
		component: AssetSettingsPage
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class AssetPortfolioRouterModule {}
