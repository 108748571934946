import {Resource} from '../../resource';
import {UUID, UUIDIdentification} from '../../uuid';
import {FFPPriority} from './ffp-priority';
import {FFPState} from './ffp-state';

/**
 * FFP (Fit for purpose) are created from each NC of the inspection completed.
 *
 * In the FFP is possible to indicate the priority and cost of repair, FFP will be later used to propose a action plan.
 *
 * They are attached to the field of the inspection filled by the inspectors.
 */
export class FFP extends UUIDIdentification {
	/**
	 * Unique identifier of the inspection from which this FFP was created.
	 */
	public inspection: UUID = null;

	/**
	 * State of the FFP entry.
	 */
	public state: number = FFPState.ACTIVE;

	/**
	 * Inspection field that failed in the equipment inspection.
	 */
	public field: string = '';

	/**
	 * Form of the field inspection (e.g. backoffice, inspector)
	 */
	public form: string = '';

	/**
	 * Priority value of the FFP, higher value means more priority
	 *
	 * Value should be filled from FFPPriority.
	 */
	public priority: number = FFPPriority.MEDIUM;

	/**
	 * Description of the FFP may contain additional suggestion.
	 */
	public description: string = '';

	/**
	 * Cost in euro of the FFP.
	 */
	public cost: number = 0;

	/**
	 * Recommendations selected for this FFP.
	 */
	public recommendations: number[] = [];

	/**
	 * Additional FFP data.
	 */
	public data: FFPData = new FFPData();

	/**
	 * Parse the data of an API retrieved FFP object.
	 *
	 * @param data - FFP object to parse data
	 */
	public static parse(data: any): FFP {
		const ffp = new FFP();

		ffp.uuid = data.uuid;
		ffp.createdAt = new Date(data.createdAt);
		ffp.updatedAt = new Date(data.updatedAt);
		ffp.state = data.state;
		ffp.description = data.description;
		ffp.field = data.field;
		ffp.form = data.form;
		ffp.priority = data.priority;
		ffp.cost = data.cost;
		ffp.inspection = data.inspection;

		if (data.recommendations !== null) {
			ffp.recommendations = data.recommendations;
		}

		if (data.data !== null) {
			ffp.data = data.data;
		}

		return ffp;
	}
}

/**
 * Data attached to the FFP.
 */
export class FFPData {
	/**
	 * Photos attached to the FFP entry.
	 */
	public photos: Resource[] = [];
}
