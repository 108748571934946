import {UnoFormFieldTypes} from '../../../components/uno-forms/uno-dynamic-form/uno-form-field-types';
import {UnoFormField} from '../../../components/uno-forms/uno-dynamic-form/uno-form-field';
import {EPIBarrierType, EPIBarrierTypeLabel} from '../../../models/epi/epi-barrier';
import {EPIResult, EPIResultLabel} from '../../../models/epi/epi-result';

/**
 * Stores the form layout to edit EPI data.
 */
export class EPILayouts {
	/**
	 * EPI main details, identification, equipments in the loop and barrier type.
	 */
	public static details: UnoFormField[] = [
		{
			label: 'epi',
			type: UnoFormFieldTypes.TITLE
		},
		{
			label: 'uuid',
			attribute: 'uuid',
			type: UnoFormFieldTypes.UUID
		},
		{
			required: true,
			attribute: 'name',
			label: 'name',
			type: UnoFormFieldTypes.TEXT
		},
		{
			attribute: 'tag',
			label: 'tag',
			type: UnoFormFieldTypes.TEXT
		},
		{
			label: 'result',
			attribute: 'result',
			type: UnoFormFieldTypes.OPTIONS,
			sort: false,
			required: true,
			isEmpty: function(object) {
				return object.result === EPIResult.NONE;
			},
			options: Object.values(EPIResult).map(function(value) {
				return {value: value, label: EPIResultLabel.get(value)};
			})
		},
		{
			required: true,
			sort: false,
			attribute: 'barrierType',
			label: 'barrierType',
			isEmpty: function(object: any): boolean {
				return object.barrierType !== EPIBarrierType.ZENER && object.barrierType !== EPIBarrierType.GALVANIC;
			},
			type: UnoFormFieldTypes.OPTIONS,
			options: Object.values(EPIBarrierType).map(function(value) {
				return {value: value, label: EPIBarrierTypeLabel.get(value)};
			})
		},
		{
			required: true,
			attribute: 'barrierId',
			label: 'barrierId',
			type: UnoFormFieldTypes.TEXT
		},
		{
			required: false,
			attribute: 'diagram',
			label: 'diagram',
			type: UnoFormFieldTypes.IMAGE_RESOURCE
		},
		{
			multiple: true,
			attribute: 'equipments',
			label: 'loopEquipments',
			type: UnoFormFieldTypes.ASSET_SELECTOR,
			showClear: true
		},
		{
			attribute: 'description',
			label: 'description',
			type: UnoFormFieldTypes.TEXT_MULTILINE
		},
		{
			attribute: 'plant',
			label: 'plant',
			type: UnoFormFieldTypes.TEXT_MULTILINE
		},
		{
			attribute: 'operationalUnit',
			label: 'operationalUnit',
			type: UnoFormFieldTypes.TEXT_MULTILINE
		}
	];

	/**
	 * EPI barrier properties.
	 */
	public static barrier: UnoFormField[] = [
		{
			label: 'barrier',
			type: UnoFormFieldTypes.TITLE
		},
		{
			attribute: 'photos',
			label: 'photos',
			type: UnoFormFieldTypes.IMAGE_RESOURCE_MULTIPLE
		},
		{
			label: 'tagPicture',
			attribute: 'tagPhotos',
			type: UnoFormFieldTypes.IMAGE_RESOURCE_MULTIPLE
		},
		{
			attribute: 'documents',
			label: 'documents',
			type: UnoFormFieldTypes.DOCUMENT_RESOURCE_MULTIPLE
		},
		{
			attribute: 'comments',
			label: 'comments',
			type: UnoFormFieldTypes.TEXT_MULTILINE
		},
		{
			required: false,
			attribute: 'maxOutputVoltage',
			label: 'maxOutputVoltage',
			type: UnoFormFieldTypes.NUMBER,
			inUnit: 'V'
		},
		{
			required: false,
			attribute: 'maxOutputCurrent',
			label: 'maxOutputCurrent',
			type: UnoFormFieldTypes.NUMBER,
			inUnit: 'A'
		},
		{
			required: false,
			attribute: 'maxOutputPower',
			label: 'maxOutputPower',
			type: UnoFormFieldTypes.NUMBER,
			inUnit: 'W'
		},
		{
			required: false,
			attribute: 'maxExternalCapacity',
			label: 'maxExternalCapacity',
			type: UnoFormFieldTypes.NUMBER_UNITS,
			inUnit: 'uF',
			unit: 'uF',
			options: [
				{value: 'uF', label: 'µF'},
				{value: 'nF', label: 'nF'}
			]
		},
		{
			required: false,
			attribute: 'maxExternalInductance',
			label: 'maxExternalInductance',
			type: UnoFormFieldTypes.NUMBER,
			inUnit: 'mH'
		},
		{
			required: false,
			attribute: 'groundResistance',
			label: 'groundResistance',
			type: UnoFormFieldTypes.NUMBER_UNITS,
			inUnit: 'Ω',
			unit: 'Ω',
			options: [
				{value: 'Ω', label: 'Ω'},
				{value: 'kΩ', label: 'kΩ'}
			],
			isActive: function(object): boolean {
				return object.barrierType === EPIBarrierType.ZENER;
			}
		}
	];

	/**
	 * EPI protected equipment properties.
	 */
	public static equipment: UnoFormField[] = [
		{
			label: 'equipment',
			type: UnoFormFieldTypes.TITLE
		},
		{
			attribute: 'photos',
			label: 'photos',
			type: UnoFormFieldTypes.IMAGE_RESOURCE_MULTIPLE
		},
		{
			label: 'tagPicture',
			attribute: 'tagPhotos',
			type: UnoFormFieldTypes.IMAGE_RESOURCE_MULTIPLE
		},
		{
			attribute: 'documents',
			label: 'documents',
			type: UnoFormFieldTypes.DOCUMENT_RESOURCE_MULTIPLE
		},
		{
			attribute: 'comments',
			label: 'comments',
			type: UnoFormFieldTypes.TEXT_MULTILINE
		},
		{
			required: false,
			attribute: 'maxInputVoltage',
			label: 'maxInputVoltage',
			type: UnoFormFieldTypes.NUMBER,
			inUnit: 'V'
		},
		{
			required: false,
			attribute: 'maxInputCurrent',
			label: 'maxInputCurrent',
			type: UnoFormFieldTypes.NUMBER,
			inUnit: 'A'
		},
		{
			required: false,
			attribute: 'maxInputPower',
			label: 'maxInputPower',
			type: UnoFormFieldTypes.NUMBER,
			inUnit: 'W'
		},
		{
			required: false,
			attribute: 'maxInternalCapacity',
			label: 'maxInternalCapacity',
			type: UnoFormFieldTypes.NUMBER_UNITS,
			inUnit: 'uF',
			unit: 'uF',
			options: [
				{value: 'uF', label: 'µF'},
				{value: 'nF', label: 'nF'}
			]
		},
		{
			required: false,
			attribute: 'maxInternalInductance',
			label: 'maxInternalInductance',
			type: UnoFormFieldTypes.NUMBER,
			inUnit: 'mH'
		}
	];

	/**
	 * EPI cable properties, only apply to Zener barriers.
	 */
	public static cable: UnoFormField[] = [
		{
			label: 'cable',
			type: UnoFormFieldTypes.TITLE
		},
		{
			required: true,
			attribute: 'length',
			label: 'length',
			type: UnoFormFieldTypes.NUMBER_UNITS,
			inUnit: 'm',
			unit: 'm',
			options: [
				{value: 'cm', label: 'cm'},
				{value: 'm', label: 'm'},
				{value: 'km', label: 'km'}
			]
		},
		{
			required: true,
			attribute: 'resistanceSection',
			label: 'resistanceSection',
			type: UnoFormFieldTypes.NUMBER,
			inUnit: 'Ω/Km'
		},
		{
			required: true,
			attribute: 'resistance',
			label: 'resistance',
			type: UnoFormFieldTypes.NUMBER_UNITS,
			inUnit: 'Ω',
			unit: 'Ω',
			options: [
				{value: 'Ω', label: 'Ω'},
				{value: 'kΩ', label: 'kΩ'}
			]
		},
		{
			required: true,
			attribute: 'inductanceSection',
			label: 'inductanceSection',
			type: UnoFormFieldTypes.NUMBER,
			inUnit: 'mH/Km'
		},
		{
			required: true,
			label: 'inductance',
			attribute: 'inductance',
			type: UnoFormFieldTypes.NUMBER_UNITS,
			inUnit: 'mH',
			unit: 'mH',
			options: [
				{value: 'mH', label: 'mH'}
			]
		},
		{
			required: true,
			attribute: 'capacitySection',
			label: 'capacitySection',
			type: UnoFormFieldTypes.NUMBER,
			inUnit: 'µF/Km'
		},
		{
			required: true,
			label: 'capacity',
			attribute: 'capacity',
			type: UnoFormFieldTypes.NUMBER_UNITS,
			inUnit: 'uF',
			unit: 'uF',
			options: [
				{value: 'uF', label: 'µF'},
				{value: 'nF', label: 'nF'}
			]
		},
		{
			attribute: 'comments',
			label: 'comments',
			type: UnoFormFieldTypes.TEXT_MULTILINE
		}
	];
}

