/**
 * Represents a GPS position in the world map.
 */
export class Geolocation {
	/**
	 * Latitude of the position in degrees.
	 */
	public latitude: number = null;

	/**
	 * Longitude of the position in degrees.
	 */
	public longitude: number = null;

	/**
	 * Altitude in meters.
	 */
	public altitude: number = null;

	public constructor(latitude: number, longitude: number, altitude: number = 0) {
		if (typeof latitude !== 'number' || typeof longitude !== 'number' || typeof altitude !== 'number') {
			throw new Error('Latitude, longitude and altitude must be numeric.');
		}

		this.latitude = latitude;
		this.longitude = longitude;
		this.altitude = altitude;
	}

	/**
	 * Parse array with geolocation points and return a geolocation object.
	 *
	 * @param array - Array where position 0 is the longitude value and position 1 is the latitude value.
	 * @returns Geolocation object instance built from the data received.
	 */
	public static fromArray(array: number[]): Geolocation {
		return new Geolocation(array[1], array[0]);
	}

	/**
	 * Parse JSON of geolocation data and return a geolocation object.
	 *
	 * @param data - Object received from API.
	 * @returns Geolocation object instance built from the data received.
	 */
	public static parse(data: any): Geolocation {
		if (!data) {
			return null;
		}

 		return new Geolocation(data.latitude, data.longitude, data.altitude || 0);
	}

	/**
	 * Check if a value is valid and can be used as a position.
	 *
	 * @param value - Value to check.
	 */
	public static isValid(value: Geolocation): boolean {
		return value && 
			typeof value.latitude === 'number' && value.latitude >= -90 && value.latitude <= 90 &&
			typeof value.longitude === 'number' && value.longitude >= -180 && value.longitude <= 180 &&
			typeof value.altitude === 'number';
	}

}
