import {Component, OnInit} from '@angular/core';
import {CdkDragDrop} from '@angular/cdk/drag-drop';
import {cloneDeep} from 'lodash-es';
import {TranslateModule} from '@ngx-translate/core';
import {IonicModule} from '@ionic/angular';
import {NgStyle} from '@angular/common';
import {UnoDynamicFormComponent} from '../../../../../../components/uno-forms/uno-dynamic-form/uno-dynamic-form.component';
import {AssetTypeService} from '../../../../services/asset-type.service';
import {AssetSubTypeService} from '../../../../services/asset-subtype.service';
import {AssetFormBlockService} from '../../../../services/asset-form-block.service';
import {ArrayUtils} from '../../../../../../utils/array-utils';
import {ServiceResponse} from '../../../../../../http/service-response';
import {App} from '../../../../../../app';
import {ScreenComponent} from '../../../../../../components/screen/screen.component';
import {Service} from '../../../../../../http/service';
import {ServiceList} from '../../../../../../http/service-list';
import {Session} from '../../../../../../session';
import {Modal} from '../../../../../../modal';
import {Locale} from '../../../../../../locale/locale';
import {UnoDynamicFormModule} from '../../../../../../components/uno-forms/uno-dynamic-form.module';
import {UserPermissions} from '../../../../../../models/users/user-permissions';
import {generateUUID, UUID} from '../../../../../../models/uuid';
import {AssetSubTypeLayout} from '../../asset-types-layout';
import {APAssetSubType} from '../../../../../../models/asset-portfolio/asset-sub-type';
import {APAssetFormTab} from '../../../../../../models/asset-portfolio/asset-form-tab';
import {UnoFormUtils} from '../../../../../../components/uno-forms/uno-dynamic-form/uno-form-utils';
import {UnoFormField} from '../../../../../../components/uno-forms/uno-dynamic-form/uno-form-field';
import {APAssetFormTabCard} from '../../../../../../models/asset-portfolio/asset-form-tab-card';
import {APAssetFormTabLayout, AssetFormBlockFieldLayout, AssetFormTabCardLayout} from '../../asset-template-layout';
import {APAssetFormBlock} from '../../../../../../models/asset-portfolio/asset-form-block';
import {APAssetFormBlockField} from '../../../../../../models/asset-portfolio/asset-form-block-field';
import {FormSortUtils} from '../../../../../../utils/form-sort-utils';
import {FormatDatePipe} from '../../../../../../pipes/format-date.pipe';import {UnoTitleComponent} from '../../../../../../components/uno/uno-title/uno-title.component';
import {UnoButtonComponent} from '../../../../../../components/uno/uno-button/uno-button.component';
import {UnoTabSectionComponent} from '../../../../../../components/uno/uno-tab/uno-tab-section/uno-tab-section.component';
import {UnoTabComponent} from '../../../../../../components/uno/uno-tab/uno-tab.component';

/**
 * Page used to edit and create asset (sub-)sub-types. Should receive the UUID of the asset (sub-...)sub-type to edit or a createMode value set to true.
 */
@Component({
	selector: 'asset-sub-type-edit-page',
	templateUrl: './asset-sub-type-edit.page.html',
	standalone: true,
	imports: [UnoTabComponent, UnoTabSectionComponent, UnoDynamicFormModule, UnoButtonComponent, UnoTitleComponent, IonicModule, NgStyle, TranslateModule, FormatDatePipe]
})
export class AssetSubTypeEditPage extends ScreenComponent implements OnInit {
	public get session(): any { return Session; }

	public get userPermissions(): any { return UserPermissions; }

	public get app(): any { return App; }

	/**
	 * This is the base layout of an asset sub-type and it must be accessible/editable on this component to implement posterior onChange callback methods on some fields to trigger actions on this component level.
	 */
	public assetSubTypeLayout: UnoFormField[] = null;
	
	public get formTabLayout(): any { return APAssetFormTabLayout; }
	
	/**
	 * This is the base layout of a form tab card and it must be accessible/editable on this component to implement posterior onChange callback methods on some fields to trigger actions on this component level.
	 */
	public formTabCardLayout: UnoFormField[] = null;
	
	public get formBlockFieldLayout(): any { return AssetFormBlockFieldLayout; }

	public permissions = [UserPermissions.ASSET_PORTFOLIO_ASSET_SUBTYPE_CREATE, UserPermissions.ASSET_PORTFOLIO_ASSET_SUBTYPE_EDIT];

	/**
	 * Object being edited in this screen.
	 */
	public subType: APAssetSubType = null;

	/**
	 * The depth level of this asset sub-type until it reaches the asset-type (level 0). 
	 *
	 * Used to organize its tabs, cards and field indexes.
	 * 
	 * Note: The first sub-type immediatly below an asset-type is of depth 1 and a sub-sub-type immediatly below the previous one, is of depth level 2.
	 */
	public depth: number = 1;

	/**
	 * The complete array of tabs with all the asset type, sub-type and (sub-...)sub-type(s) tabs merged.
	 */
	public tabs: APAssetFormTab[] = [];

	/**
	 * Flag to indicate if the page is in create mode.
	 */
	public createMode: boolean = false;

	/**
	 * Object editions' history.
	 */
	public history: any[] = [];


	public async ngOnInit(): Promise<void> {
		super.ngOnInit();

		this.initializeDynamicLayouts();

		this.createMode = false;
		this.tabs = [];
		this.subType = null;
		this.history = [];

		
		const data = App.navigator.getData();
		if (!data || !data.uuid && !data.createMode) {
			App.navigator.pop();
			return;
		}

		if (data.uuid && data.createMode) {
			throw Error('UUID and createMode cannot be used simultaneously.');
		}

		if (data.createMode === true) {
			this.createMode = true;
			this.subType = new APAssetSubType();
			this.subType.uuid = generateUUID();
			App.navigator.setTitle('create');
		} else {
			await this.loadData(data.uuid);
			App.navigator.setTitle(this.subType.name || 'edit');
		}
	}

	/**
	 * Initializes the necessary callbacks/properties on the imported layouts
	 */
	public initializeDynamicLayouts(): void {
		this.assetSubTypeLayout = cloneDeep(AssetSubTypeLayout);

		// When asset sub-type's type is changed, other properties like parent sub-type and tabs must be reset/reloaded.
		const parentTypeUuidField: UnoFormField = UnoFormUtils.getFormFieldByAttribute(this.assetSubTypeLayout, 'typeUuid');
		parentTypeUuidField.onOpen = () => {
			Modal.alert(Locale.get('warning'), Locale.get('updateWillCauseDataLoss'));
		};
		parentTypeUuidField.onChange = async(object: any, row: UnoFormField, value: any): Promise<void> => {
			object.parentSubTypeUuid = null;
			await this.loadTabs();
		};

		// When asset sub-type's sub-type parent is changed, tabs must be reset/reloaded.
		const parentSubTypeUuidField: UnoFormField = UnoFormUtils.getFormFieldByAttribute(this.assetSubTypeLayout, 'parentSubTypeUuid');
		parentSubTypeUuidField.onOpen = () => {
			Modal.alert(Locale.get('warning'), Locale.get('updateWillCauseDataLoss'));
		};
		parentSubTypeUuidField.onChange = async(object: any, row: UnoFormField, value: any): Promise<void> => {
			await this.loadTabs();
		};

		this.formTabCardLayout = cloneDeep(AssetFormTabCardLayout);

		const usesPrivateBlock = UnoFormUtils.getFormFieldByAttribute(this.formTabCardLayout, 'usesPrivateBlock');
		
		// Get the original info on card to compare with the one being edited since user can go back and forward with usePrivateBlock and we must set back block object accordingly.
		// Original object on DB may already have some private block set, possibly "erased" here on client on usesPrivateBlock changes, in which case we need to get it back again when "user changes his mind" to use a private block again.
		usesPrivateBlock.onChange = async(object: any, row: UnoFormField, value: any): Promise<void> => {
			// Reset card block
			object.blockUuid = null;
			object.block = null;

			let originalCard: APAssetFormTabCard;
			try {
				originalCard = await this.getTabCard(object.uuid);
			} catch {
				originalCard = null;
			}
			
			// If set to use a private block, prepare the form block to be filled with fields. If unset, set old form block selected (if any).
			if (value) {
				// If it was previously using a private block, get it from API. Otherwise, just load the selected block from the library (reusable ones).
				if (originalCard && originalCard.usesPrivateBlock && originalCard.blockUuid) {
					// Get original block stored from API
					object.block = await AssetFormBlockService.get(originalCard.blockUuid);
				} else {
					// Create a new block for this card
					this.initializePrivateCardBlock(object);
				}
			} else {
				// If it was previously using a reusable block, get it from API.
				if (originalCard && !originalCard.usesPrivateBlock && originalCard.blockUuid) {
					// Get original block stored from API
					object.blockUuid = originalCard.blockUuid;
					object.block = await AssetFormBlockService.get(originalCard.blockUuid);
				}
			}
		};

		const blockUuid = UnoFormUtils.getFormFieldByAttribute(this.formTabCardLayout, 'blockUuid');
		blockUuid.onChange = async(object: any, row: UnoFormField, value: any): Promise<void> => {
			object.block = await AssetFormBlockService.get(value);
		};
	}

	/**
	 * Get asset sub-type data from the API.
	 * 
	 * @param uuid - UUID of the object to load.
	 */
	public async loadData(uuid: UUID): Promise<void> {
		const requestAssetSubTyp = await Service.fetch(ServiceList.assetPortfolio.assetSubType.get, null, null, {uuid: uuid}, Session.session);
		this.subType = APAssetSubType.parse(requestAssetSubTyp.response.subType);

		await this.loadTabs();
		
		this.loadHistory(uuid);
	}

	/**
	 * Load the edit history from database.
	 */
	public async loadHistory(uuid: UUID): Promise<void> {
		const request = await Service.fetch(ServiceList.assetPortfolio.assetSubType.history.listUsers, null, null, {uuid: uuid}, Session.session);
		this.history = request.response.history;
	}

	/**
	 * Open a history entry in a modal to display the changes performed on a selected entry.
	 *
	 * @param historyId - History id of the entry from history list to be displayed.
	 */
	public async openHistoryEntry(historyId: number): Promise<void> {
		const request: ServiceResponse = await Service.fetch(ServiceList.assetPortfolio.assetSubType.history.get, null, null, {
			uuid: this.subType.uuid,
			historyId: historyId
		}, Session.session);
		const selectedEntry: APAssetSubType = APAssetSubType.parse(request.response.history);

		const buttons = [{success: false, label: 'close', color: 'primary'}];
		Modal.form(Locale.get('history'), selectedEntry, AssetSubTypeLayout, buttons, false);
	}

	/**
	 * Loads all the tabs needed to present the asset (sub-)sub-type form editor.
	 */
	public async loadTabs(): Promise<void> {
		// Reset tabs 
		this.tabs = await this.getTypeTabs(this.subType.typeUuid);

		// Get the tabs for this (sub-)sub-type
		this.tabs = this.tabs.concat(await this.getSubTypeTabs(this.subType.uuid));

		this.depth = 1;

		// Get the higher levels sub-type tabs
		if (this.subType.parentSubTypeUuid) {
			// Get the tabs for all the parents of this sub-sub-type (from the selected sub-sub-type, the lowest level, until reach the top parent) and add them to the array of arrays of tabs
			let subTypeParentId: UUID = this.subType.parentSubTypeUuid;
			do {
				// Get the sub-sub-type
				const reqAssetSubSubType = await Service.fetch(ServiceList.assetPortfolio.assetSubType.get, null, null, {uuid: subTypeParentId}, Session.session);
				const subSubType = APAssetSubType.parse(reqAssetSubSubType.response.subType);
	
				// Get tabs for this sub-sub-type and add them to the beggining of the array of arrays of tabs. Since this loop will fecth from the lowest level to the highest, items are added on the beggining of the array.
				this.tabs = this.tabs.concat(await this.getSubTypeTabs(subSubType.uuid));

				subTypeParentId = subSubType.parentSubTypeUuid;
				this.depth += 1;
			} while (subTypeParentId);
		}

		// Sort array of tabs by their indexes
		this.tabs = FormSortUtils.sortByIndexes(this.tabs);

		// Sort and merge cards and fields for every tab
		for (let i = 0; i < this.tabs.length; i++) {
			let cards: APAssetFormTabCard[] = await AssetTypeService.listTabCards(this.tabs[i].uuid);

			// Get the (sub-...)sub-type cards for this tab
			cards = cards.concat(await AssetSubTypeService.listTabCards(this.subType.uuid, this.tabs[i].uuid));

			// Get the higher levels sub-type cards
			if (this.subType.parentSubTypeUuid) {
				// Get all the tab cards for all the parents of this sub-sub-type (from the selected sub-sub-type until reach the top parent) and add them to the array of arrays of tab cards
				let subTypeParentId: UUID = this.subType.parentSubTypeUuid;
				do {
					// Get the sub-sub-type
					const reqAssetSubSubType = await Service.fetch(ServiceList.assetPortfolio.assetSubType.get, null, null, {uuid: subTypeParentId}, Session.session);
					const subSubType = APAssetSubType.parse(reqAssetSubSubType.response.subType);

					// Get tab cards for this sub-sub-type and add them to the beginning of the array of arrays of tab cards. Since this loop will fecth from the lowest level to the highest, items are added on the beggining of the array.
					cards = cards.concat(await AssetSubTypeService.listTabCards(subSubType.uuid, this.tabs[i].uuid));
					
					subTypeParentId = subSubType.parentSubTypeUuid;
				} while (subTypeParentId);
			}

			this.tabs[i].cards = FormSortUtils.sortByIndexes(cards);
		}
	}

	/**
	 * Get the card info from API.
	 * 
	 * @param cardUuid - The UUID of the card to get.
	 * @returns A form tab card info.
	 */
	public async getTabCard(cardUuid: UUID): Promise<APAssetFormTabCard> {
		const request = await Service.fetch(ServiceList.assetPortfolio.formTabCard.get, null, null, {uuid: cardUuid}, Session.session, false, false);
		return APAssetFormTabCard.parse(request.response.card);
	}

	/**
	 * Tells if a tab can be edited. 
	 * 
	 * Asset (sub-...)sub-type tabs can only be edited when editing the (sub-...)sub-type that created them.
	 * 
	 * @param tab - The tab to be validated.
	 * @param subTypeUuid - The (sub-...)-sub-type UUID used for validation of tab creator. Editors (sub-types) can only edit their tabs.
	 * @returns true if edition is allowed, false otherwise.
	 */
	public canEditTab(tab: APAssetFormTab, subTypeUuid: UUID): boolean {
		return tab.subTypeUuid === subTypeUuid;
	}

	/**
	 * Tells if a card can be edited.
	 * 
	 * Only private blocks can be edited and added fields to.
	 * 
	 * Asset (sub-...)sub-type cards can only be edited by the sub-type that created them.
	 * 
	 * @param card - The card to be validated.
	 * @returns true if edition is allowed, false otherwise.
	 */
	public canEditTabCard(card: APAssetFormTabCard): boolean {
		return card.subTypeUuid === this.subType.uuid;
	}

	/**
	 * Tells if a card block can be edited.
	 *
	 * Asset (sub-...)sub-type card blocks can only be edited when editing the (sub-...)sub-type that created them.
	 *
	 * Cards can be added to any tab of any level, even if this (sub-...)sub-type doesn't own it.
	 *
	 * @param card - The card to be validated the card belongs to.
	 * @returns true if edition is allowed, false otherwise.
	 */
	public canEditFormBlock(card: APAssetFormTabCard): boolean {
		// Only private blocks can be edited
		if (!card.usesPrivateBlock && card.block && !card.block.privateBlock) {
			return false;
		}

		// This sub-type can only edit its card blocks and fields
		return card.block.subTypeUuid === this.subType.uuid;
	}

	/**
	 * Loads the tabs of the asset type.
	 * 
	 * @param uuid - UUID of the asset type to load the tabs from.
	 */
	public async getTypeTabs(uuid: UUID): Promise<APAssetFormTab[]> {
		return await AssetTypeService.listTabs(uuid);
	}

	/**
	 * Loads the tabs of the asset (sub-...)sub-type.
	 * 
	 * @param uuid - UUID of the asset (sub-...)sub-type to load the tabs from.
	 */
	public async getSubTypeTabs(uuid: UUID): Promise<APAssetFormTab[]> {
		return await AssetSubTypeService.listTabs(uuid);
	}

	/**
	 * Update the object in the DB.
	 */
	public async update(stayOnPage: boolean = false): Promise<void> {
		// Check required fields of asset sub-type content
		if (!UnoDynamicFormComponent.requiredFilled(this.assetSubTypeLayout, this.subType)) {
			Modal.alert(Locale.get('error'), Locale.get('requiredFieldsError'));
			return;
		}

		// Check tabs forms required fields content
		for (const tab of this.tabs) {
			if (!UnoDynamicFormComponent.requiredFilled(this.formTabLayout, tab)) {
				Modal.alert(Locale.get('error'), Locale.get('requiredFieldsError'));
				return;
			}

			// Check tab cards forms required fields content
			for (const card of tab.cards) {
				if (!UnoDynamicFormComponent.requiredFilled(this.formTabCardLayout, card)) {
					Modal.alert(Locale.get('error'), Locale.get('requiredFieldsError'));
					return;
				}

				// Check block fields forms required fields content
				if (card.block) {
					for (const field of card.block.fields) {
						if (!UnoDynamicFormComponent.requiredFilled(this.formBlockFieldLayout, field)) {
							Modal.alert(Locale.get('error'), Locale.get('requiredFieldsError'));
							return;
						}
					}
				}
			}
		}

		const subType = structuredClone(this.subType);

		// All tabs must be sent top update, since a (sub-...)sub-type can add cards and other fields on other tabs then the ones it created but on API, only the fields regarding this asset sub-type (creator) will be updated.
		const tabs = structuredClone(this.tabs);

		try {
			const reqSubType = await Service.fetch(this.createMode ? ServiceList.assetPortfolio.assetSubType.create : ServiceList.assetPortfolio.assetSubType.update, null, null, subType, Session.session);
			if (!this.createMode) {
				this.subType = APAssetSubType.parse(reqSubType.response.subType);
			}

			if (!this.subType.typeUuid) {
				Modal.toast(Locale.get('updatedSuccessfully'));
			}
			await Service.fetch(ServiceList.assetPortfolio.formTab.assetSubType.set, null, null, {tabs: tabs, subTypeUuid: this.subType.uuid}, Session.session);
			if (!this.createMode && !stayOnPage) {
				await this.loadTabs();
			}

			Modal.toast(Locale.get(this.createMode ? 'createdSuccessfully' : 'updatedSuccessfully'));

			if (!stayOnPage) {
				App.navigator.pop();
				return;
			}
		} catch {
			Modal.alert(Locale.get('error'), Locale.get(this.createMode ? 'errorCreatingSubAssetType' : 'errorUpdatingSubAssetType'));
		}

		// Every time an update occurs, there is a history change.
		this.loadHistory(this.subType.uuid);
	}

	/**
	 * Delete the object on database.
	 */
	public async delete(): Promise<void> {
		const confirm = await Modal.confirm(Locale.get('confirm'), Locale.get('confirmDelete'));
		if (confirm) {
			await Service.fetch(ServiceList.assetPortfolio.assetSubType.delete, null, null, {uuid: this.subType.uuid}, Session.session);
			Modal.toast(Locale.get('deleteSuccessfully'));
			App.navigator.pop();
		}
	}

	/**
	 * Add a tab to the end of the asset sub-type form.
	 */
	public addTab(): void {
		if (this.subType.typeUuid) {
			const tab: APAssetFormTab = new APAssetFormTab();
			tab.uuid = generateUUID();
			tab.name = Locale.get('tab') + ' ' + this.tabs.length;
			tab.typeUuid = this.subType.typeUuid;
			tab.subTypeUuid = this.subType.uuid;
			this.tabs.push(tab);
			this.tabs = FormSortUtils.updateIndexes(this.tabs, this.subType.uuid, this.depth);
		} else {
			Modal.alert(Locale.get('error'), Locale.get('errorSelectAssetTypeFirst'));
		}
	}

	/**
	 * Remove a tab (only if owned by this sub-type) from the asset sub-type form.
	 * 
	 * @param tab - Tab to be removed.
	 */
	public async removeTab(tab: APAssetFormTab): Promise<void> {
		const confirm = await Modal.confirm(Locale.get('confirm'), Locale.get('confirmDelete'));
		if (confirm) {
			// Remove only the tabs for this asset (sub-)sub-type
			if (tab.subTypeUuid === this.subType.uuid) {
				const index = this.tabs.indexOf(tab);
				if (index !== -1) {
					this.tabs.splice(index, 1);
					this.tabs = FormSortUtils.updateIndexes(this.tabs, this.subType.uuid, this.depth);
				}
			}
		}
	}

	/**
	 * Move a tab on the list of tabs (only the sub-type tabs are available to move).
	 * 
	 * @param event - The drag and drop event.
	 */
	public moveTab(event: CdkDragDrop<APAssetFormTab[]>): any {
		ArrayUtils.move(this.tabs, event.previousIndex, event.currentIndex);
		this.tabs = FormSortUtils.updateIndexes(this.tabs, this.subType.uuid, this.depth);
	}

	/**
	 * Method used get the index of an item on ngFor loop.
	 *
	 * @param index - The index of the item being tracked.
	 * @returns - The index of the item being tracked.
	 */
	public trackBy(index: number): any {
		return index;
	}

	/**
	 * Add a card to a specific form tab.
	 * 
	 * Cards can be added to any tab of any level, even if this (sub-...)sub-type doesn't own the tab.
	 */
	public addTabCard(tab: APAssetFormTab): void {
		const card: APAssetFormTabCard = new APAssetFormTabCard();
		card.uuid = generateUUID();
		card.tabUuid = tab.uuid;
		card.subTypeUuid = this.subType.uuid;

		tab.cards.push(card);
		tab.cards = FormSortUtils.updateIndexes(tab.cards, this.subType.uuid, this.depth);
	}

	/**
	 * Remove a specific card from a specific tab.
	 * 
	 * @param tab - The tab where this card is, to be removed.
	 * @param card - Card to be removed.
	 */
	public removeTabCard(tab: APAssetFormTab, card: APAssetFormTabCard): void {
		const index = tab.cards.indexOf(card);
		if (index !== -1) {
			tab.cards.splice(index, 1);
			tab.cards = FormSortUtils.updateIndexes(tab.cards, this.subType.uuid, this.depth);
		}
	}

	/**
	 * Move card up in the list of cards from a given tab.
	 * 
	 * @param tab - The tab where this card is to be moved.
	 * @param card - Card to be moved.
	 */
	public moveTabCardUp(tab: APAssetFormTab, card: APAssetFormTabCard): void {
		const index = tab.cards.indexOf(card);
		if (index < tab.cards.length && index !== -1) {
			ArrayUtils.move(tab.cards, index, index + 1);
			tab.cards = FormSortUtils.updateIndexes(tab.cards, this.subType.uuid, this.depth);
		}
	}

	/**
	 * Move card down in the list of cards from a given tab.
	 * 
	 * @param tab - The tab where this card is to be moved.
	 * @param card - Tab to be moved.
	 */
	public moveTabCardDown(tab: APAssetFormTab, card: APAssetFormTabCard): void {
		const index = tab.cards.indexOf(card);
		if (index > 0) {
			ArrayUtils.move(tab.cards, index, index - 1);
			tab.cards = FormSortUtils.updateIndexes(tab.cards, this.subType.uuid, this.depth);
		}
	}

	/**
	 * Initializes a private block for a given card. And resets card properties regarding block.
	 * 
	 * @param card - The card to initialize the block for.
	 */
	public initializePrivateCardBlock(card: APAssetFormTabCard): void {
		card.block = new APAssetFormBlock();
		card.block.uuid = generateUUID();
		card.block.privateBlock = true;
		card.block.subTypeUuid = this.subType.uuid;

		card.blockUuid = card.block.uuid;
	}

	/**
	 * Add a field to the end of the array of block fields of a specific form card block.
	 * 
	 * @param card - The block to add the field to.
	 */
	public addFormBlockField(card: APAssetFormTabCard): void {
		if (!card.block) {
			this.initializePrivateCardBlock(card);
		}

		const field: APAssetFormBlockField = new APAssetFormBlockField();
		field.uuid = generateUUID();
		field.formBlockUuid = card.block.uuid;

		card.block.fields.push(field);
		card.block.fields = FormSortUtils.updateIndexes(card.block.fields);
	}
	
	/**
	 * Remove a field from a specific card block.
	 * 
	 * @param card - The card where this field exists (inside the card block), to be removed.
	 * @param field - Field to be removed.
	 */
	public removeFormBlockField(card: APAssetFormTabCard, field: APAssetFormBlockField): void {
		const index = card.block.fields.indexOf(field);
		if (index !== -1) {
			card.block.fields.splice(index, 1);
			card.block.fields = FormSortUtils.updateIndexes(card.block.fields);
		}
	}
	
	/**
	 * Move field up in the array of fields of a specific card block.
	 * 
	 * @param card - The card where this block field exists (inside the card block).
	 * @param field - Field to be moved.
	 */
	public moveFormBlockFieldUp(card: APAssetFormTabCard, field: APAssetFormBlockField): void {
		const index = card.block.fields.indexOf(field);
		if (index < card.block.fields.length && index !== -1) {
			ArrayUtils.move(card.block.fields, index, index + 1);
			card.block.fields = FormSortUtils.updateIndexes(card.block.fields);
		}
	}
	
	/**
	 * Move field down in the array of fields of a specific card block.
	 * 
	 * @param card - The card where this field exists (inside the card block).
	 * @param field - Field to be moved.
	 */
	public moveFormBlockFieldDown(card: APAssetFormTabCard, field: APAssetFormBlockField): void {
		const index = card.block.fields.indexOf(field);
		if (index > 0) {
			ArrayUtils.move(card.block.fields, index, index - 1);
			card.block.fields = FormSortUtils.updateIndexes(card.block.fields);
		}
	}
}
