import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {PlantListPage} from './screens/pipeline/plant/list/plant-list-page.component';
import {PlantEditPage} from './screens/pipeline/plant/edit/plant-edit.page';
import {PipelineListPage} from './screens/pipeline/pipeline/list/pipeline-list-page.component';
import {PipelineEditPage} from './screens/pipeline/pipeline/edit/pipeline-edit-page.component';
import {MOTCmpListPage} from './screens/mot/cmp/list/mot-cmp-list.page';
import {MOTCmpEditPage} from './screens/mot/cmp/edit/mot-cmp-edit.page';
import {MOTAcquisitionListPage} from './screens/mot/acquisition/list/mot-acquisition-list.page';
import {MOTAcquisitionEditPage} from './screens/mot/acquisition/edit/mot-acquisition-edit.page';
import {MOTAcquisitionChartPage} from './screens/mot/acquisition/chart/mot-acquisition-chart.page';
import {MOTAcquisitionChartComparisonPage} from './screens/mot/acquisition/chart-comparison/mot-acquisition-chart-comparison.page';
import {MOTExperimentListPage} from './screens/mot/experiment/list/mot-experiment-list.page';
import {MOTExperimentEditPage} from './screens/mot/experiment/edit/mot-experiment-edit.page';
import {LDSListPage} from './screens/leak-detection/lds/list/lds-list.page';
import {LDSEditPage} from './screens/leak-detection/lds/edit/lds-edit.page';
import {LDSChartPage} from './screens/leak-detection/lds/chart/lds-chart.page';

const routes: Routes = [
	{
		path: 'plant/list',
		component: PlantListPage
	},
	{
		path: 'plant/edit',
		component: PlantEditPage
	},
	{
		path: 'pipeline/list',
		component: PipelineListPage
	},
	{
		path: 'pipeline/edit',
		component: PipelineEditPage
	},
	{
		path: 'lds/list',
		component: LDSListPage
	},
	{
		path: 'lds/edit',
		component: LDSEditPage
	},
	{
		path: 'lds/chart',
		component: LDSChartPage
	},
	{
		path: 'cmp/list',
		component: MOTCmpListPage
	},
	{
		path: 'cmp/edit',
		component: MOTCmpEditPage
	},
	{
		path: 'acquisition/list',
		component: MOTAcquisitionListPage
	},
	{
		path: 'acquisition/edit',
		component: MOTAcquisitionEditPage
	},
	{
		path: 'acquisition/chart',
		component: MOTAcquisitionChartPage
	},
	{
		path: 'acquisition/chart-comparison',
		component: MOTAcquisitionChartComparisonPage
	},
	{
		path: 'experiment/list',
		component: MOTExperimentListPage
	},
	{
		path: 'experiment/edit',
		component: MOTExperimentEditPage
	},
	{
		path: '',
		redirectTo: 'plant/list',
		pathMatch: 'full'
	}
		
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class PipelineIntegrityRouterModule { }
