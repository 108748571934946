import {Pipe, PipeTransform} from '@angular/core';
import {RepairStatusLabel} from '../../../../models/repairs/repairs/repair-status';

/**
 * Pipe to map status number to a human readable format.
 */
@Pipe({
	name: 'repairStatusLabel',
	standalone: true
})
export class RepairStatusLabelPipe implements PipeTransform {
	public transform(n: number): string {
		return RepairStatusLabel.get(n);
	}
}
