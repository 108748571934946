/**
 * Possible alarm levels.
 *
 * Used to indicate the severity of the failure to the user.
 */
export enum LDSAlarmLevel {
	/**
	 * Low level alarm.
	 */
	LOW = 0,

	/**
	 * Medium level alarm.
	 */
	MEDIUM = 1,

	/**
	 * High level alarm.
	 */
	HIGH = 2
}

export const LDSAlarmLevelLabel = new Map<number, string> ([
	[LDSAlarmLevel.LOW, 'low'],
	[LDSAlarmLevel.MEDIUM, 'medium'],
	[LDSAlarmLevel.HIGH, 'high']
]);
