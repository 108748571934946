import {ServiceBodyType} from './type/service-body-type';
import {ServiceURLType} from './type/service-url-type';
import {ServiceResponseType} from './type/service-response-type';

/**
 * Service meta, stored all the metadata associated with a service.
 *
 * The data stored here is always the same for every request.
 */
export class ServiceMeta {
	/**
	 * Base URL of the API server used to for this service.
	 *
	 * Services may be accessed from different servers.
	 */
	public server: string = '';

	/**
	 * URL for this request
	 */
	public url: string = '';

	/**
	 * Type of the request (GET, POST, etc).
	 */
	public type: string = '';

	/**
	 * Indicated how to encode body data type.
	 */
	public bodyType: ServiceBodyType = null;

	/**
	 * How to encode the data send directly on the URL.
	 */
	public urlType: ServiceURLType = null;

	/**
	 * Response type of the API route.
	 */
	public responseType: ServiceResponseType = null;

	public constructor(server: string, url: string, type: string, bodyType: ServiceBodyType, urlType: ServiceURLType, responseType: ServiceResponseType, authenticate: boolean = true) {
		this.server = server;
		this.url = url;
		this.type = type;
		this.bodyType = bodyType;
		this.urlType = urlType;
		this.responseType = responseType;
	}

	/**
	 * Create a copy of this service meta.
	 *
	 * @returns ServiceMeta Clone of the service meta object.
	 */
	public clone(): ServiceMeta {
		return new ServiceMeta(this.server, this.url, this.type, this.bodyType, this.urlType, this.responseType);
	}
}
