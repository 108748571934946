import {Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation, forwardRef, OnDestroy} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {FullscreenControl, Map, Marker} from 'mapbox-gl';
import {TranslateModule} from '@ngx-translate/core';

import {IonicModule} from '@ionic/angular';
import {Geolocation} from '../../../models/geolocation';
import {App} from '../../../app';
import {Session} from '../../../session';
import {MapStyles} from '../../../theme/map-styles';
import {Environment} from '../../../../environments/environment';
import {Modal} from '../../../modal';
import {UnoFormFieldTypes} from '../../uno-forms/uno-dynamic-form/uno-form-field-types';
import {Locale} from '../../../locale/locale';
import {GeolocationUtils} from '../../../utils/geolocation-utils';
import {DOMUtils} from '../../../utils/dom-utils';
import {UnoButtonComponent} from '../../uno/uno-button/uno-button.component';

@Component({
	selector: 'geo-position',
	templateUrl: './geo-position.component.html',
	styleUrls: ['./geo-position.component.scss'],
	encapsulation: ViewEncapsulation.None,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => { return GeoPositionComponent; }),
			multi: true
		}
	],
	standalone: true,
	imports: [UnoButtonComponent, IonicModule, TranslateModule]
})
export class GeoPositionComponent implements OnInit, OnDestroy, ControlValueAccessor {
	@ViewChild('mapContainer', {static: true})
	public mapContainer: ElementRef = null;

	public get app(): any { return App; }

	/**
	 * Mapbox instance to display and control the map view.
	 */
	public map: Map = null;

	/**
	 * Marker for the user to input its position
	 */
	public marker: Marker = null;

	/**
	 * Allow the input to be disabled.
	 */
	@Input()
	public disabled = false;

	/**
	 * Position value stored in the component.
	 */
	public value: Geolocation = null;

	/**
	 * Method called when the data is changed.
	 */
	public onChange: (value: any)=> void = function(value) {};

	public ngOnInit(): void {
		this.map = new Map({
			accessToken: Environment.MAPBOX_TOKEN,
			container: this.mapContainer.nativeElement,
			style: Session.settings.mapStyle,
			center: [0, 0],
			trackResize: true,
			attributionControl: false
		});

		if (!this.disabled) {
			this.map.on('dblclick', (data: any) => {
				const position = data.lngLat;
				this.writeValue(new Geolocation(position.lat, position.lng));
			});
		}

		const marker = document.createElement('img');
		marker.src = '/assets/components/gps-input/pin.svg';
		marker.width = 30;
		marker.height = 37;

		this.marker = new Marker({draggable: !this.disabled, element: marker});
		this.marker.setLngLat([0, 0]);
		this.marker.on('dragend', (data: any) => {
			const position = this.marker.getLngLat();
			this.writeValue(new Geolocation(position.lat, position.lng));
		});
		this.marker.addTo(this.map);

		DOMUtils.waitUntilRendered(this.mapContainer.nativeElement).then(() => {
			this.map.resize();
			this.updatePosition();
		});
	}

	public ngOnDestroy(): void {
		this.map.remove();
	}

	/**
	 * Display modal to edit the coordinates of the geo position manually.
	 */
	public async showModal(): Promise<void> {
		const value = this.value ? new Geolocation(this.value.latitude, this.value.longitude, this.value.altitude) : new Geolocation(0, 0);

		try {
			await Modal.form(Locale.get('position'), value, [
				{
					label: 'latitude',
					attribute: 'latitude',
					type: UnoFormFieldTypes.NUMBER
				},
				{
					label: 'longitude',
					attribute: 'longitude',
					type: UnoFormFieldTypes.NUMBER
				},
				{
					label: 'altitude',
					attribute: 'altitude',
					type: UnoFormFieldTypes.NUMBER
				}
			]);
		} catch (e) {}

		this.writeValue(value);
	}

	/**
	 * Update map position, set the position of the marker and move the map.
	 */
	public updatePosition(): void {
		if (this.value) {
			this.marker.getElement().style.display = null;

			// Set the position of the marker and adjust the viewport
			this.marker.setLngLat([this.value.longitude, this.value.latitude]);
			this.map.flyTo({center: [this.value.longitude, this.value.latitude], zoom: 16});
		} else {
			// If no position selected in the map remove the marker
			this.marker.getElement().style.display = 'none';
		}
	}

	/**
	 * Change to fullscreen mode.
	 */
	public toggleFullscreen(): void {
		DOMUtils.setFullscreen(!DOMUtils.isFullscreen(), this.mapContainer.nativeElement);
	}


	/**
	 * Get position from GPS or browser location API.
	 */
	public async getGPSPosition(): Promise<void> {
		this.writeValue(await GeolocationUtils.getLocation());
	}

	/**
	 * Change map style between the user preference and SATELLITE, or between SATELLITE and VECTOR.
	 */
	public switchStyle(): void {
		const currentStyle = this.map.getStyle().sprite.replace('sprites', 'styles');
		if (currentStyle !== MapStyles.SATELLITE) {
			this.map.setStyle(MapStyles.SATELLITE);
		} else {
			if (Session.settings.mapStyle === MapStyles.SATELLITE) {
				this.map.setStyle(MapStyles.VECTOR);
			} else {
				this.map.setStyle(Session.settings.mapStyle);
			}
		}
	}

	public registerOnChange(onChange: any): void {
		this.onChange = onChange;
	}

	public writeValue(value: any): void {
		if (this.value !== null && !(this.value instanceof Geolocation)) {
			throw new Error('Value can only be null or Geolocation');
		}

		this.value = value;
		this.onChange(this.value);

		if (this.map.isStyleLoaded()) {
			this.updatePosition();
		} else {
			this.map.on('style.load', () => {
				this.updatePosition();
			});
		}
	}

	public setDisabledState(disabled: boolean): void {
		this.disabled = disabled;
		this.marker.setDraggable(!this.disabled);
	}

	public registerOnTouched(fn: any): void {}
}
