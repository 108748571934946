@if (this.value !== null) {
	<div style="display: flex; gap: 5px; flex-direction: column;">
		@for (tag of this.value.tags; track tag; let i = $index) {
			<atex-tag [disabled]="this.disabled" [ngModel]="tag" (onDelete)="this.removeTag(i)" [ngModelOptions]="{standalone: true}" slot="end"></atex-tag>
		}

		<uno-button (click)="this.addTag()">
			<uno-text slot="end">{{'add' | translate }}</uno-text>
		</uno-button>

	</div>

}
