import {addDays, addHours, addMonths, addYears} from 'date-fns';
import {DateFrequency} from '../models/date-frequency';

/**
 * Utils to manipulate date frequency.
 */
export class DateFrequencyUtils {
	/**
	 * Calculate the difference between two dates (a - b).
	 *
	 * @param a - Date A.
	 * @param b - Date B.
	 * @returns Difference in time between A and B.
	 */
	public static calc(a: Date, b: Date): DateFrequency {
		const diff = a.getTime() - b.getTime();
		const date = new Date(diff);

		// This is used to subtract the differences when in other timezones
		const offSetDate = new Date(0);

		return new DateFrequency(date.getFullYear() - offSetDate.getFullYear(), date.getMonth() - offSetDate.getMonth(), date.getDate() - offSetDate.getDate(), date.getHours() - offSetDate.getHours(), date.getMinutes() - offSetDate.getMinutes());
	}

	/**
	 * Add date frequency to date object.
	 *
	 * @param date - Date object to add frequency.
	 * @param freq - Date frequency object to be added to date.
	 * @returns New result date object.
	 */
	public static addDate(date: Date, freq: DateFrequency): Date {

		date = addDays(date, freq.days);
		date = addHours(date, freq.hours);
		date = addMonths(date, freq.months);
		date = addYears(date, freq.years);

		return date;
	}
}
