import {App} from '../../../app';

/**
 * Represents a menu options shown in the navigation bar.
 */
export class MenuOption {
	/**
	 * URL route of this menu option.
	 */
	public route: string = '';

	/**
	 * Name or URL of the icon to be applied.
	 */
	public icon: string = '';

	/**
	 * Label shown to the user alongside the menu icon.
	 */
	public label: string = '';

	/**
	 * Indicates if this options should be available.
	 */
	public available: boolean = false;

	/**
	 * Permissions required for this option to be accessible (only one permission is necessary).
	 */
	public permissions: number[] = [];

	/**
	 * Method to check if the options should be highlighted in the menu.
	 */
	public highlight?: (option: MenuOption)=> boolean;

	/**
	 * Data to provide to the menu route.
	 *
	 * This data is provided to the route on navigation.
	 */
	public data?: any;

	/**
	 * Check if a option should be highlighted.
	 *
	 * If the highlight method is defined it is used, otherwise check if the route is currently in use.
	 *
	 * @param option - Menu option to check.
	 */
	public static highlighted(option: MenuOption): boolean {
		if (option.highlight) {
			return option.highlight(option);
		}

		return App.navigator.has(option.route);
	}

}


