import {Component, OnInit, ViewChild} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';

import {UnoDynamicFormComponent} from '../../../../../../components/uno-forms/uno-dynamic-form/uno-dynamic-form.component';
import {ScreenComponent} from '../../../../../../components/screen/screen.component';
import {App} from '../../../../../../app';
import {UserPermissions} from '../../../../../../models/users/user-permissions';
import {Session} from '../../../../../../session';
import {Service} from '../../../../../../http/service';
import {ServiceList} from '../../../../../../http/service-list';
import {Modal} from '../../../../../../modal';
import {Locale} from '../../../../../../locale/locale';
import {UnoDynamicFormModule} from '../../../../../../components/uno-forms/uno-dynamic-form.module';
import {MotAcquisitionLayout} from '../mot-acquisition-layout';
import {UUID} from '../../../../../../models/uuid';
import {Acquisition} from '../../../../../../models/pipeline-integrity/mot/acquisition';
import {UnoButtonComponent} from '../../../../../../components/uno/uno-button/uno-button.component';
import {UnoContentComponent} from '../../../../../../components/uno/uno-content/uno-content.component';

@Component({
	selector: 'mot-acquisition-edit-page',
	templateUrl: 'mot-acquisition-edit.page.html',
	standalone: true,
	imports: [UnoContentComponent, UnoDynamicFormModule, UnoButtonComponent, TranslateModule]
})
export class MOTAcquisitionEditPage extends ScreenComponent implements OnInit {
	public get app(): any { return App; }

	public get layout(): any { return MotAcquisitionLayout; }

	public get userPermissions(): any { return UserPermissions; }

	public get session(): any { return Session; }

	@ViewChild('form', {static: false})
	public form: UnoDynamicFormComponent = null;

	public permissions = [UserPermissions.PIPELINE_INTEGRITY_MOT_ACQUISITION_CREATE, UserPermissions.PIPELINE_INTEGRITY_MOT_ACQUISITION_EDIT, UserPermissions.PIPELINE_INTEGRITY_MOT_ACQUISITION_DELETE];

	/**
	 * Acquisition being edited/created on this screen, data is fetched from the API.
	 */
	public acquisition: Acquisition = null;

	/**
	 * Flag to indicate if the screen is being used to create a new entry.
	 */
	public createMode: boolean = false;

	public async ngOnInit(): Promise<void> {
		super.ngOnInit();

		this.acquisition = null;
		this.createMode = false;
		App.navigator.setTitle('acquisition');
		const data = App.navigator.getData();

		if (!data || !data.uuid && !data.createMode) {
			App.navigator.pop();
			return;
		}

		if (data.uuid && data.createMode) {
			throw Error('UUID and createMode cannot be used simultaneously.');
		}

		// Create Acquisition.
		if (data.createMode) {
			this.createMode = true;
			this.acquisition = new Acquisition();
			this.acquisition.cmpUuid = data.cmpUuid;
		// Edit Acquisition, load specific acquisition
		} else {
			this.acquisition = await this.loadData(data.uuid);
		}
	}

	/**
	 * Load acquisition data by UUID from API
	 * 
	 * @param acquisitionUuid - Acquisition UUID
	 * @returns Data of the acquisition obtained from the API.
	 */
	public async loadData(acquisitionUuid: UUID): Promise<Acquisition> {
		const request = await Service.fetch(ServiceList.pipelineIntegrity.mot.acquisition.get, null, null, {uuid: acquisitionUuid}, Session.session);
		return Acquisition.parse(request.response.acquisition);
	}

	/**
	 * Create or update acquisition data in the API.
	 * 
	 * Checks if all required form fields are filled. Display toast on success.
	 *
	 * @param stayOnPage - Flag to indicate if the system should stay on the page after changes are saved.
	 */
	public async update(stayOnPage: boolean = false): Promise<void> {
		if (!this.form.requiredFilled()) {
			Modal.alert(Locale.get('error'), Locale.get('requiredFieldsError'));
			return;
		}

		await Service.fetch(this.createMode ? ServiceList.pipelineIntegrity.mot.acquisition.create : ServiceList.pipelineIntegrity.mot.acquisition.update, null, null, this.acquisition, Session.session);
		if (!stayOnPage) {
			App.navigator.pop();
		}

		Modal.toast(Locale.get('updatedSuccessfully'));
	}

	/**
	 * Delete acquisition.
	 */
	public async delete(): Promise<void> {
		const confirm = await Modal.confirm(Locale.get('confirm'), Locale.get('confirmDelete'));
		if (confirm) {
			await Service.fetch(ServiceList.pipelineIntegrity.mot.acquisition.delete, null, null, {uuid: this.acquisition.uuid}, Session.session);
			Modal.toast(Locale.get('deleteSuccessfully'));
			App.navigator.pop();
		}
	}

}
