import {UnoFormField} from '../../../../components/uno-forms/uno-dynamic-form/uno-form-field';
import {UnoFormFieldTypes} from '../../../../components/uno-forms/uno-dynamic-form/uno-form-field-types';
import {Service} from '../../../../http/service';
import {ServiceList} from '../../../../http/service-list';
import {Session} from '../../../../session';
import {InspectionWorkflowStepAction, InspectionWorkflowStepActionLabel} from '../../../../models/inspections/workflow/inspection-workflow-step-action';

export const InspectionWorkflowStepAdjacencyLayout: UnoFormField[] = [
	{
		label: 'uuid',
		attribute: 'uuid',
		type: UnoFormFieldTypes.UUID
	},
	{
		isActive: false,
		required: true,
		attribute: 'workflowUuid',
		label: 'workflow',
		type: UnoFormFieldTypes.OPTIONS,
		options: [{value: null, label: 'none'}],
		fetchOptions: function(object: any, row: UnoFormField) {
			Service.call(ServiceList.inspection.workflow.list, null, null, null, Session.session, (response, xhr) => {
				row.options = [{value: null, label: 'none'}];
				for (let i = 0; i < response.workflows.length; i++) {
					row.options.push({
						value: response.workflows[i].uuid,
						label: response.workflows[i].name
					});
				}
			});
		},
		sort: true
	},
	{
		required: false,
		attribute: 'bypassRequiredCheck',
		label: 'bypassRequiredCheck',
		type: UnoFormFieldTypes.CHECKBOX
	},
	{
		required: false,
		attribute: 'action',
		label: 'action',
		type: UnoFormFieldTypes.OPTIONS,
		sort: false,
		options: Object.values(InspectionWorkflowStepAction).map(function(value) {
			return {value: value, label: InspectionWorkflowStepActionLabel.get(value)};
		})
	},
	{
		required: true,
		attribute: 'originUuid',
		label: 'origin',
		type: UnoFormFieldTypes.OPTIONS,
		options: [{value: null, label: 'none'}]
	},
	{
		required: true,
		attribute: 'destinationUuid',
		label: 'destination',
		type: UnoFormFieldTypes.OPTIONS,
		options: [{value: null, label: 'none'}]
	},
	{
		required: false,
		attribute: 'analysesGaps',
		label: 'analysesGaps',
		type: UnoFormFieldTypes.CHECKBOX
	}
];
