import {UUID, UUIDIdentification} from 'src/app/models/uuid';

/**
 * Declares the model of a role as seen by the client application.
 */
export class Role extends UUIDIdentification {
	/**
	 * Name displayed when searching for the role.
	 */
	public name: string = '';

	/**
	 * Description used to describe user role.
	 */
	public description: string = '';

	/**
	 * List of permissions of the role.
	 */
	public permissions: number[] = [];

	/**
	 * UUID of the users with that role.
	 */
	public userUuids: UUID[] = [];

	/**
	 * Parse role data from object.
	 *
	 * @param data - Data to be parsed into object.
	 */
	public static parse(data: any): Role {
		const role = new Role();

		role.uuid = data.uuid;
		role.name = data.name;
		role.permissions = data.permissions;
		role.description = data.description;
		role.createdAt = new Date(data.createdAt);
		role.updatedAt = new Date(data.updatedAt);
		role.userUuids = data.userUuids || [];

		return role;
	}
}
