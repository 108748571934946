import {Component, Input, OnInit} from '@angular/core';
import {PopoverController, IonicModule} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';
import {Environment} from '../../../../../environments/environment';
import {NFC} from '../../data/nfc';
import {NFCHandler} from '../../data/nfc-handler';

/**
 * NFC input component is used to read NFC tags.
 *
 * A callback function must be provided to execute some component external processes on read data
 * 
 * Also a enabled variable may be provided to enable/disable scan.
 */
@Component({
	selector: 'nfc-reader',
	templateUrl: 'nfc-reader.component.html',
	standalone: true,
	imports: [IonicModule, TranslateModule]
})
export class NFCReaderComponent implements OnInit {
	public constructor(public controller: PopoverController) {}

	/**
	 * Callback for the external method to run when a NFC tag is detected.
	 *
	 * Received the NFC tag id as a parameter.
	 */
	@Input()
	public onRead: (data: string)=> void = null;
	
	/**
	 * Enables/Disables NFC scan.
	 */
	@Input()
	public enabled: boolean = true;

	/**
	 * Handler to read NFC data.
	 */
	public nfc: NFCHandler = null;

	public async ngOnInit(): Promise<void> {
		this.nfc = await NFC.read((data) => {
			const id = data.serialNumber;
			if (this.onRead !== null) {
				if (this.enabled) {
					this.onRead(id);
				}
			} else if (!Environment.PRODUCTION) {
				console.error('EQS: Callback method not supplied.');
			}
		});
	}

	/**
	 * Dismiss the modal controlled. Should close the modal screen instantly (if used in a modal).
	 */
	public dismiss(): void {
		try {
			this.controller.dismiss();
		} catch (e) {
			if (!Environment.PRODUCTION) {
				console.error('EQS: Error dismissing modal.', e);
			}
		}
	}
}
