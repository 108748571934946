import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {GeoJSONSource, LngLatLike, Map, Marker, NavigationControl} from 'mapbox-gl';
import {MapboxStyleDefinition, MapboxStyleSwitcherControl} from 'mapbox-gl-style-switcher';
import {Feature} from 'geojson';
import {UnoSearchBarResult} from 'src/app/components/uno/uno-searchbar-dropdown/uno-searchbar-dropdown.component';
import {ServiceMeta} from '../../../../../http/service-meta';
import {ServiceList} from '../../../../../http/service-list';
import {Session} from '../../../../../session';
import {Geolocation} from '../../../../../models/geolocation';
import {Environment} from '../../../../../../environments/environment';
import {App} from '../../../../../app';
import {ScreenComponent} from '../../../../../components/screen/screen.component';
import {UserPermissions} from '../../../../../models/users/user-permissions';
import {Service} from '../../../../../http/service';
import {ServiceResponse} from '../../../../../http/service-response';
import {Locale} from '../../../../../locale/locale';
import {MapStylesLabel} from '../../../../../theme/map-styles';
import {APAsset} from '../../../../../models/asset-portfolio/asset';
import {GeolocationUtils} from '../../../../../utils/geolocation-utils';
import {CSSUtils} from '../../../../../utils/css-utils';
import {ResizeDetector} from '../../../../../utils/resize-detector';
import {UnoSearchbarDropdownComponent} from '../../../../../components/uno/uno-searchbar-dropdown/uno-searchbar-dropdown.component';

export const MAPBOX_API_URL: string	 = 'https://api.mapbox.com';

@Component({
	selector: 'map-page',
	templateUrl: './assets-map-page.component.html',
	standalone: true,
	imports: [UnoSearchbarDropdownComponent]
})
export class AssetsMapPage extends ScreenComponent implements OnInit, OnDestroy {
	@ViewChild('mapContainer', {static: true})
	public mapContainer: ElementRef = null;

	public permissions = [UserPermissions.ASSET_PORTFOLIO_MAP];

	public get selfStatic(): any { return AssetsMapPage; }

	/**
	 * Assets to be displayed in the map.
	 */
	public assets: APAsset[] = [];

	/**
	 * The results to present on searchbar dropdown options from the assets list.
	 */
	public assetsSearchResults: UnoSearchBarResult[] = [];
	
	/**
	 * The results to present on searchbar dropdown options from the mapbox geocoding API.
	 */
	public placesSearchResults: UnoSearchBarResult[] = [];

	/**
	 * List of mapbox markers used to draw pointer in HTML mode.
	 */
	public markers: Marker[] = [];

	/**
	 * Mapboxgl instance to display and control the map view.
	 */
	public map: Map = null;

	/**
	 * Marker with the user GPS position.
	 */
	public marker: Marker = null;

	/**
	 * Current position in the map.
	 */
	public position: Geolocation = new Geolocation(0, 0, 0);

	/**
	 * Resize detector.
	 */
	public resizeDetector: ResizeDetector = null;

	public static filters = {
		/**
		 * Text used to filter list entries by their content.
		 */
		search: '',

		/**
		 * Search fields to be considered.
		 */
		searchFields: ['[ap_asset].name', '[ap_asset].tag', '[ap_asset].description', '[ap_asset].id']
	};

	public async ngOnInit(): Promise<void> {
		super.ngOnInit();

		App.navigator.setTitle('map');

		await this.createMap();

		await Promise.all([this.loadAssets(), this.loadUserPosition()]);
	}

	public ngOnDestroy(): void {
		super.ngOnDestroy();
		
		this.removeMapPointsLayer('assets');
		this.removeMapPointsLayer('places');
		this.map.removeSource('mapbox-dem');

		this.resizeDetector.destroy();
		this.map.remove();
	}

	/**
	 * Load asset data from database.
	 */
	public async loadAssets(): Promise<void> {
		const data = {
			search: AssetsMapPage.filters.search,
			searchFields: AssetsMapPage.filters.searchFields
		};

		const request: ServiceResponse = await Service.fetch(ServiceList.assetPortfolio.asset.listDetailed, null, null, data, Session.session);
		this.assets = request.response.assets.map((d: any) => { return APAsset.parse(d); });

		this.updateAssetMapLayer();
	}

	/**
	 * Get geoposition from GPS or browser location API.
	 */
	public async loadUserPosition(): Promise<void> {
		this.position = await GeolocationUtils.getLocation();

		this.marker.setLngLat([this.position.longitude, this.position.latitude]);
		this.map.flyTo({center: [this.position.longitude, this.position.latitude]});
	}

	/**
	 * Create and configure the map instance.
	 */
	public createMap(): Promise<void> {
		return new Promise((resolve, reject) => {
			this.map = new Map({
				accessToken: Environment.MAPBOX_TOKEN,
				container: this.mapContainer.nativeElement,
				style: Session.settings.mapStyle,
				projection: {name: 'globe'},
				zoom: 13,
				pitch: 0,
				bearing: 0,
				center: [this.position.longitude, this.position.latitude],
				attributionControl: false
			});

			// Click on the label of a point on the map
			this.map.on('click', 'assets-markers', (e) => {
				const feat = this.map.queryRenderedFeatures(e.point, {layers: ['assets-markers']});
				const uuid = feat[0].properties.uuid;
				App.navigator.navigate('/menu/asset-portfolio/asset/edit', {uuid: uuid});
			});

			this.map.on('style.load', () => {
				this.createLayers();
				this.createMarker();
				resolve(null);
			});


			// Click on a place label on the map
			this.map.on('click', 'places-markers', (e) => {
				this.map.flyTo({center: e.lngLat});
			});

			// Style switch controls
			const styles: MapboxStyleDefinition[] = [];
			MapStylesLabel.forEach(function(value, key) {
				styles.push({
					title: Locale.get(MapStylesLabel.get(key)),
					uri: key
				});
			});

			this.map.addControl(new MapboxStyleSwitcherControl(styles), 'top-right');
			this.map.addControl(new NavigationControl(), 'top-right');

			this.resizeDetector = new ResizeDetector(this.mapContainer.nativeElement, () => {
				this.map.resize();
			});
		});

	}

	/**
	 * Enable the map layers, should only be called after the map gets loaded.
	 *
	 * Should be called when the map style is changed (which causes a reset).
	 */
	public createLayers(): void {
		this.map.setFog({
			range: [8, 20],
			'horizon-blend': 0.3,
			color: '#FFFFFF',
			// @ts-ignore
			'high-color': ['interpolate', ['linear'], ['zoom'], 4, '#161B36', 7, '#add8e6'],
			'space-color': ['interpolate', ['linear'], ['zoom'], 4, '#0B1026', 7, '#d8f2ff'],
			'star-intensity': ['interpolate', ['linear'], ['zoom'], 4, 0.3, 7, 0.0]
		});

		this.map.addSource('mapbox-dem', {
			type: 'raster-dem',
			url: 'mapbox://mapbox.mapbox-terrain-dem-v1',
			tileSize: 512,
			maxzoom: 14
		});

		this.map.setTerrain({
			source: 'mapbox-dem',
			exaggeration: 1.0
		});

		this.createMapPointsLayer('assets', CSSUtils.getVariable('--special-blue-2'));
		this.createMapPointsLayer('places', CSSUtils.getVariable('--special-orange-2'));
	}

	/**
	 * Set map position.
	 *
	 * Centers the map on the geo position and add a marker on that location.
	 */
	public createMarker(): void {
		if (!this.map) {
			throw new Error('Map instance not available');
		}

		this.marker = new Marker();
		this.marker.setLngLat([this.position.longitude, this.position.latitude]);
		this.marker.addTo(this.map);

		this.map.flyTo({center: [this.position.longitude, this.position.latitude]});
	}

	/**
	 * Create a source and its layers for map.
	 *
	 * Will create 4 layers ("name-clusters", "name-clusters-count", "name-markers" and "name-point") and 1 data source "name".
	 * 
	 * @param name - Name of the map source and layers prefix.
	 * @param color - The color string for the point features on map.
	 */
	public createMapPointsLayer(name: string, color: string): void {
		if (this.map) {
			// GeoJSON data source used to draw points in WebGL mode.
			this.map.addSource(name, {
				type: 'geojson',
				data: {
					type: 'FeatureCollection',
					features: []
				},
				cluster: true,
				clusterMaxZoom: 14,
				clusterRadius: 30
			});
		
			// Base circle for clustered points
			this.map.addLayer({
				id: name + '-clusters',
				type: 'circle',
				source: name,
				filter: ['has', 'point_count'],
				paint: {
					'circle-color': ['step', ['get', 'point_count'], CSSUtils.getVariable('--success-normal'), 1e2, CSSUtils.getVariable('--warning-normal'), 1e3, CSSUtils.getVariable('--error-normal')],
					'circle-radius': ['step', ['get', 'point_count'], 20, 1e2, 25, 1e3, 30]
				}
			});
			
			// Count of the clustered points
			this.map.addLayer({
				id: name + '-clusters-count',
				type: 'symbol',
				source: name,
				filter: ['has', 'point_count'],
				layout: {
					'text-field': '{point_count_abbreviated}',
					'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
					'text-size': 13
				},
				paint: {'text-color': CSSUtils.getVariable('--light')}
			});
		
			// Individual feature text markers
			this.map.addLayer({
				id: name + '-markers',
				type: 'symbol',
				source: name,
				filter: ['!', ['has', 'point_count']],
				layout: {
					'text-field': '{text}',
					'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
					'text-size': 12,
					'text-offset': [0, 1],
					'text-anchor': 'top'
				},
				paint: {
					'text-color': CSSUtils.getVariable('--dark'),
					'text-halo-color': CSSUtils.getVariable('--light'),
					'text-halo-width': 1
				}
			});
		
			// Individual feature points
			this.map.addLayer({
				id: name + '-point',
				type: 'circle',
				source: name,
				filter: ['!', ['has', 'point_count']],
				paint: {
					'circle-color': color,
					'circle-radius': 5,
					'circle-stroke-width': 1,
					'circle-stroke-color': CSSUtils.getVariable('--light')
				}
			});
		}
	}

	/**
	 * Remove map point layers and source created using the createMapPointsLayer() method.
	 *
	 * @param name - Name of the layer.
	 */
	public removeMapPointsLayer(name: string): void {
		this.map.removeLayer(name + '-clusters');
		this.map.removeLayer(name + '-clusters-count');
		this.map.removeLayer(name + '-markers');
		this.map.removeLayer(name + '-point');
		this.map.removeSource(name);
	}

	/**
	 * Create points layer to draw the position markers of the assets.
	 *
	 * Uses GeoJSON points rendered, using WebGL (faster than DOM markers).
	 */
	public updateAssetMapLayer(): void {
		const features: Feature[] = [];

		this.assetsSearchResults = [];

		// Create a list of features from the assets retrieved from the server
		for (let i = 0; i < this.assets.length; i++) {
			if (Geolocation.isValid(this.assets[i].position)) {
				const position = this.assets[i].position;
				
				if (i < 10) {
					this.assetsSearchResults.push({value: [position.longitude, position.latitude], label: this.assets[i].tag || this.assets[i].name});
				}

				features.push({
					type: 'Feature',
					geometry: {
						type: 'Point',
						coordinates: [position.longitude, position.latitude]
					},
					id: this.assets[i].uuid,
					properties: {
						uuid: this.assets[i].uuid,
						text: this.assets[i].tag
					}
				});
			}
		}

		const assetsSource: GeoJSONSource = this.map.getSource('assets') as GeoJSONSource;
		if (this.map && assetsSource) {
			assetsSource.setData({
				type: 'FeatureCollection',
				features: features
			});
		}
	}

	/**
	 * Create places layer to draw the position markers of the places found on search.
	 *
	 * Uses GeoJSON points rendered, using WebGL (faster than DOM markers).
	 */
	public updatePlacesMapLayer(places: GeoJSON.Feature[] = []): void {
		this.placesSearchResults = [];
		
		for (let i = 0; i < places.length; i++) {
			places[i].properties.text = places[i]['text'];

			if (i < 10) {
				this.placesSearchResults.push({value: (places[i].geometry as GeoJSON.Point).coordinates, label: places[i]['text']});
			}
		}

		const placesSource: GeoJSONSource = this.map.getSource('places') as GeoJSONSource;
		if (this.map && placesSource) {
			placesSource.setData({
				type: 'FeatureCollection',
				features: places
			});
		}
	}

	/**
	 * Update the assets list with the search used term.
	 *
	 * @param coordinates - The chosen option value coordinates, from the search dropdown. It is expected to receive and a single feature coordinates ([long, lat]);
	 */
	public async onFilterChange(coordinates: LngLatLike): Promise<void> {
		// Reload data when searchbar is cleaned
		if (!coordinates) {
			AssetsMapPage.filters.search = '';
			
			// Reset places position markers
			this.updatePlacesMapLayer();
		
			// Reload filtered assets and reset assets position markers
			await this.loadAssets();
		} else if (this.map) {
			this.map.flyTo({center: coordinates});
		}
	}

	/**
	 * Fetch the mapbox search results from the mapbox API, using a serach string.
	 * 
	 * @param searchValue - The text to search for on mapbox API.
	 * @returns An array of mapbox features to render on map.
	 */
	public async getPlacesSearchResult(searchValue: string = ''): Promise<Feature[]> {
		let features: Feature[] = [];

		// Only perform the search if there is text to search for
		if (searchValue.length > 0) {
			const mapboxPlacesSearchMeta: ServiceMeta = structuredClone(ServiceList.mapbox.places);
			mapboxPlacesSearchMeta.url = mapboxPlacesSearchMeta.url + '/' + encodeURI(AssetsMapPage.filters.search) + '.json';
			
			// Linter must be ignored to accept access token as it is
			// eslint-disable-next-line
			const request: ServiceResponse = await Service.fetch(mapboxPlacesSearchMeta, {access_token: Environment.MAPBOX_TOKEN, limit: 10}, null, null, Session.session);
			features = request.response.features;
		}

		return features;
	}

	/**
	 * Build the options to display on searchbar dropdown.
	 * 
	 * @param searchValue - The text of search filter.
	 * @returns An array of uno searchbar results to render on searchbar dropdown.
	 */
	public async getSearchResults(searchValue: string = ''): Promise<UnoSearchBarResult[]> {
		AssetsMapPage.filters.search = searchValue;

		// Load mapbox places features
		const placesFeatures: Feature[] = AssetsMapPage.filters.search && AssetsMapPage.filters.search.length > 0 ? await this.getPlacesSearchResult(searchValue) : [];
		this.updatePlacesMapLayer(placesFeatures);
		
		// Reload filtered assets and reset assets position markers
		await this.loadAssets();

		return this.assetsSearchResults.concat(this.placesSearchResults);
	};


}
