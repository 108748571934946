import {UUID} from './uuid';

/**
 * Type of resource, depending on the type the user is stored in a different location in the resource server.
 */
export const ResourceType = {
	FILE: 'file',
	IMAGE: 'image'
};

/**
 * Resource represents data stored in the resource server.
 */
export class Resource {
	/**
	 * UUID of the resource.
	 */
	public uuid: UUID = null;

	/**
	 * File format used to store the data. (e.g. jpeg, png, mkv)
	 */
	public format: string = '';

	/**
	 * Textual description of the resource file.
	 */
	public description: string = '';

	/**
	 * Type of the resource stored (e.g. file, image)
	 */
	public type: string = ResourceType.IMAGE;

	public constructor(uuid: UUID, format: string) {
		this.uuid = uuid;
		this.format = format;
	}

	/**
	 * Parse JS object data into a valid Resource object.
	 *
	 * @param data - Data to parse into a resource object.
	 * @returns Resource object parsed from data. Return null when no data is provided.
	 */
	public static parse(data: any): Resource {
		if (!data) {
			return null;
		}

		const resource = new Resource(data.uuid, data.format);

		if (data.description) {
			resource.description = data.description;
		}

		if (data.type) {
			resource.type = data.type;
		}

		return resource;
	}

	/**
	 * Parse multiple resources stored in an array object.
	 *
	 * @param data - Object to parse with resources.
	 */
	public static parseArray(data: any): Resource[] {
		const resources: Resource[] = [];

		if (data instanceof Array) {
			for (let i = 0; i < data.length; i++) {
				resources.push(Resource.parse(data[i]));
			}
		}

		return resources;
	}
}
