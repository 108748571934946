import {UnoFormField} from '../../../../components/uno-forms/uno-dynamic-form/uno-form-field';
import {UnoFormFieldTypes} from '../../../../components/uno-forms/uno-dynamic-form/uno-form-field-types';

export const LdsChannelLayout: UnoFormField[] = [
	{
		required: true,
		attribute: 'tag',
		label: 'tag',
		type: UnoFormFieldTypes.TEXT
	},
	{
		required: false,
		attribute: 'offset',
		label: 'offset',
		type: UnoFormFieldTypes.NUMBER
	}
];
