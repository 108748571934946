import {AfterViewInit, Component, Input, OnChanges, SimpleChanges, ViewChild} from '@angular/core';
import {ZXingScannerComponent, ZXingScannerModule} from '@zxing/ngx-scanner';
import {BarcodeFormat} from '@zxing/library';
import {PopoverController, IonicModule} from '@ionic/angular';

import {Locale} from '../../../../locale/locale';
import {Environment} from '../../../../../environments/environment';
import {Modal} from '../../../../modal';

/**
 * QR input component is used to read QR code tags.
 *
 * A 'callback' function must be provided to execute some component external processes on read data
 * 
 * Also, a 'enabled' variable may be provided to enable/disable scan
 */
@Component({
	selector: 'qr-reader',
	templateUrl: 'qr-reader.component.html',
	standalone: true,
	imports: [ZXingScannerModule, IonicModule]
})
export class QRReaderComponent implements AfterViewInit, OnChanges {
	@ViewChild('scanner', {static: true})
	public scanner: ZXingScannerComponent = null;

	/**
	 * Format enabled for the scanner, QR only.
	 */
	public formats = [BarcodeFormat.QR_CODE];

	/**
	 * Callback for the external method to run when a QR code is detected.
	 *
	 * Receives the QR code content as a parameter.
	 */
	@Input()
	public onRead: (data: string)=> void = null;
	
	/**
	 * Enables/Disables QR scan.
	 */
	@Input()
	public enabled: boolean = true;

	/**
	 * List of cameras available to be used with the scanner.
	 */
	public cameras: MediaDeviceInfo[] = [];

	/**
	 * Camera selected to scan the QR tag.
	 */
	public cameraSelected: number = 0;

	/**
	 * Indicates if the device is torch compatible.
	 */
	public torchCompatible = true;

	public constructor(public controller: PopoverController) {}

	public async ngAfterViewInit(): Promise<void> {
		// @ts-ignore
		this.scanner.initAutostartOn();
		await this.scanner.askForPermission();

		this.scanner.scanSuccess.subscribe((data: string) => {
			// If the scanner is not enabled ignore read
			if (this.enabled) {
				if (!Environment.PRODUCTION) {
					console.log('EQS: Input QR read code data:', data);
				}

				if (data) {
					if (this.onRead) {
						this.onRead(data);
						this.dismiss();
					} else if (!Environment.PRODUCTION) {
						console.error('EQS: Callback method not supplied.');
					}
				}
			}
		});

		if (!Environment.PRODUCTION) {
			console.log('EQS: QR scanner started.', this.scanner);
		}
	}

	public ngOnChanges(changes: SimpleChanges): void {
		this.scanner.enable = this.enabled;
	}

	/**
	 * Select next camera.
	 */
	public nextCamera(): void {
		this.cameraSelected = (this.cameraSelected + 1) % this.cameras.length;
		this.scanner.device = this.cameras[this.cameraSelected];

		Modal.toast(Locale.get('cameraSelected', {name: this.cameras[this.cameraSelected].label}));
	}

	/**
	 * Toggle torch (if there is one available).
	 */
	public toggleTorch(): void {
		this.scanner.torch = !this.scanner.torch;
	}

	/**
	 * Callback method when cameras are found.
	 */
	public camerasFound(cameras: any[]): void {
		this.cameras = cameras;

		if (!Environment.PRODUCTION) {
			console.log('EQS: Cameras found for the QR scanner:', cameras);
		}
	}

	/**
	 * Show an error when cameras are not found.
	 */
	public camerasNotFound(): void {
		Modal.alert(Locale.get('error'), Locale.get('errorCameraNotFound'));
	}

	/**
	 * Dismiss the modal controlled. Should close the modal screen instantly (if used as a modal).
	 */
	public async dismiss(): Promise<void> {
		this.enabled = false;
		this.scanner.enable = false;

		await this.controller.dismiss();
	}
}
