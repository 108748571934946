<div style="height: 110px; margin: 10px;">
	<uno-searchbar style="width: 100%;" [value]="selfStatic.filters.search" (valueChange)="this.onSearchChange($event)"></uno-searchbar>

	<uno-filter-bar [filters]="selfStatic.filters" [options]="selfStatic.filterOptions" (onChange)="this.onFilterChange($event);"></uno-filter-bar>
</div>

<ng-template #itemTemplate let-observation="item">
	<uno-list-item (click)="app.navigator.navigate('/menu/observations/edit', {uuid: observation.uuid});">
		<uno-list-item-label>
			<h2>{{observation.name}}</h2>
			<h3>{{observation.description}}</h3>
			<p>{{observationStatusLabel.get(observation.status) | translate}}</p>
		</uno-list-item-label>
	</uno-list-item>
</ng-template>

<uno-content [ngStyle]="{height: 'calc(100% - 130px)'}">
	<uno-list [handler]="this.handler" [itemTemplate]="itemTemplate"></uno-list>
</uno-content>
