import {UUID} from '../../uuid';
import {Geolocation} from '../../geolocation';

/**
 * Plants define the highest hierarchy of MOT module. It is the factory/area/place where the MOT is installed.
 * 
 * Asset attribute refers to an asset in asset porfolio, if the client defines that relation and has the asset porfolio module.
 */
export class Plant {

	/**
	 * UUID of the plant.
	 */
	public uuid: UUID = null;

	/**
	 * Name of the plant.
	 */
	public name: string = '';

	/**
	 * Tag of the plant.
	 */
	public tag: string = '';

	/**
	 * Description of the plant.
	 */
	public description: string = '';

	/**
	 * GPS position of the plant.
	 */
	public position: Geolocation = null;

	/**
	 * Asset UUID of the plant.
	 */
	public assetUuid: UUID = null;

	/**
	 * Parse the data of an API retrieved plant object.
	 *
	 * @param data - Plant object to parse data
	 */
	public static parse(data: any): Plant {
		const plant = new Plant();

		plant.uuid = data.uuid;
		plant.name = data.name;
		plant.description = data.description;
		plant.assetUuid = data.assetUuid;
		plant.tag = data.tag;

		// GPS Position
		if (data.position) {
			plant.position = Geolocation.parse(data.position);
		}

		return plant;
	}
}

