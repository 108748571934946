import {Service} from '../../../../http/service';
import {ServiceList} from '../../../../http/service-list';
import {Session} from '../../../../session';
import {AtexInspectionFormField} from '../data/master-data/atex-inspection-fields';

export class AtexInspectionFieldsService {
	/**
	 * Load list of inspection fields from the API.
	 */
	public static async get(): Promise<{[key: string]: AtexInspectionFormField}> {
		const request = await Service.fetch(ServiceList.atexInspection.fieldsGet, null, null, null, Session.session);

		const data: {[key: string]: AtexInspectionFormField} = {};
		for (const i in request.response) {
			data[i] = AtexInspectionFormField.parse(request.response[i]);
		}

		return data;
	}
}
