
/**
 * Contains the possible type of alarms for calendar event actions.
 */
export const CalendarEventAlarmType = {
	/**
	 * Send alarm by Email.
	 */
	EMAIL: 0,

	/**
	 * Send alarm by SMS.
	 */
	SMS: 1
};


export const CalendarEventAlarmTypeLabel = new Map<number, string> ([
	[CalendarEventAlarmType.EMAIL, 'email'],
	[CalendarEventAlarmType.SMS, 'sms']
]);
