<!-- Mobile -->
@if (app.device.isMobile()) {
	<ion-list>
		@for (section of this.groups; track section) {
			@if (session.hasPermissions(section.permissions)) {
				@if (section.label) {
					<ion-title class="ion-margin-vertical" color="primary">{{section.label | translate}}</ion-title>
				}

				@for (option of section.options; track option) {
					@if (session.hasPermissions(option.permissions)) {
						<ion-item (click)="app.navigator.navigate(option.route, option.data)" button="true">
							<ion-icon name="{{option.icon}}" src="{{option.icon}}" style="padding: 10px;"></ion-icon>
							<ion-label>{{option.label | translate }}</ion-label>
							<div slot="end">
								@if (option.badge !== null) {
									<ion-badge color="primary" mode="ios">{{option.badge | async}}</ion-badge>
								}
								<ion-icon name="caret-forward-outline"></ion-icon>
							</div>
						</ion-item>
					}
				}
			}
		}
	</ion-list>
}
<!-- Desktop -->
@if (app.device.isDesktop()) {
	@for (section of this.groups; track section) {
		@if (session.hasPermissions(section.permissions)) {
			<ion-card-content>
				<ion-title color="primary">{{section.label | translate}}</ion-title>
				<ion-grid>
					<ion-row>
						@for (option of section.options; track option) {
							@if (session.hasPermissions(option.permissions)) {
								<ion-col size="4">
									<uno-card icon="{{option.icon}}" title="{{option.label | translate }}" (click)="app.navigator.navigate(option.route, option.data)" badge="{{option.badge | async}}">
									</uno-card>
								</ion-col>
							}
						}
					</ion-row>
				</ion-grid>
			</ion-card-content>
		}
	}
}
