import {Component, OnInit} from '@angular/core';
import {App} from 'src/app/app';
import {ScreenComponent} from 'src/app/components/screen/screen.component';
import {UserPermissions} from 'src/app/models/users/user-permissions';

/**
 * Page used to edit dl50 inspections.
 */
@Component({
	selector: 'dl50-inspections-edit-page',
	templateUrl: 'dl50-inspections-edit.page.html',
	standalone: true,
	imports: []
})
export class DL50InspectionsEditPage extends ScreenComponent implements OnInit {
	/**
	 * The permissions to access this screen.
	 */
	public permissions = [UserPermissions.DL50_INSPECTIONS_EDIT];

	public ngOnInit(): void {
		super.ngOnInit();

		App.navigator.setTitle('inspection');
	}
}
