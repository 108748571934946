/**
 * Service content type indicates the type of data returned by the API call.
 */
// eslint-disable-next-line no-shadow
export enum ServiceResponseType {
	/**
	 * No response content is expected from this API.
	 */
	NONE,

	/**
	 * Response is a text value, in this case no parse is done to the response.
	 */
	TEXT,

	/**
	 * JSON response received as text, if possible parsed to JSON.
	 */
	JSON_TEXT,

	/**
	 * JSON strict, received as text and parsed automatically.
	 */
	JSON,

	/**
	 * Binary data response presented as an arraybuffer.
	 */
	ARRAYBUFFER
}
