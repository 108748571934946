<!-- The template for each day's cell -->
<ng-template #unoCellTemplate let-day="day" let-locale="locale">
	<!-- If the weekday is an even one -->
	<div class="uno-calendar-week-day" [ngClass]="{'uno-calendar-even-weekday': this.evenWeekday(day.date)}">

		<!-- The top part of the day cell, containing the number of events and day -->
		<div class="cal-cell-top">
			@if (day.badgeTotal > 0) {
				<span class="cal-day-badge" [ngStyle]="{'background-color': (this.daySelected && compareDates(day.date, this.viewDate) === 0 || day.isToday) ? 'var(--brand-primary)': 'var(--gray-8)'}">{{day.badgeTotal}}</span>
			}
			<span class="cal-day-number">{{day.date.getDate()}}</span>
		</div>

		<!-- The bottom part of the day cell containing the circles with each subtypes event count for that day -->
		@if (day.date.getMonth) {
			<div class="uno-calendar-event-numbers-container">
				@for (color of CalendareventSubtypeColors | keyvalue; track color; let i = $index) {
					<span class="uno-calendar-event-number" [ngStyle]="{'background-color': color.value}" [ngClass]="{'uno-calendar-hidden': this.eventsByDay[addHours(day.date, -day.date.getTimezoneOffset() / 60).toISOString().split('T')[0]] === undefined ? true: this.eventsByDay[addHours(day.date, -day.date.getTimezoneOffset() / 60).toISOString().split('T')[0]][i] === 0}">{{this.eventsByDay[addHours(day.date, -day.date.getTimezoneOffset() / 60).toISOString().split('T')[0]] !== undefined ? (this.eventsByDay[addHours(day.date, -day.date.getTimezoneOffset() / 60).toISOString().split('T')[0]][i] > this.badgeLimit ? (this.badgeLimit + '+'): this.eventsByDay[addHours(day.date, -day.date.getTimezoneOffset() / 60).toISOString().split('T')[0]][i]): 0}}</span>
				}
			</div>
		}
	</div>
</ng-template>

<!-- Dropdown template for the event list sort -->
@if (this.eventListSortIconExpanded) {
	<div>
		<!-- If the background is clicked close the dropdown -->
		<div class="uno-calendar-sort-dropdown-background" (click)="this.toggleEventListSort()"> </div>
		<!-- The dropdown itself -->
		<div class="uno-calendar-event-list-sort-dropdown" [attr.aria-label]="'event-list-sort-dropdown'">
			<!-- The ascending Option on the dropdown -->
			<div class="uno-calendar-event-list-sort-option" (click)="this.changeSort('ascending')">
				<input style="width:16px; height:16px;accent-color: var(--brand-primary)" type="radio" id="ascending" name="sort">
				<label style="padding-left:5px" for="ascending">{{'Ascending' | translate}}</label>
			</div>
			<!-- The ascending Option on the dropdown -->
			<div class="uno-calendar-event-list-sort-option" (click)="this.changeSort('descending')">
				<input style="width:16px; height:16px;accent-color: var(--brand-primary)" type="radio" id="descending" name="sort">
				<label style="padding-left:5px" for="descending">{{'Descending' | translate}}</label>
			</div>
		</div>
	</div>
}

<!-- Template for the event list when a day with events is clicked -->
<ng-template #unoEventsListTemplate let-events="events" let-eventClicked="eventClicked" let-isOpen="isOpen" let-validateDrag="validateDrag">
	<!-- If the day is open, open the list, also has a collapse animation -->
	@if (isOpen) {
		<div class="cal-open-day-events" role="application" [@collapse]>
			<!-- The title containing the day on the event list -->
			<div class="uno-calendar-event-list-title" [ngStyle]="{'background-color': this.theme === 'dark' ? 'var(--gray-11)': 'var(--special-blue-3)'}">
				<span>{{viewDate.toLocaleString(locale.code, {day:'2-digit', month:'long', year:'numeric'})}}</span>
				<uno-icon [src]="!this.eventListSortIconExpanded ? 'assets/components/global-calendar/sortby-icon.svg': 'assets/components/global-calendar/close-icon.svg'" [color]="'var(--brand-primary)'" [width]="32" [height]="32" (click)="this.toggleEventListSort()" [attr.aria-label]="'event-list-sort'"></uno-icon>
			</div>
			<!-- The container for the event list options -->
			<cdk-virtual-scroll-viewport [itemSize]="46" [ngStyle]="{'height': (events.length < 5 ? events.length * 46: 200) + 'px', 'width': '100%'}">
				<!-- Each option on the event list -->
				<div class="uno-calendar-event-list-option" [ngStyle]="{'border-left': '4px solid ' + event.color.primary, 'background-color':i%2 ? 'var(--gray-12)': 'var(--gray-13)'}" *cdkVirtualFor="let event of events; let i = index">
					<!-- The left side of the event list option -->
					<div class="uno-calendar-event-list-option-left-side" [ngStyle]="{'cursor': this.hasActionAssociated(event) ? 'pointer': 'auto'}" (click)="this.occurrenceClicked({event: event})">
						<span class="uno-calendar-event-list-option-title" style="flex: 1 0 40px !important;">
							<img src="{{priorityIcon.get(event.meta.occurrence.priority)}}">
							{{event.title}}
						</span>
						@if (event.meta.actionSubtype === this.calendarEventSubtypes.ASSET_DYNAMIC_INSPECTION || event.meta.actionSubtype === this.calendarEventSubtypes.ASSET_ATEX_INSPECTION) {
							<span class="uno-calendar-event-list-option-title">{{this.assetInfo.get(event.meta.assetUuid)?.name}}</span>
							<span class="uno-calendar-event-list-option-title">{{this.assetInfo.get(event.meta.assetUuid)?.tag}}</span>
						}
						@else if (event.meta.actionSubtype === this.calendarEventSubtypes.REPAIR_TEMPORARY_INSPECTION || event.meta.actionSubtype === this.calendarEventSubtypes.REPAIR_DEFINITIVE_REPAIR) {
							<span class="uno-calendar-event-list-option-title">{{this.assetInfo.get(this.repairAssetUuid.get(event.meta.repairUuid)?.asset)?.name}}</span>
							<span class="uno-calendar-event-list-option-title">{{this.assetInfo.get(this.repairAssetUuid.get(event.meta.repairUuid)?.asset)?.tag}}</span>
						}
						@else {
							<span class="uno-calendar-event-list-option-title"></span>
							<span class="uno-calendar-event-list-option-title"></span>
						}

						<!-- The starting and ending time of the event -->
						<span class="uno-calendar-event-list-option-title" style="flex: 1 0 80px !important;white-space: normal;">{{event.start.toLocaleString(locale.code, {hour: "2-digit", minute: "2-digit"})}} - {{event.end.toLocaleString(locale.code, {hour: "2-digit", minute: "2-digit"})}}</span>
						<!-- The status of the event -->
						<span class="uno-calendar-event-list-option-status" [ngStyle]="{'height': '25px', 'visibility': (this.hasActionAssociated(event) || event.meta.occurrence.status > 1) ? 'visible': 'hidden', 'background-color': (event.meta.occurrence.status > 1 ? calendarEventStatusBackground.get(event.meta.occurrence.status) : calendarEventStatusBackground.get(calendarEventStatus.TODO)), 'color' :'var(--dark)'}">
							{{(event.meta.occurrence.status > 1 ? calendarEventStatusLabel.get(event.meta.occurrence.status): calendarEventStatusLabel.get(calendarEventStatus.TODO)) | translate | uppercase}}
						</span>
					</div>
					<!-- The event actions -->
					<mwl-calendar-event-actions [event]="event"></mwl-calendar-event-actions>
				</div>
			</cdk-virtual-scroll-viewport>
		</div>
	}
</ng-template>

<!-- The month view -->
@if (this.displayMode === 'month') {
	<mwl-calendar-month-view [cellTemplate]="unoCellTemplate" [openDayEventsTemplate]="unoEventsListTemplate" [locale]="locale.code" [events]="this.events" [viewDate]="viewDate" [refresh]="refresh" (dayClicked)="dayClicked($event.day.date, $event.day.events)" [activeDayIsOpen]="this.daySelected" (eventClicked)="occurrenceClicked($event)"></mwl-calendar-month-view>
}

<!-- The day view -->
@if (this.displayMode === 'day') {
	<div class="uno-calendar-day" style="height: 100%">
		<div style="width: calc(100% - 24px); height: 100%">
			<!-- The header for the day view -->
			<div class="uno-calendar-day-header">
				<!-- The right side of the header -->
				<div class="uno-calendar-day-header">
					<!-- The left arrow to go back one day -->
					<uno-icon class="uno-calendar-icon" [src]="'assets/components/global-calendar/left-specialarrow-icon.svg'" [color]="'var(--brand-primary)'" [width]="32" [height]="32" (click)="this.dayChanged(-1)"></uno-icon>
					<!-- The current day -->
					<div style="color: var(--light); margin-right:10px; font-weight: 700; font-size: 14px;">{{viewDate.toLocaleString(locale.code, {weekday: 'long', day:'numeric', month:'long', year:'numeric'})}}</div>
					<!-- How many events exist in that day -->
					<span class="uno-calendar-event-number" [ngStyle]="{'background-color': 'var(--brand-primary)', 'width':'24px', 'height':'24px', 'border': 0, 'color': 'var(--light)'}">{{getEventNumberByDay(this.viewDate) > this.badgeLimit ? (this.badgeLimit + '+'): getEventNumberByDay(this.viewDate)}}</span>
				</div>
				<!-- The right arrow to go forward one day -->
				<uno-icon class="uno-calendar-icon" [src]="'assets/components/global-calendar/right-specialarrow-icon.svg'" [color]="'var(--brand-primary)'" [width]="32" [height]="32" (click)="this.dayChanged(1)"></uno-icon>
			</div>
			@if (this.events.length === 0) {
				@defer {
					<div class="uno-calendar-day-event-container">
						<uno-no-data style="width: 100%;"></uno-no-data>
					</div>
				}
			}
			@else {
				<!-- The Container for the event types -->
				<div class="uno-calendar-day-event-container">
					<!-- The collumns divided by event type -->
					@for (color of CalendareventSubtypeColors | keyvalue; track color; let i = $index) {
						<div class="uno-calendar-event-container">
							<!-- Reset the event's background when moving to a new column -->
							<div style="display:none">{{getEventBackground(true)}}</div>
							<!-- Span showing how many events of that type exist in that day -->
							<span class="uno-calendar-event-number" [ngStyle]="{'background-color': color.value, 'margin-left': '8px', 'margin-bottom': '10px'}">{{this.getNumber(this.viewDate, i+1) > this.badgeLimit ? (this.badgeLimit + '+'): getNumber(this.viewDate, i+1)}}</span>
							<cdk-virtual-scroll-viewport class="uno-calendar-day-event-scroll" [itemSize]="48">
								<!-- The Events -->
								<div *cdkVirtualFor="let event of this.getDayEventsBySubtype(this.viewDate, i+1); let index = index">
									<!-- Adds left border according to the event subtype -->
									<div class="uno-calendar-day-event" [ngStyle]="{'background-color':this.getEventBackground(), 'border-left': event.meta.actionSubtype === i+1 ? '4px solid ' + color.value: 'var(--gray-13)'}">
										<div style="display: flex;flex-direction: row;justify-content: space-between; width:100%">
											<!-- The left side of the event with the title and duration -->
											<div class="uno-calendar-event-left" [ngStyle]="{'cursor': this.hasActionAssociated(event) ? 'pointer': 'auto'}" (click)="this.occurrenceClicked({event: event})">

												<!-- Title of the event -->
												<span class="uno-calendar-event-day-option-title">
													<img src="{{priorityIcon.get(event.meta.occurrence.priority)}}">
													{{event.title}}</span>
												<!-- Duration of the event -->
												<span style="width: calc(100% - 24px);min-width: 60px; font-size: 12px;font-weight: 700;">{{event.start.toLocaleString(locale.code, {hour: "2-digit", minute: "2-digit"})}} - {{event.end.toLocaleString(locale.code, {hour: "2-digit", minute: "2-digit"})}}</span>
												<!-- The status of the event -->
												<span class="uno-calendar-event-list-option-status" [ngStyle]="{'height': '14px', 'visibility': (this.hasActionAssociated(event) || (event.meta.occurrence.status > 1)) ? 'visible': 'hidden', 'background-color': (event.meta.occurrence.status > 1 ? calendarEventStatusBackground.get(event.meta.occurrence.status) : calendarEventStatusBackground.get(calendarEventStatus.TODO)), 'color' :'var(--dark)'}">
													{{(event.meta.occurrence.status > 1 ? calendarEventStatusLabel.get(event.meta.occurrence.status): calendarEventStatusLabel.get(calendarEventStatus.TODO)) | translate | uppercase}}
												</span>
											</div>
											<!-- The Actions for the event (edit and delete) -->
											@if (event.meta.calendarEventUuid === undefined) {
												<div>
													<uno-icon class="uno-calendar-icon" [src]="!this.eventListDayOptions[i][index] ? 'assets/components/global-calendar/more-options-icon.svg': 'assets/components/global-calendar/close-icon.svg'" [color]="'var(--brand-primary)'" [width]="24" [height]="24" cdkOverlayOrigin #origin="cdkOverlayOrigin" (click)="this.toggleEventDayOptions(i, index)"></uno-icon>
													<!-- Template for the event actions -->
													<ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="origin" [cdkConnectedOverlayOpen]="this.eventListDayOptions[i][index]" (overlayOutsideClick)="this.eventListDayOptions[i][index] = false">
														<div class="uno-calendar-sort-dropdown-background" (click)="this.eventListDayOptions[i][index] = false"> </div>
														<!-- The dropdown containing the options -->
														<div class="uno-calendar-event-action-dropdown">
															<div class="uno-calendar-event-action" (click)="this.eventActionClick(i, index, event, 'edit')">
																<span style="padding-left:5px">{{'Edit' | translate}}</span>
															</div>
															<div class="uno-calendar-event-action" (click)="this.eventActionClick(i, index, event, 'delete')">
																<span style="padding-left:5px">{{'Delete' | translate}}</span>
															</div>
														</div>
													</ng-template>
												</div>
											}
										</div>
									</div>
								</div>
							</cdk-virtual-scroll-viewport>
						</div>
					}
				</div>
			}
		</div>
	</div>
}

<!-- The list view -->
@if (this.displayMode === 'list') {
	<!-- The container for the event list options -->
	<div class="uno-calendar-list-container">
		<!-- The topbar of the table -->
		<div class="uno-calendar-list-topbar">
			<span class="uno-calendar-list-topbar-title" style="justify-content:start;padding-left:20px;">{{'title' | translate}}</span>
			<span class="uno-calendar-list-topbar-title" style="justify-content:start;padding-left:5px">{{'assetName' | translate}}</span>
			<span class="uno-calendar-list-topbar-title" style="justify-content:start;padding-left:5px">{{'assetTag' | translate}}</span>
			<div style="width:350px; display:flex;justify-content: space-between;">
				<!-- The date the event starts -->
				<span class="uno-calendar-list-topbar-date-title">{{'date' | translate}}</span>
				<!-- The starting and ending time of the event -->
				<span class="uno-calendar-list-topbar-date-title">{{'duration' | translate}}</span>
			</div>
			<div style="display: flex;flex-direction: row;">
				<!-- The event status -->
				<span class="uno-calendar-list-topbar-title">{{'status' | translate}}</span>
				<!-- The event actions -->
				<span class="uno-calendar-list-topbar-title">{{'actions' | translate}}</span>
			</div>
		</div>
		<div class="uno-calendar-list-items-container" #listContainer>
			@if (this.events.length === 0) {
				<uno-no-data></uno-no-data>
			}
			@else {
				<cdk-virtual-scroll-viewport [itemSize]="48" style="height: 100%; width: 100%;" (scrolledIndexChange)="this.eventListScrolled($event)">
					<!-- Each option on the event list -->
					<div class="uno-calendar-event-list-option" [ngStyle]="{'border-left': '4px solid ' + event.color.primary, 'background-color':i%2 === 1 ? 'var(--gray-12)': 'var(--gray-13)', 'margin': '2px 10px', 'cursor': this.hasActionAssociated(event) ? 'pointer': 'auto'}" (click)="this.occurrenceClicked({event: event})" *cdkVirtualFor="let event of this.events; let i = index">
						<span class="uno-calendar-event-list-option-title" style="flex: 1 0 40px !important;">
							<img src="{{priorityIcon.get(event.meta.occurrence.priority)}}">
							{{event.title}}
						</span>
						@if (event.meta.actionSubtype === this.calendarEventSubtypes.ASSET_DYNAMIC_INSPECTION || event.meta.actionSubtype === this.calendarEventSubtypes.ASSET_ATEX_INSPECTION) {
							<span class="uno-calendar-event-list-option-title">{{this.assetInfo.get(event.meta.assetUuid)?.name}}</span>
							<span class="uno-calendar-event-list-option-title">{{this.assetInfo.get(event.meta.assetUuid)?.tag}}</span>
						}
						@else if (event.meta.actionSubtype === this.calendarEventSubtypes.REPAIR_TEMPORARY_INSPECTION || event.meta.actionSubtype === this.calendarEventSubtypes.REPAIR_DEFINITIVE_REPAIR) {
							<span class="uno-calendar-event-list-option-title">{{this.assetInfo.get(this.repairAssetUuid.get(event.meta.repairUuid)?.asset)?.name}}</span>
							<span class="uno-calendar-event-list-option-title">{{this.assetInfo.get(this.repairAssetUuid.get(event.meta.repairUuid)?.asset)?.tag}}</span>
						}
						@else {
							<span class="uno-calendar-event-list-option-title"></span>
							<span class="uno-calendar-event-list-option-title"></span>
						}
						<div style="width:350px; display:flex;justify-content: space-between;">
							<!-- The date the event starts -->
							<span class="uno-calendar-event-list-option-title" style="display:flex; justify-content: center;flex: 1 0 40px !important;">{{event.start.toLocaleString(locale.code, {month: "long", year: "numeric", day:"2-digit"})}}</span>
							<!-- The starting and ending time of the event -->
							<span class="uno-calendar-event-list-option-title" style="display:flex; justify-content: center;flex: 1 0 40px !important;">{{event.start.toLocaleString(locale.code, {hour: "2-digit", minute: "2-digit"})}} - {{event.end.toLocaleString(locale.code, {hour: "2-digit", minute: "2-digit"})}}</span>
						</div>
						<!-- The status of the event -->
						<span class="uno-calendar-event-list-option-status" [ngStyle]="{'height': '25px', 'visibility': (this.hasActionAssociated(event) || event.meta.occurrence.status > 1) ? 'visible': 'hidden', 'background-color': (event.meta.occurrence.status > 1 ? calendarEventStatusBackground.get(event.meta.occurrence.status) : calendarEventStatusBackground.get(calendarEventStatus.TODO)), 'color' :'var(--dark)'}">
							{{(event.meta.occurrence.status > 1 ? calendarEventStatusLabel.get(event.meta.occurrence.status): calendarEventStatusLabel.get(calendarEventStatus.TODO)) | translate | uppercase}}
						</span>
						<!-- The event actions -->
						<div style="width:100px; display:flex; justify-content: center;">
							<mwl-calendar-event-actions [event]="event"></mwl-calendar-event-actions>
						</div>
					</div>
				</cdk-virtual-scroll-viewport>
			}
		</div>
	</div>
}
