import {generateUUID, UUID, UUIDIdentification} from '../../uuid';
import {InspectionWorkflowStep} from './inspection-workflow-step';
import {InspectionWorkflowStepAdjacency} from './inspection-workflow-step-adjacency';

/**
 * An inspection workflow describes a specific workflow for a specific type of inspections.
 *
 * These workflows are used as a base for inspection projects and fully specify the inspection process used.
 */
export class InspectionWorkflow extends UUIDIdentification {
	/**
	 * Name of the workflow.
	 */
	public name: string = '';

	/**
	 * Description of the workflow.
	 */
	public description: string = '';

	/**
	 * Locks all the inspection steps data for editing except the current step.
	 */
	public lockDataEditingOutsideStep: boolean = true;

	/**
	 * List of steps that compose this workflow.
	 */
	public steps: InspectionWorkflowStep[] = [];

	/**
	 * Connections (adjacency's) between the workflow steps.
	 */
	public adjacencies: InspectionWorkflowStepAdjacency[] = [];

	/**
	 * Parse the data of an API retrieved and create a inspection workflow object.
	 *
	 * @param data - Object to parse data.
	 * @returns Object of the correct type.
	 */
	public static parse(data: any): InspectionWorkflow {
		const workflow = new InspectionWorkflow();

		workflow.uuid = data.uuid;
		workflow.createdAt = new Date(data.createdAt);
		workflow.updatedAt = new Date(data.updatedAt);

		workflow.name = data.name;
		workflow.description = data.description;
		workflow.lockDataEditingOutsideStep = data.lockDataEditingOutsideStep;

		if (data.steps instanceof Array) {
			workflow.steps = InspectionWorkflowStep.parseArray(data.steps);

			// Sort the steps by their index
			workflow.steps.sort(function(a: InspectionWorkflowStep, b: InspectionWorkflowStep) {
				return a.index - b.index;
			});
		}

		if (data.adjacencies instanceof Array) {
			workflow.adjacencies = data.adjacencies.map(function(d) {
				return InspectionWorkflowStepAdjacency.parse(d);
			});
		}

		return workflow;
	}

	/**
	 * Update the indexes of the workflow steps.
	 *
	 * Should be called after any changes are applied to the order of the steps.
	 */
	public updateStepsIndex(): void {
		for (let i = 0; i < this.steps.length; i++) {
			this.steps[i].index = i;
		}
	}

	/**
	 * Add an adjacency to the workflow.
	 */
	public addAdjacency(): void {
		const adjacency = new InspectionWorkflowStepAdjacency();
		adjacency.uuid = generateUUID();
		adjacency.workflowUuid = this.uuid;
		this.adjacencies.push(adjacency);
	}

	/**
	 * Remove an adjacency from the workflow.
	 */
	public removeAdjacency(adjacency: InspectionWorkflowStepAdjacency): void {
		const index = this.adjacencies.indexOf(adjacency);
		if (index !== -1) {
			this.adjacencies.splice(index, 1);
		}
	}

	/**
	 * Add a step to the workflow.
	 */
	public addStep(): void {
		const step = new InspectionWorkflowStep();
		step.uuid = generateUUID();
		step.workflowUuid = this.uuid;
		this.steps.push(step);
		this.updateStepsIndex();
	}

	/**
	 * Remove a step from the workflow.
	 * 
	 * Also checks the associated adjacencies and clears the step.
	 */
	public removeStep(step: InspectionWorkflowStep): void {
		const index = this.steps.indexOf(step);
		if (index !== -1) {
			this.steps.splice(index, 1);
		}
		
		for (let i = 0; i < this.adjacencies.length; i++) {
			const adjacency = this.adjacencies[i];

			if (adjacency.originUuid === step.uuid) {
				adjacency.originUuid = null;
			}

			if (adjacency.destinationUuid === step.uuid) {
				adjacency.destinationUuid = null;
			}
		}

		this.updateStepsIndex();
	}
}
