/**
 * List of feature types available. 
 * 
 * When adding feature types, update map legend with new layer and color.
 */
export const FeatureType = {
	NONE: 0,
	WELD: 1,
	SUPPORT: 2,
	FLANGE: 3,
	TEE_BRANCH: 4,
	ELBOW: 5,
	BRACE: 6,
	DEFECT: 7,
	INDICATION: 8,
	VALVES: 9,
	FEATURE_GENERIC: 10
};

export const FeatureTypeLabel: Map<number, string> = new Map([
	[FeatureType.NONE, 'none'],
	[FeatureType.WELD, 'weld'],
	[FeatureType.SUPPORT, 'support'],
	[FeatureType.FLANGE, 'flange'],
	[FeatureType.TEE_BRANCH, 'teeBranch'],
	[FeatureType.ELBOW, 'elbow'],
	[FeatureType.BRACE, 'brace'],
	[FeatureType.DEFECT, 'defect'],
	[FeatureType.INDICATION, 'indication'],
	[FeatureType.VALVES, 'valves'],
	[FeatureType.FEATURE_GENERIC, 'featureGeneric']
]);

export const FeatureTypeColor: Map<number, string> = new Map([
	[FeatureType.NONE, '--light'],
	[FeatureType.WELD, '--special-yellow-2'],
	[FeatureType.SUPPORT, '--special-green-3'],
	[FeatureType.FLANGE, '--special-purple-1'],
	[FeatureType.TEE_BRANCH, '--special-green-1'],
	[FeatureType.ELBOW, '--special-orange-1'],
	[FeatureType.BRACE, '--special-red-2'],
	[FeatureType.DEFECT, '--special-pink-1'],
	[FeatureType.INDICATION, '--special-green-4'],
	[FeatureType.VALVES, '--special-red-1'],
	[FeatureType.FEATURE_GENERIC, '--special-yellow-1']
]);
