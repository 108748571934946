<div style="height: 110px; margin: 10px;">
	<!-- Search bar -->
	<uno-searchbar style="width: 100%;" [value]="selfStatic.filters.search" (valueChange)="onFilterChange($event)"></uno-searchbar>

	<!-- Filters -->
	<div style="width: 100%; height: 80px; overflow-x: auto; overflow-y: hidden; white-space: nowrap;">
		<!-- Sort direction -->
		<div style="min-width: 100px; display: inline-block; margin-right: 5px;">
			<ion-text style="font-size: 12px;">{{'direction' | translate}}</ion-text>
			<ion-select (ionChange)="onFilterChange($event)" [(ngModel)]="selfStatic.filters.sortDirection" interface="{{app.device.isMobile() ? 'alert' : 'popover'}}" fill="outline">
				@for (value of selfStatic.sortDirectionValues; track value) {
					<ion-select-option [value]="value">{{value | translate}}</ion-select-option>
				}
			</ion-select>
		</div>

		<!-- Sort field -->
		<div style="min-width: 150px; display: inline-block; margin-right: 5px;">
			<ion-text style="font-size: 12px;">{{'sortField' | translate}}</ion-text>
			<ion-select (ionChange)="onFilterChange($event)" [(ngModel)]="selfStatic.filters.sortField" interface="{{app.device.isMobile() ? 'alert' : 'popover'}}" fill="outline">
				@for (value of selfStatic.sortFieldValues; track value) {
					<ion-select-option [value]="value.attribute">{{value.label | translate}}</ion-select-option>
				}
			</ion-select>
		</div>

		<!-- Search fields -->
		<div style="min-width: 150px; display: inline-block; margin-right: 5px;">
			<ion-text style="font-size: 12px;">{{'searchFields' | translate}}</ion-text>
			<ion-select (ionChange)="onFilterChange($event)" [(ngModel)]="selfStatic.filters.searchFields" [interfaceOptions]="{header: ('searchFields' | translate)}" [multiple]="true" interface="alert" fill="outline">
				@for (field of selfStatic.searchFieldValues; track field) {
					<ion-select-option [value]="field.attribute">{{field.label | translate}}</ion-select-option>
				}
			</ion-select>
		</div>

		<!-- Repair Longevity filter -->
		<div style="min-width: 150px; display: inline-block; margin-right: 5px;">
			<ion-text style="font-size: 12px;">{{'longevity' | translate}}</ion-text>
			<ion-select (ionChange)="onFilterChange($event)" [(ngModel)]="selfStatic.filters.longevityFilters" [interfaceOptions]="{header: ('longevity' | translate)}" [multiple]="true" interface="alert" fill="outline">
				@for (field of selfStatic.longevityFilterValues; track field) {
					<ion-select-option [value]="field.attribute">{{field.label | translate}}</ion-select-option>
				}
			</ion-select>
		</div>
	</div>
</div>

<ng-template #itemTemplate let-repair="item">
	<uno-list-item (click)="app.navigator.navigate('/menu/repairs/works/edit', {uuid: repair.uuid});">
		<uno-list-item-icon src="{{repair.pictures != null && repair.pictures.length > 0 ? ResourceUtils.getURL(repair.pictures[0], './assets/placeholder/asset.png') : './assets/placeholder/asset.png'}}"></uno-list-item-icon>

		<uno-list-item-label>
			@if (repair.description) {
				<h2>
					@if (repair.qr !== null) {
						<ion-icon color="medium" name="qr-code-outline"></ion-icon>
					}
					{{repair.description}}
				</h2>
			}
			@if (repair.asset && (repair.asset.tag || repair.asset.name)) {
				<h2>{{repair.asset.tag || ('nd' | translate)}} ({{repair.asset.name ? repair.asset.name : ('nd' | translate)}})</h2>
				}
				<h3>{{repair.criticality | repairCriticalityLabel | translate}} | {{repair.status | repairStatusLabel | translate }}</h3>
				<p>{{'updatedAt' | translate}}: {{repair.updatedAt | formatDate}}</p>
		</uno-list-item-label>

		<!-- Open new tab -->
		@if (app.device.isDesktop()) {
			<ion-icon (click)="$event.cancelBubble = true; generateQr(repair.uuid);" name="qr-code-outline" slot="end"></ion-icon>
			<ion-icon (click)="app.openInTab('/menu/repairs/works/edit', {uuid: repair.uuid}, $event);" name="desktop-outline" slot="end"></ion-icon>
		}
	</uno-list-item>
</ng-template>

<uno-content [ngStyle]="{height: 'calc(100% - 130px)'}">
	<uno-list [handler]="this.handler" [itemTemplate]="itemTemplate"></uno-list>
</uno-content>
