import {Component, OnInit} from '@angular/core';
import {SortDirection} from 'src/app/utils/sort-direction';
import {TranslateModule} from '@ngx-translate/core';
import {NgStyle} from '@angular/common';
import {App} from '../../../../app';
import {ServiceList} from '../../../../http/service-list';
import {Session} from '../../../../session';
import {ScreenComponent} from '../../../../components/screen/screen.component';
import {UserPermissions} from '../../../../models/users/user-permissions';
import {ObservationStatus, ObservationStatusLabel} from '../../../../models/observations/observation-status';
import {ServiceSync} from '../../../../http/service-sync';
import {UnoListLazyLoadHandler} from '../../../../components/uno/uno-list/uno-list-lazy-load-handler';
import {
	UnoFilterBarComponent,
	UnoFilterBarOption,
	UnoFilterBarOptionType
} from '../../../../components/uno/uno-filter-bar/uno-filter-bar.component';
import {UnoListComponent} from '../../../../components/uno/uno-list/uno-list-component';
import {UnoContentComponent} from '../../../../components/uno/uno-content/uno-content.component';
import {UnoListItemLabelComponent} from '../../../../components/uno/uno-list-item/uno-list-item-label.component';
import {UnoListItemComponent} from '../../../../components/uno/uno-list-item/uno-list-item.component';
import {UnoSearchbarComponent} from '../../../../components/uno/uno-searchbar/uno-searchbar.component';

@Component({
	selector: 'observations-list-page',
	templateUrl: 'observations-list.page.html',
	standalone: true,
	imports: [UnoSearchbarComponent, UnoFilterBarComponent, UnoListItemComponent, UnoListItemLabelComponent, UnoContentComponent, NgStyle, UnoListComponent, TranslateModule]
})
export class ObservationsListPage extends ScreenComponent implements OnInit {

	public get observationStatusLabel(): any { return ObservationStatusLabel; }

	public get app(): any { return App; }

	public get selfStatic(): any { return ObservationsListPage; }

	public permissions = [UserPermissions.OBSERVATIONS];

	/**
	 * Possible database filter to be used for ordering the user roles list.
	 */
	public static filterOptions: UnoFilterBarOption[] = [
		// Sort direction applied to the loaded list from database.
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'sortDirection',
			label: 'direction',
			default: SortDirection.DESC,
			options: [
				{label: 'asc', value: SortDirection.ASC},
				{label: 'desc', value: SortDirection.DESC}
			]
		},
		// Database attribute name used to sort the values.
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'sortField',
			label: 'sortField',
			default: '[observation].[updated_at]',
			options: [
				{label: 'updatedAt', value: '[observation].[updated_at]'},
				{label: 'createdAt', value: '[observation].[created_at]'},
				{label: 'name', value: '[observation].[name]'},
				{label: 'description', value: '[observation].[description]'}
			]
		},
		// Search fields to be considered.
		{
			type: UnoFilterBarOptionType.OPTIONS,
			multiple: true,
			attribute: 'searchFields',
			label: 'searchFields',
			default: ['[observation].[name]'],
			options: [
				{label: 'name', value: '[observation].[name]'},
				{label: 'description', value: '[observation].[description]'},
				{label: 'uuid', value: '[observation].[id]'}
			]
		}
	];

	/**
	 * Status of the observations to show in this list, received from page data.
	 */
	public status: number = 0;

	/**
	 * Lazy list loading handler.
	 */
	public handler: UnoListLazyLoadHandler<any> = new UnoListLazyLoadHandler<any>();

	/**
	 * Object to synchronize service requests.
	 */
	public serviceSync: ServiceSync = new ServiceSync();

	public static filters = UnoFilterBarComponent.reset({
		// Text used to filter list entries by their content.
		search: ''
	}, ObservationsListPage.filterOptions);

	public static defaultFilters = structuredClone(ObservationsListPage.filters);

	public resetFilters(): void {
		UnoFilterBarComponent.reset(ObservationsListPage.filters, ObservationsListPage.filterOptions);

		this.serviceSync.reset();
	}

	public ngOnInit(): void {
		super.ngOnInit();

		this.status = ObservationStatus.ALL;

		const data = App.navigator.getData();
		if (data !== null && data.status !== undefined) {
			this.status = data.status;
		}

		App.navigator.setTitle(this.status !== ObservationStatus.ALL ? ObservationStatusLabel.get(this.status) : 'list');

		this.handler.loadMore = async(count: number, pageSize: number) => {
			const params = {
				status: this.status,
				from: count,
				count: pageSize,
				search: ObservationsListPage.filters.search,
				searchFields: ObservationsListPage.filters.searchFields,
				sortDirection: ObservationsListPage.filters.sortDirection,
				sortField: ObservationsListPage.filters.sortField
			};

			const request = await this.serviceSync.fetch(ServiceList.observation.list, null, null, params, Session.session);
			const response = request.response;

			return {
				elements: response.observations,
				hasMore: response.hasMore
			};
		};
		this.handler.reset();
	}

	/**
	 * Update filters and reload data from the API if required.
	 *
	 * @param search - Search value
	 */
	public async onSearchChange(search: string): Promise<void> {
		ObservationsListPage.filters.search = search;
		this.serviceSync.reset();
		await this.handler.reset();
	}

	/**
	 * Update filters and reload data from the API if required.
	 *
	 * @param filters - Filters
	 */
	public onFilterChange(filters: any): void {
		ObservationsListPage.filters = filters;

		this.serviceSync.reset();
		this.handler.reset();
	}
}
