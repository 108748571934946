import {Component, Input, ViewEncapsulation} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {IonicModule} from '@ionic/angular';
import {AsyncPipe} from '@angular/common';
import {App} from '../../../app';
import {Session} from '../../../session';
import {UnoCardComponent} from '../../uno/uno-card/uno-card.component';

/**
 * Menu cards layout should be used for navigation menus is compatible with desktop and mobile.
 *
 * Displays the menu as a grid of cards in the desktop platform and as a list in mobile devices.
 */
@Component({
	selector: 'menu-cards',
	templateUrl: './menu-cards.component.html',
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [IonicModule, UnoCardComponent, AsyncPipe, TranslateModule]
})
export class MenuCardsComponent {
	public get app(): any { return App; }

	public get session(): any { return Session; }

	@Input()
	public options: MenuCardsOptions[] = [];
}

export class MenuCardsOptions {
	/**
	 * Label shown to the user on the card.
	 */
	public label: string = '';

	/**
	 * Navigation route of this option.
	 */
	public route: string = '';

	/**
	 * Data passed to the navigation route for this option.
	 */
	public data: any = null;

	/**
	 * Icon displayed for the option, can be a ion icon or file.
	 */
	public icon: string = '';

	/**
	 * Method to get the badge count from the option.
	 *
	 * Should be an async method that returns a Promise with the value to represent in the badge.
	 */
	public badge: Promise<number> = null;

	/**
	 * List of permissions required for the option.
	 *
	 * At least one of these permissions is required, if not permission is needed should be empty.
	 */
	public permissions: number[] = [];
}
