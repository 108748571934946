/**
 * Privacy policy is presented to allow the user to understand how their data is handled by the company.
 *
 * Users have to accept the last version of the privacy policy before using the application.
 */
export class PrivacyPolicy {
	/**
	 * Date when this policy was created.
	 */
	public createdAt: Date = null;

	/**
	 * Version of the privacy policy.
	 *
	 * The client has to accept the last version of the privacy policy.
	 */
	public version: number = 0;

	/**
	 * The content of the policy.
	 *
	 * Policies are composed of multiple chapters
	 */
	public data: PrivacyPolicyContent[] = [];

	/**
	 * Get the policy content based on locale value.
	 *
	 * If localized content is not available the first version found is returned.
	 *
	 * @param locale - Localization code.
	 */
	public getLocaleContent(locale: string): PrivacyPolicyContent {
		for (let i = 0; i < this.data.length; i++) {
			if (this.data[i].locale.toLowerCase() === locale.toLowerCase()) {
				return this.data[i];
			}
		}

		return this.data[0];
	}

	/**
	 * Parse data and create a PrivacyPolicy object.
	 *
	 * @param data - Data received from the API to parse.
	 */
	public static parse(data: any): PrivacyPolicy {
		const policy = new PrivacyPolicy();

		policy.createdAt = new Date(data.createdAt);
		policy.version = data.version;
		policy.data = PrivacyPolicy.parseData(data.data);

		return policy;
	}

	/**
	 * Parse data of the privacy policy, to ensure that the correct object type is used.
	 *
	 * @param data - Data received from the API to parse.
	 */
	public static parseData(data: any[]): PrivacyPolicyContent[] {
		if (!data.length) {
			throw new Error('Privacy policy content should not be empty.');
		}

		const values: PrivacyPolicyContent[] = [];

		for (let j = 0; j < data.length; j++) {
			const content: PrivacyPolicyContent = {
				title: data[j].title,
				locale: data[j].locale,
				sections: []
			};

			for (let i = 0; i < data[j].sections.length; i++) {
				content.sections.push({
					title: data[j].sections[i].title,
					content: data[j].sections[i].content
				});
			}

			values.push(content);
		}

		return values;
	}
}

/**
 * Structure to store the privacy policy content.
 */
export type PrivacyPolicyContent = {
	/**
	 * Title of the policy.
	 */
	title: string,
	
	/**
	 * Locale of the privacy policy
	 */
	locale: string,

	/**
	 * Content of the policy by section.
	 */
	sections: {
		title: string,
		content: string[],
	}[]
}
