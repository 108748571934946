import {Locale} from './locale/locale';
import {CSSUtils} from './utils/css-utils';

/**
 * Loading screen modal is shown to block user interaction while waiting for some task to be finished (e.g API call, data processing).
 */
export class Loading {
	/**
	 * Number of requests to show loading box, might be multiple calls waiting for load to finish.
	 */
	private static count: number = 0;

	/**
	 * DOM element of the loading screen, attached to the document body.
	 *
	 * Visibility can be toggled using the CSS display property (that causes page reflow but the element is fully ignored in the DOM tree) or the CSS visibility option (that does not cause full reflow).
	 */
	private static element: HTMLElement = null;

	/**
	 * Create the DOM element to represent the Loading screen and attach it to the document.
	 */
	private static create(): void {
		if (Loading.element !== null) {
			return;
		}

		const base = document.createElement('div');
		base.style.display = 'block';
		base.style.visibility = 'hidden';
		base.style.position = 'absolute';
		base.style.top = '0px';
		base.style.left = '0px';
		base.style.width = '100%';
		base.style.height = '100%';
		base.style.backgroundColor = CSSUtils.getVariable('--backdrop');
		base.style.zIndex = '1000';
		document.body.append(base);

		const box = document.createElement('div');
		box.style.flexDirection = 'row';
		box.style.display = 'flex';
		box.style.gap = '10px';
		box.style.alignItems = 'center';
		box.style.justifyContent = 'space-evenly';
		box.style.pointerEvents = 'none';
		box.style.position = 'absolute';
		box.style.top = 'calc(50% - 45px)';
		box.style.left = 'calc(50% - 100px)';
		box.style.width = '200px';
		box.style.height = '90px';
		box.style.backgroundColor = 'var(--gray-12)';
		box.style.color = 'var(--gray-1)';
		box.style.fontSize = '16px';
		box.style.boxShadow = 'rgba(0, 0, 0, 0.4) 0px 16px 20px 0px;';
		box.style.borderRadius = '4px';
		base.appendChild(box);

		const spinner = document.createElement('div');
		spinner.style.height = '30px';
		spinner.style.width = '30px';
		spinner.style.top = 'calc(50% - 15px)';
		spinner.style.left = '20px';
		spinner.innerHTML = 
			`<svg width="30" height="30" viewBox="0 0 300 300" xmlns="http://www.w3.org/2000/svg" version="1.1">
				<path d="M 150,0 a 150,150 0 0,1 106.066,256.066 l -35.355,-35.355 a -100,-100 0 0,0 -70.711,-170.711 z" fill="var(--brand-primary)">
					<animateTransform attributeName="transform" attributeType="XML" type="rotate" from="0 150 150" to="360 150 150" begin="0s" dur="1.0s" fill="freeze" repeatCount="indefinite"></animateTransform>
				</path>
			</svg>`;
		box.appendChild(spinner);

		const text = document.createElement('div');
		text.innerText = Locale.get('loadingData');
		box.appendChild(text);

		Loading.element = base;
	}

	/**
	 * Show the loading box. Internally uses a control semaphore.
	 *
	 * Can be called multiple times simultaneously, but after load the loading box has to be hidden for each one of the show calls.
	 */
	public static show(): void {
		Loading.count++;
		Loading.create();

		if (Loading.count > 0) {
			Loading.element.style.visibility = 'visible';
		}
	}

	/**
	 * Hide the loading box.
	 */
	public static hide(): void {	
		Loading.count--;

		if (Loading.count <= 0) {
			Loading.element.style.visibility = 'hidden';

			if (Loading.count < 0) {
				Loading.count = 0;
			}
		}
	}
}
