import {enableProdMode, importProvidersFrom} from '@angular/core';
import {IMAGE_CONFIG} from '@angular/common';
import {
	Chart,
	ArcElement,
	LineElement,
	BarElement,
	PointElement,
	BarController,
	BubbleController,
	DoughnutController,
	LineController,
	PieController,
	PolarAreaController,
	RadarController,
	ScatterController,
	CategoryScale,
	LinearScale,
	LogarithmicScale,
	RadialLinearScale,
	TimeScale,
	TimeSeriesScale,
	Filler,
	Legend,
	Title,
	Tooltip
} from 'chart.js';

import {bootstrapApplication} from '@angular/platform-browser';
import {ServiceWorkerModule} from '@angular/service-worker';
import {adapterFactory} from 'angular-calendar/date-adapters/date-fns';
import {CalendarModule, DateAdapter} from 'angular-calendar';
import {AngularSvgIconModule} from 'angular-svg-icon';
import {withInterceptorsFromDi, provideHttpClient} from '@angular/common/http';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {OverlayModule} from '@angular/cdk/overlay';
import {TranslateService, TranslateModule} from '@ngx-translate/core';
import {Platform, IonicModule} from '@ionic/angular';
import {RouteReuseStrategy, Router, withRouterConfig, provideRouter} from '@angular/router';
import {AssetPlanningModule} from './app/modules/asset-planning/asset-planning.module';
import {ServiceWorkerUpdateService} from './app/services/service-worker-update.service';
import {AppRoutes} from './app/app-routes';
import {AppComponent} from './app/app.component';
import {Environment} from './environments/environment';
import 'chartjs-adapter-date-fns';
import 'zone.js';
import {RouteStrategy} from './app/services/route-strategy.service';


if (!Environment.TEST) {
	Chart.register(ArcElement, LineElement, BarElement, PointElement, BarController, BubbleController, DoughnutController, LineController, PieController, PolarAreaController, RadarController, ScatterController, CategoryScale, LinearScale, LogarithmicScale, RadialLinearScale, TimeScale, TimeSeriesScale, Filler, Legend, Title, Tooltip);
}

// @ts-ignore
window.Buffer = window.ArrayBuffer;

if (!Environment.TEST) {
	if (Environment.PRODUCTION) {
		enableProdMode();
	}

	// @ts-ignore
	window.enableDevMode = function() {
		Environment.PRODUCTION = false;
	};

	bootstrapApplication(AppComponent, {
		providers: [
			{
				provide: IMAGE_CONFIG,
				useValue: {
				  disableImageSizeWarning: true, 
				  disableImageLazyLoadWarning: true
				}
			},
			importProvidersFrom(AssetPlanningModule, OverlayModule, DragDropModule, AngularSvgIconModule.forRoot(), TranslateModule.forRoot(), IonicModule.forRoot({
				mode: 'md',
				hardwareBackButton: true,
				statusTap: false,
				swipeBackEnabled: false
			}), CalendarModule.forRoot({
				provide: DateAdapter,
				useFactory: adapterFactory
			}), ServiceWorkerModule.register('ngsw-worker.js', {
				enabled: true,
				registrationStrategy: 'registerImmediately'
			})),
			{
				provide: RouteReuseStrategy,
				useClass: RouteStrategy
			},
			ServiceWorkerUpdateService,
			Platform,
			TranslateService,
			Router,
			provideRouter(AppRoutes, withRouterConfig({onSameUrlNavigation: 'reload'})),
			provideHttpClient(withInterceptorsFromDi())
		]
	}).catch((err) => {
		console.log('EQS: Error bootstrapping the application.', err);
	});
	
}
