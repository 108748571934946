<div style="width:100%; height:100%;">
	<uno-tab>
		<uno-tab-section [active]="true" title="{{'actionPlan' | translate}}">
			<uno-title>{{'actionPlan' | translate}}</uno-title>

			<!-- Base Form -->
			<uno-dynamic-form [layout]="this.actionPlanLayout" [object]="this.actionPlan" #actionPlanForm></uno-dynamic-form>

			<!-- Submit buttons -->
			<div style="display: flex; flex-direction: row-reverse; gap: 5px;" class="ion-padding">
				@if (this.createMode && session.hasPermissions([userPermissions.GA_ACTION_PLAN_CREATE])) {
					<uno-button (click)="this.save();">{{'create' | translate}}</uno-button>
				}

				@if (!this.createMode) {
					<uno-button (click)="this.save();">{{'save' | translate}}</uno-button>
					<uno-button (click)="this.save(undefined, true);">{{'update' | translate}}</uno-button>
					@if (this.actionPlan?.status === actionPlanStatus.PROPOSAL) {
						<uno-button (click)="this.save(actionPlanStatus.WAITING_APPROVAL);" color="success">{{'submit' | translate}}</uno-button>
					}
					@if (this.actionPlan?.status === actionPlanStatus.WAITING_APPROVAL) {
						<uno-button (click)="this.save(actionPlanStatus.IMPLEMENTATION);" color="success">{{'approve' | translate}}</uno-button>
						<uno-button (click)="this.save(actionPlanStatus.REJECTED);" color="error">{{'reject' | translate}}</uno-button>
					}
					@if (this.actionPlan?.status === actionPlanStatus.IMPLEMENTATION) {
						<uno-button (click)="this.save(actionPlanStatus.REINSPECTION);" color="success">{{'finish' | translate}}</uno-button>
						<uno-button (click)="this.save(actionPlanStatus.BLOCKED);" color="error">{{'blocked' | translate}}</uno-button>
					}
					@if (this.actionPlan?.status === actionPlanStatus.BLOCKED) {
						<uno-button (click)="this.save(actionPlanStatus.IMPLEMENTATION);" color="success">{{'unlock' | translate}}</uno-button>
					}
					@if (this.actionPlan?.status === actionPlanStatus.REINSPECTION) {
						<uno-button (click)="this.save(actionPlanStatus.DONE);">{{'finish' | translate}}</uno-button>
					}
					@if (this.actionPlan?.status !== actionPlanStatus.ARCHIVED) {
						<uno-button (click)="this.save(actionPlanStatus.ARCHIVED);" color="error">{{'archive' | translate}}</uno-button>
					}
					@if (session.hasPermissions([userPermissions.GA_ACTION_PLAN_DELETE])) {
						<uno-button (click)="this.delete();" color="error">{{'delete' | translate}}</uno-button>
					}
				}
			</div>
		</uno-tab-section>

		<!-- Assets -->
		@if (session.hasPermissions(userPermissions.ASSET_PORTFOLIO)) {
			<uno-tab-section title="{{'assets' | translate}}">
				<uno-title>{{'assets' | translate}}</uno-title>

				@for (asset of this.assets; track asset) {
					<!-- Asset information -->
					<uno-dynamic-form [editable]="false" [layout]="this.assetsLayout" [object]="asset"></uno-dynamic-form>
					<!-- Open page -->
					<div style="display: flex; flex-direction: row-reverse; gap: 5px;" class="ion-padding">
						@if (session.hasPermissions([userPermissions.ASSET_PORTFOLIO])) {
							<uno-button (click)="app.navigator.navigate('/menu/asset-portfolio/asset/edit', {uuid: asset.uuid});">{{'viewAsset' | translate}}</uno-button>
						}
					</div>
				}
			</uno-tab-section>
		}

		<!-- History -->
		<uno-tab-section title="{{'history' | translate}}">

			@if (this.history && this.history.length > 0) {
				<uno-title>{{'history' | translate}}</uno-title>

				<ion-list class="ion-no-padding ion-no-margin">
					@for (option of this.history; track option) {
						<ion-item class="ion-margin-horizontal ion-no-padding" lines="full">
							<ion-label>{{option.updatedAt | formatDate}}</ion-label>
							<div class="ion-text-end">
								<ion-label>{{"userName" | translate}}: {{option.userName}}</ion-label>
								<ion-label color="medium" style="font-size: 13px;">{{option.userEmail}}</ion-label>
							</div>
						</ion-item>
					}
				</ion-list>
			}
		</uno-tab-section>
	</uno-tab>
</div>
