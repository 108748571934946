import {Service} from '../../../http/service';
import {ServiceList} from '../../../http/service-list';
import {Session} from '../../../session';
import {UUID} from '../../../models/uuid';
import {APAssetFieldData} from '../../../models/asset-portfolio/asset-field-data';

export class AssetFieldDataService {
	/**
	 * Gets all the data fields from all the cards and fields for multiple assets by their UUIDs.
	 * 
	 * @param assetUuids - The UUIDs of the assets to get the data for.
	 * @returns All the datas related with the cards and fields of the given asset UUIDs.
	 */
	public static async listBatch(assetUuids: UUID[]): Promise<APAssetFieldData[]> {
		const request = await Service.fetch(ServiceList.assetPortfolio.asset.data.listBatch, null, null, {assetUuids: assetUuids}, Session.session);
		
		const assetFieldsData: APAssetFieldData[] = request.response.data.map((d: any) => {
			return APAssetFieldData.parse(d);
		});

		return assetFieldsData;
	}
}
