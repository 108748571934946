import {Resource} from '../../resource';
import {UUID, UUIDIdentification} from '../../uuid';
import {ActionPlanState} from './action-plan-state';
import {ActionPlanPriority} from './action-plan-priority';

/**
 * Action plan is used to propose corrective solution for the project.
 *
 * These action plans are built from FFP entries.
 */
export class ActionPlan extends UUIDIdentification {
	/**
	 * UUID of the team responsible for this action plan.
	 */
	public teamUuid: UUID = '';

	/**
	 * UUID of the company responsible for this action plan.
	 */
	public companyUuid: UUID = '';

	/**
	 * Work order of the action plan.
	 */
	public workOrder: string = '';

	/**
	 * Description of the action plan should include notes about the measures that will be applied.
	 */
	public description: string = '';

	/**
	 * State of the action plan.
	 */
	public state: number = ActionPlanState.TODO;

	/**
	 * Total cost in euro of the action plan proposed.
	 */
	public cost: number = 0;

	/**
	 * Priority of this action plan entry.
	 */
	public priority: number = ActionPlanPriority.MEDIUM;

	/**
	 * Limit date for which these actions need to be applied.
	 */
	public limitDate: Date = null;

	/**
	 * Alert via email the company that will execute the action plan.
	 */
	public alertEmail: boolean = false;

	/**
	 * List of the FFP UUIDs that will be solved in this action plan.
	 */
	public ffpUuids: UUID[] = [];

	/**
	 * List of actions that need to be applied to fix the FFP entries of this plan.
	 *
	 * These actions are stored as index of the value put in the master data.
	 */
	public actions: number[] = [];

	/**
	 * Additional data stored for each action plan step
	 */
	public data: ActionPlanData = new ActionPlanData();

	/**
	 * Parse JSON data received from the API into an object.
	 *
	 * @param data - Object received from API.
	 * @returns ActionPlan object instance built from the data received.
	 */
	public static parse(data: any): ActionPlan {
		const plan = new ActionPlan();

		plan.uuid = data.uuid;
		plan.updatedAt = new Date(data.updatedAt);
		plan.createdAt = new Date(data.createdAt);

		plan.companyUuid = data.companyUuid;
		plan.description = data.description;
		plan.workOrder = data.workOrder;
		plan.state = data.state;
		plan.cost = data.cost;
		plan.priority = data.priority;
		plan.limitDate = data.limitDate ? new Date(data.limitDate) : null;
		plan.alertEmail = data.alertEmail === true;
		plan.ffpUuids = data.ffpUuids;
		plan.teamUuid = data.teamUuid;
		plan.actions = data.actions ? data.actions : [];

		return plan;
	}
}

export class ActionPlanData {
	/**
	 * Photos attached to the action plan entry, with the repair and re-inspection data attached.
	 */
	public photos: Resource[] = [];

	/**
	 * Documents attached to the action plan entry.
	 */
	public documents: Resource[] = [];

	/**
	 * Notes about the action plan procedure.
	 */
	public notes: string = '';
}

