import {UUID} from 'crypto';
import {Session} from 'src/app/session';
import {Service} from 'src/app/http/service';
import {ServiceList} from 'src/app/http/service-list';
import {FFPRecommendation} from '../../../../models/atex-inspections/ffp/ffp-recommendation';

export type AtexFfpListParams = {
	sortField?: string,
	sortDirection?: string,
	state?: number,
	from?: number,
	count?: number,
	search?: string,
	searchFields?: string[],
	filterActionPlan?: number,
	filterRecommendations?: number
};

export type AtexFfpCountParams = {
	status?: number,
	search?: string,
	searchFields?: string[],
	filterActionPlan?: number,
	filterRecommendations?: number,
	searchMode?: number
};

export type AtexFFPListItem = {
	uuid: UUID,
	asset: {name: string, tag: string, uuid: UUID},
	result: number,
	actionPlanCount: number,
	cost: number,
	field: string,
	form: string,
	inspection: {uuid: UUID},
	parentAsset: {name: string, tag: string, uuid: UUID},
	priority: number,
	recommendations: number
}

export class AtexFfpService {
	/**
	 * List all ffps meeting the parameters.
	 *
	 * @param params - The parameters to apply on the fetch request.
	 */
	public static async list(params: AtexFfpListParams): Promise<{hasMore: boolean, ffp: AtexFFPListItem[], id: number}> {
		const request = await Service.fetch(ServiceList.ffp.list, null, null, params, Session.session);
		return {
			hasMore: request.response.hasMore,
			ffp: request.response.ffp,
			id: request.id
		};
	}

	/**
	 * Lists FFP recommendations.
	 */
	public static async listRecommendations(): Promise<FFPRecommendation[]> {
		const request = await Service.fetch(ServiceList.ffp.recommendationsGet, null, null, null, Session.session);
		return request.response.map((d: any) => { return FFPRecommendation.parse(d); });
	}

	/**
	 * Count all ffps meeting the parameters.
	 *
	 * @param params - The parameters to apply on the fetch request.
	 */
	public static async count(params: AtexFfpCountParams): Promise<number> {
		return (await Service.fetch(ServiceList.ffp.count, null, null, params, Session.session)).response.count;
	}
}


