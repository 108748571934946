@for (item of this.items; track item; let i = $index) {
	<div style="display: flex; flex-direction: row;">
		<ion-input [ngStyle]="{width: 'calc(100% - 130px)'}" (ngModelChange)="this.setItem(i, $event);" [debounce]="250" [disabled]="this.disabled" [ngModel]="this.items[i]" [ngModelOptions]="{standalone: true}" fill="outline" inputmode="text" placeholder="{{'edit' | translate }}" type="text"></ion-input>
		<div style="align-items: center; display: flex; gap: 2px; padding-left: 5px;">
			@if (!this.disabled && this.allowMove && i < this.items.length - 1) {
				<uno-button icon="/assets/icons/uno/down-specialarrow-icon.svg" (click)="this.moveFieldUp(i)" size="extraSmall"></uno-button>
			}
			@if (!this.disabled && this.allowMove && i > 0) {
				<uno-button icon="/assets/icons/uno/up-specialarrow-icon.svg" (click)="this.moveFieldDown(i)" size="extraSmall"> </uno-button>
			}
			@if (!this.disabled) {
				<uno-button icon="/assets/icons/uno/bin-icon.svg" (click)="this.removeItem(i);" size="extraSmall"></uno-button>
			}
		</div>
	</div>

}

@if (!this.disabled) {
	<uno-button (click)="this.addItem()" size="medium">
		<uno-text>{{'add' | translate }}</uno-text>
	</uno-button>
}
