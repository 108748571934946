<div class="uno-table-component-container" [ngStyle]="{display: this.totalItemCount > 0 || this.handler.items.length > 0 ? 'flex' : 'none'}">
	<!-- The container for the table -->
	<div class="uno-table-container">
		<!-- The topbar of the table -->
		<div class="uno-table-topbar">
			<span class="uno-table-header uno-table-center" [ngStyle]="{'flex-basis': '55px', 'flex-shrink': 0, display: this.selectable ? 'flex' : 'none'}">
				<label class="uno-table-label" for="selectAll">
					<input class="uno-table-checkbox" id="selectAll" type="checkbox" (change)="this.selectAll($event.target.checked)">
					@if (!this.allSelected && this.checkedIndexes.length > 0) {
						<span class="uno-table-partial-checkmark">
							<span class="uno-table-partial-checkmark-dash"></span>
						</span>
					} @else {
						<span class="uno-table-checkmark"></span>
					}
				</label>
			</span>
			@for (column of this.layout; track column) {
				<span class="uno-table-header" [ngClass]="{'uno-table-center' : column.type !== this.types.TEXT}" [ngStyle]="this.getStyle(column, true)" (click)="column.sortBy ? this.sortBy(column.sortBy) : null">
					@if (column.sortBy) {
						<div style="height:40px;width:24px;display: flex; align-items: center;">
							<uno-icon [src]="'assets/icons/assets/sortby-icon.svg'" [style]="{transform: this.handler.sortDirection === this.sortDirection.DESC ? 'rotate(180deg)' : 'none'}" [color]="this.handler.sortField === column.sortBy ? 'var(--brand-primary)' : 'var(--gray-5)'" [width]="24" [height]="24"></uno-icon>
						</div>
					}
					<span [ngStyle]="{'justify-content': column.type === this.types.ICONS ? 'center' : 'baseline', 'display': column.type === this.types.ICONS ? 'flex' : 'block'}" [ariaLabel]="column.attribute">{{column.header | translate}}</span>
				</span>
			}
		</div>

		<cdk-virtual-scroll-viewport [itemSize]="48" style="height: 100%; width: 100%;" (scrolledIndexChange)="this.checkItems()">
			<!-- List item -->
			<div class="uno-table-option" [ngStyle]="{'background-color':i%2 === 1 ? 'var(--gray-12)' : 'var(--gray-13)', 'cursor': this.rowClickable ? 'pointer' : 'auto'}" *cdkVirtualFor="let element of this.handler.items; let i = index; let last = last;">
				<div class="uno-table-option-content uno-table-center" [ngClass]="{'uno-table-inactive': !element?.status}" [ngStyle]="{display: this.selectable ? 'flex' : 'none', 'flex-basis': '40px', 'flex-shrink' : 0, 'flex-grow': 0}">
					<!-- Adds checkbox to the start of each row if its enabled -->
					@if (this.selectable) {
						<div class="uno-table-item uno-table-center">
							<label class="uno-table-label" for="select{{i}}">
								<input class="uno-table-checkbox" id="select{{i}}" type="checkbox" [(ngModel)]="this.checkedRows[i]" (ngModelChange)="this.select()">
								<span class="uno-table-checkmark"></span>
							</label>
						</div>
					}
				</div>

				<!-- Skeleton Placeholder -->
				@if (element === null) {
					@for (column of this.layout; track column) {
						<div class="uno-table-option-content" [ngClass]="{'uno-table-inactive': true, 'uno-table-center': column.type !== this.types.TEXT && column.type !== this.types.DATE}" [ngStyle]="this.getStyle(column)">
							<div class="uno-table-item">
								<span class="uno-table-text">...</span>
							</div>
						</div>
					}
				} @else { <!-- Item -->
					<!-- Table headers -->
					@for (column of this.layout; track column) {
						<div class="uno-table-option-content" [ngClass]="{'uno-table-inactive': !element.status, 'uno-table-center': column.type !== this.types.TEXT && column.type !== this.types.DATE}" [ngStyle]="this.getStyle(column)">
							<!-- If the elements attribute is of the Image type -->
							@if (column.type === this.types.IMAGE) {
								<div class="uno-table-center" (click)="this.rowClicked(i, element)">
									@if (element[column.attribute]) {
										<img [ngStyle]="{objectFit: 'cover', width: '30px', height: '30px', 'border-radius': '90px'}" [src]="this.getImage(element[column.attribute].value)" loading="lazy" />
									}
								</div>
							}
							@if (column.type === this.types.ICONS) {
								<div class="uno-table-item uno-table-center" style="padding:0 !important">
									@if (column.icons) {
										@for (icon of column.icons; track icon) {
											<uno-icon [ngStyle]="{width: '30px', height: '30px'}" color="var(--brand-primary)" [width]="30" [height]="30" [src]="this.getImage(icon.src)" (click)="icon.click(element)" />
										}
									}
									@if (element[column.attribute]) {
										@for (icon of element[column.attribute].value; track icon) {
											<uno-icon [ngStyle]="{width: '30px', height: '30px'}" color="var(--brand-primary)" [width]="30" [height]="30" [src]="this.getImage(icon.src)" (click)="icon.click(element)" />
										}
									}
								</div>
							}
							@else if (column.type === this.types.STATUS) {
								<!-- If the elements attribute is of the Status type -->
								<div class="uno-table-item uno-table-status uno-table-center" (click)="this.rowClicked(i, element)">
									<span class="uno-table-status" [style.background-color]="element[column.attribute].value"></span>
								</div>
							}
							@else if (column.type === this.types.NUMBER) {
								<!-- If the elements attribute is of the Number type -->
								<div class="uno-table-item uno-table-number-container uno-table-center" (click)="this.rowClicked(i, element)">
									<span class="uno-table-number">{{element[column.attribute].value !== 0 ? element[column.attribute].value : '-'}}</span>
									@if (element[column.attribute].value !== 0 && element[column.attribute].src) {
										<uno-icon style="width: 32px; height: 32px" [src]="element[column.attribute].icon" [width]="32" [height]="32" [color]="element[column.attribute].value ? 'var(--information-normal)' : 'var(--gray-9)'"></uno-icon>
									}
								</div>
							}
							@else if (column.type === this.types.TEXT) {
								<!-- If the elements attribute is of the Text type -->
								<div class="uno-table-item" (click)="this.rowClicked(i, element)">
									<span class="uno-table-text" [ngStyle]="{'padding-left': element[column.attribute].value ? 0 : '20px', display: element[column.attribute].value ? 'block' : 'flex', width: '100%'}">{{element[column.attribute].value ? element[column.attribute].value : '-' | translate}}</span>
								</div>
							}
							@else if (column.type === this.types.DATE) {
								<!-- If the elements attribute is of the Date type -->
								<div class="uno-table-item" (click)="this.rowClicked(i, element)">
									<span class="uno-table-text" [ngStyle]="{'justify-content': element[column.attribute].value ? 'baseline' : 'center', display: element[column.attribute].value ? 'block' : 'flex', width: '100%'}">{{(element[column.attribute].value ? element[column.attribute].value : '-') | formatDate}}</span>
								</div>
							}
						</div>
					}
				}
			</div>
		</cdk-virtual-scroll-viewport>
	</div>

	<div class="uno-table-legend">
		<span class="uno-table-legend-text">{{locale.get('itemsOf', {n: this.currentItem > this.totalItemCount ? this.totalItemCount : this.currentItem, total: this.totalItemCount})}}</span>

		<div class="uno-table-legend-arrow-container">
			<uno-icon [src]="'assets/components/table/first_hover.svg'" [color]="this.currentPage === 0 ? 'var(--gray-5 )' : 'var(--brand-primary)'" [width]="11" [height]="12" (click)="this.changePage(0)"></uno-icon>
			<uno-icon [src]="'assets/components/table/previous_hover.svg'" [color]="this.currentPage === 0 ? 'var(--gray-5 )' : 'var(--brand-primary)'" [width]="7" [height]="12" (click)="this.changePage(this.currentPage - 1)"></uno-icon>
		</div>

		<span class="uno-table-legend-text">{{locale.get('pagesOf', {n: this.currentPage + 1, total: this.handler.itemPage(this.totalItemCount) + 1})}}</span>

		<div class="uno-table-legend-arrow-container">
			<uno-icon [src]="'assets/components/table/next_hover.svg'" [color]="this.currentPage !== this.handler.itemPage(this.totalItemCount) ? 'var(--brand-primary)' : 'var(--gray-5 )'" [width]="7" [height]="12" (click)="this.changePage(this.currentPage + 1)"></uno-icon>
			<uno-icon [src]="'assets/components/table/last_hover.svg'" [color]="this.currentPage !== this.handler.itemPage(this.totalItemCount) ? 'var(--brand-primary)' : 'var(--gray-5 )'" [width]="11" [height]="12" (click)="this.changePage(this.handler.itemPage(this.totalItemCount))"></uno-icon>
		</div>
	</div>
</div>
@if (this.totalItemCount === 0 || this.handler.items.length === 0 ) {
	<uno-no-data></uno-no-data>
}
