import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation, forwardRef} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR, FormsModule} from '@angular/forms';
import {IonicSelectableComponent} from 'ionic-selectable';
import {TranslateModule} from '@ngx-translate/core';
import {NgStyle} from '@angular/common';
import {IonicModule} from '@ionic/angular';

/**
 * Input option page request.
 */
export class InputOptionsMultipleLazyPageRequest {
	/**
	 * Index offset of the items to request.
	 */
	public from: number = 0;

	/**
	 * Number of elements to fetch.
	 */
	public count: number = 0;

	/**
	 * Textual search filter.
	 */
	public search: string = '';

	/**
	 * Method to be called if an error occurs while processing the request.
	 */
	public onError: ()=> void;

	/**
	 * Method to call when the method finishes, to return the options received and a flag to mark the existence of more elements.
	 */
	public onFinish: (optionsReceived: any[], hasMore: boolean, id: number)=> void;
}

/**
 * Object to carry batch request for multiple option selector.
 *
 * Used to obtain the data of the pre-selected objects.
 */
export class InputOptionsMultipleBatchRequest {
	/**
	 * Options to be fetched from the API.
	 */
	public options: any[];

	/**
	 * Method to provide the options retrieved.
	 */
	public onFinish: (optionsReceived: any[] | any)=> void;
}

/**
 * Form input options box used to lazy load data from the API.
 *
 * Receives function to define the lazyLoad / search / display etc.
 */
@Component({
	selector: 'options-multiple-lazy',
	templateUrl: './options-multiple-lazy.component.html',
	encapsulation: ViewEncapsulation.None,
	styleUrls: ['./options-multiple-lazy.component.css'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => { return OptionsMultipleLazyComponent; }),
			multi: true
		}
	],
	standalone: true,
	imports: [
		IonicModule,
		IonicSelectableComponent,
		FormsModule,
		NgStyle,
		TranslateModule
	]
})
export class OptionsMultipleLazyComponent implements ControlValueAccessor {

	/**
	 * Object Attribute used to identify each individual entry.
	 */
	@Input()
	public identifierAttribute: string = 'uuid';

	/**
	 * Indicate if the user can select multiple assets.
	 *
	 * When selecting multiple, the value stored is as an array.
	 */
	@Input()
	public multiple: boolean = true;

	/**
	 * Allow the input to be disabled.
	 */
	@Input()
	public disabled: boolean = false;

	/**
	 * If set, clear button will be shown on component to clear its value.
	 */
	@Input()
	public showClear: boolean = false;

	/**
	 * If set, clear button will be shown on modal to clear its value.
	 */
	@Input()
	public showClearButton: boolean = true;

	/**
	 * If set, the options will be automatically translated
	 */
	@Input()
	public translate: boolean = true;

	/**
	 * A placeholder to set as option when no option is selected
	 */
	@Input()
	public placeholder: string = 'empty';

	/**
	 * Method used to fetch options.
	 *
	 * Receives as parameter a request object with `{from: number, count: number, search: string, onError: Function(), onFinish: Function(optionsReceived: any[], hasMore: boolean, id: number)}`
	 *
	 * The onFinish method should be called when the options are received, and the onError() should be called when something goes wrong.
	 * 
	 * An optional object may also be passed to be used to filter fetch requests.
	 */
	@Input()
	public fetchOptionsLazy: (request: InputOptionsMultipleLazyPageRequest, object?: any)=> void = null;

	/**
	 * Method used to get a set of options from their identifier value.
	 *
	 * Receives as parameter a request object with `{options: any[], onFinish: Function(optionsReceived: any[])}`
	 * 
	 * An optional object may also be passed to be used to filter fetch requests.
	 */
	@Input()
	public fetchOptionsBatch: (request: {options: any[], onFinish: (optionsReceived: any[])=> void}, object?: any)=> void = null;

	/**
	 * Function that defines the text to be displayed for each option.
	 *
	 * Receives the option object obtained from the API.
	 */
	@Input()
	public getOptionText: (option: any)=> string = () => { return 'n/d'; };

	/**
	 * This object is intended to be passed to the fetchOptions method to filter data.
	 *
	 * Should contain the params passed to the API call in fetchOptions and fetchOptions batch
	 */
	@Input()
	public params: any = null;

	/**
	 * Callback method to be called when the options list is opened.
	 */
	@Input()
	public onOpen: ()=> void = null;

	/**
	 * Callback method to be called when the options list is closed.
	 */
	@Input()
	public onClose: ()=> void = null;

	/**
	 * Callback method to be called when any option is selected or unselected from the open list.
	 *
	 * This method is called even if the value selected does not change.
	 */
	@Input()
	public onSelect: (item: any, isSelected: boolean)=> void = null;

	/**
	 * Callback method to be called when the filters change.
	 */
	@Output()
	public valueChanged = new EventEmitter<any>();

	/**
	 * List of values stored in this component.
	 */
	public value: any = [];

	/**
	 * Indicates if the component is visible or not.
	 *
	 * Used to keep track of the component state and refresh the size of the map
	 */
	public visible: boolean = false;

	/**
	 * Options that are selected on the object and are not yet available from the API.
	 */
	public baseOptions: any[] = [];

	/**
	 * Options available based on the search value inserted.
	 */
	public options: any[] = [];

	/**
	 * Search value inserted.
	 */
	public search: string = '';

	/**
	 * Number of elements to fetch on each request when using lazy loading.
	 */
	public batchSize: number = 20;

	/**
	 * Number of element already loaded from the API, used to control lazy loading.
	 */
	public count: number = 0;

	/**
	 * Load more data from the API to display. Only loads a chunk of data at a time.
	 *
	 * @param component - Component used to control the data flow.
	 * @param reset - If set true it's assumed to be a search or first request, the lazy loader counter is reset and data is cleared.
	 */
	public loadDataLazy(component?: IonicSelectableComponent, reset: boolean = false): void {
		// Auxiliary method to add options to the list.
		const addOptions = (options: any[]): void => {
			for (let i = 0; i < options.length; i++) {
				if (this.baseOptions.find((a) => { return a[this.identifierAttribute] === options[i][this.identifierAttribute]; }) === undefined) {
					options[i]._display = this.getOptionText(options[i]);

					if (!this.options) {
						this.options = [];
					}
					this.options.push(options[i]);
				}
			}
		};

		const onFinish = (optionsReceived: any[], hasMore: boolean, id: number): void => {
			if (reset) {
				if (component !== undefined) {
					component.enableInfiniteScroll();
				}

				this.options = this.baseOptions.concat([]);
				addOptions(optionsReceived);

				this.count = optionsReceived.length;
			} else {
				addOptions(optionsReceived);
				this.count += optionsReceived.length;
			}

			if (component) {
				if (!hasMore) {
					component.disableInfiniteScroll();
				}
				if (reset) {
					component.endSearch();
				}
				component.endInfiniteScroll();
			}
		};

		const onError = (): void => {
			if (reset) {
				this.options = this.baseOptions ? this.baseOptions.concat([]) : [];
				this.count = 0;
			}

			if (component) {
				component.endInfiniteScroll();
			}
		};

		const request: InputOptionsMultipleLazyPageRequest = {
			from: reset ? 0 : this.count,
			count: this.batchSize,
			search: this.search,
			onError: onError,
			onFinish: onFinish
		};

		if (this.fetchOptionsLazy !== null) {
			this.fetchOptionsLazy(request, this.params);
		} else {
			onError();
		}
	}

	/**
	 * Load base options from the API server, these options are the ones selected on the object.
	 *
	 * They need to be fetched to show on the list (their name is not known, if their info is not retrieved).
	 */
	public loadOptionsBatch(): void {
		const onFinish = (options: any[]): void => {
			for (let i = 0; i < options.length; i++) {
				options[i]._display = this.getOptionText(options[i]);
			}

			this.baseOptions = options;
			if (this.options.length === 0) {
				this.options = this.baseOptions.slice(0);
			}
		};

		const request = {
			options: [],
			onFinish: onFinish
		};

		if (!this.value || this.value.length === 0) {
			this.baseOptions = [];
			this.options = [];
			return;
		}

		request.options = this.multiple ? this.value : [this.value];

		if (this.fetchOptionsBatch) {
			this.fetchOptionsBatch(request, this.params);
		}
	}

	/**
	 * The callback method called internally to the event onOpen of the ion-selectable input component.
	 *
	 * @param event - The content associated to the event that triggered this call.
	 */
	public onOpenEvent(event: { component: IonicSelectableComponent }): void {
		if (this.onOpen) {
			this.onOpen();
		}
	}

	/**
	 * The callback method called internally to the event onClose of the ion-selectable input component.
	 *
	 * @param event - The content associated to the event that triggered this call.
	 */
	public onCloseEvent(event: { component: IonicSelectableComponent }): void {
		if (this.onClose) {
			this.onClose();
		}
	}

	/**
	 * The callback method called internally to the event onSelect of the ion-selectable input component.
	 *
	 * @param event - The content associated to the event that triggered this call.
	 */
	public onSelectEvent(event: { component: IonicSelectableComponent, item: any, isSelected: boolean }): void {
		if (this.onSelect) {
			this.onSelect(event.item, event.isSelected);
		}
	}

	/**
	 * Method called when the data is changed.
	 */
	public onChange: (value: any)=> void = function(value) {
		this.valueChanged.emit(value);
	};

	/**
	 * Callback method called when the user inputs something into the search box.
	 */
	public onSearch(event: { component: IonicSelectableComponent, text: string }): void {
		this.search = event.text;
		this.loadDataLazy(event.component, true);
	}

	/**
	 * Callback method used to load more data from the API.
	 */
	public onInfiniteLoad(event: { component: IonicSelectableComponent, text: string }): void {
		this.loadDataLazy(event.component, false);
	}

	public registerOnChange(onChange: any): void {
		this.onChange = onChange;
	}

	public writeValue(value: any): void {
		this.value = value;
		this.loadOptionsBatch();
		this.onChange(this.value);
	}

	public setDisabledState(disabled: boolean): void {
		this.disabled = disabled;
	}

	public registerOnTouched(fn: any): void {}
}
