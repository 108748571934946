<ion-item button="true">
	<div [ngStyle]="{width: this.level * 30 + 'px'}" style="text-align: end;">
		@if (this.canExpand();) {
			<ion-icon (click)="this.expand($event)" name="{{this.expanded ? 'chevron-down-outline' : 'chevron-forward-outline'}}" style="width: 20px; height: 30px;"></ion-icon>
		}
	</div>
	<ion-avatar style="margin: 5px;">
		<ion-img src="{{this.settings.showListPictures && this.item.pictures.length > 0 ? ResourceUtils.getURL(this.item.pictures[0], './assets/placeholder/asset.png') : './assets/placeholder/asset.png'}}"></ion-img>
	</ion-avatar>

	<ion-label (click)="this.editAsset();" button="true">
		{{this.item.name}}
		@if (this.item.tag && this.item.tag.length > 0) {
			[{{this.item.tag}}]
		}
		@if (this.item.description && this.item.description.length > 0) {
			<h3>
				{{this.item.description}}
			</h3>
		}
	</ion-label>

	@if (app.device.isDesktop()) {
		<!-- Generate QR Code -->
		<ion-icon (click)="$event.cancelBubble = true; generateQr(this.item.uuid);" name="qr-code-outline" slot="end"></ion-icon>
		<!-- Open new tab -->
		<ion-icon (click)="app.openInTab('/menu/asset-portfolio/asset/edit', {uuid: this.item.uuid}, $event);" name="desktop-outline" slot="end"></ion-icon>
	}
</ion-item>

@if (this.expanded) {
	@for (child of this.item.children; track child) {
		<asset-tree-list-item [item]="child" [level]="this.level + 1" [search]="this.search" [searchFields]="this.searchFields" [sortDirection]="this.sortDirection" [sortField]="this.sortField"></asset-tree-list-item>
	}
}
