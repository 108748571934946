import {Resource} from '../../resource';
import {UUID, UUIDIdentification} from '../../uuid';
import {InspectionDataPath} from '../../inspections/inspection/inspection-data';
import {GAGapStatus} from './gap-status';
import {GAGapPriority} from './gap-priority';

export class GAGap extends UUIDIdentification {
	/**
	 * Description of the gap.
	 */
	public description: string = '';

	/**
	 * Status of the gap from the list of GAGapStatus.
	 */
	public status: GAGapStatus = GAGapStatus.WAITING;

	/**
	 * Defines the level of priority that the gap should be handled from the list of GAGapPriority.
	 */
	public priority: GAGapPriority = GAGapPriority.MEDIUM;

	/**
	 * Estimative of the financial impact of this gap (in Euros).
	 */
	public financialImpact: number = 0;

	/**
	 * Documents related to the gap.
	 * 
	 * (e.g. previous budgets, photos, data sheets)
	 */
	public documents: Resource[] = [];

	/**
	 * The UUID of the inspection data.
	 * 
	 * Inspection data contains the data of the inspection step.
	 */
	public inspectionDataUuid: UUID = null;

	/**
	 * The path of of the inspection field.
	 * 
	 * Composed of UUIDs and index values. Each UUID corresponds to an inspection field UUID.
	 * 
	 * Indicates the path to the inspection field that generated the gap through the sub-forms used to composed the inspection form.
	 */
	public inspectionFieldPath: InspectionDataPath = [];

	/**
	 * Parse JSON data received from the API into an object of the correct type.
	 * 
	 * @param data - Object received from API.
	 * @returns Gap object instance built from the data received.
	 */
	public static parse(data: any): GAGap {
		const gap = new GAGap();

		gap.uuid = data.uuid;
		gap.updatedAt = new Date(data.updatedAt);
		gap.createdAt = new Date(data.createdAt);

		gap.description = data.description;
		gap.status = data.status;
		gap.priority = data.priority;
		gap.financialImpact = data.financialImpact;
		gap.documents = data.documents;
		gap.inspectionDataUuid = data.inspectionDataUuid;
		gap.inspectionFieldPath = data.inspectionFieldPath || [];

		return gap;
	}
}
