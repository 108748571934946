import {UUID} from '../../../models/uuid';
import {Service} from '../../../http/service';
import {ServiceList} from '../../../http/service-list';
import {Session} from '../../../session';
import {FormSortUtils} from '../../../utils/form-sort-utils';
import {APAssetFormBlock} from '../../../models/asset-portfolio/asset-form-block';

// Filters to apply on the Asset Form Block List.
export type AssetFormBlockListParams = {
	// Search value to apply
	search?: string,
	// Fields to search by
	searchFields?: string[],
	// From where to start fetching items
	from?: number,
	// How many items to fetch
	count?: number,
	// Sort Direction to apply on the response
	sortDirection?: string,
	// Sort Field to apply on the response
	sortField?: string,
};

// Filters to apply on the Asset Form Block Count.
export type AssetFormBlockCountParams = {
	// Search value to apply
	search?: string,
	// Fields to search by
	searchFields?: string[],
};

// Asset form block list API request format.
export type APAssetFormBlockListResponse = {
	// If true, there are more items to fetch on the list.
	hasMore: boolean,
	// The fetched Asset Form Blocks.
	blocks: APAssetFormBlock[],
	// The id of the api call.
	id: number
};

export class AssetFormBlockService {
	/**
	 * Get a form block by its UUID
	 */
	public static async get(uuid: UUID): Promise<APAssetFormBlock> {
		const request = await Service.fetch(ServiceList.assetPortfolio.formBlock.get, null, null, {uuid: uuid}, Session.session);
			
		const block: APAssetFormBlock = APAssetFormBlock.parse(request.response.block);
		block.fields = FormSortUtils.sortByIndexes(block.fields);

		return block;
	}

	/*
	 * List all Asset Form Blocks matching the params.
	 */
	public static async list(params: AssetFormBlockListParams): Promise<APAssetFormBlockListResponse> {
		const request = await Service.fetch(ServiceList.assetPortfolio.formBlock.list, null, null, params, Session.session);

		return {
			hasMore: request.response.hasMore,
			blocks: request.response.blocks.map((data: any) => {return APAssetFormBlock.parse(data);}),
			id: request.id
		};
	}
	
	/**
	 * Count all Asset Form Blocks matching the params.
	 */
	public static async count(params: AssetFormBlockCountParams): Promise<number> {
		return (await Service.fetch(ServiceList.assetPortfolio.formBlock.count, null, null, params, Session.session)).response.count;
	}
}
