/**
 * Service type contains the type of HTTP services supported by the platform.
 */
export const ServiceType = {
	GET: 'GET',
	POST: 'POST',
	DELETE: 'DELETE',
	PUT: 'PUT',
	UPDATE: 'UPDATE'
};
