import {Resource} from '../../../../models/resource';
import {RepairSettings} from './repair-settings';

export class RepairSettingsLoader {
	/**
	 * Parse the data from API for a settings object.
	 *
	 * @param data - settings object to parse data
	 */
	public static parse(data: any): RepairSettings {
		const settings = new RepairSettings();

		settings.reportTemplate = Resource.parse(data.reportTemplate);
		settings.proposalTemplate = Resource.parse(data.proposalTemplate);
		settings.inspectionReportTemplate = Resource.parse(data.inspectionReportTemplate);

		return settings;
	}
}
