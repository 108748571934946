import {Service} from '../../../http/service';
import {ServiceList} from '../../../http/service-list';
import {Session} from '../../../session';
import {FileUtils} from '../../../utils/file-utils';
import {Locale} from '../../../locale/locale';
import {Modal} from '../../../modal';
import {XlsxUtils} from '../../../utils/xlsx-utils';
import {ProgressBar} from '../../../progress-bar';
import {ServiceResponse} from '../../../http/service-response';
import {EPIBarrierTypeLabel} from '../../../models/epi/epi-barrier';
import {EPIStateLabel} from '../../../models/epi/epi-state';
import {EPIResultLabel} from '../../../models/epi/epi-result';
import {EPI} from '../../../models/epi/epi';

export class EPIExport {
	/**
	 * Export EPI as JSON file, contains all the data stored in the database for the EPI entry.
	 */
	public static async exportJSON(): Promise<void> {
		let from: number = 0;
		const count: number = 300;
		const epi: any[] = [];

		const progress = new ProgressBar();
		progress.show();

		try {
			const total = (await Service.fetch(ServiceList.epi.count, null, null, null, Session.session, true)).response.count;
			if (total > 0) {
				while (true) {
					progress.update(Locale.get('loadingData'), from / total);

					const request = await Service.fetch(ServiceList.epi.list, null, null, {from: from, count: count, status: -1}, Session.session, true);
					const response = request.response;

					for (let i = 0; i < response.epi.length; i++) {
						epi.push(EPI.parse(response.epi[i]));
					}

					from += response.epi.length;
					if (!response.hasMore) {
						break;
					}
				}

				FileUtils.writeFile('epi.json', JSON.stringify(epi, null, '\t'));
			}
		} catch (e) {
			Modal.alert(Locale.get('error'), Locale.get('errorExport'));
			console.error('EQS: Error exporting file.', e);
		}

		progress.destroy();
	}

	/**
	 * Export EPI as an excel file.
	 */
	public static async exportExcel(): Promise<void> {
		let from: number = 0;
		const count: number = 300;

		const data: any[][] = [[Locale.get('uuid'), Locale.get('name'), Locale.get('description'), Locale.get('tag'), Locale.get('barrierId'),
			Locale.get('barrierType'), Locale.get('state'), Locale.get('plant'), Locale.get('operationalUnit'),
			Locale.get('result'), Locale.get('createdAt'), Locale.get('updatedAt')]];

		const progress = new ProgressBar();
		progress.show();

		try {
			const total = (await Service.fetch(ServiceList.epi.count, null, null, null, Session.session, true)).response.count;
			if (total > 0) {
				let request: ServiceResponse;

				do {
					progress.update(Locale.get('loadingData'), from / total);

					request = await Service.fetch(ServiceList.epi.list, null, null, {
						from: from,
						count: count
					}, Session.session, true);

					for (let i = 0; i < request.response.epi.length; i++) {
						const epi = request.response.epi[i];
						const row = [epi.uuid, epi.name, epi.description, epi.tag, epi.barrierId,
							Locale.get(EPIBarrierTypeLabel.get(epi.barrierType)), Locale.get(EPIStateLabel.get(epi.state)), epi.plant, epi.operationalUnit,
							Locale.get(EPIResultLabel.get(epi.result)), epi.createdAt, epi.updatedAt];

						data.push(row);
					}

					from += request.response.epi.length;
				} while (request.response.hasMore);

				// Generate and export document
				XlsxUtils.writeFile(data, 'epi.xlsx');
			}
		} catch (e) {
			Modal.alert(Locale.get('error'), Locale.get('errorExport'));
			console.error('EQS: Error exporting file.', e);
		}

		progress.destroy();
	}
}
