import {cloneDeep} from 'lodash-es';
import {Component, OnInit, ViewChild} from '@angular/core';
import {UnoResponsiveTableListComponent} from 'src/app/components/uno/uno-responsive-table-list/uno-responsive-table-list.component';
import {UnoTableColumnLayout, UnoTableColumnType} from 'src/app/components/uno/uno-table/uno-table.component';
import {DL50RegulatoryStandardsListParams, DL50RegulatoryStandardsListResponse, DL50Service} from 'src/app/modules/dl50/services/dl50.service';
import {DL50RegulatoryStandard} from 'src/app/models/dl50/dl50-regulatory-standard';
import {Modal} from 'src/app/modal';
import {Locale} from 'src/app/locale/locale';
import {UUID} from 'src/app/models/uuid';
import {UnoButtonComponent} from 'src/app/components/uno/uno-button/uno-button.component';
import {TranslateModule} from '@ngx-translate/core';
import {UnoSearchbarComponent} from 'src/app/components/uno/uno-searchbar/uno-searchbar.component';
import {UnoContentComponent} from 'src/app/components/uno/uno-content/uno-content.component';
import {Session} from 'src/app/session';
import {UnoFormField} from 'src/app/components/uno-forms/uno-dynamic-form/uno-form-field';
import {DynamicFormModalComponentButton} from 'src/app/components/uno-forms/uno-form-modal/uno-form-modal.component';
import {UnoDynamicFormComponent} from 'src/app/components/uno-forms/uno-dynamic-form/uno-dynamic-form.component';
import {DL50RegulatoryStandardLayout} from '../dl50-regulatory-standard-layout';
import {UserPermissions} from '../../../../../../models/users/user-permissions';
import {ScreenComponent} from '../../../../../../components/screen/screen.component';
import {App} from '../../../../../../app';

/**
 * Page used to list dl50 regulatory standards.
 */
@Component({
	selector: 'dl50-regulatory-standards-list-page',
	templateUrl: 'dl50-regulatory-standards-list.page.html',
	standalone: true,
	imports: [
		UnoButtonComponent,
		UnoSearchbarComponent,
		UnoContentComponent,
		UnoResponsiveTableListComponent,
		TranslateModule
	]
})
export class DL50RegulatoryStandardsListPage extends ScreenComponent implements OnInit {
	/**
	 * The permissions to access this screen.
	 */
	public permissions = [UserPermissions.DL50_MASTER_DATA_REGULATORY_STANDARDS_LIST];

	@ViewChild(UnoResponsiveTableListComponent) 
	public table: UnoResponsiveTableListComponent;
		
	public get session(): any {return Session;}

	public get app(): any {return App;}

	public get userPermissions(): any {return UserPermissions;}
	
	public get selfStatic(): any { return DL50RegulatoryStandardsListPage; }
	
	/**
	 * The total number of items stored on API.
	 */
	public totalItems: number = 0;

	/**
 	 * The number of items to present on table per page.
 	 */
	public tablePageSize: number = 30;
	
	/**
	 * The items that were already fetched from API.
	 */
	public loadedItems: DL50RegulatoryStandard[] = [];

	/**
	  * The layout to use on the table.
	  */
	public tableLayout: UnoTableColumnLayout[] = [
		{header: 'label', type: UnoTableColumnType.TEXT, attribute: 'label', visible: true, size: 'small'},
		{header: 'description', type: UnoTableColumnType.TEXT, attribute: 'description', visible: true, size: 'small'}
	];

	public static filters: any = {
		/**
		 * Text used to filter items by.
		 * 
		 * The search is perform in any of the textual .
		 */
		search: '',
		
		/**
		 * The fields to search on the DL50 regulatory standards table
		 */
		searchFields: [
			'[dl50_regulatory_standard].[label]',
			'[dl50_regulatory_standard].[description]'
		]
	};

	/**
	 * Loads table data.
	 */
	public async loadData(): Promise<void> {
		this.totalItems = await DL50Service.regulatoryStandardsCount({
			search: DL50RegulatoryStandardsListPage.filters.search,
			searchFields: DL50RegulatoryStandardsListPage.filters.searchFields
		});
		
		if (this.table) {
			this.loadedItems = [];
			this.table.reset();
		}
	}

	/**
	 * Update filters and reload data from the API if required.
	 *
	 * @param search - Search value.
	 */
	public async onSearch(search: string): Promise<void> {
		DL50RegulatoryStandardsListPage.filters.search = search;
		
		await this.loadData();
	}

	/**
	 * Fetches more items from API to display on table in a paginated way.
	 * 
	 * @param count - The first item 'index' to get from the API.
	 * @param pageSize - How many items to fetch from the first one.
	 * @returns A list of items to display on table and a hasMore boolean telling if there are any more items to fetch from API.
	 */
	public loadMore = async(count: number, pageSize: number): Promise<any> => {
		const params: DL50RegulatoryStandardsListParams = {
			from: count,
			count: pageSize,
			search: DL50RegulatoryStandardsListPage.filters.search,
			searchFields: DL50RegulatoryStandardsListPage.filters.searchFields
		};

		const request: DL50RegulatoryStandardsListResponse = await DL50Service.regulatoryStandardsList(params);

		const rowsdata: any[] = [];
		for (const standard of request.standards) {
			// Keep object locally
			this.loadedItems.push(standard);

			const row: any = structuredClone(standard);

			row.delete = [
				{
					src: './assets/icons/uno/bin-icon.svg',
					click: (): void => {
						this.deleteRegulatoryStandard(standard.uuid);
					}
				}
			];

			rowsdata.push(row);
		}

		return {
			elements: rowsdata,
			hasMore: request.hasMore
		};
	};

	public async ngOnInit(): Promise<void> {
		super.ngOnInit();

		if (Session.hasPermissions(UserPermissions.DL50_MASTER_DATA_REGULATORY_STANDARDS_DELETE)) {
			this.tableLayout.push({header: 'delete', type: UnoTableColumnType.ICONS, attribute: 'delete', visible: true, size: 'small'});
		}

		// Load table data
		await this.loadData();


		App.navigator.setTitle('standards');
	}

	/**
	 * Updates a regulatory standard and reloads table data.
	 * 
	 * A UUID of the regulatory standard may be received to edit a specific regulatory standard. If no UUID provided, a new regulatory standard will be created.
	 * 
	 * @param uuid - The uuid of the regulatory standard to edit (optional).
	 */
	public async save(uuid?: UUID): Promise<void> {
		let standard: DL50RegulatoryStandard;
		if (uuid) {
			const q: DL50RegulatoryStandard = this.loadedItems.find((item: DL50RegulatoryStandard) => { return item.uuid === uuid; });
			standard = structuredClone(q);
		} else {
			standard = new DL50RegulatoryStandard();
		}

		const canEdit: boolean = uuid && Session.hasPermissions(UserPermissions.DL50_MASTER_DATA_REGULATORY_STANDARDS_EDIT) || !uuid && Session.hasPermissions(UserPermissions.DL50_MASTER_DATA_REGULATORY_STANDARDS_CREATE);

		const layout: UnoFormField[] = structuredClone(DL50RegulatoryStandardLayout);

		const buttons: DynamicFormModalComponentButton[] = [{success: false, label: uuid ? 'close' : 'cancel', color: 'primary'}];

		if (canEdit) {
			buttons.push({
				success: true,
				label: uuid ? 'save' : 'create',
				color: 'primary',
				callback: async(object) => {
					try {
						if (object.uuid) {
							// Update regulatory question
							await DL50Service.regulatoryStandardUpdate(standard);
						} else {
							// Create regulatory question
							await DL50Service.regulatoryStandardCreate(standard);
						}
			
						Modal.toast(Locale.get('success'), 3000, 'success');
						
						await this.loadData();
					} catch {
						Modal.toast(Locale.get('error'), 3000, 'danger');
					}
				}
			});
		}

		await Modal.form(Locale.get('standards'), standard, layout, buttons, canEdit);
		
		if (!UnoDynamicFormComponent.requiredFilled(layout, standard)) {
			Modal.alert(Locale.get('error'), Locale.get('requiredFieldsError'));
			return;
		}
	}

	/**
	 * Deletes a regulatory standard from API.
	 * 
	 * @param standard - The uuid of the regulatory standard to be deleted.
	 */
	public async deleteRegulatoryStandard(uuid: UUID): Promise<void> {
		if (Session.hasPermissions(UserPermissions.DL50_MASTER_DATA_REGULATORY_STANDARDS_DELETE)) {
			throw new Error('User does not have permissions to delete a regulatory standard');
		}

		// Ask to confirm deletion
		const confirm = await Modal.confirm(Locale.get('confirm'), Locale.get('confirmDelete'));
		if (confirm) {
			try {
				await DL50Service.regulatoryStandardDelete(uuid);
				Modal.toast(Locale.get('success'), 3000, 'success');
				await this.loadData();
			} catch {
				Modal.toast(Locale.get('error'), 3000, 'danger');
			}
		}
	}
}
