import {Component, OnInit} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {NgStyle} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {Session} from '../../../../../session';
import {Locale} from '../../../../../locale/locale';
import {Service} from '../../../../../http/service';
import {ServiceList} from '../../../../../http/service-list';
import {DoughnutChartData, DoughnutChartComponent} from '../../../../../components/charts/doughnut-chart/doughnut-chart.component';
import {CSSUtils} from '../../../../../utils/css-utils';

@Component({
	selector: 'graph-repairs-states',
	templateUrl: './repair-states-graph.component.html',
	standalone: true,
	imports: [IonicModule, DoughnutChartComponent, NgStyle, TranslateModule]
})
export class RepairStatesGraphComponent implements OnInit {
	public data: DoughnutChartData[] = [];

	public ngOnInit(): void {
		Service.call(ServiceList.repairs.countStatusAll, null, null, null, Session.session, (response, xhr) => {
			this.data = [
				new DoughnutChartData(Locale.get('waitingProposal'), response.waitingProposal, CSSUtils.parseColor('rgb(255, 99, 132)')),
				new DoughnutChartData(Locale.get('waitingProposalApproval'), response.waitingProposalApproval, CSSUtils.parseColor('rgb(128, 64, 188)')),
				new DoughnutChartData(Locale.get('onGoing'), response.onGoing, CSSUtils.parseColor('rgb(255, 206, 86)')),
				new DoughnutChartData(Locale.get('qualityAssurance'), response.waitingDone, CSSUtils.parseColor('rgb(54, 162, 235)')),
				new DoughnutChartData(Locale.get('implemented'), response.implemented, CSSUtils.parseColor('rgb(75, 192, 104)')),
				new DoughnutChartData(Locale.get('blocked'), response.blocked, CSSUtils.parseColor('rgb(255, 30, 60)')),
				new DoughnutChartData(Locale.get('archived'), response.archived, CSSUtils.parseColor('rgb(56, 125, 120)'))
			];
		}, undefined, true);
	}
}
