import {Component, OnInit} from '@angular/core';
import {App} from '../../../../app';
import {Session} from '../../../../session';
import {ScreenComponent} from '../../../../components/screen/screen.component';
import {UserPermissions} from '../../../../models/users/user-permissions';
import {MenuCardsOptions, MenuCardsComponent} from '../../../../components/layout/menu-cards/menu-cards.component';
import {EPIState} from '../../../../models/epi/epi-state';
import {Service} from '../../../../http/service';
import {ServiceList} from '../../../../http/service-list';
import {UnoContentComponent} from '../../../../components/uno/uno-content/uno-content.component';

@Component({
	selector: 'epi-page',
	templateUrl: 'epi.page.html',
	standalone: true,
	imports: [UnoContentComponent, MenuCardsComponent]
})
export class EPIPage extends ScreenComponent implements OnInit {
	public get app(): any { return App; }


	public get session(): any { return Session; }

	public permissions = [UserPermissions.EPI];

	/**
	 * List of options in this menu.
	 */
	public options: MenuCardsOptions[] = [
		{
			label: 'create',
			route: '/menu/epi/edit',
			data: {createMode: true},
			icon: 'add',
			permissions: [UserPermissions.EPI_CREATE],
			badge: null
		},
		{
			label: 'todo',
			route: '/menu/epi/list',
			data: {state: EPIState.TODO},
			icon: 'hammer',
			permissions: [],
			badge: (async() => {
				return (await Service.fetch(ServiceList.epi.count, null, null, {state: EPIState.TODO}, Session.session, true)).response.count;
			})()
		},
		{
			label: 'backoffice',
			route: '/menu/epi/list',
			data: {state: EPIState.BACKOFFICE},
			icon: 'search',
			permissions: [],
			badge: (async() => {
				return (await Service.fetch(ServiceList.epi.count, null, null, {state: EPIState.BACKOFFICE}, Session.session, true)).response.count;
			})()
		},
		{
			label: 'completed',
			route: '/menu/epi/list',
			data: {state: EPIState.COMPLETED},
			icon: 'checkbox-outline',
			permissions: [],
			badge: (async() => {
				return (await Service.fetch(ServiceList.epi.count, null, null, {state: EPIState.COMPLETED}, Session.session, true)).response.count;
			})()
		}
	];

	public ngOnInit(): void {
		super.ngOnInit();
		
		App.navigator.setTitle('epi');
	}
}
