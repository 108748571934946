<uno-list-item (click)="this.editCompany();">
	<uno-list-item-icon src="{{ResourceUtils.getURL(company.picture, './assets/placeholder/profile.png')}}">
		<div [ngStyle]="{width: this.level * 30 + 'px'}" style="text-align: end;">
			@if (this.company.hasChildren) {
				<ion-icon (click)="this.expand($event)" name="{{this.expanded ? 'chevron-down-outline' : 'chevron-forward-outline'}}" style="width: 20px; height: 30px;  margin-right: 5px;"></ion-icon>
			}
		</div>
	</uno-list-item-icon>

	<uno-list-item-label>
		<h2>{{this.company.name}}</h2>
	</uno-list-item-label>
</uno-list-item>

@if (this.company.hasChildren && this.expanded) {
	@for (child of company.children; track child) {
		<company-list-item [level]="this.level + 1" [company]="child"></company-list-item>
	}
}
