import {Resource} from '../resource';
import {UUID, UUIDIdentification} from '../uuid';

/**
 * Users are grouped in teams, a workflow should be done through the same team.
 *
 * When the inspector picks up a inspection that inspection becomes responsibility of its team.
 */
export class Team extends UUIDIdentification {	
	/**
	 * Name of the team as show to the users.
	 */
	public name: string = '';

	/**
	 * Description of the team.
	 */
	public description: string = '';

	/**
	 * Picture of the team.
	 */
	public picture: Resource = null;

	/**
	 * Parent team ID (possibly null)
	 */
	public parent: UUID = null;

	/**
	 * User IDs that belong to this team
	 */
	public users: UUID[] = [];

	/**
	 * Parse JSON data received from the API into an object.
	 *
	 * @param data - Object received from API.
	 * @returns Team object instance built from the data received.
	 */
	public static parse(data: any): Team {
		const team = new Team();
		team.uuid = data.uuid;
		team.createdAt = new Date(data.createdAt);
		team.updatedAt = new Date(data.updatedAt);

		team.name = data.name;
		team.description = data.description;
		team.picture = Resource.parse(data.picture);
		team.parent = data.parent;
		team.users = data.users || [];

		return team;
	}
}
