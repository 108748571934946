import {Resource} from '../resource';
import {UnitConverter} from '../../utils/unit-converter';
import {UUID, UUIDIdentification} from '../uuid';
import {EPIResult} from './epi-result';
import {EPIBarrierType} from './epi-barrier';
import {EPIState} from './epi-state';

/**
 * EPI stands for Intrinsic Protection Equipment these equipments are used to limit power draw from other equipments.
 *
 * Equipments are organized in loops that are protected by the same EPI, that EPI has to be correctly setup to meet the power requirements and tolerances of the loop.
 */
export class EPI extends UUIDIdentification {
	/**
	 * Name of the protection loop.
	 */
	public name: string = '';

	/**
	 * Tag of the protection equipment.
	 */
	public tag: string = '';

	/**
	 * Description of the loop.
	 */
	public description: string = '';

	/**
	 * Identifier of the barrier.
	 */
	public barrierId: string = '';

	/**
	 * Type of the barrier used can be galvanic or Zener.
	 */
	public barrierType: number = EPIBarrierType.NONE;

	/**
	 * State of the EPI inspection.
	 *
	 * Zener EPI have fields fto be done by the backoffice team.
	 */
	public state: number = EPIState.TODO;

	/**
	 * Plant loop description.
	 */
	public plant: string = '';

	/**
	 * Operational unit loop installation description.
	 */
	public operationalUnit: string = '';

	/**
	 * Final result of the EPI inspection calculated by the parameters inserted in the data field.
	 */
	public result: number = EPIResult.NONE;

	/**
	 * Equipments that belong to this loop.
	 */
	public equipments: UUID[] = [];

	/**
	 * Diagram of the EPI.
	 */
	public diagram: Resource = null;

	/**
	 * Data of the EPI inspection.
	 *
	 * Structure may differ depending on the type of EPI.
	 */
	public data: EPIData = new EPIData();

	/**
	 * Parse the data of an API retrieved EPI object.
	 *
	 * @param data - EPI object to parse data
	 */
	public static parse(data: any): EPI {
		const epi = new EPI();

		epi.uuid = data.uuid;
		epi.name = data.name;
		epi.description = data.description;
		epi.barrierType = data.barrierType;
		epi.barrierId = data.barrierId;
		epi.state = data.state;
		epi.plant = data.plant;
		epi.operationalUnit = data.operationalUnit;
		epi.result = data.result;
		epi.diagram = Resource.parse(data.diagram);
		epi.equipments = data.equipments || [];

		if (data.tag !== undefined) {
			epi.tag = data.tag;
		}

		// Barrier data
		Object.assign(epi.data.barrier, data.data.barrier);

		// Equipment data
		Object.assign(epi.data.equipment, data.data.equipment);

		// Check if cables array exists
		if (!data.data.cables) {
			data.data.cables = [];
		}

		// Update old cable attribute to cables array if necessary
		if (data.cable !== undefined) {
			const cable = new EPICableData();
			cable.inductanceSection = data.data.cable.inductanceSection;
			cable.inductance = data.data.cable.inductance;
			cable.capacitySection = data.data.cable.capacitySection;
			cable.capacity = data.data.cable.capacity;
			cable.length = data.data.cable.length;
			cable.comments = data.data.cable.comments;
			data.data.cables.push(data.data.cable);
		}

		// Cables data
		epi.data.cables = [];
		for (let i = 0; i < data.data.cables.length; i++) {
			const cable: EPICableData = new EPICableData();
			Object.assign(cable, data.data.cables[i]);
			epi.data.cables.push(cable);
		}

		return epi;
	}
}

/**
 * Data of the EPI inspection, contains the barrier, equipment and cable data.
 */
export class EPIData {
	/**
	 * Protection barrier related data.
	 */
	public barrier: EPIBarrierData = new EPIBarrierData();

	/**
	 * Equipment related data.
	 */
	public equipment: EPIEquipmentData = new EPIEquipmentData();

	/**
	 * List of the specification of all cables available.
	 *
	 * These will be combined to calculate the final cable spec.
	 */
	public cables: EPICableData[] = [new EPICableData()];
}

export class EPIBarrierData {
	/**
	 * Photos of the EPI barrier equipment.
	 */
	public photos: Resource[] = [];

	/**
	 * Documents related with the barrier.
	 */
	public documents: Resource[] = [];

	/**
	 * Photos of the equipment tag.
	 */
	public tagPhotos: Resource[] = [];

	/**
	 * Comments about the barrier installation.
	 */
	public comments: string = '';

	/**
	 * Maximum output voltage measured in (V)
	 */
	public maxOutputVoltage: number = null;

	/**
	 * Maximum output current measured in (A)
	 */
	public maxOutputCurrent: number = null;

	/**
	 * Maximum output power measured in (W)
	 */
	public maxOutputPower: number = null;

	/**
	 * Maximum external capacity measured in (uF)
	 */
	public maxExternalCapacity: number = null;

	/**
	 * Max external inductance measured in (mH)
	 */
	public maxExternalInductance: number = null;

	/**
	 * Resistance to ground (Ohm), only applicable in zener barriers.
	 */
	public groundResistance: number = null;
}

export class EPIEquipmentData {
	/**
	 * Documents related with the EPI equipment.
	 */
	public documents: Resource[] = [];

	/**
	 * Photos of the equipments covered by the barrier.
	 */
	public photos: Resource[] = [];

	/**
	 * Photos of the equipments tag.
	 */
	public tagPhotos: Resource[] = [];

	/**
	 * Comments about the equipments in the loop and their installation.
	 */
	public comments: string = '';

	/**
	 * Maximum input voltage measured in (V)
	 */
	public maxInputVoltage: number = null;

	/**
	 * Maximum input current measured in (A)
	 */
	public maxInputCurrent: number = null;

	/**
	 * Maximum input power measured in (W)
	 */
	public maxInputPower: number = null;

	/**
	 * Maximum internal capacity measured in (uF)
	 */
	public maxInternalCapacity: number = null;

	/**
	 * Maximum internal inductance measured in (mH)
	 */
	public maxInternalInductance: number = null;
}

export class EPICableData {
	/**
	 * Comments about the cable being used.
	 */
	public comments: string = '';

	/**
	 * Length of the cable in (m)
	 */
	public length: number = null;

	/**
	 * Resistance per kilometer of the cable in (ohm/km)
	 */
	public resistanceSection: number = null;

	/**
	 * Total resistance of the cable in (ohm)
	 */
	public resistance: number = null;

	/**
	 * Inductance per kilometer of the cable in (mH/km)
	 */
	public inductanceSection: number = null;

	/**
	 * Total inductance of the cable in (mH)
	 */
	public inductance: number = null;

	/**
	 * Capacity per kilometer of the cable in (uF/km)
	 */
	public capacitySection: number = null;

	/**
	 * Total capacity of the cable in (uF)
	 */
	public capacity: number = null;

	/**
	 * Calculate attributes based of the existing ones based on the attribute that was updated.
	 *
	 * @param attribute - Name of the attribute updated by the user.
	 */
	public calculateAttributes(attribute: string): void {
		if (this.length !== null) {
			const lengthKm = UnitConverter.convert(this.length, 'm', 'km');

			if (attribute === 'resistance' && this.resistance !== null) {
				this.resistanceSection = lengthKm / this.resistance;
			}
			if (attribute === 'resistanceSection' && this.resistanceSection !== null) {
				this.resistance = this.resistanceSection * lengthKm;
			}

			if (attribute === 'capacity' && this.capacity !== null) {
				this.capacitySection = lengthKm / this.capacity;
			}
			if (attribute === 'capacitySection' && this.capacitySection !== null) {
				this.capacity = this.capacitySection * lengthKm;
			}

			if (attribute === 'inductance' && this.inductance !== null) {
				this.inductanceSection = lengthKm / this.inductance;
			}
			if (attribute === 'inductanceSection' && this.inductanceSection !== null) {
				this.inductance = this.inductanceSection * lengthKm;
			}

			if (attribute === 'length') {
				if (this.resistanceSection !== null) {
					this.resistance = this.resistanceSection * lengthKm;
				} else if (this.resistance !== null) {
					this.resistanceSection = lengthKm / this.resistance;
				}

				if (this.capacitySection !== null) {
					this.capacity = this.capacitySection * lengthKm;
				} else if (this.capacity !== null) {
					this.capacitySection = lengthKm / this.capacity;
				}

				if (this.inductanceSection !== null) {
					this.inductance = this.inductanceSection * lengthKm;
				} else if (this.inductance !== null) {
					this.inductanceSection = lengthKm / this.inductance;
				}
			}
		}
	}
}
