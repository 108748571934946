import {Service} from '../../../http/service';
import {ServiceList} from '../../../http/service-list';
import {Session} from '../../../session';
import {User} from '../../../models/users/user';

export type UserServiceListParams = {
	from?: number,
	count?: number,
	search?: string,
	searchFields?: string[],
	sortField?: string,
	sortDirection?: string,
	filterActive?: number,
};

export class UserService {
	/**
	 * Get a user by its UUID.
	 *
	 * @param uuid - The UUID of the user.
	 * @returns The user object.
	 */
	public static async get(uuid: string): Promise<User> {
		const request = await Service.fetch(ServiceList.users.get, null, null, {uuid: uuid}, Session.session);
		
		return User.parse(request.response.user);
	}

	/**
	 * List all users meeting the parameters.
	 *
	 * @param params - The parameters to apply on the fetch request.
	 */
	public static async listUsers(params: UserServiceListParams): Promise<{hasMore: boolean, users: User[]}> {
		const request = await Service.fetch(ServiceList.users.list, null, null, params, Session.session);
		
		return {
			hasMore: request.response.hasMore,
			users: request.response.users.map((user) => {
				return User.parse(user);
			})
		};
	}

	/**
	 * Count all users meeting the parameters.
	 *
	 * @param params - The parameters to apply on the fetch request.
	 */
	public static async countUsers(params: UserServiceListParams): Promise<number> {
		const request = await Service.fetch(ServiceList.users.count, null, null, params, Session.session);
			
		return request.response.count;
	}
}
