<uno-content>
	<div style="display: flex; flex-direction: row-reverse; gap: 5px;" class="ion-padding">
		@if (session.hasPermissions(userPermissions.PERM_INSPECTION_EXPORT)) {
			<uno-button icon="assets/icons/uno/document-icon.svg" (click)="this.exportProjectInspectionsXLSX();">
				@if (app.device.isDesktop()) {
					{{'exportAllXlsx' | translate}}
				}
			</uno-button>
		}
	</div>

	<!-- Dashboard -->
	@if (session.hasPermissions([userPermissions.INSPECTION_PROJECT_DASHBOARD_STEPS])) {
		<ion-card-content>
			<ion-grid>
				<ion-row>
					<ion-col size="12" size-md="6">
						<inspection-steps-graph [project]="this.projectUuid"></inspection-steps-graph>
					</ion-col>
				</ion-row>
			</ion-grid>
		</ion-card-content>
	}

	@if (this.steps.length === 0) {
		<uno-no-data></uno-no-data>
	}
	@if (this.steps.length !== 0) {
		<!-- Mobile -->
		@if (app.device.isMobile()) {
			<ion-list>
				<ion-title class="ion-margin-vertical" color="primary">{{'global' | translate}}</ion-title>
				<!-- Create -->
				<ion-item (click)="app.navigator.navigate('/menu/inspection/edit', {createMode: true, project: this.projectUuid})" button="true">
					<ion-icon name="add" style="padding: 10px;"></ion-icon>
					<ion-label>{{'create' | translate }}</ion-label>
					<ion-icon name="caret-forward-outline" slot="end"></ion-icon>
				</ion-item>
				<!-- All -->
				<ion-item (click)="app.navigator.navigate('/menu/inspection/list', {project: this.projectUuid})" button="true">
					<ion-icon name="list" style="padding: 10px;"></ion-icon>
					<ion-label>{{'list' | translate }}</ion-label>
					<div slot="end">
						@if (this.count !== null) {
							<ion-badge color="primary" mode="ios">{{this.count | async}}</ion-badge>
						}
						<ion-icon name="caret-forward-outline"></ion-icon>
					</div>
				</ion-item>
				<!-- Steps -->

				@for (s of this.status; track s) {
					@if (this.stepsByStatus.get(s).length > 0) {
						<div>
							<ion-title class="ion-margin-vertical" color="primary">{{inspectionWorkflowStepStatusLabel.get(s) | translate}}</ion-title>
							@for (step of this.stepsByStatus.get(s); track step) {
								<ion-item (click)="app.navigator.navigate('/menu/inspection/list', {project: this.projectUuid, step: step.uuid})" button="true">
									<ion-icon name="apps-outline" style="padding: 10px;"></ion-icon>
									<ion-label>{{step.name | translate }}</ion-label>
									<div slot="end">
										@if (step.count !== null) {
											<ion-badge color="primary" mode="ios">{{step.count | async}}</ion-badge>
										}
										<ion-icon name="caret-forward-outline"></ion-icon>
									</div>
								</ion-item>
							}
						</div>
					}
				}

			</ion-list>
		}
		<!-- Desktop -->
		@if (app.device.isDesktop()) {
			<ion-card-content>
				<ion-title color="primary">{{'global' | translate}}</ion-title>
				<ion-grid>
					<ion-row>
						<!-- Create -->
						<ion-col size="4">
							<uno-card icon="add" title="{{'create' | translate }}" (click)="app.navigator.navigate('/menu/inspection/edit', {createMode: true, project: this.projectUuid})"></uno-card>
						</ion-col>
						<!-- All -->
						<ion-col size="4">
							<uno-card icon="list" title="{{'list' | translate }}" (click)="app.navigator.navigate('/menu/inspection/list', {project: this.projectUuid})" badge="{{this.count | async}}"></uno-card>
						</ion-col>
					</ion-row>
				</ion-grid>

				@for (s of this.status; track s) {
					@if (this.stepsByStatus.get(s).length > 0) {
						<!-- Steps -->
						<ion-title color="primary">{{inspectionWorkflowStepStatusLabel.get(s) | translate}}</ion-title>

						<ion-grid>
							<ion-row>
								@for (step of this.stepsByStatus.get(s); track step) {
									<ion-col size="4">
										<uno-card icon="apps-outline" title="{{step.name | translate }}" (click)="app.navigator.navigate('/menu/inspection/list', {project: this.projectUuid, step: step.uuid})" badge="{{step.count | async}}"></uno-card>
									</ion-col>
								}
							</ion-row>
						</ion-grid>
					}
				}
			</ion-card-content>
		}
	}
</uno-content>
