
import {Service} from 'src/app/http/service';
import {ServiceList} from 'src/app/http/service-list';
import {Acquisition} from 'src/app/models/pipeline-integrity/mot/acquisition';
import {UUID} from 'src/app/models/uuid';
import {Session} from 'src/app/session';

export type AcquisitionListParams = {
	// CMP uuid to use on the api request
	cmpUuid?: UUID,
}

// CMP list API response format.
export type AcquisitionListResponse = {
	// If true, there are more items to fetch on the list.
	hasMore: boolean,
	// The response id
	id: number,
	// The acquisitions list
	acquisitions: Acquisition[]
}


export class AcquisitionService {
	/**
	 * List all acquisitions that match the params.
	 *
	 * @param params - The parameters to apply on the fetch request.
	 */
	public static async list(params: AcquisitionListParams): Promise<AcquisitionListResponse> {
		const request = await Service.fetch(ServiceList.pipelineIntegrity.mot.acquisition.list, null, null, params, Session.session);

		return {
			hasMore: request.response.hasMore,
			id: request.id,
			acquisitions: request.response.acquisitions.map((data: any) => {return Acquisition.parse(data);})
		};
	}

	/**
	 * Count all acquisitions that match the params.
	 *
	 * @param params - The parameters to apply on the fetch request.
	 */
	public static async count(params: AcquisitionListParams): Promise<number> {
		return (await Service.fetch(ServiceList.pipelineIntegrity.mot.acquisition.count, null, null, params, Session.session)).response.count;
	}
}
