import {Component, OnInit} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {NgStyle} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {Session} from '../../../../../../session';
import {Locale} from '../../../../../../locale/locale';
import {Service} from '../../../../../../http/service';
import {ServiceList} from '../../../../../../http/service-list';
import {AtexInspectionStatus} from '../../../../../../models/atex-inspections/inspections/atex-inspection-status';
import {App} from '../../../../../../app';
import {AtexInspectionResult} from '../../../../../../models/atex-inspections/inspections/atex-inspection-result';
import {DoughnutChartData, DoughnutChartComponent} from '../../../../../../components/charts/doughnut-chart/doughnut-chart.component';
import {CSSUtils} from '../../../../../../utils/css-utils';

@Component({
	selector: 'graph-atex-inspection-states',
	templateUrl: './atex-inspection-states.component.html',
	standalone: true,
	imports: [IonicModule, DoughnutChartComponent, NgStyle, TranslateModule]
})
export class AtexInspectionStatesComponent implements OnInit {
	/**
	 * Chart to display how many inspection exist for each state.
	 */
	public data: DoughnutChartData[] = [];

	public async ngOnInit(): Promise<void> {
		const request = await Service.fetch(ServiceList.atexInspection.countStatusResult, null, null, null, Session.session, true);
		const result = request.response.inspections.result;

		this.data = [
			new DoughnutChartData(Locale.get('notFinished'), result.none, CSSUtils.parseColor('rgb(255, 153, 0)'), () => {
				App.navigator.navigate('/menu/atex/inspections/list', {status: AtexInspectionStatus.ALL, filterResult: AtexInspectionResult.NONE});
			}),
			new DoughnutChartData(Locale.get('approved'), result.approved, CSSUtils.parseColor('rgb(153, 200, 0)'), () => {
				App.navigator.navigate('/menu/atex/inspections/list', {status: AtexInspectionStatus.ALL, filterResult: AtexInspectionResult.APPROVED});
			}),
			new DoughnutChartData(Locale.get('failed'), result.failed, CSSUtils.parseColor('rgb(255, 100, 102)'), () => {
				App.navigator.navigate('/menu/atex/inspections/list', {status: AtexInspectionStatus.ALL, filterResult: AtexInspectionResult.FAILED});
			}),
			new DoughnutChartData(Locale.get('conditionallyApproved'), result.conditionallyApproved, CSSUtils.parseColor('rgb(255, 255, 0)'), () => {
				App.navigator.navigate('/menu/atex/inspections/list', {status: AtexInspectionStatus.ALL, filterResult: AtexInspectionResult.CONDITIONALLY_APPROVED});
			})
		];
	}
}
