/**
 * Indicates the longevity of the repair, it can be temporary meaning that it is a not the full ideal fix for the repair and that a definitive repair will have to be schedule for a later date.
 *
 * Or it can be definitive meaning that this repair will not required any further maintenance.
 */
export const RepairLongevity = {
	UNKNOWN: 0,
	TEMPORARY: 1,
	DEFINITIVE: 2
};

export const RepairLongevityLabel: Map<number, string> = new Map([
	[RepairLongevity.UNKNOWN, 'unknown'],
	[RepairLongevity.TEMPORARY, 'temporary'],
	[RepairLongevity.DEFINITIVE, 'definitive']
]);
