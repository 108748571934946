<uno-tab>
	<!-- Settings -->
	<uno-tab-section [active]="true" title="{{'settings' | translate}}">
		<uno-dynamic-form [layout]="this.layout.settings" [object]="this.settings" [onChange]="this.onChange"></uno-dynamic-form>
	</uno-tab-section>

	<!-- Version -->
	<uno-tab-section title="{{'version' | translate}}">
		<uno-dynamic-form [layout]="this.layout.buildInfo" [object]="this.environment"></uno-dynamic-form>
	</uno-tab-section>
</uno-tab>
