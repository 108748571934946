import {Component, Input, forwardRef, ViewEncapsulation} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR, FormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {IonicModule} from '@ionic/angular';
import {NgStyle} from '@angular/common';
import {UUID, generateUUID} from '../../../models/uuid';
import {ArrayUtils} from '../../../utils/array-utils';
import {UnoTextComponent} from '../../uno/uno-text/uno-text.component';
import {UnoButtonComponent} from '../../uno/uno-button/uno-button.component';

export class KeyValue {
	/**
	 * The key to relate the value with.
	 */
	public key: any;

	/**
	 * The value pair for the key.
	 */
	public value: any;

	public constructor(key?: UUID, value?: string) {
		this.key = key || generateUUID();
		this.value = value;
	}
}

@Component({
	selector: 'key-value-array',
	templateUrl: './key-value-array.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => { return KeyValueComponent; }),
		multi: true
	}],
	standalone: true,
	imports: [IonicModule, NgStyle, FormsModule, UnoButtonComponent, UnoTextComponent, TranslateModule]
})
export class KeyValueComponent implements ControlValueAccessor {
	/**
	 * Disables the input.
	 */
	@Input()
	public disabled = false;

	/**
	 * Allow items to be moved
	 */
	@Input()
	public allowMove: boolean = true;

	/**
	 * Values representing the array of key-values.
	 */
	public items: KeyValue[] = [];

	public onChange: (value: any)=> void = function(value) {};

	public registerOnChange(onChange: any): void {
		this.onChange = onChange;
	}

	public registerOnTouched(fn: any): void {}

	public writeValue(value: KeyValue[]): void {
		this.items = structuredClone(value);
		this.onChange(this.items);
	}

	/**
	 * Sets the component edition state.
	 * 
	 * @param disabled - If true, the component value input cannot be modified.
	 */
	public setDisabledState(disabled: boolean): void {
		this.disabled = disabled;
	}

	/**
	 * Set the value of an item in the list by its index.
	 *
	 * @param index - Index of the item to be edited.
	 * @param value - New value to be put in the item.
	 */
	public setItem(index: number, value: string): void {
		this.items[index].value = value;
		this.writeValue(this.items);
	}

	/**
	 * Remove an item from the array by its index.
	 *
	 * @param index - Index of the item to be removed.
	 */
	public removeItem(index: number): void {
		if (index !== -1) {
			this.items.splice(index, 1);
			this.writeValue(this.items);
		}
	}

	/**
	 * Add a new item to array list.
	 */
	public addItem(): void {
		if (!this.items || !(this.items instanceof Array)) {
			this.items = [];
		}
		
		this.items.push(new KeyValue());

		this.writeValue(this.items);
	}
	
	/**
	 * Method used get the index of a item on ngFor loop
	 */ 
	public trackBy(index: number): any {
		return index;
	}

	/**
	 * Move item up in the list of items. Increase index of item.
	 */
	public moveFieldUp(index: number): void {
		if (index < this.items.length - 1 && index !== -1) {
			ArrayUtils.move(this.items, index, index + 1);
			this.writeValue(this.items);
		}
	}

	/**
	 * Move item down in the list of items. Decrease index of item.
	 */
	public moveFieldDown(index: number): void {
		if (index > 0) {
			ArrayUtils.move(this.items, index, index - 1);
			this.writeValue(this.items);
		}
	}
}
