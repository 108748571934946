import {Component, Input, ViewEncapsulation} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {IonicModule} from '@ionic/angular';
import {AsyncPipe} from '@angular/common';
import {App} from '../../../app';
import {Session} from '../../../session';
import {MenuCardsOptions} from '../menu-cards/menu-cards.component';
import {UnoCardComponent} from '../../uno/uno-card/uno-card.component';

/**
 * Menu cards layout should be used for navigation menus is compatible with desktop and mobile.
 *
 * Displays the menu as a grid of cards in the desktop platform and as a list in mobile devices.
 */
@Component({
	selector: 'menu-cards-groups',
	templateUrl: './menu-cards-groups.component.html',
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [IonicModule, UnoCardComponent, AsyncPipe, TranslateModule]
})
export class MenuCardsGroupsComponent {
	public get app(): any { return App; }

	public get session(): any { return Session; }

	@Input()
	public groups: MenuCardsGroup[] = [];
}

export class MenuCardsGroup {
	/**
	 * Label shown to the user on the card.
	 */
	public label: string = '';

	/**
	 * List of permissions required for the option.
	 *
	 * At least one of these permissions is required, if not permission is needed should be empty.
	 */
	public permissions: number[] = [];

	/**
	 * Options of this group.
	 */
	public options: MenuCardsOptions[] = [];
}
