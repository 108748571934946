<div style="width:100%; height:100%;">
	<uno-tab>
		<!-- Asset Info tab -->
		@if (this.asset) {
			<uno-tab-section [active]="true" title="{{'assetInfo' | translate}}">
				<!-- Structure Layout -->
				<uno-title>{{'structure' | translate}}</uno-title>
				<uno-dynamic-form [layout]="this.structureLayout" [object]="this.asset" [editable]="session.hasPermissions(userPermissions.ASSET_PORTFOLIO_ASSET_EDIT)"></uno-dynamic-form>
				<!-- Base Information-->
				<uno-title>{{'assetInfo' | translate}}</uno-title>
				<uno-dynamic-form [layout]="this.baseLayout" [object]="this.asset" [editable]="session.hasPermissions(userPermissions.ASSET_PORTFOLIO_ASSET_EDIT)"></uno-dynamic-form>
				<!-- Model Identification -->
				<uno-title>{{'modelIdentification' | translate}}</uno-title>
				<uno-dynamic-form [layout]="this.modelLayout" [object]="this.asset" [editable]="session.hasPermissions(userPermissions.ASSET_PORTFOLIO_ASSET_EDIT)"></uno-dynamic-form>
				@if (!this.createMode) {
					<div style="display: flex; flex-direction: row-reverse; gap: 5px;" class="ion-padding">
						<!-- Generate QR Code -->
						@if (session.hasPermissions(userPermissions.ASSET_PORTFOLIO_QR)) {
							<uno-button (click)="this.generateQR();">{{'generateQR' | translate}}</uno-button>
						}
						<!-- Attach NFC -->
						@if (session.hasPermissions(userPermissions.ASSET_PORTFOLIO_NFC) && app.device.hasNFC()) {
							<uno-button (click)="this.attachNFC();">{{'attachNFC' | translate}}</uno-button>
						}
					</div>
				}
				<!-- Submit buttons -->
				<div style="display: flex; flex-direction: row-reverse; gap: 5px;" class="ion-padding">
					<!-- Save / Create -->
					<uno-button (click)="session.hasPermissions(userPermissions.ASSET_PORTFOLIO_ASSET_EDIT) ? this.update() : app.navigator.pop();">{{(this.createMode ? 'create' : session.hasPermissions(userPermissions.ASSET_PORTFOLIO_ASSET_EDIT) ? 'save' : 'close') | translate}}</uno-button>
					<!-- Update / Delete -->
					@if (!this.createMode) {
						<uno-button (click)="this.exportReportDOCX(true);">{{'exportReportDOCX' | translate}}</uno-button>
						<uno-button (click)="this.exportReportPDF();">{{'exportReportPDF' | translate}}</uno-button>

						@if (session.hasPermissions([userPermissions.ASSET_PORTFOLIO_ASSET_EDIT])) {
							<uno-button (click)="this.update(true);">{{'update' | translate}}</uno-button>
						}
						@if (session.hasPermissions([userPermissions.ASSET_PORTFOLIO_ASSET_DELETE])) {
							<uno-button (click)="this.delete();" color="error">{{'delete' | translate}}</uno-button>
						}
					}
				</div>
			</uno-tab-section>
		}

		<!-- Atex tab -->
		@if (this.asset && !this.createMode) {
			<uno-tab-section title="{{'atex' | translate}}">
				<uno-title>{{'atexInfo' | translate}}</uno-title>
				<uno-dynamic-form [layout]="this.atexLayout" [object]="this.asset"></uno-dynamic-form>
				<!-- Atex Inspections -->
				@if (!this.createMode && this.atexInspections && this.assetType && session.hasPermissions([userPermissions.ATEX_INSPECTION])) {
					<uno-title>{{'atexInspections' | translate}}</uno-title>
					@if (this.atexInspections.length > 0) {
						<ion-list class="ion-no-padding ion-no-margin">
							@for (inspection of this.atexInspections; track inspection) {
								<ion-item class="ion-margin-horizontal ion-no-padding" lines="full">
									<!-- Data -->
									<ion-label>
										<h2>{{'status' | translate}}: {{inspectionStatusLabel.get(inspection.status) | translate}}</h2>
										@if (inspection.status === atexInspectionStatus.COMPLETED) {
											<h2>{{'result' | translate}}: {{inspectionResultLabel.get(inspection.resultFinal) | translate}}</h2>
										}
										<h3>{{'updatedAt' | translate}}: {{inspection.updatedAt | formatDate}}</h3>
									</ion-label>
									<!-- Button -->
									<uno-button (click)="app.navigator.navigate('/menu/atex/inspections/edit', {uuid: inspection.uuid});">{{'view' | translate}}</uno-button>
								</ion-item>
							}
						</ion-list>
					}
					@if (this.atexInspections.length === 0) {
						<ion-item class="ion-margin-horizontal ion-no-padding" lines="none">
							<ion-label>
								<p class="ion-margin-horizontal">{{'noInspectionsToShow' | translate}}</p>
							</ion-label>
						</ion-item>
					}
					@if (session.hasPermissions([userPermissions.ATEX_INSPECTION_CREATE])) {
						<div style="display: flex; flex-direction: row-reverse; gap: 5px;" class="ion-padding">
							<uno-button (click)="this.createAtexInspection();">{{'createAtexInspection' | translate}}</uno-button>
						</div>
					}
				}
			</uno-tab-section>
		}
		<!-- Dynamic asset type and sub-type tabs -->
		@for (tab of this.tabs; track tab) {
			<uno-tab-section title="{{tab.name | translate}}">
				<uno-dynamic-form [layout]="this.tabsLayouts.get(tab.uuid)" [object]="this.tabsData.get(tab.uuid)"></uno-dynamic-form>
				<!-- Submit buttons -->
				<div style="display: flex; flex-direction: row-reverse; gap: 5px;" class="ion-padding">
					<!-- Save / Create -->
					<uno-button (click)="this.update();">{{(this.createMode ? 'create' : 'save') | translate}}</uno-button>
					<!-- Update / Delete -->
					@if (!this.createMode) {
						<uno-button (click)="this.exportReportDOCX(true);">{{'exportReportDOCX' | translate}}</uno-button>
						<uno-button (click)="this.exportReportPDF();">{{'exportReportPDF' | translate}}</uno-button>

						@if (session.hasPermissions([userPermissions.ASSET_PORTFOLIO_ASSET_EDIT])) {
							<uno-button (click)="this.update(true);">{{'update' | translate}}</uno-button>
						}

						@if (session.hasPermissions([userPermissions.ASSET_PORTFOLIO_ASSET_DELETE])) {
							<uno-button (click)="this.delete();" color="error">{{'delete' | translate}}</uno-button>
						}
					}
				</div>
			</uno-tab-section>
		}

		<!-- Gap analysis tab -->
		@if (this.asset && !this.createMode && this.gapsCount !== null) {
			<uno-tab-section title="{{'gapAnalysis' | translate}}">
				<uno-title>{{'gaps' | translate}}</uno-title>

				<div style="padding: 0px 16px; height: calc(100% - 48px);">
					<uno-responsive-table-list [layout]="this.gapsTableLayout" [loadMore]="this.loadGapsTableItems" [totalItems]="this.gapsCount" [pageSize]="50" [selectable]="false" (rowClick)="this.navigateToGap($event.element.uuid.value)">
					</uno-responsive-table-list>
				</div>

			</uno-tab-section>
		}

		<!-- Asset planning tab -->
		@if (this.asset && !this.createMode && this.eventsCount !== null) {
			<uno-tab-section [active]="false" title="{{'planning' | translate}}">
				<!-- Events -->
				<uno-title>{{'events' | translate}}</uno-title>
				<div style="padding: 0px 16px; height: calc(100% - 48px);">
					<!-- Table buttons -->
					<div style="padding-bottom: 8px; display: flex; align-items: center; justify-content: flex-end;">
						<uno-button color="primary" type="fill" size="medium" (click)="this.createEvent();">{{'createEvent' | translate}}</uno-button>
					</div>

					<!-- Table content -->
					<div style="height: calc(100% - 48px);">
						<uno-responsive-table-list #eventsTable [layout]="this.eventsTableLayout" [loadMore]="this.loadCalendarEvents" [totalItems]="this.eventsCount" [pageSize]="50" [selectable]="false" [labelShown]="true" (rowClick)="this.editEvent($event.element.event)">
						</uno-responsive-table-list>
					</div>
				</div>
			</uno-tab-section>
		}

		<!-- Activities tab -->
		@if (!dl50Module.hasRole() && !this.createMode) {
			<uno-tab-section title="{{'activities' | translate}}">
				<!-- Dynamic Inspections -->
				@if (!this.createMode && this.projectInspections && session.hasPermissions([userPermissions.INSPECTION_ACCESS_TEAM])) {
					<uno-title>{{'inspections' | translate}}</uno-title>
					@if (this.projectInspections.length > 0) {
						@for (p of this.projectInspections; track p) {
							<div class="ion-no-padding ion-no-margin">
								<uno-title>{{p.project.name}}</uno-title>
								<ion-list class="ion-no-padding ion-no-margin">
									@for (i of p.inspections; track i) {
										<ion-item class="ion-margin-horizontal ion-no-padding" lines="full">
											<ion-label>
												@if (i.name) {
													<h2>{{'name' | translate}}: {{i.name}}</h2>
												}
												@if (i.updatedAt) {
													<h3>{{'updatedAt' | translate}}: {{i.updatedAt | formatDate}}</h3>
												}
											</ion-label>
											<uno-button (click)="app.navigator.navigate('/menu/inspection/edit', {uuid: i.uuid});">{{'view' | translate}}</uno-button>
										</ion-item>
									}
								</ion-list>
							</div>
						}
					}
					@if (this.projectInspections.length === 0) {
						<ion-item class="ion-margin-horizontal ion-no-padding" lines="none">
							<ion-label>
								<p class="ion-margin-horizontal">{{'noDataOnServer' | translate}}</p>
							</ion-label>
						</ion-item>
					}
					@if (!this.createMode && session.hasPermissions([userPermissions.INSPECTION_CREATE])) {
						<div style="display: flex; flex-direction: row-reverse; gap: 5px;" class="ion-padding">
							<uno-button (click)="this.createInspection()">{{'createInspection' | translate}}</uno-button>
						</div>
					}
				}

				<!-- Repairs -->
				@if (!this.createMode && this.repairs && session.hasPermissions([userPermissions.REPAIR])) {
					<uno-title>{{'repairs' | translate}}</uno-title>
					@if (this.repairs.length > 0) {
						<ion-list class="ion-no-padding ion-no-margin">
							@for (repair of this.repairs; track repair) {
								<ion-item class="ion-margin-horizontal ion-no-padding" lines="full">
									<ion-label>
										@if (repair.description) {
											<h2>{{'description' | translate}}:{{repair.description}}</h2>
										}
										<h3>{{'criticality' | translate}}: {{repair.criticality | repairCriticalityLabel | translate}}</h3>
										<h3>{{'status' | translate}}:{{repair.status | repairStatusLabel | translate }}</h3>
										<p>{{'updatedAt' | translate}}: {{repair.updatedAt | formatDate}}</p>
									</ion-label>
									<uno-button (click)="app.navigator.navigate('/menu/repairs/works/edit', {uuid: repair.uuid});">{{'view' | translate}}</uno-button>
								</ion-item>
								}
						</ion-list>
						}
						@if (this.repairs.length === 0) {
							<ion-item class="ion-margin-horizontal ion-no-padding" lines="none">
								<ion-label>
									<p class="ion-margin-horizontal">{{'noDataOnServer' | translate}}</p>
								</ion-label>
							</ion-item>
						}
						@if (session.hasPermissions([userPermissions.REPAIR_CREATE])) {
							<div style="display: flex; flex-direction: row-reverse; gap: 5px;" class="ion-padding">
								<uno-button (click)="this.createRepair()">{{'createRepair' | translate}}</uno-button>
							</div>
						}
						}
			</uno-tab-section>
			}

			<!-- Digital twin tab -->
			@if (!this.createMode && session.hasPermissions([userPermissions.DIGITAL_TWIN]) && this.digitalTwinObjects.length > 0) {
				<uno-tab-section title="{{'digitalTwin' | translate}}">
					<!-- Digital Twin -->
					<uno-title>{{'digitalTwin' | translate}}</uno-title>
					<ion-list class="ion-no-padding ion-no-margin">
						@for (object of this.digitalTwinObjects; track object) {
							<ion-item class="ion-margin-horizontal ion-no-padding" lines="full">
								<ion-label>
									@if (object.name) {
										<h2>{{'name' | translate}}: {{object.name}}</h2>
									}
									@if (object.description) {
										<h2>{{'description' | translate}}:{{object.description}}</h2>
									}
								</ion-label>
								<uno-button (click)="app.navigator.navigate('/menu/digital-twin/editor', {uuid: object.sceneUuid, objectUuid: object.uuid});">{{'view' | translate}}</uno-button>
							</ion-item>
							}
					</ion-list>
				</uno-tab-section>
				}
	</uno-tab>
</div>
