/**
 * Forms used in the Atex inspection process.
 */
export enum AtexInspectionForm {
	/**
	 * Form filled by a field inspector, focused on capturing information of the asset status.
	 */
	INSPECTOR = 'inspector',

	/**
	 * Validation form filled by a backoffice inspector.
	 * 
	 * Focused on Atex related data and validation of inspector work.
	 */
	BACKOFFICE = 'backoffice'
}
