import {UnoFormField} from '../../../../../../components/uno-forms/uno-dynamic-form/uno-form-field';
import {UnoFormFieldTypes} from '../../../../../../components/uno-forms/uno-dynamic-form/uno-form-field-types';
import {AtexInspectionReport} from '../../../data/atex-inspection-report';

export class AtexInspectionSettingsLayout {
	public static layout: UnoFormField[] = [
		{
			type: UnoFormFieldTypes.TITLE,
			label: 'settings'
		},
		{
			attribute: 'inspectorsCount',
			label: 'inspectorsCount',
			type: UnoFormFieldTypes.NUMBER,
			isActive: true
		},
		{
			attribute: 'reportTemplate',
			label: 'reportTemplate',
			type: UnoFormFieldTypes.DOCUMENT_RESOURCE,
			filter: '.docx',
			isActive: true,
			sampleData: AtexInspectionReport.defaultReportURL
		}
	];
}
