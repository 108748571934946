/**
 * List of possible priorities for the gap entries.
 */
export enum GAGapPriority {
	LOW = 0,
	MEDIUM = 1,
	HIGH = 2
};

/**
 * List of possible priority labels for the gap entries.
 */
export const GAGapPriorityLabel: Map<number, string> = new Map([
	[GAGapPriority.LOW, 'low'],
	[GAGapPriority.MEDIUM, 'medium'],
	[GAGapPriority.HIGH, 'high']
]);
