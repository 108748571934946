<div style="width:100%; height:100%;">
	<uno-tab>
		@if (this.inspection) {
			<uno-tab-section [active]="true" title="{{'inspection' | translate}}">

				<!-- Base data -->
				<uno-title>{{this.steps.has(this.inspection.stepUuid) ? this.steps.get(this.inspection.stepUuid).name : 'inspection' | translate}}</uno-title>
				<uno-dynamic-form [layout]="this.layout" [object]="this.inspection" #form></uno-dynamic-form>

				<!-- Inspection step forms -->
				@for (stepData of this.inspection.data; track stepData) {
					@if (stepData && this.steps.get(stepData.stepUuid)) {
						<uno-title>{{this.steps.get(stepData.stepUuid).name}}</uno-title>
						<uno-dynamic-form [layout]="this.stepFormLayout.get(stepData.stepUuid)" [object]="stepData.data" #inspectionForm></uno-dynamic-form>
					}
				}

				<!-- Adjacency's buttons -->
				@if (this.todoAdjacencies.length !== 0) {
					<p style="text-align: end; padding-right: 16px">{{'todo' | translate}}</p>
					<div style="display: flex; flex-direction: row-reverse; gap: 5px; padding-right: 16px;flex-wrap: wrap">
						@for (button of this.todoAdjacencies; track button) {
							<uno-button (click)="this.update(false, button.destination);">{{button.label}}</uno-button>
						}
					</div>
				}

				@if (this.inProgressAdjacencies.length !== 0) {
					<p style="text-align: end; padding-right: 16px">{{'inProgress' | translate}}</p>
					<div style="display: flex; flex-direction: row-reverse; gap: 5px; padding-right: 16px;flex-wrap: wrap">
						@for (button of this.inProgressAdjacencies; track button) {
							<uno-button (click)="this.update(false, button.destination);">{{button.label}}</uno-button>
						}
					</div>
				}

				@if (this.doneAdjacencies.length !== 0) {
					<p style="text-align: end; padding-right: 16px">{{'done' | translate}}</p>
					<div style="display: flex; flex-direction: row-reverse; gap: 5px; padding-right: 16px;flex-wrap: wrap">
						@for (button of this.doneAdjacencies; track button) {
							<uno-button (click)="this.update(false, button.destination);">{{button.label}}</uno-button>
						}
					</div>
				}

				<div style="display: flex; flex-direction: row-reverse; gap: 5px; padding-top: 50px;" class="ion-padding">
					<!-- Create -->
					@if (this.createMode && session.hasPermissions([userPermissions.INSPECTION_CREATE])) {
						<uno-button (click)="this.update();">{{'create' | translate}}</uno-button>
					}
					<!-- Save/Update -->
					@if (!this.createMode) {
						@if (session.hasPermissions([userPermissions.INSPECTION_EDIT])) {
							<uno-button (click)="this.update();">{{'save' | translate}}</uno-button>
						}
						@if (session.hasPermissions([userPermissions.INSPECTION_EDIT])) {
							<uno-button (click)="this.update(true);">{{'update' | translate}}</uno-button>
						}
						@if (session.hasPermissions([userPermissions.INSPECTION_DELETE])) {
							<uno-button (click)="this.delete();" color="error">{{'delete' | translate}}</uno-button>
						}
					}
				</div>
				<!-- Reports -->
				@if (this.project) {
					@for (report of this.project.reports; track $index) {
						@if (report?.formats?.length > 0) {
							<div style="display: flex; flex-direction: row-reverse; gap: 5px;" class="ion-padding">
								@if (report.formats.indexOf(inspectionReportTemplateFormat.DOCX) !== -1) {
									<uno-button (click)="this.exportReportDOCX(report);">{{report.name}} ({{'DOCX' | translate}})</uno-button>
								}
								@if (report.formats.indexOf(inspectionReportTemplateFormat.PDF) !== -1) {
									<uno-button (click)="this.exportReportPDF(report);">{{report.name}} ({{'PDF' | translate}})</uno-button>
								}
							</div>
							}
							}
							}
			</uno-tab-section>
			}
			@if (session.hasPermissions([userPermissions.ASSET_PORTFOLIO]) && this.asset) {
				<uno-tab-section title="{{'asset' | translate}}">
					<!-- Asset data -->
					<uno-title>{{'asset' | translate}}</uno-title>
					<uno-dynamic-form [editable]="session.hasPermissions([userPermissions.ASSET_PORTFOLIO_ASSET_EDIT])" [onChange]="this.setAssetEdited" [layout]="this.assetBaseLayout" [object]="this.asset"></uno-dynamic-form>
					<!-- Model Identification -->
					<uno-title>{{'modelIdentification' | translate}}</uno-title>
					<uno-dynamic-form [editable]="session.hasPermissions([userPermissions.ASSET_PORTFOLIO_ASSET_EDIT])" [onChange]="this.setAssetEdited" [layout]="this.modelLayout" [object]="this.asset"></uno-dynamic-form>
					<div style="display: flex; flex-direction: row-reverse; gap: 5px;" class="ion-padding">
						<!-- Save/Update -->
						@if (!this.createMode) {
							@if (session.hasPermissions([userPermissions.INSPECTION_EDIT])) {
								<uno-button (click)="this.update();">{{'save' | translate}}</uno-button>
							}
							@if (session.hasPermissions([userPermissions.INSPECTION_EDIT])) {
								<uno-button (click)="this.update(true);">{{'update' | translate}}</uno-button>
							}
							@if (session.hasPermissions([userPermissions.INSPECTION_DELETE])) {
								<uno-button (click)="this.delete();" color="error">{{'delete' | translate}}</uno-button>
							}
						}
						<!-- Open Asset -->
						<uno-button (click)="app.navigator.navigate('/menu/asset-portfolio/asset/edit', {uuid: asset.uuid})">{{'asset' | translate}}</uno-button>
					</div>
				</uno-tab-section>
			}
			@if (session.hasPermissions([userPermissions.INSPECTION_PROJECT]) && this.project) {
				<uno-tab-section title="{{'project' | translate}}">
					<!-- Project data -->
					<uno-title>{{'project' | translate}}</uno-title>
					<uno-dynamic-form [editable]="session.hasPermissions([userPermissions.INSPECTION_PROJECT_EDIT])" [onChange]="this.setProjectEdited" [layout]="this.projectLayout" [object]="this.project"></uno-dynamic-form>

					<div style="display: flex; flex-direction: row-reverse; gap: 5px;" class="ion-padding">
						<uno-button (click)="app.navigator.navigate('/menu/inspection/project/edit', {uuid: project.uuid})">{{'project' | translate}}</uno-button>
						<uno-button (click)="app.navigator.navigate('/menu/inspection/list/steps', {project: project.uuid})">{{'steps' | translate}}</uno-button>
					</div>
				</uno-tab-section>
			}
	</uno-tab>
</div>
