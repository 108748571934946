import {NgStyle} from '@angular/common';
import {Component, Input, ViewEncapsulation} from '@angular/core';

/**
 * UNO list item can be used to represent an item in a list.
 *
 * Items can have an image, text and a set of shortcut icons.
 */
@Component({
	selector: 'uno-list-item',
	templateUrl: './uno-list-item.component.html',
	styleUrls: ['./uno-list-item.component.css'],
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [
		NgStyle
	]
})
export class UnoListItemComponent {
	/**
	 * If the user can select this item.
	 */
	@Input()
	public clickable: boolean = true;
}
