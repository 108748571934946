import {Component, OnInit} from '@angular/core';
import {SortDirection} from 'src/app/utils/sort-direction';
import {TranslateModule} from '@ngx-translate/core';
import {NgStyle, KeyValuePipe} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {ScreenComponent} from '../../../../../components/screen/screen.component';
import {UserPermissions} from '../../../../../models/users/user-permissions';
import {App} from '../../../../../app';
import {Session} from '../../../../../session';
import {ServiceList} from '../../../../../http/service-list';
import {Resource} from '../../../../../models/resource';
import {RepairInspectionStatus, RepairInspectionStatusLabel} from '../../../../../models/repairs/inspections/repair-inspection-status';
import {RepairInspectionResult, RepairInspectionResultLabel} from '../../../../../models/repairs/inspections/repair-inspection-result';
import {ServiceSync} from '../../../../../http/service-sync';
import {UnoListLazyLoadHandler} from '../../../../../components/uno/uno-list/uno-list-lazy-load-handler';
import {RepairCriticalityLabelPipe} from '../../../repair-work/pipes/repair-criticality-label.pipe';
import {RepairStatusLabelPipe} from '../../../repair-work/pipes/repair-status-label.pipe';
import {FormatDatePipe} from '../../../../../pipes/format-date.pipe';
import {UnoListComponent} from '../../../../../components/uno/uno-list/uno-list-component';
import {UnoContentComponent} from '../../../../../components/uno/uno-content/uno-content.component';
import {UnoListItemLabelComponent} from '../../../../../components/uno/uno-list-item/uno-list-item-label.component';
import {UnoListItemIconComponent} from '../../../../../components/uno/uno-list-item/uno-list-item-icon.component';
import {UnoListItemComponent} from '../../../../../components/uno/uno-list-item/uno-list-item.component';
import {UnoSearchbarComponent} from '../../../../../components/uno/uno-searchbar/uno-searchbar.component';
import {ResourceUtils} from '../../../../../utils/resource-utils';

@Component({
	selector: 'repair-inspection-list-page',
	templateUrl: 'repair-inspection-list.page.html',
	standalone: true,
	imports: [UnoSearchbarComponent, IonicModule, FormsModule, UnoListItemComponent, UnoListItemIconComponent, UnoListItemLabelComponent, UnoContentComponent, NgStyle, UnoListComponent, KeyValuePipe, TranslateModule, FormatDatePipe, RepairStatusLabelPipe, RepairCriticalityLabelPipe]
})
export class RepairInspectionListPage extends ScreenComponent implements OnInit {

	public get app(): any { return App; }

	public get selfStatic(): any { return RepairInspectionListPage; }

	public get session(): any { return Session; }

	public get resource(): any { return Resource; }
	
	public get inspectionStatusLabel(): any { return RepairInspectionStatusLabel; }

	public get inspectionResultLabel(): any { return RepairInspectionResultLabel; }

	public permissions = [UserPermissions.REPAIR_INSPECTIONS];

	/**
	 * Text used to filter repair inspections by.
	 */
	public search: string = '';

	/**
	 * List of possible search fields.
	 */
	public static searchFieldValues: any = [
		{label: 'assetName', attribute: '[ap_asset].[name]'},
		{label: 'assetTag', attribute: '[ap_asset].[tag]'},
		{label: 'repairOrder', attribute: '[repair].[repair_order]'},
		{label: 'uuid', attribute: '[repair_inspection].[id]'},
		{label: 'assetUuid', attribute: '[ap_asset].[id]'},
		{label: 'repairUuid', attribute: '[repair_inspection].[repair_id]'},
		{label: 'inspectionNumber', attribute: '[repair_inspection].[inspection_number]'}
	];

	/**
	 * Search fields to be considered.
	 */
	public searchFields: string[] = [];

	/**
	 * Database attribute name used to sort the values.
	 */
	public sortField: string = '';

	/**
	 * Sort direction applied to the loaded list from database.
	 */
	public sortDirection: string = '';

	/**
	 * Possible sort direction values.
	 */
	public static sortDirectionValues: any = [SortDirection.ASC, SortDirection.DESC];

	/**
	 * Possible database filter to be used for ordering the repair inspections list.
	 */
	public static sortFieldValues: any = [
		{label: 'updatedAt', attribute: '[repair_inspection].[updated_at]'},
		{label: 'createdAt', attribute: '[repair_inspection].[created_at]'},
		{label: 'dueDate', attribute: '[repair_inspection].[due_date]'}
	];

	/**
	 * Search fields to be considered.
	 */
	public static resultField: number[] = [RepairInspectionResult.NONE, RepairInspectionResult.COMPLIANT, RepairInspectionResult.NON_COMPLIANT];

	/**
	 * Object to synchronize service requests.
	 */
	public serviceSync: ServiceSync = new ServiceSync();

	/**
	 * Lazy list loading handler.
	 */
	public handler: UnoListLazyLoadHandler<any> = new UnoListLazyLoadHandler<any>();

	/**
	 * Status of inspection.
	 */
	public status: number = 0;

	public static filters = {
		/**
		 * Text used to filter actionPlan by their name.
		 */
		search: '',

		/**
		 * Sort direction applied to the loaded list from database.
		 */
		sortDirection: RepairInspectionListPage.sortDirectionValues[1],

		/**
		 * Database attribute name used to sort the values.
		 */
		sortField: RepairInspectionListPage.sortFieldValues[0].attribute,

		/**
		 * Search fields to be considered.
		 */
		searchFields: [RepairInspectionListPage.searchFieldValues[0].attribute, RepairInspectionListPage.searchFieldValues[1].attribute],

		/**
		 * Filter by inspection result
		 */
		resultField: [RepairInspectionListPage.resultField[0], RepairInspectionListPage.resultField[1], RepairInspectionListPage.resultField[2]]
	};

	public static defaultFilters = structuredClone(RepairInspectionListPage.filters);

	public resetFilters(): void {
		this.serviceSync.reset();
		Object.assign(RepairInspectionListPage.filters, RepairInspectionListPage.defaultFilters);
	}

	public ngOnInit(): void {
		super.ngOnInit();

		this.status = RepairInspectionStatus.ALL;

		// Read status from the route data
		const data = App.navigator.getData();
		if (!data) {
			App.navigator.pop();
			return;
		}

		this.status = data.status !== undefined ? data.status : RepairInspectionStatus.ALL;

		if (this.status !== RepairInspectionStatus.ALL) {
			App.navigator.setTitle(RepairInspectionStatusLabel.get(data.status));
		} else {
			App.navigator.setTitle('list');
		}

		this.handler.loadMore = async(count: number, pageSize: number) => {
			const params = {
				status: this.status,
				from: count,
				count: pageSize,
				search: RepairInspectionListPage.filters.search,
				sortDirection: RepairInspectionListPage.filters.sortDirection,
				sortField: RepairInspectionListPage.filters.sortField,
				searchFields: RepairInspectionListPage.filters.searchFields,
				resultField: RepairInspectionListPage.filters.resultField
			};

			const request = await this.serviceSync.fetch(ServiceList.repairInspections.list, null, null, params, Session.session);
			const response = request.response;

			return {
				elements: response.inspections,
				hasMore: response.hasMore
			};
		};
		this.handler.reset();
	}

	/**
	 * Update the search term used.
	 *
	 * @param event - DOM event.
	 */
	public onFilterChange(event: any): void {
		if (event.target === undefined) {
			RepairInspectionListPage.filters.search = event;
		}

		this.serviceSync.reset();
		this.handler.reset();
	}

	protected readonly ResourceUtils = ResourceUtils;
}


