import {UnoFormFieldTypes} from '../../../../components/uno-forms/uno-dynamic-form/uno-form-field-types';
import {UnoFormField} from '../../../../components/uno-forms/uno-dynamic-form/uno-form-field';
import {Service} from '../../../../http/service';
import {ServiceList} from '../../../../http/service-list';
import {Session} from '../../../../session';
import {Locale} from '../../../../locale/locale';
import {RepairInspectionResult, RepairInspectionResultLabel} from '../../../../models/repairs/inspections/repair-inspection-result';
import {RepairInspectionStatus} from '../../../../models/repairs/inspections/repair-inspection-status';
import {RepairStatus} from '../../../../models/repairs/repairs/repair-status';

export class RepairInspectionFormLayout {
	/**
	 * New repair report form layout.
	 */
	public static base: UnoFormField[] = [
		{
			label: 'repairInspection',
			type: UnoFormFieldTypes.TITLE
		},
		{
			label: 'uuid',
			attribute: 'uuid',
			type: UnoFormFieldTypes.UUID
		},
		{
			label: 'inspectionNumber',
			attribute: 'inspectionNumber',
			type: UnoFormFieldTypes.TEXT,
			editable: true,
			isActive: true
		},
		{
			label: 'date',
			attribute: 'date',
			type: UnoFormFieldTypes.DATE,
			editable: true,
			isActive: true
		},
		{
			required: true,
			label: 'repair',
			attribute: 'repairUuid',
			type: UnoFormFieldTypes.OPTIONS,
			editable: false,
			options: [{value: null, label: 'none'}],
			fetchOptions: async function(object: any, row: UnoFormField): Promise<void> {
				const request = await Service.fetch(ServiceList.repairs.list, null, null, {status: RepairStatus.ALL}, Session.session);
				const options = [{value: null, label: 'none'}];
				row.options = options.concat(request.response.repairs.map(function(repair) {
					return {
						value: repair.uuid,
						label: repair.asset.name || Locale.get('nd')
					};
				}));
			}
		},
		{
			required: true,
			attribute: 'teamUuid',
			label: 'team',
			type: UnoFormFieldTypes.TEAM_SELECTOR,
			editable: true
		},
		{
			required: true,
			attribute: 'dueDate',
			label: 'dueDate',
			type: UnoFormFieldTypes.DATE,
			editable: (object, row) => {return object.status === RepairInspectionStatus.NONE;},
			isActive: true
		}
	];

	public static result: UnoFormField[] = [
		{
			required: true,
			attribute: 'result',
			label: 'result',
			type: UnoFormFieldTypes.OPTIONS,
			sort: false,
			editable: (object, row) => {return object.status >= RepairInspectionStatus.TODO;},
			isEmpty: function(object) {
				return object.result === RepairInspectionResult.NONE;
			},
			options: Object.values(RepairInspectionResult).map(function(value) {
				return {value: value, label: RepairInspectionResultLabel.get(value)};
			})
		},
		{
			required: true,
			attribute: 'pictures',
			label: 'pictures',
			type: UnoFormFieldTypes.IMAGE_RESOURCE_MULTIPLE,
			editable: (object, row) => {return object.status >= RepairInspectionStatus.TODO;},
			isActive: true			
		},
		{
			attribute: 'documents',
			label: 'documents',
			type: UnoFormFieldTypes.DOCUMENT_RESOURCE_MULTIPLE,
			editable: (object, row) => {return object.status >= RepairInspectionStatus.TODO;},
			isActive: true
		},
		{
			attribute: 'notes',
			label: 'notes',
			type: UnoFormFieldTypes.TEXT_MULTILINE,
			editable: (object, row) => {return object.status >= RepairInspectionStatus.TODO;},
			isActive: true
		}
	];

	/**
	 * Repair inspection rejected message form layout.
	 *
	 * Shown when the repair is rejected.
	 */
	public static rejected: UnoFormField[] = [
		{
			label: 'message',
			attribute: 'rejectedMessage',
			type: UnoFormFieldTypes.TEXT_MULTILINE
		}
	];
}
