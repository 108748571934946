/**
 * Describes the types available for calendar events.
 */
export const CalendarEventTypes = {
	/**
	 * Asset related event.
	 */
	ASSET: 1,

	/**
	 * Repair related event.
	 */
	REPAIR: 2,

	/**
	 * Scheduled events.
	 */
	SCHEDULED: 3
};

export const CalendarEventTypesLabel = new Map<number, string> ([
	[CalendarEventTypes.ASSET, 'asset'],
	[CalendarEventTypes.REPAIR, 'repair'],
	[CalendarEventTypes.SCHEDULED, 'scheduled']
]);

/**
 * List of possible subtypes for the calendar event.
 */
export const CalendarEventSubtypes = {
	/**
	 * Asset dynamic inspection schedule.
	 */
	ASSET_DYNAMIC_INSPECTION: 1,

	/**
	 * Atex inspection schedule.
	 */
	ASSET_ATEX_INSPECTION: 2,
	
	/**
	 * Repair temporary 
	 */
	REPAIR_TEMPORARY_INSPECTION: 3,

	/**
	 * Definitive repair schedule.
	 */
	REPAIR_DEFINITIVE_REPAIR: 4,

	/**
	 * Scheduled maintenance stop.
	 */
	SCHEDULED_PLANNED_STOP_MAINTENANCE: 5,

	/**
	 * Scheduled audit.
	 */
	SCHEDULED_AUDIT: 6
};

export const CalendarEventSubtypesLabel = new Map<number, string> ([
	[CalendarEventSubtypes.ASSET_DYNAMIC_INSPECTION, 'inspections'],
	[CalendarEventSubtypes.ASSET_ATEX_INSPECTION, 'atexInspections'],
	[CalendarEventSubtypes.REPAIR_TEMPORARY_INSPECTION, 'repairInspection'],
	[CalendarEventSubtypes.REPAIR_DEFINITIVE_REPAIR, 'definitiveRepair'],
	[CalendarEventSubtypes.SCHEDULED_PLANNED_STOP_MAINTENANCE, 'stopMaintenance'],
	[CalendarEventSubtypes.SCHEDULED_AUDIT, 'audit']

]);

/**
 * Map with the relation between types and subtypes in the calendar event.
 */
export const CalendarEventTypeSubtypes: Map<number, number[]> = new Map([
	[CalendarEventTypes.ASSET, [CalendarEventSubtypes.ASSET_ATEX_INSPECTION, CalendarEventSubtypes.ASSET_DYNAMIC_INSPECTION]],
	[CalendarEventTypes.REPAIR, [CalendarEventSubtypes.REPAIR_DEFINITIVE_REPAIR, CalendarEventSubtypes.REPAIR_TEMPORARY_INSPECTION]],
	[CalendarEventTypes.SCHEDULED, [CalendarEventSubtypes.SCHEDULED_AUDIT, CalendarEventSubtypes.SCHEDULED_PLANNED_STOP_MAINTENANCE]]
]);

/**
 * Map with colors used to present each calendar event subtype on GUI.
 */
export const CalendareventSubtypeColors: Map<number, string> = new Map<number, string>([
	[CalendarEventSubtypes.ASSET_DYNAMIC_INSPECTION, 'var(--special-violet-2)'],
	[CalendarEventSubtypes.ASSET_ATEX_INSPECTION, 'var(--special-blue-2)'],
	[CalendarEventSubtypes.REPAIR_TEMPORARY_INSPECTION, 'var(--dark-error-hover)'],
	[CalendarEventSubtypes.REPAIR_DEFINITIVE_REPAIR, 'var(--special-orange-1)'],
	[CalendarEventSubtypes.SCHEDULED_PLANNED_STOP_MAINTENANCE, 'var(--special-green-2)'],
	[CalendarEventSubtypes.SCHEDULED_AUDIT, 'var(--special-green-3)']
]);
