/**
 * Format to present dates.
 */
export enum DateFormat {
    /**
     * Follow the format defined for locale.
     */
    DEFAULT = 0,

    /**
     * Day month and year.
     */
    DDMMYYY = 1,

    /**
     * Month day and year.
     */
    MMDDYYYY = 2,

    /**
     * Year month and day.
     */
    YYYYMMDD = 3,
}

export const DateFormatLabel: Map<DateFormat, String> = new Map<DateFormat, String>([
	[DateFormat.DEFAULT, 'default'],
	[DateFormat.DDMMYYY, 'DD/MM/YYY'],
	[DateFormat.MMDDYYYY, 'MM/DD/YYYY'],
	[DateFormat.YYYYMMDD, 'YYYY/MM/DD']
]);
