import {UUID} from '../../../models/uuid';
import {FormSortUtils} from '../../../utils/form-sort-utils';
import {FileUtils} from '../../../utils/file-utils';
import {Locale} from '../../../locale/locale';
import {DocxUtils} from '../../../utils/docx-utils';
import {Resource} from '../../../models/resource';
import {AssetSubTypeService} from '../services/asset-subtype.service';
import {AssetTypeService} from '../services/asset-type.service';
import {AssetSettingsService} from '../services/asset-settings.service';
import {
	AtexExplosionGroupsLabels,
	AtexTemperatureLabels,
	AtexZones,
	AtexZonesLabel
} from '../../../models/atex/atex-enums';
import {Atex, AtexTag} from '../../../models/atex/atex';
import {APAsset} from '../../../models/asset-portfolio/asset';
import {APAssetFormTab} from '../../../models/asset-portfolio/asset-form-tab';
import {APAssetFormTabCard} from '../../../models/asset-portfolio/asset-form-tab-card';
import {ResourceUtils} from '../../../utils/resource-utils';

/**
 * Asset report generates reports about the asset.
 *
 * These reports are generated using a docx (Microsoft Word) template that specifies the layout of the data.
 */
export class AssetReport {
	/**
	 * Default report file.
	 */
	public static defaultReportURL: string = 'assets/template/asset-file.docx';

	/**
	 * Generate asset file document using a docx template.
	 *
	 * @param asset - Asset object to generate report for.
	 * @param templateReport - Template report used as default.
	 */
	public static async generateDocx(asset: APAsset, templateReport: string = AssetReport.defaultReportURL): Promise<ArrayBuffer> {
		const settings = await AssetSettingsService.get();

		const template = ResourceUtils.getURL(settings.reportTemplate, templateReport);
		
		const arraybuffer: ArrayBuffer = await FileUtils.readFileArrayBuffer(template);

		// Get asset structure tabs content
		const assetTabs: APAssetFormTab[] = await AssetReport.getAssetStructureTabsContent(asset.typeUuid, asset.subTypeUuid);
		
		const data = {
			asset: asset,
			assetTabs: assetTabs,
			data: AssetReport.getAssetData(asset),
			images: AssetReport.getAssetImages(asset),
			atex: AssetReport.getAssetAtex(asset)
		};

		return DocxUtils.generateDocxFromTemplate(arraybuffer, data);
	}

	/**
	 * Prepare and get Atex tags from asset object.
	 *
	 * @param asset - Asset to get data from.
	 * @returns An array with the Atex tags for the asset.
	 */
	public static getAssetAtex(asset: APAsset): string[] {
		const atexTags = [];
		if (asset.atex) {
			for (let l = 0; l < asset.atex.tags.length; l++) {
				const tag = AtexTag.parse(asset.atex.tags[l]).toString();
				if (tag.length > 0) {
					atexTags.push(tag);
				}
			}
		}
		
		return atexTags;
	}

	/**
	 * Prepare asset data to make available in the asset report.
	 *
	 * @param asset - Asset to get data from.
	 * @returns An array of objects containing the attribute name and its value.
	 */
	public static getAssetData(asset: APAsset): {attr: string, value: any}[] {
		let data: {attr: string, value: any}[] = [
			{attr: Locale.get('uuid'), value: asset.uuid},
			{attr: Locale.get('name'), value: asset.name},
			{attr: Locale.get('description'), value: asset.description},
			{attr: Locale.get('tag'), value: asset.tag},
			{attr: Locale.get('qr'), value: asset.qr},
			{attr: Locale.get('manufacturer'), value: asset.manufacturer},
			{attr: Locale.get('model'), value: asset.model},
			{attr: Locale.get('serialNumber'), value: asset.serialNumber}
		];

		if (asset.position) {
			data.push({attr: Locale.get('position'), value: asset.position.latitude + ',' + asset.position.longitude});
		}

		if (asset.atex) {
			if (asset.atex.zone && asset.atex.zone.length > 0) {
				const zones: string[] = [];
				for (let z = 0; z < asset.atex.zone.length; z++) {
					zones.push(Locale.get(AtexZonesLabel.get(asset.atex.zone[z])));
				}
				data = data.concat([{attr: Locale.get('atexZone'), value: zones.join(',')}]);
			} else {
				data = data.concat([{attr: Locale.get('atexZone'), value: Locale.get(AtexZonesLabel.get(AtexZones.UNCLASSIFIED))}]);
			}

			// Atex Temperature
			if (Atex.isGasZone(asset.atex.zone)) {
				data.push({attr: Locale.get('atexZoneTemperature'), value: AtexTemperatureLabels.get(asset.atex.zoneTemperature)});
			} else {
				data.push({attr: Locale.get('atexZoneTemperature'), value: asset.atex.zoneTemperature});
			}

			data.push({attr: Locale.get('atexZoneExplosion'), value: AtexExplosionGroupsLabels.get(asset.atex.zoneExplosion)});
		}

		return data;
	}

	/**
	 * Get all the images attached to the asset.
	 *
	 * @param asset - Asset object to get images from.
	 */
	public static getAssetImages(asset: APAsset): Resource[] {
		let images: Resource[] = [];

		if (asset?.pictures) {
			images = images.concat(asset.pictures);
		}

		return images;
	}

	/**
	 * Gets all the tabs content for an asset.
	 * 
	 * @param assetTypeUuid - The asset type UUID to get the tabs for.
	 * @param assetSubTypeUuid - The asset subtype UUID to get the tabs for.
	 * @returns An array with all the asset structure tabs, cards, blocks and fields
	 */
	public static async getAssetStructureTabsContent(assetTypeUuid: UUID, assetSubTypeUuid: UUID): Promise<APAssetFormTab[]> {
		if (!assetTypeUuid) {
			throw new Error('Invalid asset type UUID provided when trying to get asset structure tabs content.');
		}

		let tabs: APAssetFormTab[] = await AssetTypeService.listTabs(assetTypeUuid);
		
		// The array that contains all the tabs' arrays of all the (sub-)sub-types
		if (assetSubTypeUuid) {
			// Get the tabs for the chosen (sub-)sub-type (if any)
			tabs = tabs.concat(await AssetSubTypeService.listTabs(assetSubTypeUuid));
	
			// Get the chosen sub-type
			const subType = await AssetSubTypeService.getSubtype(assetSubTypeUuid);
	
			// Get the higher levels' sub-type tabs
			if (subType.parentSubTypeUuid) {
				// Get the tabs for all the parents of this sub-sub-type (from the selected sub-sub-type, the lowest level, until reach the top parent) and add them to the array of arrays of tabs
				let subTypeParentId: UUID = subType.parentSubTypeUuid;
				do {
					// Get the sub-type parent
					const subSubType = await AssetSubTypeService.getSubtype(subTypeParentId);
	
					// Get tabs for this sub-type parent and add them to the beggining of the array of arrays of tabs. Since this loop will fecth from the lowest level to the highest, items are added on the beggining of the array.
					tabs = tabs.concat(await AssetSubTypeService.listTabs(subSubType.uuid));
	
					subTypeParentId = subSubType.parentSubTypeUuid;
				} while (subTypeParentId);
			}
		}
	
		// Sort and merge cards for every tab
		for (let i = 0; i < tabs.length; i++) {
			const tab: APAssetFormTab = tabs[i];
	
			// Get asset type tab cards content
			let cards: APAssetFormTabCard[] = await AssetTypeService.listTabCards(tab.uuid);
	
			// Get the (sub-...)sub-type cards for this tab
			if (assetSubTypeUuid) {
				cards = cards.concat(await AssetSubTypeService.listTabCards(assetSubTypeUuid, tab.uuid));

				// Get the chosen sub-type
				let subType = await AssetSubTypeService.getSubtype(assetSubTypeUuid);
	
				// Get all the tab cards for all the parents of this sub-type
				while (subType.parentSubTypeUuid) {
					// Get the sub-type parent
					const parentSubType = await AssetSubTypeService.getSubtype(subType.parentSubTypeUuid);

					// Get tab cards for this sub-type
					cards = cards.concat(await AssetSubTypeService.listTabCards(parentSubType.uuid, tab.uuid));

					subType = parentSubType;
				};
			}
	
			tab.cards = FormSortUtils.sortByIndexes(cards);
		}
		
		return FormSortUtils.sortByIndexes(tabs);
	}		
}
