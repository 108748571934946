import {UUID} from '../../app/models/uuid';

/**
 * Contains all the secil related constant values.
 *
 * (e.g. UUID of roles, project etc.)
 */
export class SecilConst {
	/**
	 * Client user role.
	 */
	public static ROLE: UUID = '2fc5d39c-602f-16e4-8603-687063050438';

	/**
	 * The DL50 Project uuid.
	 */
	public static PROJECT_UUID: UUID = '3611cfc7-143f-18a0-b3d2-f408a11ea211';

	/**
	 * The step to filter the inspections for
	 */
	public static STEP_UUID: UUID = '71822c22-ab7e-44fe-bd8f-3e42f6ee4dc7';

	/**
	 * The step that contains the conclusion
	 */
	public static CONCLUSION_STEP_UUID: UUID = '3f20dbfe-0070-4cc0-b499-f3c4407923a4';

	/**
	 * The path to reach the conclusion
	 */
	public static CONCLUSION_PATH: UUID[] = ['c5f7800a-ebc7-1f6b-9ed6-030ddb48d0e3', '3395b9d4-1982-1f5d-8619-dfe43a2a2bde'];

	/**
	 * Map with the different colors based on the conclusion.
	 */
	public static CONCLUSION_OPTIONS: Map<UUID, string> = new Map([
		['cf9db760-7bfe-4ebc-a9df-a508fa510749', 'var(--success-normal)'],
		['bb18a11f-b6d9-438d-93e0-e6d860653fe5', 'var(--warning-normal)'],
		['c7e1d0d9-b870-4e60-8da4-1afe2b5a5c8b', 'var(--warning-normal)'],
		['224a1454-85d1-44cc-a4c9-a26a0b844ea0', 'var(--error-normal)']
	]);
}

/**
 * Contains all the cires related constant values.
 *
 * (e.g. UUID of roles, project etc.)
 */
export class CiresConst {
	/**
	 * Client user role.
	 */
	public static ROLE: UUID = 'fbc90a06-45e0-1b11-939c-1d2f3fb87338';

	/**
	 * The DL50 Project uuid.
	 */
	public static PROJECT_UUID: UUID = 'fe170a71-06df-104d-bbda-ebd693447483';
	
	/**
	 * The step to filter the inspections for
	 */
	public static STEP_UUID: UUID = '71822c22-ab7e-44fe-bd8f-3e42f6ee4dc7';

	/**
	 * The step that contains the conclusion
	 */
	public static CONCLUSION_STEP_UUID: UUID = '3f20dbfe-0070-4cc0-b499-f3c4407923a4';

	/**
	 * The path to reach the conclusion
	 */
	public static CONCLUSION_PATH: UUID[] = ['c5f7800a-ebc7-1f6b-9ed6-030ddb48d0e3', '3395b9d4-1982-1f5d-8619-dfe43a2a2bde'];
	
	/**
	 * Map with the different colors based on the conclusion.
	 */
	public static CONCLUSION_OPTIONS: Map<UUID, string> = new Map([
		['cf9db760-7bfe-4ebc-a9df-a508fa510749', 'var(--success-normal)'],
		['bb18a11f-b6d9-438d-93e0-e6d860653fe5', 'var(--warning-normal)'],
		['c7e1d0d9-b870-4e60-8da4-1afe2b5a5c8b', 'var(--warning-normal)'],
		['224a1454-85d1-44cc-a4c9-a26a0b844ea0', 'var(--error-normal)']
	]);
}
