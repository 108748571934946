import {Service} from '../../../../../http/service';
import {ServiceList} from '../../../../../http/service-list';
import {Session} from '../../../../../session';
import {UnoFormField} from '../../../../../components/uno-forms/uno-dynamic-form/uno-form-field';
import {UnoFormFieldTypes} from '../../../../../components/uno-forms/uno-dynamic-form/uno-form-field-types';

export const PipelineLayout: UnoFormField[] = [
	{
		label: 'uuid',
		attribute: 'uuid',
		type: UnoFormFieldTypes.UUID
	},
	{
		required: true,
		attribute: 'name',
		label: 'name',
		type: UnoFormFieldTypes.TEXT
	},
	{
		required: false,
		attribute: 'description',
		label: 'description',
		type: UnoFormFieldTypes.TEXT_MULTILINE
	},
	{
		required: false,
		attribute: 'tag',
		label: 'tag',
		type: UnoFormFieldTypes.TEXT
	},
	{
		required: true,
		attribute: 'plantUuid',
		label: 'plant',
		type: UnoFormFieldTypes.OPTIONS,
		options: [],
		editable: true,
		fetchOptions: async(object: any, row: UnoFormField) => {
			const request = await Service.fetch(ServiceList.pipelineIntegrity.pipeline.plant.list, null, null, {}, Session.session);
			const plants = request.response.plants;

			const options = [];
			for (let i = 0; i < plants.length; i++) {
				options.push({
					label: plants[i].name,
					value: plants[i].uuid
				});
			}
			row.options = options;
		}
	}
];
