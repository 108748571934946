import {Component, Input, OnChanges, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {cloneDeep} from 'lodash-es';
import {App} from '../../../app';
import {UnoFormFieldTypes} from './uno-form-field-types';
import {UnoFormField} from './uno-form-field';
import {UnoFormUtils} from './uno-form-utils';

/**
 * Dynamic forms are defined by an array of DynamicFormFields that contain a type and attribute details.
 *
 * These forms are built from that list of fields and can be attached to any object that contain those fields.
 *
 * Dynamic forms can be used without the field specification. It is possible to generate automatically a list of fields by traversing the object (fields are selected based on attribute types).
 */
@Component({
	selector: 'uno-dynamic-form',
	templateUrl: 'uno-dynamic-form.component.html',
	styleUrls: ['uno-dynamic-form.component.css'],
	encapsulation: ViewEncapsulation.None
})
export class UnoDynamicFormComponent implements OnChanges {
	public get form(): any { return UnoDynamicFormComponent; }

	public get formUtils(): any { return UnoFormUtils; }

	public get types(): any { return UnoFormFieldTypes; }

	public get app(): any { return App; }

	/**
	 * Layout of the dynamic form, is an array of form entry descriptions.
	 *
	 * Each field has the attribute name, label, type of data and additional type specific elements.
	 *
	 * e.g. `[{label: abc, attribute: something, type: UnoFormFieldTypes.TEXT}]`
	 */
	@Input()
	public layout: UnoFormField[] = [];

	/**
	 * Object being edited in the dynamic form.
	 */
	@Input()
	public object: any = {};

	/**
	 * Indicates if the form fields are editable or view only.
	 */
	@Input()
	public editable: boolean = true;

	/**
	 * Flag to define if the component should generate fields automatically for the input object.
	 *
	 * By default, it does not generate fields automatically.
	 */
	@Input()
	public generateFields: boolean = false;

	/**
	 * Callback method called every time that a field from the form is edited.
	 *
	 * Automatically called by the setAttribute() method if used, otherwise the field has to manually check and call this on change.
	 */
	@Input()
	public onChange: (object: any, row: UnoFormField, value: any)=> void = null;

	/**
	 * Check for changes in the layout object and create one if necessary.
	 */
	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.layout) {
			this.layout = cloneDeep(this.layout);
		}
		
		if (changes.layout || changes.object) { 
			if (this.layout && this.object) {
				UnoFormUtils.fetchOptions(this.layout, this.object);
			}
		}

		// Generate fields automatically
		if (changes.generateFields) {
			if (this.generateFields && !this.layout && this.object !== null) {
				this.layout = UnoFormUtils.createLayout(this.object);
			}
		}

	}

	/**
	 * Check if a row is editable based on the editable value and the form field description.
	 *
	 * @param row - Row of the form to check.
	 * @returns True if the row can be edited, false otherwise.
	 */
	public rowEditable(row: UnoFormField): boolean {
		return this.editable !== false && UnoFormUtils.checkBool(row.editable, true, this.object, row);
	}

	/**
	 * Check if all required fields are filled. Should be called before submitting any data to ensure that required fields are filled.
	 *
	 * It does not checks if the data inserted is valid.
	 *
	 * @param layout - Layout to be used to analyse the object.
	 * @param object - Object to check against the layout.
	 * @returns True if all required fields are filled, false otherwise.
	 */
	public static requiredFilled(layout: UnoFormField[], object: any): boolean {
		const form = new UnoDynamicFormComponent();
		form.object = object;
		form.layout = layout;
		return form.requiredFilled();
	}

	/**
	 * Check if all required fields are filled. Should be called before submitting any data to ensure that required fields are filled.
	 *
	 * It does not check if the data inserted is valid.
	 *
	 * @returns True if all required fields are filled, false otherwise.
	 */
	public requiredFilled(): boolean {
		const checkRequired = function(fields: UnoFormField[], object: any): boolean {
			if (!fields) {
				return true;
			}

			for (let i = 0; i < fields.length; i++) {
				const field: UnoFormField = fields[i];
				
				if (field.type === UnoFormFieldTypes.TITLE) {
					continue;
				}
	
				// Ignore titles and inactive layout rows
				if (!UnoFormUtils.checkBool(field.isActive, true, object, field)) {
					continue;
				}

				
				// Sub-forms and composed fields
				if (field.type === UnoFormFieldTypes.COMPOSED_FIELD || field.type === UnoFormFieldTypes.SUB_FORM) {
					if (field?.fields?.length > 0 && !checkRequired(field.fields, UnoFormUtils.getAttribute(object, field))) {
						return false;
					}

				} else if (field.type === UnoFormFieldTypes.REPETITIVE_FORM) {

					if (field?.fields?.length > 0) {
						// Check items for a repetitive form field
						const items = UnoFormUtils.getAttribute(object, field);
						if (items) {
							for (let j = 0; j < items.length; j++) {
								if (!checkRequired(field.fields, items[j])) {
									return false;
								}
							}
						}
					}
				} else {
					// Only check if the row is required.
					if (UnoFormUtils.checkBool(field.required, false, object, field) && UnoFormUtils.fieldEmpty(field, object)) {
						return false;
					}
				}
			}

			return true;
		};

		return checkRequired(this.layout, this.object);
	}
}
