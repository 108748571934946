@if (this.object) {
	<uno-title (click)="this.expanded.set(!this.expanded());" style="cursor: pointer; margin-top: 10px;">
		<ion-icon src="{{this.expanded() ? 'assets/icons/material/expand_more/outline.svg' : 'assets/icons/material/chevron_right/outline.svg'}}"></ion-icon>
		{{row.label | translate}}
	</uno-title>

	@if (this.expanded()) {
		<uno-dynamic-form [editable]="this.editable" [layout]="row.fields" [object]="this.object"></uno-dynamic-form>
	}
}
