export const AtexInspectionStatus = {
	ALL: -1,
	NONE: 0,
	TODO: 1,
	REVIEW: 2,
	COMPLETED: 3,
	TODO_SUPERVISION: 4,
	REVIEW_SUPERVISION: 5,
	REJECTED: 6
};

export const AtexInspectionStatusLabel: Map<number, string> = new Map([
	[AtexInspectionStatus.ALL, 'all'],
	[AtexInspectionStatus.NONE, 'none'],
	[AtexInspectionStatus.TODO, 'todo'],
	[AtexInspectionStatus.REVIEW, 'review'],
	[AtexInspectionStatus.COMPLETED, 'completed'],
	[AtexInspectionStatus.TODO_SUPERVISION, 'underSupervision'],
	[AtexInspectionStatus.REVIEW_SUPERVISION, 'underSupervision'],
	[AtexInspectionStatus.REJECTED, 'rejected']
]);
