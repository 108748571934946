import {Component, Input, ViewEncapsulation} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {RouterOutlet} from '@angular/router';
import {NgStyle} from '@angular/common';
import {App} from '../../../../app';
import {Session} from '../../../../session';
import {StyleManager} from '../../../../theme/style-manager';
import {MenuOption} from '../../data/menu-option';
import {UnoIconComponent} from '../../../../components/uno/uno-icon/uno-icon.component';
import {UserAvatarComponent} from '../user-avatar/user-avatar.component';

@Component({
	selector: 'menu-mobile-page',
	templateUrl: './menu-mobile.component.html',
	styleUrls: ['./menu-mobile.component.scss'],
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [NgStyle, UserAvatarComponent, RouterOutlet, UnoIconComponent, TranslateModule]
})
export class MenuMobileComponent {
	public get menuOption(): any { return MenuOption; }

	public get app(): any { return App; }

	public get session(): any { return Session; }

	public get themes(): any { return StyleManager; }

	@Input()
	public pages: MenuOption[] = [];
}
