import {Component, ViewChild} from '@angular/core';
import {App} from 'src/app/app';
import {Session} from 'src/app/session';
import {Modal} from 'src/app/modal';
import {Locale} from 'src/app/locale/locale';
import {AssetBaseLayout} from 'src/app/modules/asset-portfolio/screens/asset/asset-layout';
import {TranslateModule} from '@ngx-translate/core';
import {IonicModule} from '@ionic/angular';
import {UnoFormField} from '../../../../../components/uno-forms/uno-dynamic-form/uno-form-field';
import {UnoDynamicFormModule} from '../../../../../components/uno-forms/uno-dynamic-form.module';
import {UnoDynamicFormComponent} from '../../../../../components/uno-forms/uno-dynamic-form/uno-dynamic-form.component';
import {ServiceList} from '../../../../../http/service-list';
import {Service} from '../../../../../http/service';
import {APAsset} from '../../../../../models/asset-portfolio/asset';
import {UUID} from '../../../../../models/uuid';
import {UserPermissions} from '../../../../../models/users/user-permissions';
import {GAActionPlanFormLayout} from '../../data/action-plan-form-layout';
import {GAActionPlanStatus} from '../../../../../models/gap-analysis/action-plans/action-plan-status';
import {GAActionPlanHistory} from '../../../../../models/gap-analysis/action-plans/action-plan-history';
import {GAActionPlan} from '../../../../../models/gap-analysis/action-plans/action-plan';
import {ScreenComponent} from '../../../../../components/screen/screen.component';
import {FormatDatePipe} from '../../../../../pipes/format-date.pipe';
import {UnoTitleComponent} from '../../../../../components/uno/uno-title/uno-title.component';
import {UnoButtonComponent} from '../../../../../components/uno/uno-button/uno-button.component';
import {UnoTabSectionComponent} from '../../../../../components/uno/uno-tab/uno-tab-section/uno-tab-section.component';
import {UnoTabComponent} from '../../../../../components/uno/uno-tab/uno-tab.component';

/**
 * Screen to create, edit and present gap analysis action plans info.
 * 
 * When in creation mode, createMode attribute should be passed as true on navigator data.
 * 
 * When in edition mode, the UUID of the action plan to edit should be passed in order to load its data from DB into the screen forms.
 * 
 * Action plan history entries are also presented on screen.
 */
@Component({
	selector: 'gap-analysis-action-plan-edit-page',
	templateUrl: './action-plan-edit.page.html',
	standalone: true,
	imports: [UnoTabComponent, UnoTabSectionComponent, UnoDynamicFormModule, UnoButtonComponent, UnoTitleComponent, IonicModule, TranslateModule, FormatDatePipe]
})
export class GAActionPlanEditPage extends ScreenComponent {
	@ViewChild('actionPlanForm', {static: false})
	public actionPlanForm: UnoDynamicFormComponent = null;

	public get app(): any { return App; }

	public get session(): any { return Session; }

	public get userPermissions(): any { return UserPermissions; }

	public get actionPlanLayout(): UnoFormField[] { return GAActionPlanFormLayout; }

	public get actionPlanStatus(): any { return GAActionPlanStatus; }

	public get assetsLayout(): UnoFormField[] { return AssetBaseLayout; }

	public permissions = [UserPermissions.GA_ACTION_PLAN_CREATE, UserPermissions.GA_ACTION_PLAN_EDIT];

	/**
	 * Action plan being edited in this screen.
	 */
	public actionPlan: GAActionPlan = null;

	/**
	 * Flag to indicate if the screen is in create mode.
	 */
	public createMode: boolean = false;

	/**
	 * Edit history of the action plan.
	 */
	public history: GAActionPlanHistory[] = [];

	/**
	 * The assets referenced on this action plan.
	 */
	public assets: APAsset[] = [];

	public async ngOnInit(): Promise<void> {
		super.ngOnInit();
		
		// Reset all the objects
		this.createMode = false;
		this.actionPlan = null;
		this.history = [];

		App.navigator.setTitle('actionPlan');

		const data: {createMode: boolean, uuid: UUID, gapUuids: UUID[]} = App.navigator.getData();
		if (!data?.uuid && !data?.createMode) {
			App.navigator.pop();
			return;
		}

		if (data?.createMode) {
			this.createMode = true;
			this.actionPlan = new GAActionPlan();

			if (data.gapUuids) {
				this.actionPlan.gapUuids = data.gapUuids;
			}
		} else {
			await Promise.all([await this.loadData(data.uuid), await this.loadHistory(data.uuid)]);
		}
	}

	/**
	 * Get all the needed data from the API to the objects to be presented on screen forms.
	 *
	 * @param uuid - UUID of the action plan.
	 */
	public async loadData(uuid: UUID): Promise<void> {
		await Promise.all([this.loadActionPlan(uuid), this.loadAssets(uuid)]);
	}

	/**
	 * Load the action plan data from API.
	 *
	 * @param uuid - UUID of the action plan.
	 */
	public async loadActionPlan(uuid: UUID): Promise<void> {
		this.actionPlan = GAActionPlan.parse((await Service.fetch(ServiceList.gapAnalysis.actionPlan.get, null, null, {uuid: uuid}, Session.session)).response.actionPlan);
	}

	/**
	 * Load the UUIDs of all the assets referenced by the gaps of this action plan from the API.
	 *
	 * @param uuid - UUID of the action plan.
	 */
	public async loadAssets(uuid: UUID): Promise<void> {
		// Get the UUIDs of all the assets referenced by the gaps of this action plan
		const assetUuids: UUID[] = (await Service.fetch(ServiceList.gapAnalysis.actionPlan.listAssets, null, null, {uuid: uuid}, Session.session)).response.assetUuids;
		
		// Fetch the assets to present on screen
		this.assets = (await Service.fetch(ServiceList.assetPortfolio.asset.getBatch, null, null, {assets: assetUuids}, Session.session)).response.assets.map((d: any) => {return APAsset.parse(d);});
	}

	/**
	 * Load the action plan history from database.
	 * 
	 * @param uuid - UUID of the action plan.
	 */
	public async loadHistory(uuid: UUID): Promise<void> {
		this.history = (await Service.fetch(ServiceList.gapAnalysis.actionPlan.history.list, null, null, {uuid: uuid}, Session.session)).response.actionPlanHistories.map((h) => {return GAActionPlanHistory.parse(h); });
	}

	/**
	 * Save the action plan data.
	 * 
	 * Depending on createMode value, this method can either create or update an action plan.
	 * 
	 * @param status - The optional status to transit the action plan to.
	 * @param stayOnPage - Whether to stay or leave the page after save.
	 */
	public async save(status?: number, stayOnPage: boolean = false): Promise<void> {
		if (!this.actionPlanForm.requiredFilled()) {
			Modal.alert(Locale.get('error'), Locale.get('requiredFieldsError'));
			return;
		}

		const actionPlan = structuredClone(this.actionPlan);
		if (status !== undefined) {
			actionPlan.status = status;
		}

		await Service.fetch(this.createMode ? ServiceList.gapAnalysis.actionPlan.create : ServiceList.gapAnalysis.actionPlan.update, null, null, actionPlan, Session.session);

		Modal.toast(Locale.get(this.createMode ? 'createdSuccessfully' : 'updatedSuccessfully'));

		if (this.createMode || !stayOnPage) {
			App.navigator.pop();
			return;
		}
		
		// Every time an update occurs, there is an history change.
		this.loadHistory(this.actionPlan.uuid);
	}

	/**
	 * Delete the action plan from the API.
	 */
	public async delete(): Promise<void> {
		// Ask for confirmation before delete
		if (await Modal.confirm(Locale.get('confirm'), Locale.get('confirmDelete'))) {
			await Service.fetch(ServiceList.gapAnalysis.actionPlan.delete, null, null, {uuid: this.actionPlan.uuid}, Session.session);
			Modal.toast(Locale.get('deleteSuccessfully'));
			App.navigator.pop();
			return;
		}
	}
}
