import {Component, OnInit} from '@angular/core';
import {App} from '../../../../app';
import {ScreenComponent} from '../../../../components/screen/screen.component';
import {UserPermissions} from '../../../../models/users/user-permissions';
import {Session} from '../../../../session';
import {MenuCardsOptions, MenuCardsComponent} from '../../../../components/layout/menu-cards/menu-cards.component';
import {Service} from '../../../../http/service';
import {ServiceList} from '../../../../http/service-list';
import {UnoContentComponent} from '../../../../components/uno/uno-content/uno-content.component';

@Component({
	selector: 'atex-page',
	templateUrl: 'atex.page.html',
	standalone: true,
	imports: [UnoContentComponent, MenuCardsComponent]
})
export class AtexPage extends ScreenComponent implements OnInit {
	public get app(): any { return App; }

	public get session(): any { return Session; }

	public permissions = [UserPermissions.ATEX_INSPECTION, UserPermissions.FFP, UserPermissions.ACTION_PLAN];

	public ngOnInit(): void {
		super.ngOnInit();
		
		App.navigator.setTitle('atex');
	}

	/**
	 * List of options in this menu.
	 */
	public options: MenuCardsOptions[] = [
		{
			route: '/menu/atex/inspections',
			icon: 'checkbox-outline',
			label: 'atexInspections',
			permissions: [UserPermissions.ATEX_INSPECTION],
			data: null,
			badge: (async() => {
				return (await Service.fetch(ServiceList.atexInspection.count, null, null, null, Session.session, true)).response.count;
			})()
		},
		{
			route: '/menu/atex/ffp',
			icon: 'hammer',
			label: 'ffpShort',
			permissions: [UserPermissions.FFP],
			data: null,
			badge: (async() => {
				return (await Service.fetch(ServiceList.ffp.count, null, null, null, Session.session, true)).response.count;
			})()
		},
		{
			route: '/menu/atex/action-plan',
			icon: 'construct',
			label: 'actionPlan',
			permissions: [UserPermissions.ACTION_PLAN],
			data: null,
			badge: (async() => {
				return (await Service.fetch(ServiceList.actionPlan.count, null, null, null, Session.session, true)).response.count;
			})()
		}
	];
}
