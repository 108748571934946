import {
	Component,
	ElementRef,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	SimpleChanges,
	ViewChild,
	ViewEncapsulation
} from '@angular/core';
import {Chart, ChartData, ChartEvent, ActiveElement} from 'chart.js';
import {Color} from 'three';
import {NgStyle} from '@angular/common';
import {CssNgStyle} from '../../../utils/css-ng-style';

export class DoughnutChartData {
	/**
	 * Label associated with the value.
	 */
	public label: string = '';

	/**
	 * Number of occurrences of the entry.
	 */
	public value: number = 0;

	/**
	 * Color of the section
	 */
	public color: Color = null;

	/**
	 * Method called when the doughnut section is clicked.
	 */
	public onClick: (event: ChartEvent, elements: ActiveElement[], chart: Chart)=> void;

	public constructor(label: string, value: number, color: Color, onClick: (event: ChartEvent, elements: ActiveElement[], chart: Chart)=> void = null) {
		this.label = label;
		this.value = value;
		this.color = color;
		this.onClick = onClick;
	}
}

@Component({
	selector: 'doughnut-chart',
	templateUrl: './doughnut-chart.component.html',
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [NgStyle]
})
export class DoughnutChartComponent implements OnChanges, OnInit, OnDestroy {
	@ViewChild('canvas', {static: true})
	public canvas: ElementRef = null;

	@Input()
	public data: DoughnutChartData[] = null;

	/**
	 * Either to compute and show percentage of chart slices.
	 */
	@Input()
	public showPercentages?: boolean = false;

	@Input()
	public ngStyle: CssNgStyle = null;

	public context: CanvasRenderingContext2D = null;

	public chart: Chart = null;

	public dataset: ChartData = null;

	/**
	 * Update the graph data should be called when the data is changed.
	 */
	public ngOnChanges(changes: SimpleChanges): void {
		const labels = [];
		const data = [];
		const backgroundColor = [];
		const borderColor = [];

		for (let i = 0; i < this.data.length; i++) {
			labels.push(this.data[i].label);
			data.push(this.data[i].value);

			const color = '#' + this.data[i].color.getHexString();
			backgroundColor.push(color + '66');
			borderColor.push(color);
		}

		if (this.chart) {
			this.dataset.labels = labels;
			this.dataset.datasets[0].data = data;
			this.dataset.datasets[0].backgroundColor = backgroundColor;
			this.dataset.datasets[0].borderColor = borderColor;

			this.chart.update();
		}
	}

	public ngOnInit(): void {
		this.dataset = {
			labels: [],
			datasets: [{
				data: [],
				backgroundColor: [],
				borderColor: [],
				hoverOffset: 3
			}]
		};

		this.context = this.canvas.nativeElement.getContext('2d');
		
		this.chart = new Chart(this.context, {
			type: 'doughnut',
			data: this.dataset,
			options: {
				responsive: true,
				maintainAspectRatio: false,
				plugins: {
					legend: {
						display: true,
						position: 'top'
					},
					tooltip: {
						callbacks: {
							label: (context) => {
								// Change tooltip label to be presented on chart slice hover
								let label: string = context.label + ': ' + context.raw;
								
								// Add percentage values
								if (this.showPercentages) {
									// Compute the sum of all the values on graph
									let sum: number = 0;
									for (const v of context.dataset.data) {
										sum += v as number;
									}
									
									label += ' (' + (context.raw as number / sum * 100).toFixed(2) + '%)';
								}

								return label;
							}
						}
					}
				},
				onClick: (event: ChartEvent, elements: ActiveElement[], chart: Chart) => {
					if (elements.length > 0) {
						const index = elements[0].index;
						if (this.data[index].onClick) {
							this.data[index].onClick(event, elements, chart);
						}
					}
				}
			}
		});
	}

	public ngOnDestroy(): void {
		if (this.chart) {
			this.chart.destroy();
			this.chart = null;
		}
	}
}
