import {Service} from 'src/app/http/service';
import {ServiceList} from 'src/app/http/service-list';
import {Repair} from 'src/app/models/repairs/repairs/repair';
import {UUID} from 'src/app/models/uuid';
import {Session} from 'src/app/session';

export class RepairService {
	/**
	 * Get the repair by UUID.
	 * 
	 * @param uuid - The UUID of the repair to get.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 * @returns Repair object.
	 */
	public static async get(uuid: UUID, hideLoading: boolean = false, displayError: boolean = true): Promise<Repair> {
		const request = await Service.fetch(ServiceList.repairs.get, null, null, {uuid: uuid}, Session.session, hideLoading, displayError);
		return Repair.parse(request.response.repair);
	}

	/**
	* Get the repair by QR code.
	* 
	* @param qr - The QR code of the repair to get.
	* @param hideLoading - True to hide the loading spinner, false otherwise.
	* @param displayError - True to display an error message, false otherwise.
	* @returns Repair object.
	*/
	public static async getByQR(qr: string, hideLoading: boolean = false, displayError: boolean = true): Promise<Repair> {
		const request = await Service.fetch(ServiceList.repairs.getQR, null, null, {qr: qr}, Session.session, hideLoading, displayError);
		return Repair.parse(request.response.repair);
	}
}
