import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {IonicModule} from '@ionic/angular';
import {SvgIconComponent} from 'angular-svg-icon';
import {NgStyle} from '@angular/common';
import {CssNgStyle} from '../../../../../../utils/css-ng-style';
import {DigitalTwinEditor} from '../../editor/digital-twin-editor';
import {DOMUtils} from '../../../../../../utils/dom-utils';
import {DigitalTwinEditorMode} from '../../editor/digital-twin-editor-mode';
import {CSSUtils} from '../../../../../../utils/css-utils';
import {FileUtils} from '../../../../../../utils/file-utils';
import {Locale} from '../../../../../../locale/locale';
import {Marker} from '../../../../data/objects/marker';
import {Box} from '../../../../data/objects/box';
import {Modal} from '../../../../../../modal';
import {App} from '../../../../../../app';
import {UnoTooltipDirective} from '../../../../../../components/uno/uno-tooltip/uno-tooltip.directive';

/**
 * Toolbar option entry.
 */
class ToolBarOption {
	/**
	 * Label displayed when the pointer is over the button.
	 */
	public label: string;

	/**
	 * Icon of the button.
	 */
	public icon: string;

	/**
	 * Callback method executed when the user presses the button.
	 */
	public onClick: ()=> void;
}

@Component({
	selector: 'digital-twin-tool-bar',
	templateUrl: './digital-twin-tool-bar.component.html',
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [NgStyle, UnoTooltipDirective, SvgIconComponent, IonicModule, TranslateModule]
})
export class DigitalTwinToolBarComponent implements OnInit {
	public get app(): any { return App; }

	/**
	 * Style to apply to the element.
	 */
	@Input()
	public ngStyle: CssNgStyle = {};

	/**
	 * Toggle switch style.
	 */
	@Input()
	public toggleButtonStyle: CssNgStyle = {left: '55px'};

	/**
	 * Digital twin editor object.
	 */
	@Input()
	public editor: DigitalTwinEditor = null;

	/**
	 * Indicates if the toolbar is collapsed or visible.
	 */
	public collapsed: boolean = false;

	/**
	 * Options available in the toolbar.
	 */
	public options: ToolBarOption[] = [
		{
			label: 'navigate',
			icon: 'assets/icons/3d/navigate.svg',
			onClick: () => {
				this.editor.setMode(DigitalTwinEditorMode.NAVIGATION);
			}
		},
		{
			label: 'select',
			icon: 'assets/icons/3d/select.svg',
			onClick: () => {
				this.editor.setMode(DigitalTwinEditorMode.SELECT_OBJECT);
			}
		},
		{
			label: 'add',
			icon: 'assets/icons/3d/marker.svg',
			onClick: () => {
				this.editor.objectConstructor = Marker;
				this.editor.setMode(DigitalTwinEditorMode.PLACE_OBJECT);
			}
		},
		{
			label: 'add',
			icon: 'assets/icons/3d/box.svg',
			onClick: () => {
				this.editor.objectConstructor = Box;
				this.editor.setMode(DigitalTwinEditorMode.PLACE_OBJECT);
			}
		},
		{
			label: 'move',
			icon: 'assets/icons/3d/transform.svg',
			onClick: () => {
				this.editor.transformControls.setSpace('world');
				this.editor.transformControls.setMode('translate');
			}
		},
		{
			label: 'rotate',
			icon: 'assets/icons/3d/rotate.svg',
			onClick: () => {
				this.editor.transformControls.setSpace('world');
				this.editor.transformControls.setMode('rotate');
			}
		},
		{
			label: 'scale',
			icon: 'assets/icons/3d/resize.svg',
			onClick: () => {
				this.editor.transformControls.setSpace('world');
				this.editor.transformControls.setMode('scale');
			}
		},
		{
			label: 'delete',
			icon: 'assets/icons/3d/trash.svg',
			onClick: () => {
				if (this.editor.selected) {
					(async() => {
						const confirm = await Modal.confirm(Locale.get('confirm'), Locale.get('confirmDelete'));
						if (confirm) {
							await this.editor.deleteObject(this.editor.selected);
							this.editor.select(null);
						}
					})();
				} else {
					Modal.alert(Locale.get('warning'), Locale.get('selectObjectFirst'));
				}
			}
		},
		{
			label: 'focus',
			icon: 'assets/icons/3d/focus.svg',
			onClick: () => {
				if (this.editor.selected) {
					this.editor.controls.focusObject(this.editor.selected);
				} else {
					Modal.alert(Locale.get('warning'), Locale.get('selectObjectFirst'));
				}
			}
		},
		{
			label: 'fullscreen',
			icon: 'assets/icons/3d/fullscreen.svg',
			onClick: async() => {
				await DOMUtils.setFullscreen(true, this.editor.canvas.canvas);
			}
		},
		{
			label: 'location',
			icon: 'assets/icons/3d/location.svg',
			onClick: async() => {
				await this.editor.getGeolocation();
			}
		},
		{
			label: 'upload',
			icon: 'assets/icons/3d/zip.svg',
			onClick: async() => {
				const files = await FileUtils.chooseFile('.upcl');
				console.log('EQS: Selected .zip files', files);
				if (files.length > 0) {
					await this.editor.loadFile(files[0]);
				}
			}
		},
		{
			label: 'screenshot',
			icon: 'assets/icons/3d/screenshot.svg',
			onClick: () => {
				this.editor.takeScreenshot();
			}
		}
	];

	public ngOnInit(): void {
		this.updateStyle();
	}

	/**
	 * Update the style of the element.
	 */
	public updateStyle(): void {
		if (App.device.isDesktop()) {
			Object.assign(this.ngStyle, {
				position: 'absolute',
				display: 'block',
				bottom: '30px',
				'background-color': CSSUtils.getVariableWithAlpha('--dark', 0.2),
				'border-radius': '10px',
				height: '65px',
				width: this.options.length * 65 + 'px',
				left: 'calc(50% - ' + this.options.length * 65 / 2 + 'px)'
			});
		} else {
			Object.assign(this.ngStyle, {
				position: 'absolute',
				display: 'block',
				'background-color': 'var(--gray-13)',
				height: '100%',
				left: '0px',
				top: '0px',
				width: '55px',
				'overflow-y': 'auto',
				direction: 'rtl'
			});
		}

		this.ngStyle.display = this.collapsed ? 'none' : 'block';
		this.toggleButtonStyle.left = this.collapsed ? '0px' : '55px';
	}


	/**
	 * Toggle the collapsed state of the toolbar.
	 */
	public toggleCollapsed(): void {
		this.collapsed = !this.collapsed;
		this.updateStyle();
	}
}
