import {UnoFormField} from '../../../../../components/uno-forms/uno-dynamic-form/uno-form-field';
import {UnoFormFieldTypes} from '../../../../../components/uno-forms/uno-dynamic-form/uno-form-field-types';
import {AssetReport} from '../../../data/asset-report';

export class AssetSettingsLayout {
	public static layout: UnoFormField[] = [
		{
			type: UnoFormFieldTypes.TITLE,
			label: 'settings'
		},
		{
			attribute: 'reportTemplate',
			label: 'reportTemplate',
			type: UnoFormFieldTypes.DOCUMENT_RESOURCE,
			filter: '.docx',
			isActive: true,
			sampleData: AssetReport.defaultReportURL
		}
	];
}
