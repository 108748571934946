import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {IonicModule} from '@ionic/angular';
import {UnoDynamicFormComponent} from '../../../../../components/uno-forms/uno-dynamic-form/uno-dynamic-form.component';
import {AssetService} from '../../../../asset-portfolio/services/asset.service';
import {ServiceResponse} from '../../../../../http/service-response';
import {APAsset} from '../../../../../models/asset-portfolio/asset';
import {Session} from '../../../../../session';
import {App} from '../../../../../app';
import {AtexInspectionStatus} from '../../../../../models/atex-inspections/inspections/atex-inspection-status';
import {Service} from '../../../../../http/service';
import {ServiceList} from '../../../../../http/service-list';
import {Modal} from '../../../../../modal';
import {Locale} from '../../../../../locale/locale';
import {AtexInspectionLayouts} from '../atex-inspection-layouts';
import {UnoDynamicFormModule} from '../../../../../components/uno-forms/uno-dynamic-form.module';
import {Loading} from '../../../../../loading';
import {ScreenComponent} from '../../../../../components/screen/screen.component';
import {UnoFormField} from '../../../../../components/uno-forms/uno-dynamic-form/uno-form-field';
import {AtexInspection} from '../../../../../models/atex-inspections/inspections/atex-inspection';
import {UserPermissions} from '../../../../../models/users/user-permissions';
import {UnoFormFieldTypes} from '../../../../../components/uno-forms/uno-dynamic-form/uno-form-field-types';
import {AtexInspectionReport} from '../../data/atex-inspection-report';
import {FileUtils} from '../../../../../utils/file-utils';
import {AtexInspectionForm} from '../../../../../models/atex-inspections/inspections/atex-inspection-form';
import {AtexInspectionResult} from '../../../../../models/atex-inspections/inspections/atex-inspection-result';
import {UUID} from '../../../../../models/uuid';
import {AtexInspectionFieldResult} from '../../../../../models/atex-inspections/inspections/atex-inspection-field-result';
import {AtexInspectionResponses} from '../../../../../models/atex-inspections/inspections/atex-inspection-data';
import {
	AssetAtexLayout,
	AssetBaseLayout,
	AssetModelLayout
} from '../../../../asset-portfolio/screens/asset/asset-layout';
import {FormatDatePipe} from '../../../../../pipes/format-date.pipe';
import {UnoButtonComponent} from '../../../../../components/uno/uno-button/uno-button.component';
import {UnoTitleComponent} from '../../../../../components/uno/uno-title/uno-title.component';
import {UnoTabSectionComponent} from '../../../../../components/uno/uno-tab/uno-tab-section/uno-tab-section.component';
import {UnoTabComponent} from '../../../../../components/uno/uno-tab/uno-tab.component';
import {AtexInspectionHistory, AtexInspectionService} from '../../services/atex-inspection.service';
import {AtexInspectionChecklistService} from '../../services/atex-inspection-checklist.service';
import {AtexInspectionUtils} from '../../data/atex-inspection-utils';

@Component({
	selector: 'atex-inspections-review-page',
	templateUrl: 'atex-inspections-edit.page.html',
	standalone: true,
	imports: [UnoTabComponent, UnoTabSectionComponent, UnoTitleComponent, UnoDynamicFormModule, UnoButtonComponent, IonicModule, TranslateModule, FormatDatePipe]
})
export class AtexInspectionsEditPage extends ScreenComponent implements OnInit {
	public get assetLayout(): any { return AssetBaseLayout; }

	public get inspectionLayouts(): any { return AtexInspectionLayouts; }

	public get atexLayout(): any { return AssetAtexLayout; }

	public get modelLayout(): any { return AssetModelLayout; }

	public get inspectionStatus(): any { return AtexInspectionStatus; }

	public get session(): any { return Session; }

	public get userPermissions(): any { return UserPermissions; }

	public get app(): any { return App; }

	@ViewChild('resultForm', {static: false})
	public resultForm: UnoDynamicFormComponent = null;

	@ViewChild('inspectionForm', {static: false})
	public inspectionForm: UnoDynamicFormComponent = null;

	@ViewChild('backofficeForm', {static: false})
	public backofficeForm: UnoDynamicFormComponent = null;

	public permissions = [UserPermissions.ATEX_INSPECTION];

	/**
	 * Inspection object received from the API.
	 */
	@Input()
	public inspection: AtexInspection = null;

	/**
	 * Layout to select the inspection forms that will be filled.
	 */
	public inspectionSelectionFormLayout: UnoFormField[] = [];

	/**
	 * Method to call after the inspection form selection is changed.
	 */
	public inspectionSelectionFormOnChange: Function = null;

	/**
	 * Layout of the backoffice inspection form if any.
	 */
	public backofficeFormLayout: UnoFormField[] = [];

	/**
	 * Layout to select the inspection forms that will be filled.
	 */
	public inspectorFormLayout: UnoFormField[] = [];

	/**
	 * Asset of the inspection being edited.
	 */
	public asset: APAsset = null;

	/**
	 * List of actions applied to the inspection.
	 */
	public history: AtexInspectionHistory[] = [];

	/** 
	 * Cache atex inspections checklist to prevent multiple requests of the same data.
	*/
	public checklist: any = {};

	/**
	 * Flag set true when any asset information is edited.
	 */
	public assetEdited: boolean = false;

	/**
	 * Sets asset edited flag true.
	 */
	public setAssetEdited: Function = () => {
		this.assetEdited = true;
	};

	public async ngOnInit(): Promise<void> {
		super.ngOnInit();

		this.assetEdited = false;
		this.inspection = null;
		this.asset = null;
		this.history = [];
		this.inspectionSelectionFormLayout = [];
		this.backofficeFormLayout = [];
		this.inspectorFormLayout = [];

		this.inspectionSelectionFormOnChange = async() => {
			await this.buildInspectionForms();
		};

		App.navigator.setTitle('inspections');

		const data = App.navigator.getData();
		if (!data || !data.uuid) {
			App.navigator.pop();
			return;
		}

		await this.buildInspectionSelectionForm();
		await this.loadData(data.uuid);
		this.history = await AtexInspectionService.listHistoryUsers(data.uuid);
	}

	/**
	 * Build inspections form from inspector and backoffice checklists.
	 */
	public async buildInspectionForms(): Promise<void> {
		if (!this.inspection || !this.asset) {
			throw new Error('Inspection and asset should be loaded before building inspection form');	
		}

		this.inspectorFormLayout = await AtexInspectionUtils.buildForm(this.inspection, AtexInspectionForm.INSPECTOR, this.asset);

		if (this.inspection.status === AtexInspectionStatus.REVIEW || this.inspection.status === AtexInspectionStatus.COMPLETED) {
			this.backofficeFormLayout = await AtexInspectionUtils.buildForm(this.inspection, AtexInspectionForm.BACKOFFICE, this.asset);
		}
	}

	/**
	 * Open an history entry in a modal to display the changes performed on a selected entry.
	 *
	 * @param historyId - History id of the entry from history list to be displayed.
	 */
	public async openHistoryEntry(historyId: number): Promise<void> {
		const selectedEntryResponse: ServiceResponse = await Service.fetch(ServiceList.atexInspection.historyGet, null, null, {
			uuid: this.inspection.uuid,
			historyId: historyId
		}, Session.session);
		const selectedInspection: AtexInspection = AtexInspection.parse(selectedEntryResponse.response.history);
		selectedInspection.assetUuid = this.inspection.assetUuid;

		const buttons = [
			{
				success: true,
				label: 'restore',
				color: 'primary',
				callback: async(object) => {
					await this.restoreToHistory(selectedInspection);
				}
			},
			{success: false, label: 'close', color: 'primary'}
		];

		let formLayout: UnoFormField[] = [];

		if (selectedInspection.status === AtexInspectionStatus.REJECTED) {
			formLayout = formLayout.concat(AtexInspectionLayouts.rejectedMessage);
		}

		if (selectedInspection.status === AtexInspectionStatus.TODO_SUPERVISION || selectedInspection.status === AtexInspectionStatus.REVIEW_SUPERVISION) {
			formLayout = formLayout.concat(AtexInspectionLayouts.supervisorMessage);
		}

		formLayout = formLayout.concat(AtexInspectionLayouts.inspectionHistory);
		formLayout = formLayout.concat(this.inspectionSelectionFormLayout);

		const inspectorFields: UnoFormField[] = await AtexInspectionUtils.buildForm(selectedInspection, AtexInspectionForm.INSPECTOR, this.asset);
		if (inspectorFields.length > 0) {
			formLayout.push({
				label: 'inspector',
				type: UnoFormFieldTypes.SUB_FORM,
				attribute: 'data.responses.inspector',
				expanded: true,
				fields: inspectorFields
			});
		}

		if (selectedInspection.status === AtexInspectionStatus.REVIEW || selectedInspection.status === AtexInspectionStatus.COMPLETED) {
			const backofficeFields: UnoFormField[] = await AtexInspectionUtils.buildForm(selectedInspection, AtexInspectionForm.BACKOFFICE, this.asset);
			if (backofficeFields.length > 0) {
				formLayout.push({
					label: 'backoffice',
					type: UnoFormFieldTypes.SUB_FORM,
					attribute: 'data.responses.backoffice',
					expanded: true,
					fields: backofficeFields
				});
			}
		}

		Modal.form(Locale.get('history'), selectedInspection, formLayout, buttons, false);
	}

	/**
	 * Restore the Atex inspection to a previous version, creating a new entry in the history.
	 * 
	 * @param inspection - previous version of the Atex inspection being restored to.
	 */
	public async restoreToHistory(inspection: AtexInspection): Promise<void> {
		// Revert to previous version
		const reverted: AtexInspection = structuredClone(this.inspection);
		reverted.data = inspection.data;
		reverted.result = inspection.result;
		reverted.resultFinal = inspection.resultFinal;
		reverted.status = inspection.status;

		// Store the change and update UI.
		await this.update(reverted, true);
		await this.loadData(reverted.uuid);
		Modal.toast(Locale.get('restoreSuccessful'));
	}

	/**
	 * Generate inspection selection form layout.
	 */
	public async buildInspectionSelectionForm(): Promise<void> {
		this.checklist = await AtexInspectionChecklistService.get();

		this.inspectionSelectionFormLayout = [{
			label: 'inspections',
			type: UnoFormFieldTypes.TITLE
		}];

		// Fill inspection selection
		for (const attr in this.checklist) {
			this.inspectionSelectionFormLayout.push({
				label: Locale.get('inspection') + ' ' + this.checklist[attr].name,
				attribute: 'data.inspections.' + attr,
				type: UnoFormFieldTypes.CHECKBOX
			});
		}
	}

	/**
	 * Indicates if the user has permissions to edit the inspection.
	 */
	public canEditInspection(): boolean {
		return Session.hasPermissions([UserPermissions.ATEX_INSPECTION_EDIT]) ||
			this.inspection.status === AtexInspectionStatus.TODO && Session.hasPermissions([UserPermissions.ATEX_INSPECTION_INSPECT]) ||
			this.inspection.status === AtexInspectionStatus.TODO_SUPERVISION && Session.hasPermissions([UserPermissions.ATEX_INSPECTION_INSPECT_SUPERVISOR]) ||
			this.inspection.status === AtexInspectionStatus.REVIEW && Session.hasPermissions([UserPermissions.ATEX_INSPECTION_REVIEW]);
	}

	/**
	 * Load inspection data and inspection asset data.
	 * 
	 * @param inspectionUuid - The UUID of the inspection to load the data for.
	 */
	public async loadData(inspectionUuid: UUID): Promise<void> {
		// Load inspection data.
		this.inspection = await AtexInspectionService.get(inspectionUuid);

		// Load asset data
		this.asset = await AssetService.get(this.inspection.assetUuid);

		await this.buildInspectionForms();
	}

	/**
	 * Export inspection report as a Docx file, using a template file.
	 */
	public async exportReportDOCX(): Promise<void> {
		Loading.show();

		try {
			const report = await AtexInspectionReport.generateDocx(this.inspection, this.asset);
			FileUtils.writeFileArrayBuffer(this.inspection.uuid + '.docx', report);
			Modal.toast(Locale.get('reportGeneratedSuccessfully'));
		} catch (e) {
			Modal.alert(Locale.get('error'), Locale.get('errorGeneratingReportDetails', {details: e}));
		} finally {
			Loading.hide();
		}
	}

	/**
	 * Generate a DOCX report with the inspection data. Send it to the file conversion server to convert from DOCX to PDF and export report file on PDF format.
	 */
	public async exportReportPDF(): Promise<void> {
		Loading.show();

		try {
			const report = await AtexInspectionReport.generateDocx(this.inspection, this.asset);
			const form = new FormData();
			form.append('file', new Blob([report]), this.inspection.uuid + '.docx');

			const request = await Service.fetch(ServiceList.fileConverter.docxToPdf, null, null, form, null);
			FileUtils.writeFileArrayBuffer(this.inspection.uuid + '.pdf', request.response);
			Modal.toast(Locale.get('reportGeneratedSuccessfully'));
		} catch (e) {
			Modal.alert(Locale.get('error'), Locale.get('errorGeneratingReportDetails', {details: e}));
		} finally {
			Loading.hide();
		}
	}

	/**
	 * Save the state of the inspection as it is to be continued later. If the inspection is COMPLETED, update the inspection data.
	 *
	 * @param overrideStatus - If specified the status of the inspection will be overwritten.
	 * @param stayOnPage - If true it stays on the page after update.
	 */
	public async save(overrideStatus?: number, stayOnPage: boolean = false): Promise<void> {
		// If inspection is already completed perform additional checks
		if (this.inspection.status === AtexInspectionStatus.COMPLETED) {
			// Ensure that there is a inspection form selected
			if (overrideStatus !== AtexInspectionStatus.TODO && overrideStatus !== AtexInspectionStatus.REJECTED && !this.inspection.hasForm()) {
				Modal.alert(Locale.get('error'), Locale.get('errorSelectForm'));
				return;
			}

			// Ensure that all fields are filled in
			if (!this.resultForm.requiredFilled() || this.inspection.hasForm() && (!this.inspectionForm.requiredFilled() || !this.backofficeForm.requiredFilled())) {
				Modal.alert(Locale.get('error'), Locale.get('requiredFieldsError'));
				return;
			}
		}

		const inspection = structuredClone(this.inspection);

		// Override the status of the inspection
		if (overrideStatus !== undefined) {
			inspection.status = overrideStatus;
		}

		await this.update(inspection, stayOnPage);
	}

	/**
	 * Update inspection to the status for revision on the BACKOFFICE and update inspection.
	 */
	public async submit(): Promise<void> {

		// Check if all forms are filled.
		if (this.inspection.hasForm()) {
			if (!this.inspectionForm.requiredFilled()) {
				Modal.alert(Locale.get('error'), Locale.get('requiredFieldsError'));
				return;
			}				
		} else {
			Modal.alert(Locale.get('error'), Locale.get('errorSelectForm'));
			return;
		}

		// Check the inspection result
		if (this.inspection.result === AtexInspectionResult.NONE) {
			Modal.alert(Locale.get('error'), Locale.get('errorInspectionResult'));
			return;
		}

		// If result is APPROVED, check for Not Oks in the inspector responses and prompt user to confirm them
		if (this.inspection.result === AtexInspectionResult.APPROVED) {
			const fields = Object.values(this.inspection.data.responses.inspector);
			for (let i = 0; i < fields.length; i++) {
				if (!fields[i].notApplicable && fields[i].result === AtexInspectionFieldResult.NOK) {
					const confirm = await Modal.confirm(Locale.get('confirm'), Locale.get('warningApproveNok'));
					if (!confirm) {
						return;
					}
					break;
				}
			}
		}

		// Send inspection data to the API.
		const inspection = structuredClone(this.inspection);
		inspection.status = AtexInspectionStatus.REVIEW;

		await this.update(inspection);
	}

	/**
	 * Approve the inspection on backoffice and update inspection on API.
	 */
	public async approve(): Promise<void> {
		if (!this.resultForm.requiredFilled() || this.inspection.hasForm() && !this.backofficeForm.requiredFilled()) {
			Modal.alert(Locale.get('error'), Locale.get('requiredFieldsError'));
			return;
		}

		// If resultFinal is APPROVED, check for Not Ok in the backoffice responses
		if (this.inspection.resultFinal === AtexInspectionResult.APPROVED || this.inspection.resultFinal === AtexInspectionResult.CONDITIONALLY_APPROVED) {
			const fields = Object.values(this.inspection.data.responses.backoffice);
			for (let i = 0; i < fields.length; i++) {
				if (!fields[i].notApplicable && fields[i].result === AtexInspectionFieldResult.NOK) {
					const confirm = await Modal.confirm(Locale.get('confirm'), Locale.get('warningApproveNok'));
					if (!confirm) {
						return;
					}
					break;
				}
			}
		}

		const inspection = structuredClone(this.inspection);
		inspection.status = AtexInspectionStatus.COMPLETED;

		await this.update(inspection);
	}

	/**
	 * Reject the inspection and send to the API, move it to the TO DO list again.
	 *
	 * The user has to input a message as justification for the rejection.
	 */
	public async reject(): Promise<void> {
		const result = await Modal.prompt(Locale.get('confirm'), [{name: 'message', placeholder: Locale.get('messageRejection'), type: 'text'}]);
		if (result.confirm) {
			// Update status of the inspection and store the message to the inspector
			const inspection = structuredClone(this.inspection);
			inspection.data.rejectionMessage = result.data.message;
			inspection.status = AtexInspectionStatus.REJECTED;
			inspection.data.rejectedCount++;

			await this.update(inspection);
		}
	}

	/**
	 * Send inspection data to the supervision process.
	 *
	 * The supervisor will verify everything so, there is no need for the data to be fully filled.
	 */
	public async sendSupervision(): Promise<void> {
		const result = await Modal.prompt(Locale.get('confirm'), [{name: 'message', placeholder: Locale.get('messageSupervisor'), type: 'text'}]);
		if (result.confirm) {
			// Store the supervisor message in the inspection
			const inspection = structuredClone(this.inspection);
			inspection.data.supervisorMessage = result.data.message;
			if (inspection.status === AtexInspectionStatus.TODO || inspection.status === AtexInspectionStatus.REJECTED) {
				inspection.status = AtexInspectionStatus.TODO_SUPERVISION;
			} else if (inspection.status === AtexInspectionStatus.REVIEW) {
				inspection.status = AtexInspectionStatus.REVIEW_SUPERVISION;
			}

			await this.update(inspection);
		}
	}

	/**
	 * Update the inspection.
	 *
	 * @param inspection - Inspection object data to send to the API.
	 * @param stayOnPage - If false, page is redirected to the previous page on navigator routes stack.
	 */
	public async update(inspection: AtexInspection, stayOnPage: boolean = false): Promise<void> {
		const responses: AtexInspectionResponses = {
			inspector: {},
			backoffice: {}
		};

		// Store only the fields of the checklist in use for this inspection
		for (const attr in this.checklist) {
			if (inspection.data.inspections[attr]) {
				for (const f in this.checklist[attr].fields) {
					const fieldName: string = this.checklist[attr].fields[f];

					if (inspection.data.responses.inspector[fieldName]) {
						responses.inspector[fieldName] = inspection.data.responses.inspector[fieldName];
					}

					if (inspection.data.responses.backoffice[fieldName]) {
						responses.backoffice[fieldName] = inspection.data.responses.backoffice[fieldName];
					}
				}
			}
		}

		inspection.data.responses = responses;

		await Service.fetch(ServiceList.atexInspection.update, null, null, inspection, Session.session);

		if (this.assetEdited && Session.hasPermissions([UserPermissions.ASSET_PORTFOLIO_ASSET_EDIT])) {
			await Service.fetch(ServiceList.assetPortfolio.asset.update, null, null, this.asset, Session.session);
		}

		Modal.toast(Locale.get('successInspection'));

		if (!stayOnPage) {
			App.navigator.pop();
			return;
		}

		// Every time an update occurs, there is a new history change entry.
		await AtexInspectionService.listHistoryUsers(inspection.uuid);
	}

	/**
	 * Delete inspection from the API.
	 *
	 * This action cannot be reversed. User is prompted to confirm the action.
	 */
	public async delete(): Promise<void> {
		const confirm = await Modal.confirm(Locale.get('confirm'), Locale.get('confirmDelete'));
		if (confirm) {
			await Service.fetch(ServiceList.atexInspection.delete, null, null, this.inspection, Session.session);
			Modal.toast(Locale.get('deleteSuccessfully'));
			App.navigator.pop();
		}
	}
}
