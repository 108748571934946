import {UnoFormFieldTypes} from '../../../components/uno-forms/uno-dynamic-form/uno-form-field-types';
import {UnoFormField} from '../../../components/uno-forms/uno-dynamic-form/uno-form-field';

export const PrivacyPolicyLayout: UnoFormField[] = [
	{
		attribute: 'date',
		label: 'date',
		editable: false,
		type: UnoFormFieldTypes.DATE
	},
	{
		attribute: 'version',
		label: 'version',
		editable: false,
		type: UnoFormFieldTypes.NUMBER
	},
	{
		attribute: 'data',
		label: 'data',
		type: UnoFormFieldTypes.TEXT_MULTILINE
	}
];
