import {Component, OnInit} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';

import {UnoDynamicFormComponent} from '../../../../../../components/uno-forms/uno-dynamic-form/uno-dynamic-form.component';
import {App} from '../../../../../../app';
import {Session} from '../../../../../../session';
import {FileUtils} from '../../../../../../utils/file-utils';
import {Service} from '../../../../../../http/service';
import {ServiceList} from '../../../../../../http/service-list';
import {Locale} from '../../../../../../locale/locale';
import {Modal} from '../../../../../../modal';
import {ScreenComponent} from '../../../../../../components/screen/screen.component';
import {Environment} from '../../../../../../../environments/environment';
import {UserPermissions} from '../../../../../../models/users/user-permissions';
import {UnoFormField} from '../../../../../../components/uno-forms/uno-dynamic-form/uno-form-field';
import {UnoFormFieldTypes} from '../../../../../../components/uno-forms/uno-dynamic-form/uno-form-field-types';
import {UnoFormUtils} from '../../../../../../components/uno-forms/uno-dynamic-form/uno-form-utils';
import {UnoDynamicFormModule} from '../../../../../../components/uno-forms/uno-dynamic-form.module';
import {FileReaderAsync} from '../../../../../../utils/file-reader-async';
import {ObjectKeysPipe} from '../../../../../../pipes/object-keys.pipe';
import {UnoButtonComponent} from '../../../../../../components/uno/uno-button/uno-button.component';
import {UnoTitleComponent} from '../../../../../../components/uno/uno-title/uno-title.component';
import {UnoContentComponent} from '../../../../../../components/uno/uno-content/uno-content.component';
import {AtexInspectionFieldsService} from '../../../services/atex-inspection-fields.service';
import {AtexInspectionChecklistService} from '../../../services/atex-inspection-checklist.service';
import {AtexInspectionFormField} from '../../../data/master-data/atex-inspection-fields';

@Component({
	selector: 'masterdata-atex-inspections-checklist-page',
	templateUrl: 'atex-inspections-checklist.page.html',
	standalone: true,
	imports: [UnoContentComponent, UnoTitleComponent, UnoDynamicFormModule, UnoButtonComponent, TranslateModule, ObjectKeysPipe]
})
export class AtexInspectionsChecklistPage extends ScreenComponent implements OnInit {
	public get app(): any { return App; }

	public get session(): any { return Session; }

	public permissions = [UserPermissions.ATEX_INSPECTION_CHECKLIST_EDIT];

	/**
	 * Checklist data being edited, contains all checklist fields that compose the inspection forms.
	 */
	public data: any = null;

	/**
	 * Master data inspection fields used for validation.
	 */
	public fields: {[key: string]: AtexInspectionFormField} = {};

	public layout: UnoFormField[] = [
		{
			label: 'name',
			attribute: 'name',
			type: UnoFormFieldTypes.TEXT
		},
		{
			label: 'fields',
			attribute: 'fields',
			type: UnoFormFieldTypes.OPTIONS_MULTIPLE,
			required: true,
			options: []
		}
	];

	public async ngOnInit(): Promise<void> {
		super.ngOnInit();
		
		App.navigator.setTitle('inspectionChecklists');

		this.fields = await AtexInspectionFieldsService.get();
		this.data = await AtexInspectionChecklistService.get();

		const options = [];
		for (const i in this.fields) {
			const attr = this.fields[i].attribute;
			options.push({label: attr, value: attr});
		}

		UnoFormUtils.getFormFieldByAttribute(this.layout, 'fields').options = options;
	}

	/**
	 * Import data from JSON file.
	 */
	public async import(): Promise<void> {
		const files = await FileUtils.chooseFile('.json');
		if (files.length > 0) {
			const result = await FileReaderAsync.readAsText(files[0]);
			try {
				// @ts-ignore
				const data = JSON.parse(result);

				if (this.validate(data)) {
					this.data = data;
				} else {
					if (!Environment.PRODUCTION) {
						console.log('EQS: Validation of data imported failed.', this.data);
					}

					Modal.alert(Locale.get('error'), Locale.get('errorImport'));
				}
			} catch (e) {
				Modal.alert(Locale.get('error'), Locale.get('errorImport'));
			}
		}
	}

	/**
	 * Export checklist as JSON file.
	 */
	public export(): void {
		if (this.validate(this.data)) {
			FileUtils.writeFile('inspections-checklist.json', JSON.stringify(this.data, null, '\t'));
		}
	}

	/**
	 * Validate the field data before submitting it to the API.
	 */
	public validate(data: any): boolean {
		for (const i in data) {
			if (!UnoDynamicFormComponent.requiredFilled(this.layout, data[i])) {
				Modal.alert(Locale.get('error'), Locale.get('requiredFieldsError'));
				return false;
			}

			for (let j = 0; j < data[i].length; j++) {
				if (!this.fields[data[i][j]]) {
					Modal.alert(Locale.get('error'), Locale.get('errorFieldInspectionMissing', {field: data[i][j], inspection: i}));
					return false;
				}
			}

		}

		return true;
	}

	/**
	 * Update the checklist value in the server.
	 */
	public async save(stayOnPage: boolean = false): Promise<void> {
		if (!this.validate(this.data)) {
			return;
		}

		const confirm = await Modal.confirm(Locale.get('confirm'), Locale.get('masterdataConfirm'));
		if (confirm) {
			await Service.fetch(ServiceList.atexInspection.checklistsUpdate, null, null, this.data, Session.session);

			Modal.toast(Locale.get('updatedSuccessfully'));

			if (!stayOnPage) {
				App.navigator.pop();
			}
		}
	}
}
