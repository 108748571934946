/**
 * Result of the EPI inspection.
 */
export const EPIResult = {
	NONE: 0,
	APPROVED: 1,
	FAILED: 2,
	CONDITIONALLY_APPROVED: 3
};

export const EPIResultLabel: Map<number, string> = new Map([
	[EPIResult.NONE, 'none'],
	[EPIResult.APPROVED, 'approved'],
	[EPIResult.FAILED, 'nonCompliant'],
	[EPIResult.CONDITIONALLY_APPROVED, 'conditionallyApproved']
]);
