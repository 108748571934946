import {Resource} from '../resource';
import {UUID, UUIDIdentification} from '../uuid';

/**
 * Asset type is used to define assets relations and to store page of information relative a specific asset type.
 */
export class APAssetType extends UUIDIdentification {
	/**
	 * Name of the asset type. This name must be unique.
	 */
	public name: string = '';

	/**
	 * Description of the asset type.
	 */
	public description: string = '';

	/**
	 * The picture used to represent this asset type
	 */
	public picture: Resource = null;

	/**
	 * Defines the parents that an asset is allowed to have.
	 */
	public allowedParentTypesUuids: UUID[] = [];

	/**
	 * Allows assets of an asset type to have no asset parent set.
	 */
	public allowedOnRoot: boolean = true;

	/**
	 * Parse JSON data received from the API into an object of the correct type.
	 *
	 * @param data - Object received from API.
	 * @returns APAssetType object instance built from the data received.
	 */
	public static parse(data: any): APAssetType {
		const type = new APAssetType();

		type.uuid = data.uuid;
		type.updatedAt = new Date(data.updatedAt);
		type.createdAt = new Date(data.createdAt);

		type.name = data.name;
		type.description = data.description;
		type.picture = data.picture;

		type.allowedParentTypesUuids = data.allowedParentTypesUuids ? data.allowedParentTypesUuids : [];
		type.allowedOnRoot = data.allowedOnRoot;

		return type;
	}
}
