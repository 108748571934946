@if (this.appearance !== null) {
	<uno-content [borders]="true">
		<uno-dynamic-form [layout]="this.appearanceLayout" [object]="this.appearance" #appearanceForm></uno-dynamic-form>
		<div style="display: flex; flex-direction: row-reverse; gap: 5px;" class="ion-padding">
			<uno-button (click)="this.update();">{{('save') | translate}}</uno-button>
			<uno-button (click)="this.update(true);">{{'update' | translate}}</uno-button>
			<uno-button (click)="this.reset();">{{'reset' | translate}}</uno-button>
		</div>
	</uno-content>
}
