import {Component, OnInit, ViewChild} from '@angular/core';
import {SortDirection} from 'src/app/utils/sort-direction';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {NgStyle} from '@angular/common';
import {UnoFilterBarComponent, UnoFilterBarOption, UnoFilterBarOptionType} from 'src/app/components/uno/uno-filter-bar/uno-filter-bar.component';
import {UnoResponsiveTableListComponent} from 'src/app/components/uno/uno-responsive-table-list/uno-responsive-table-list.component';
import {UnoTableColumnLayout, UnoTableColumnType} from 'src/app/components/uno/uno-table/uno-table.component';
import {Locale} from 'src/app/locale/locale';
import {App} from '../../../../app';
import {ScreenComponent} from '../../../../components/screen/screen.component';
import {EPIBarrierTypeLabel} from '../../../../models/epi/epi-barrier';
import {EPIState} from '../../../../models/epi/epi-state';
import {EPIResultLabel} from '../../../../models/epi/epi-result';
import {FormatDatePipe} from '../../../../pipes/format-date.pipe';
import {UnoListComponent} from '../../../../components/uno/uno-list/uno-list-component';
import {UnoContentComponent} from '../../../../components/uno/uno-content/uno-content.component';
import {UnoListItemLabelComponent} from '../../../../components/uno/uno-list-item/uno-list-item-label.component';
import {UnoListItemIconComponent} from '../../../../components/uno/uno-list-item/uno-list-item-icon.component';
import {UnoListItemComponent} from '../../../../components/uno/uno-list-item/uno-list-item.component';
import {UnoSearchbarComponent} from '../../../../components/uno/uno-searchbar/uno-searchbar.component';
import {UnoButtonComponent} from '../../../../components/uno/uno-button/uno-button.component';
import {ResourceUtils} from '../../../../utils/resource-utils';
import {EPIListParams, EPIService} from '../../services/epi.service';

@Component({
	selector: 'epi-list-page',
	templateUrl: 'epi-list.page.html',
	standalone: true,
	imports: [
		UnoButtonComponent,
		UnoSearchbarComponent,
		IonicModule,
		FormsModule,
		UnoListItemComponent,
		UnoListItemIconComponent,
		UnoListItemLabelComponent,
		UnoContentComponent,
		NgStyle,
		UnoListComponent,
		TranslateModule,
		FormatDatePipe,
		UnoFilterBarComponent,
		UnoResponsiveTableListComponent
	]
})
export class EPIListPage extends ScreenComponent implements OnInit {

	@ViewChild(UnoResponsiveTableListComponent) 
	public table: UnoResponsiveTableListComponent;

	public get app(): any { return App; }

	public get epiState(): any { return EPIState; }

	public get selfStatic(): any { return EPIListPage; }

	/**
	 * State of the EPI being listed in this screen.
	 */
	public state: number = 0;

	/**
	 * The maximum number of items to show on table component.
	 */
	public totalItems: number = 1;

	/**
 	 * The number of items to show on table per page.
 	 */
	public tablePageSize: number = 30;

	/**
 	 * The layout to use on the table.
 	 */
	public layout: UnoTableColumnLayout[] = [
		{header: 'diagram', type: UnoTableColumnType.IMAGE, attribute: 'image', visible: this.headerActive('image'), size: 'small'},
		{header: 'barrierId', type: UnoTableColumnType.TEXT, attribute: 'barrierId', visible: this.headerActive('barrierId'), size: 'small', sortBy: '[epi].[barrier_id]'},
		{header: 'name', type: UnoTableColumnType.TEXT, attribute: 'name', visible: this.headerActive('name'), size: 'small', sortBy: '[epi].[name]'},
		{header: 'description', type: UnoTableColumnType.TEXT, attribute: 'description', visible: this.headerActive('description'), size: 'small', sortBy: '[epi].[description]'},
		{header: 'barrierType', type: UnoTableColumnType.TEXT, attribute: 'barrierType', visible: this.headerActive('barrierType'), size: 'small', sortBy: '[epi].[barrier_type]'},
		{header: 'result', type: UnoTableColumnType.TEXT, attribute: 'result', visible: this.headerActive('result'), size: 'small', sortBy: '[epi].[result]'},
		{header: 'createdAt', type: UnoTableColumnType.TEXT, attribute: 'createdAt', visible: this.headerActive('createdAt'), size: 'small', sortBy: '[epi].[created_at]'},
		{header: 'updatedAt', type: UnoTableColumnType.TEXT, attribute: 'updatedAt', visible: this.headerActive('updatedAt'), size: 'small', sortBy: '[epi].[updated_at]'},
		{
			header: 'actions',
			type: UnoTableColumnType.ICONS,
			attribute: 'actions',
			visible: true,
			size: 'small',
			icons:
			[
				{
					src: './assets/icons/assets/expand-icon.svg',
					click: (row): void => {
						App.openInTab('/menu/epi/edit', {uuid: row.uuid.value});
					}
				}
			]
		}
	];

	/**
	 * Table filters for the EPI list.
	 */
	public static filterOptions: UnoFilterBarOption[] = [
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'sortDirection',
			label: 'direction',
			default: SortDirection.DESC,
			options: [
				{label: 'asc', value: SortDirection.ASC},
				{label: 'desc', value: SortDirection.DESC}
			]
		},
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'sortField',
			label: 'sortField',
			default: '[epi].[updated_at]',
			options: [
				{label: 'barrierId', value: '[epi].[barrier_id]'},
				{label: 'createdAt', value: '[epi].[created_at]'},
				{label: 'updatedAt', value: '[epi].[updated_at]'},
				{label: 'description', value: '[epi].[description]'},
				{label: 'barrierType', value: '[epi].[barrier_type]'},
				{label: 'name', value: '[epi].[name]'},
				{label: 'result', value: '[epi].[result]'}
			]
		},
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'searchFields',
			label: 'searchFields',
			default: ['[epi].[name]', '[epi].[tag]', '[epi].[description]'],
			multiple: true,
			options: [
				{label: 'name', value: '[epi].[name]'},
				{label: 'barrierId', value: '[epi].[barrier_id]'},
				{label: 'uuid', value: '[epi].[id]'},
				{label: 'tag', value: '[epi].[tag]'},
				{label: 'description', value: '[epi].[description]'}
			]
		},
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'tableFields',
			label: 'tableFields',
			multiple: true,
			default: ['image', 'updatedAt', 'name', 'description', 'barrierType', 'result', 'actions'],
			options: [
				{label: 'image', value: 'image'},
				{label: 'barrierId', value: 'barrierId'},
				{label: 'createdAt', value: 'createdAt'},
				{label: 'updatedAt', value: 'updatedAt'},
				{label: 'name', value: 'name'},
				{label: 'description', value: 'description'},
				{label: 'barrierType', value: 'barrierType'},
				{label: 'result', value: 'result'},
				{label: 'actions', value: 'actions'}
			]
		}
	];

	public static filters = UnoFilterBarComponent.reset({
		/**
		 * Sort direction applied to the loaded list from database.
		 */
		sortDirection: '',

		/**
		 * Database attribute name used to sort the values.
		 */
		sortField: '',

		/**
		 * Text used to filter EPIs by their name
		 */
		search: '',

		/**
		 * Search fields to be considered.
		 */
		searchFields: [],

		/**
		 * Table fields to be shown.
		 */
		tableFields: []
	}, EPIListPage.filterOptions);

	public loadMore = async(count: number, pageSize: number): Promise<any> => {

		const params: EPIListParams = {
			state: this.state,
			from: count,
			count: pageSize,
			search: EPIListPage.filters.search,
			sortDirection: EPIListPage.filters.sortDirection,
			sortField: EPIListPage.filters.sortField,
			searchFields: EPIListPage.filters.searchFields
		};

		const list = await EPIService.list(params);

		const epis: any[] = list.epi;

		for (const epi of epis) {
			epi.image = epi.diagram;
			epi.createdAt = new Date(epi.createdAt).toLocaleDateString(Locale.code, {year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric'});
			epi.updatedAt = new Date(epi.updatedAt).toLocaleDateString(Locale.code, {year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric'});
			epi.result = Locale.get(EPIResultLabel.get(epi.result));
			epi.barrierType = Locale.get(EPIBarrierTypeLabel.get(epi.barrierType));
		}

		return {
			elements: list.epi,
			hasMore: list.hasMore
		};
	};

	public ngOnInit(): void {
		super.ngOnInit();

		// Read state from the route data
		const data = App.navigator.getData();
		if (!data) {
			App.navigator.pop();
			return;
		}

		this.state = data?.state !== undefined ? data.state : EPIState.ALL;

		App.navigator.setTitle('epi');
	}

	public resetFilters(): void {
		Object.assign(EPIListPage.filters, EPIListPage.defaultFilters);

		this.table.reset();
	}

	/**
	 * Update filters and reload data from the API.
	 *
	 * @param event - DOM event.
	 */
	public async onFilterChange(event: any): Promise<void> {
		EPIListPage.filters = event;

		for (const column of this.layout) {
			column.visible = EPIListPage.filters.tableFields.indexOf(column.attribute) !== -1;
		};

		this.table.sortDirection = EPIListPage.filters.sortDirection;
		this.table.sortField = EPIListPage.filters.sortField;

		this.totalItems = await EPIService.count({
			state: this.state,
			search: EPIListPage.filters.search,
			searchFields: EPIListPage.filters.searchFields
		});

		this.table.reset();
	}

	/**
	 * Update the search term used.
	 *
	 * @param event - DOM event.
	 */
	public async onSearch(event: any): Promise<void> {
		EPIListPage.filters.search = event;
		this.totalItems = await EPIService.count({
			state: this.state,
			search: EPIListPage.filters.search,
			searchFields: EPIListPage.filters.searchFields
		});
		this.table.reset();
	}

	/**
	 * Check if a attribute is supposed to be visible or not.
	 * 
	 * @param attribute - The attribute to look for.
	 * @returns True if the attribute is in the tableFields filter.
	 */
	public headerActive(attribute: string): boolean {
		return EPIListPage.filters.tableFields.indexOf(attribute) !== -1;
	}

	/**
	 * Change the sort direction/field used in the api list.
	 * 
	 * @param sortBy - Attribute to sort by.
	 */
	public sortChanged(sortBy: string): void {
		// If the attribute is already the current one, change the sort direction.
		if (sortBy === EPIListPage.filters.sortField) {
			EPIListPage.filters.sortDirection = this.table.sortDirection;
		} else {
			EPIListPage.filters.sortField = sortBy;
			EPIListPage.filters.sortDirection = SortDirection.ASC;
		}

		this.table.reset();
	}

	protected readonly ResourceUtils = ResourceUtils;
}
