/**
 * Class to represent a time section between two dates.
 */
export class DateRange {
	/**
	 * Initial date of the range.
	 */
	public from: Date = null;

	/**
	 * Final date of the range.
	 */
	public to: Date = null;

	public constructor(from?: Date, to?: Date) {
		if (from) {
			this.from = from;
		}

		if (to) {
			this.to = to;
		}
	}

	/**
	 * Set date range from two dates.
	 *
	 * Checks if they are ISO 8601 and tries to parse them if so.
	 */
	public set(from: Date, to: Date): void {
		if (typeof from === 'string') {
			from = new Date(from);
		}

		if (typeof to === 'string') {
			to = new Date(to);
		}

		this.from = from;
		this.to = to;
	}

	/**
	 * Get a sub range inside of the this date range.
	 *
	 * The values received are percentage of the global range from 0 to 1.
	 *
	 * @returns Sub-time section inside of the time range accordingly to the start and end points provided.
	 */
	public subRange(start: number, end: number): DateRange {
		const from = this.from.getTime();
		const diff = this.to.getTime() - from;

		const sub = new DateRange(new Date(), new Date());
		sub.from.setTime(from + diff * start);
		sub.to.setTime(from + diff * end);

		return sub;
	}
}
