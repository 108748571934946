import {UUID, UUIDIdentification} from '../../uuid';
import {InspectionReportTemplate} from './inspection-report-template';

/**
 * A inspection project contains inspections that use the same workflow.
 *
 * Its possible to have multiple projects reusing the workflows.
 */
export class InspectionProject extends UUIDIdentification {
	/**
	 * Name of the project.
	 */
	public name: string = '';

	/**
	 * Inspection project description.
	 */
	public description: string = '';

	/**
	 * Indicate if the project should be displayed in the main menu.
	 */
	public showInMenu: boolean = false;

	/**
	 * Indicates if an asset is required when creating new entries in the project.
	 */
	public requireAsset: boolean = true;

	/**
	 * Workflow to be used in this inspection project.
	 */
	public workflowUuid: UUID = null;

	/**
	 * Team assigned to this inspection project.
	 */
	public teamUuid: UUID = null;

	/**
	 * Report template used to generate reports.
	 */
	public reports: InspectionReportTemplate[] = [];

	/**
	 * Indicates if the inspection project generates gaps.
	 */
	public generatesGaps: boolean = false;

	/**
	 * RBAC list of allowed roles
	 */
	public rbacMasterAllowedList: UUID[] = [];

	/**
	 * RBAC list of denied roles
	 */
	public rbacMasterDeniedList: UUID[] = [];

	/**
	 * RBAC list of allowed roles
	 */
	public rbacAllowedList: UUID[] = [];

	/**
	 * RBAC list of denied roles
	 */
	public rbacDeniedList: UUID[] = [];

	/**
	 * Parse inspection project data to create a InspectionProject object.
	 *
	 * @param data - Data to be parsed.
	 * @returns Object of the correct type.
	 */
	public static parse(data: any): InspectionProject {
		const project = new InspectionProject();

		project.uuid = data.uuid;
		project.createdAt = new Date(data.createdAt);
		project.updatedAt = new Date(data.updatedAt);

		project.name = data.name;
		project.description = data.description;
		project.showInMenu = data.showInMenu === true;
		project.requireAsset = data.requireAsset !== false;
		project.workflowUuid = data.workflowUuid;
		project.teamUuid = data.teamUuid;
		project.generatesGaps = data.generatesGaps;

		project.reports = [];
		if (data.reports instanceof Array) {
			for (let i = 0; i < data.reports.length; i++) {
				project.reports.push(InspectionReportTemplate.parse(data.reports[i]));
			}
		}


		project.rbacMasterAllowedList = data.rbacMasterAllowedList || [];
		project.rbacMasterDeniedList = data.rbacMasterDeniedList || [];

		project.rbacAllowedList = data.rbacAllowedList || [];
		project.rbacDeniedList = data.rbacDeniedList || [];
		
		return project;
	}
}
