import {Service} from 'src/app/http/service';
import {ServiceList} from 'src/app/http/service-list';
import {LDS} from 'src/app/models/pipeline-integrity/leak-detection/lds';
import {UUID} from 'src/app/models/uuid';
import {Session} from 'src/app/session';

// LDS list API request format.
export type LDSListParams = {
	// Pipeline UUID to get the LDS from.
	pipelineUuid?: UUID,
	filters?: {
		// Search value to apply
		search?: string,
		// Fields to search by
		searchFields?: string[],
		// From where to start fetching items
		offset?: number,
		// How many items to fetch
		count?: number,
		// Sort Direction to apply on the response
		sortDirection?: string,
		// Sort Field to apply on the response
		sortField?: string,
	}
}

// Params to apply on the LDS count.
export type LDSCountParams = {
	// Pipeline UUID to get the LDS from.
	pipelineUuid?: UUID,
	filters?: {
		// Search value to apply
		search?: string,
		// Fields to search by
		searchFields?: string[],
	}
}

// LDS list API response format.
export type LDSListResponse = {
	// If true, there are more items to fetch on the list.
	hasMore: boolean,
	// The fetched lds.
	lds: LDS[],
	// The id of the api call.
	id: number
};

export class LDSService {

	/**
	 * List all LDS that match the params.
	 *
	 * @param params - The parameters to apply on the fetch request.
	 */
	public static async list(params: LDSListParams): Promise<LDSListResponse> {
		const request = await Service.fetch(ServiceList.pipelineIntegrity.leakDetection.lds.list, null, null, params, Session.session);

		return {
			hasMore: request.response.hasMore,
			lds: request.response.lds.map((data: any) => {return LDS.parse(data);}),
			id: request.id
		};
	}

	/**
	 * Count all LDS that match the params.
	 *
	 * @param params - The parameters to apply on the fetch request.
	 */
	public static async count(params: LDSCountParams): Promise<number> {
		return (await Service.fetch(ServiceList.pipelineIntegrity.leakDetection.lds.count, null, null, params, Session.session)).response.count;
	}
}
