import {UUID} from '../../uuid';

/**
 * Data from a channel.
 */
export class ChannelData {

	/**
	 * Data is stored as X,Y values where X is the speed sound propagation and Y the voltage measured by the sensor. 
	 */
	public data: number[][] = [];

	/**
	 * UUID of the channel
	 */
	public channelUuid: UUID = null;

	/**
	 * Name of the channel.
	 */
	public name: string = '';

	/**
	 * Parse the data of an API retrieved pipeline object.
	 *
	 * @param data - Pipeline object to parse data
	 */
	public static parse(data: any): ChannelData {
		const channel = new ChannelData();

		channel.channelUuid = data.channelUuid;
		channel.data = data.data;
		channel.name = data.name ? data.name : '';
		
		return channel;
	}
}
