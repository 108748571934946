<uno-content [borders]="true">
	@if (this.project !== null) {
		<!-- Base Information -->
		<uno-dynamic-form [layout]="this.layout" [object]="this.project" #form></uno-dynamic-form>

		<!-- Save/Update -->
		<div style="display: flex; flex-direction: row-reverse; gap: 5px;" class="ion-padding">
			@if (this.createMode) {
				@if (session.hasPermissions([userPermissions.INSPECTION_PROJECT_CREATE])) {
					<uno-button (click)="this.update();">{{'create' | translate}}</uno-button>
				}
			}
			@if (!this.createMode) {
				@if (session.hasPermissions([userPermissions.INSPECTION_PROJECT_EDIT])) {
					<uno-button (click)="this.update();">{{'save' | translate}}</uno-button>
				}
				@if (session.hasPermissions([userPermissions.INSPECTION_PROJECT_EDIT])) {
					<uno-button (click)="this.update(true);">{{'update' | translate}}</uno-button>
				}
				@if (session.hasPermissions([userPermissions.INSPECTION_PROJECT_DELETE])) {
					<uno-button (click)="this.delete();" color="error">{{'delete' | translate}}</uno-button>
				}
			}
		</div>
	}
</uno-content>
