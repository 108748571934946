@if (this.object) {
	<div (dblclick)="this.showModal();" (click)="this.editor.select($any(this.object));" class="digital-twin-tree-object" (mouseenter)="this.hover = true;" (mouseleave)="this.hover = false;" [ngStyle]="{'padding-left': (this.level * 25) + 'px', 'background-color': this.hover || this.editor.isSelected($any(this.object)) ? 'var(--gray-10)' : ''}">
		<!-- Arrow -->
		<div style="width: 15px;">
			@if ($any(this.object).childrenCount > 0) {
				<uno-icon (click)="this.expand()" [src]="this.expanded ? 'assets/icons/3d/down-arrow.svg' : 'assets/icons/3d/right-arrow.svg'" [width]="15" [height]="15"></uno-icon>
			}
		</div>
		<!-- Icon -->
		<uno-icon color="contrast" [src]="typeIcon.get(this.object.type)" [width]="20" [height]="20"></uno-icon>
		<!-- Info -->
		<uno-text overflow="ellipsis" verticalAlignment="center" style="width: 100%; height: 100%;">
			{{this.object.name}}
		</uno-text>
		<!-- Asset Link -->
		@if ($any(this.object).assetUuid) {
			<uno-icon color="contrast" (click)="app.navigator.navigate('/menu/asset-portfolio/asset/edit', {uuid: $any(this.object).assetUuid});" [src]="'assets/icons/3d/page.svg'" [width]="18" [height]="18"></uno-icon>
		}
		<!-- Visibility -->
		<uno-icon color="contrast" [src]="this.object.visible ? 'assets/icons/3d/visible.svg' : 'assets/icons/3d/invisible.svg'" (click)="this.toggleVisibility(); " [width]="18" [height]="18"></uno-icon>
		<!-- Lock -->
		@if ($any(this.object).locked) {
			<uno-icon color="contrast" [src]="'assets/icons/3d/lock.svg'" [width]="18" [height]="18"></uno-icon>
		}
	</div>
	@if (this.expanded) {
		@for (child of this.object.children; track child) {
			@if ($any(child).isDigitalTwinObject) {
				<digital-twin-tree-object [editor]="this.editor" [object]="child" [level]="this.level + 1"></digital-twin-tree-object>
			}
		}
	}
}
