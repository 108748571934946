import JSZip from 'jszip';
import {AssetService} from '../../../asset-portfolio/services/asset.service';
import {Locale} from '../../../../locale/locale';
import {ProgressBar} from '../../../../progress-bar';
import {Service} from '../../../../http/service';
import {ServiceList} from '../../../../http/service-list';
import {Session} from '../../../../session';
import {Modal} from '../../../../modal';
import {UnoFormField} from '../../../../components/uno-forms/uno-dynamic-form/uno-form-field';
import {UnoFormFieldTypes} from '../../../../components/uno-forms/uno-dynamic-form/uno-form-field-types';
import {
	ReportTemplateFormat,
	ReportTemplateFormatLabel
} from '../../../../utils/report-template-format';
import {FileUtils} from '../../../../utils/file-utils';
import {MathUtils} from '../../../../utils/math-utils';
import {Repair} from '../../../../models/repairs/repairs/repair';
import {RepairInspectionStatus} from '../../../../models/repairs/inspections/repair-inspection-status';
import {RepairInspection} from '../../../../models/repairs/inspections/repair-inspection';
import {RepairService} from '../../repair-work/services/repair.service';
import {RepairInspectionReport} from './repair-inspection-report';

/**
 * Tools to export repair inspection data from the platform.
 */
export class RepairInspectionExport {
	/**
	 * Export reports for all repair inspections available in the platform.
	 */
	public static async exportReportsBulk(): Promise<void> {
		// Object with the needed layout attributes for the tool modal prompted to user
		const values = {
			// Export inspection reports formats
			formats: [ReportTemplateFormat.PDF]
		};

		const layout: UnoFormField[] = [
			{
				required: true,
				attribute: 'formats',
				label: 'formats',
				type: UnoFormFieldTypes.OPTIONS_MULTIPLE,
				sort: false,
				isEmpty: function(object) {
					return object.formats.length === 0;
				},
				options: Object.values(ReportTemplateFormat).map((value: number) => {
					return {
						label: ReportTemplateFormatLabel.get(value),
						value: value
					};
				})
			}
		];

		await Modal.form(Locale.get('exportInspectionsReportsBulk'), values, layout);

		let from: number = 0;
		const count: number = 100;

		// Keep every report to export in a single zip file
		const zipFile = new JSZip();

		const progress = new ProgressBar();
		progress.show();

		try {
			const total = (await Service.fetch(ServiceList.repairInspections.countStatus, null, null, {status: RepairInspectionStatus.ALL}, Session.session, true)).response.count;
			if (total > 0) {
				while (true) {
					progress.update(Locale.get('loadingData'), from / total);

					const request = await Service.fetch(ServiceList.repairInspections.listDetailed, null, null, {
						from: from,
						count: count
					}, Session.session, true);

					const inspections = request.response.inspections;

					for (let i = 0; i < inspections.length; i++) {

						progress.update(Locale.get('loadingData'), (from + i) / total);

						const inspection = RepairInspection.parse(inspections[i]);


						const repair: Repair = await RepairService.get(inspection.repairUuid);

						const asset = await AssetService.get(repair.asset);

						const date = inspection.date;
						const fname = `${asset.tag}_${date.getFullYear()}-${MathUtils.formatNumber(date.getMonth() + 1, 2, 0)}-${MathUtils.formatNumber(date.getDate(), 2, 0)}_${inspection.uuid}`;

						const doc = await RepairInspectionReport.generateReportDocx(inspection, repair, asset);

						// DOCX
						if (values.formats.includes(ReportTemplateFormat.DOCX)) {
							zipFile.file(fname + '.docx', doc);
						}

						// PDF
						if (values.formats.includes(ReportTemplateFormat.PDF)) {
							const form = new FormData();
							form.append('file', new Blob([doc]), inspection.uuid + '.docx');

							const requestPdf = await Service.fetch(ServiceList.fileConverter.docxToPdf, null, null, form, null, true);
							zipFile.file(fname + '.pdf', requestPdf.response);
						}

					}

					from += inspections.length;
					if (!request.response.hasMore) {
						break;
					}
				}
			} else {
				Modal.alert(Locale.get('warning'), Locale.get('noDataOnServer'));
			}
		} catch (e) {
			Modal.alert(Locale.get('error'), Locale.get('errorExport'));
			console.error('EQS: Error exporting file.', e);
		}

		progress.destroy();

		const data: ArrayBuffer = await zipFile.generateAsync({type: 'arraybuffer'});

		FileUtils.writeFileArrayBuffer('inspections_reports.zip', data);
	}
}
